import React from 'react';
import LateralMenu from '../LateralMenu';
import { faGear, faKey, faLock, faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';

export default function UserLateralMenu({ navigation, open, authenticatedUser, setOpen, selectedMenu }: LateralMenuProps) {
    return (
        <LateralMenu
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={[{
                icon: faGear,
                text: translate('account'),
                action: () => navigation.navigate('User', { screen: 'Account' }),
                selected: selectedMenu == 'account'
            }, {
                icon: faKey,
                text: translate('changePassword'),
                action: () => navigation.navigate('User', { screen: 'Password' }),
                selected: selectedMenu == 'password'
            }, {
                icon: faLock,
                text: translate('sessions'),
                action: () => navigation.navigate('User', { screen: 'Sessions' }),
                selected: selectedMenu == 'sessions'
            }, {
                icon: faNoteSticky,
                text: translate('notes'),
                action: () => navigation.navigate('User', { screen: 'Notes' }),
                selected: selectedMenu == 'notes'
            }]}
        />
    );
}
