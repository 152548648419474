import React, { useContext, useEffect, useState } from 'react';
import { ScrollView, View, StyleSheet, ActivityIndicator, TouchableOpacity, Image } from 'react-native';
import { translate } from '../../services/translate';
import MyAppText from '../../components/MyAppText';
import MyRadio from '../../components/MyRadio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../components/Tooltip';
import Toast from 'react-native-toast-message';
import { useNavigationState } from '@react-navigation/native';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import { crmService } from '../../services/central-api/crm';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import DeleteModal from '../../components/DeleteModal';
import { accessService } from '../../services/central-api/access';
import FormInput from '../../components/formInput';
import { ClientError } from '../../services/central-api/base-service';
import { useAuth } from '../../context/Auth';

interface AlarmCenterDetailsParameters {
    navigation: Navigation;
    route: {
        params?: {
            id: string;
        };
    };
}

export default function AlarmCenterDetails({ navigation, route }: AlarmCenterDetailsParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const { user: authenticatedUser } = useAuth();

    const index = useNavigationState(state => state);
    const [isLoading, setIsLoading] = useState(false);
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [canEditFields, setCanEditFields] = useState(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [isInstallationCompanyPickerOpen, setIsInstallationCompanyPickerOpen] = useState<boolean>(false);
    const [selectableInstallationCompanyFilter, setSelectableInstallationCompanyFilter] = useState<{ label: string, value: number; }[]>([]);
    const [isInternetCompanyPickerOpen, setIsInternetCompanyPickerOpen] = useState<boolean>(false);
    const [selectableInternetCompanyFilter, setSelectableInternetCompanyFilter] = useState<{ label: string, value: number; }[]>([]);

    // fields
    const [title, setTitle] = useState('');
    const [address, setAddress] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [serialNo, setSerialNo] = useState('');
    const [internetType, setInternetType] = useState<InternetType>('4g');
    const [installationCompanyId, setInstallationCompanyId] = useState(0);
    const [internetCompanyId, setInternetCompanyId] = useState(0);
    const [installer, setInstaller] = useState('');
    const [networkType, setNetworkType] = useState<NetworkType>('ipv6');

    async function getAlarmCenter(id: string) {
        try {
            setIsLoading(true);
            const alarmCenter = await crmService.getAlarmCenter(id);
            getInstallationCompanies(alarmCenter.installationCompanyId);
            getInternetCompanies(alarmCenter.internetCompanyId);
            setTitle(alarmCenter.title);
            setAddress(alarmCenter.address);
            setLatitude(String(alarmCenter.latitude));
            setLongitude(String(alarmCenter.longitude));
            setSerialNo(alarmCenter.serialNo);
            setInternetType(alarmCenter.internetType);
            setInstaller(alarmCenter.installer);
            setNetworkType(alarmCenter.networkType);
            setInstallationCompanyId(alarmCenter.installationCompanyId);
            setInternetCompanyId(alarmCenter.internetCompanyId);
        } catch (err) {
            console.error(err);
        } finally {
            setAlreadyLoaded(true);
            setIsLoading(false);
        }
    }

    async function getInstallationCompanies(currentInstallationCompany?: number | null) {
        try {
            const res = await accessService.getInstallationCompaniesSimplified();
            setSelectableInstallationCompanyFilter(res.filter((company) => !company.deletedAt || company.id == currentInstallationCompany)
                .map((companies) => {
                    return {
                        value: companies.id,
                        label: companies.name
                    };
                }));
        } catch (err) {
            console.error(err);
        }
    }

    async function getInternetCompanies(currentInternetCompany?: number | null) {
        try {
            const res = await accessService.getInternetCompaniesSimplified();
            setSelectableInternetCompanyFilter(res.filter((company) => !company.deletedAt || company.id == currentInternetCompany)
                .map((companies) => {
                    return {
                        value: companies.id,
                        label: companies.name
                    };
                }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (authenticatedUser) {
            setCanEditFields(authenticatedUser.isAdmin || authenticatedUser.permissions.crm_module_access);
        }
    }, [authenticatedUser]);

    useEffect(() => {
        if (route.params?.id && !alreadyLoaded) {
            getAlarmCenter(route.params.id);
        } else {
            getInstallationCompanies();
            getInternetCompanies();
        }
    }, [route.params]);

    useEffect(() => {
        if (title === '') {
            return setIsFormValid(false);
        }
        if (address === '') {
            return setIsFormValid(false);
        }
        if (installer === '' && !route.params?.id) {
            return setIsFormValid(false);
        }
        if (serialNo === '' && !route.params?.id) {
            return setIsFormValid(false);
        }
        if (latitude.trim() === '' || Number.isNaN(Number(latitude))) {
            return setIsFormValid(false);
        }
        if (longitude.trim() === '' || Number.isNaN(Number(longitude))) {
            return setIsFormValid(false);
        }
        if (Number.isNaN(Number(installationCompanyId)) || installationCompanyId == 0) {
            return setIsFormValid(false);
        }
        if (Number.isNaN(Number(internetCompanyId)) || internetCompanyId == 0) {
            return setIsFormValid(false);
        }

        return setIsFormValid(true);
    }, [title, address, latitude, longitude, installer, networkType, serialNo, installationCompanyId, internetCompanyId]);

    async function save() {
        try {
            setIsLoading(true);
            if (route.params?.id) {
                await crmService.updateAlarmCenter(route.params.id, {
                    title,
                    address,
                    latitude: Number(latitude),
                    longitude: Number(longitude),
                    serialNo,
                    internetType,
                    installer,
                    installationCompanyId: installationCompanyId,
                    internetCompanyId: internetCompanyId,
                    networkType,
                });
            } else {
                await crmService.createAlarmCenter({
                    title,
                    address,
                    latitude: Number(latitude),
                    longitude: Number(longitude),
                    serialNo,
                    internetType,
                    installationCompanyId: installationCompanyId,
                    internetCompanyId: internetCompanyId,
                    installer,
                    networkType,
                });
            }
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            if (index.routes.length > 1) {
                return navigation.goBack();
            } else {
                window.location.href = '/CRM/alarm-centers';
            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>
                        <TouchableOpacity onPress={() => {
                            if (index.routes.length > 1) {
                                return navigation.goBack();
                            } else {
                                window.location.href = '/CRM/alarm-centers';
                            }
                        }}>
                            <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={getThemedColor(theme, '#000028')} />
                        </TouchableOpacity>
                        <MyAppText style={{ fontSize: 24, color: getThemedColor(theme, '#58595B'), fontWeight: 'bold' }}>
                            {translate('alarmCenter')}
                        </MyAppText>
                    </View>
                    {canEditFields && route.params?.id ?
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity
                                onPress={() => {
                                    setDeleteModalVisible(true);
                                }}
                                style={[{
                                    borderWidth: 1,
                                    borderRadius: 2,
                                    borderColor: '#FE0127',
                                    backgroundColor: getThemedColor(theme, '#FFFFFF'),
                                    width: 180,
                                    height: 40,
                                    justifyContent: 'center',
                                }]}
                            >
                                <MyAppText style={[{ color: '#FE0127', alignSelf: 'center', fontSize: 16 }]}>
                                    {translate('delete')}
                                </MyAppText>
                            </TouchableOpacity>
                        </View>
                        : null}
                    {canEditFields ?
                        <TouchableOpacity
                            onPress={save}
                            disabled={!isFormValid}
                            style={[{
                                borderWidth: 0,
                                borderRadius: 2,
                                backgroundColor: getThemedColor(theme, '#000028'),
                                width: 128,
                                height: 40,
                                justifyContent: 'center',
                            }, !isFormValid ? styles.buttonDisabled : null]}
                        >
                            <MyAppText style={[{ color: getThemedColor(theme, '#FFFFFF'), alignSelf: 'center', fontSize: 16 }, !isFormValid ? styles.textDisabled : null]}>
                                {translate('save')}
                            </MyAppText>
                        </TouchableOpacity>
                        : null
                    }
                </View>
                <View style={styles.cardList}>
                    <ScrollView style={{ padding: 24 }} contentContainerStyle={{ rowGap: 15 }}>
                        {isLoading &&
                            <ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} />
                        }
                        <View style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'row', columnGap: 40, rowGap: 10 }}>
                            <View style={{ rowGap: 20, maxWidth: 400, width: '100%' }}>
                                <View style={styles.generalInformationContainer}>
                                    <FormInput
                                        label={translate('title')}
                                        placeholder=''
                                        value={title}
                                        disabled={!canEditFields}
                                        onChangeText={setTitle}
                                        invalid={() => title === ''}
                                    />
                                </View>
                                <View style={styles.generalInformationContainer}>
                                    <FormInput
                                        label={translate('address')}
                                        placeholder=''
                                        value={address}
                                        disabled={!canEditFields}
                                        onChangeText={setAddress}
                                        invalid={() => address === ''}
                                    />
                                </View>
                                <View style={styles.generalInformationContainer}>
                                    <FormInput
                                        label={translate('latitude')}
                                        placeholder=''
                                        value={latitude}
                                        disabled={!canEditFields}
                                        onChangeText={setLatitude}
                                        invalid={() => latitude === ''}
                                    />
                                </View>
                                <View style={styles.generalInformationContainer}>
                                    <FormInput
                                        label={translate('longitude')}
                                        placeholder=''
                                        value={longitude}
                                        disabled={!canEditFields}
                                        onChangeText={setLongitude}
                                        invalid={() => longitude === ''}
                                    />
                                </View>
                                <View style={[styles.generalInformationContainer, { flexDirection: 'row', alignItems: 'center', columnGap: 5 }]}>
                                    <View style={{ width: '95%' }}>
                                        <FormInput
                                            label={translate('serialNo')}
                                            placeholder=''
                                            value={serialNo}
                                            disabled={!canEditFields}
                                            onChangeText={setSerialNo}
                                            invalid={() => serialNo === ''}
                                            maxLength={9}
                                        />
                                    </View>
                                    <View style={{ paddingTop: 24 }}>
                                        <Tooltip tooltipDirection='right' width={600}
                                            tooltipContent={
                                                <View style={{ flexDirection: 'column', rowGap: 10, alignItems: 'center', paddingVertical: 5 }}>
                                                    <View>{translate('hikSerialNoExplanation')}</View>
                                                    <Image source={require('../../../assets/hikvision_serial_no.png')} style={{ height: 300, width: 580, objectFit: 'contain' }} />
                                                </View>
                                            }>
                                            <FontAwesomeIcon icon={faInfoCircle} color={getThemedColor(theme, '#222222')} />
                                        </Tooltip>
                                    </View>
                                </View>
                                <View style={[styles.generalInformationContainer, { rowGap: 5 }]}>
                                    <MyAppText style={styles.generalInformationTitleText}>
                                        {translate('networkType')}
                                    </MyAppText>
                                    <MyRadio
                                        editable={canEditFields}
                                        selected={networkType}
                                        setSelected={setNetworkType}
                                        options={[{ value: 'ipv6', label: 'IPv6' }, { value: 'ipv4', label: 'IPv4' }]}
                                    />
                                </View>
                                <View style={[styles.generalInformationContainer, { zIndex: 5, rowGap: 5 }]}>
                                    <MyAppText style={styles.generalInformationTitleText}>
                                        {translate('installationCompany')}
                                    </MyAppText>
                                    <View>
                                        <MyDropDownPicker
                                            open={isInstallationCompanyPickerOpen}
                                            value={installationCompanyId}
                                            items={selectableInstallationCompanyFilter}
                                            setOpen={setIsInstallationCompanyPickerOpen}
                                            setValue={setInstallationCompanyId}
                                            height={40}
                                            searchable={true}
                                            borderColor={getThemedColor(theme, '#CCCCCC')}
                                            disabled={!(authenticatedUser?.isAdmin) && !!(route.params?.id)}
                                        />
                                    </View>
                                </View>
                                <View style={styles.generalInformationContainer}>
                                    <FormInput
                                        label={translate('installer')}
                                        placeholder=''
                                        value={installer}
                                        disabled={!(authenticatedUser?.isAdmin) && !!(route.params?.id)}
                                        onChangeText={setInstaller}
                                        invalid={() => installer === ''}
                                    />
                                </View>
                                <View style={[styles.generalInformationContainer, { rowGap: 5 }]}>
                                    <MyAppText style={styles.generalInformationTitleText}>
                                        {translate('internetType')}
                                    </MyAppText>
                                    <MyRadio
                                        selected={internetType}
                                        editable={canEditFields}
                                        setSelected={setInternetType}
                                        options={[
                                            { label: translate('fiber'), value: 'fiber' },
                                            { label: '5G', value: '5g' },
                                            { label: '4G', value: '4g' },
                                            { label: translate('radio'), value: 'radio' }
                                        ]}
                                    />
                                </View>
                            </View>
                            <View style={{ rowGap: 20, maxWidth: 400, width: '100%' }}>
                                <View style={[styles.generalInformationContainer, { zIndex: 4, rowGap: 5 }]}>
                                    <MyAppText style={styles.generalInformationTitleText}>
                                        {translate('internetCompany')}
                                    </MyAppText>
                                    <View>
                                        <MyDropDownPicker
                                            open={isInternetCompanyPickerOpen}
                                            value={internetCompanyId}
                                            items={selectableInternetCompanyFilter}
                                            setOpen={setIsInternetCompanyPickerOpen}
                                            setValue={setInternetCompanyId}
                                            height={40}
                                            searchable={true}
                                            borderColor={getThemedColor(theme, '#CCCCCC')}
                                            disabled={!canEditFields}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </View>

            <DeleteModal
                setModalVisible={setDeleteModalVisible}
                isModalVisible={isDeleteModalVisible}
                itemName={serialNo ?? title}
                onSubmit={async () => {
                    try {
                        if (route.params?.id) {
                            await crmService.deleteAlarmCenter(route.params?.id);
                            Toast.show({
                                type: 'sentinelxSuccess',
                                text1: translate('ActionSuccessfully'),
                            });
                            if (index.routes.length > 1) {
                                return navigation.goBack();
                            } else {
                                window.location.href = '/CRM/alarm-centers';
                            }
                        }
                    } catch (err) {
                        if (err instanceof ClientError) {
                            return Toast.show({
                                type: 'sentinelxError',
                                text1: translate(err.message),
                            });
                        }

                        console.error(err);
                        Toast.show({
                            type: 'sentinelxError',
                            text1: translate('unexpectedError'),
                        });
                    }
                }}
            />
        </>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            flexWrap: 'wrap',
            minHeight: 65
        },
        headerText: {
            fontSize: 24,
            color: getThemedColor(theme, '#58595B'),
            fontWeight: 'bold'
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flex: 1,
            flexDirection: 'row',
        },
        generalInformationContainer: {
            minHeight: 40,
        },
        disabled: {
            backgroundColor: getThemedColor(theme, '#EEEEEE')
        },
        generalInformationTitleText: {
            color: getThemedColor(theme, '#58595B')
        },
        generalInformationInput: {
            padding: 10,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            height: 40,
            borderRadius: 4,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            fontFamily: 'Open Sans',
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        buttonDisabled: {
            borderWidth: 0,
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        textDisabled: {
            color: getThemedColor(theme, '#888888')
        }
    });
}
