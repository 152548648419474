import React from 'react';
import DataTable, { TableProps, createTheme } from 'react-data-table-component';
import { ActivityIndicator, View } from 'react-native';
import { translate } from '../services/translate';
import MyAppText from './MyAppText';
import { createStyleSheet, UnistylesRuntime, useStyles } from 'react-native-unistyles';

export default function MyDataTable<T>(props: TableProps<T & { odd?: boolean; }> & {
    shortPadding?: boolean;
    tableFlex?: boolean;
}) {
    const { styles, theme } = useStyles(stylesheet);

    createTheme('dark', {
        text: {
            primary: '#D3CFC9',
        },
        background: {
            default: '#181A1B',
        },
        context: {
            color: '#D3CFC9',
        }
    }, 'dark');

    if (typeof props.data[0] === 'object') {
        let index = 0;
        for (const row of props.data) {
            row.odd = index % 2 != 0;
            index++;
        }
    }

    return <DataTable
        {...props}
        fixedHeader={true}
        noDataComponent={<MyAppText style={styles.noDataComponent}>
            {translate('noResults')}.
        </MyAppText>}
        customStyles={{
            rows: {
                style: styles.rows,
            },
            headCells: {
                style: styles.headCells,
            },
            cells: {
                style: styles.cells(props.shortPadding)
            },
            table: {
                style: props.tableFlex ? { flex: 1 } : {}
            },
            tableWrapper: {
                style: props.tableFlex ? { display: 'flex', flex: 1 } : {}
            },
            responsiveWrapper: {
                style: props.tableFlex ? { display: 'flex', flex: 1 } : {}
            }
        }}
        progressComponent={<View style={{ paddingTop: 20 }}>
            <ActivityIndicator size='small' color={theme.colors.dataTable.loading} />
        </View>}
        conditionalRowStyles={[{
            when: row => row.odd || false,
            style: {
                backgroundColor: theme.colors.dataTable.oddRowBackground
            }
        }]}
        theme={UnistylesRuntime.themeName}
    />;
}

const stylesheet = createStyleSheet(theme => ({
    noDataComponent: {
        color: theme.colors.dataTable.noDataMessage,
        paddingTop: 10,
    },
    rows: {
        fontFamily: 'Open Sans',
        fontSize: 14,
    },
    headCells: {
        fontFamily: 'Open Sans',
        fontSize: 13,
        fontWeight: 'normal'
    },
    cells: (horizontalPadding?: boolean) => ({
        paddingLeft: horizontalPadding ? 5 : 16,
        paddingRight: horizontalPadding ? 5 : 16,
    })
}));
