import React from 'react';
import { TeamNameCategory } from '../../../services/central-api/dispatch';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';

interface Props {
    value: TeamNameCategory[];
    setValue: React.Dispatch<React.SetStateAction<TeamNameCategory[]>>;
    zIndex: number;
}

function CategoriesDropdown({ value, setValue, zIndex }: Props) {
    return (
        <FilterDropdown
            label={translate('category')}
            items={Object.values(TeamNameCategory).map((item: string) => {
                return {
                    label: item ? translate(item) : '', value: item
                };
            })}
            mode='BADGE'
            multiple={true}
            value={value}
            setValue={setValue}
            zIndex={zIndex}
        />
    );
}

export default CategoriesDropdown;
