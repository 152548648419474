// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA


export async function register() {
    await new Promise((resolve, reject) => {
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', async () => {
                try {
                    const swUrl = `${window.location.origin}/firebase-messaging-sw.js`;
                    await registerValidSW(swUrl, resolve);
                } catch (err) {
                    return reject(err);
                }
            });
        } else {
            reject(`Development environment won't register a service-worker`);
        }
    });

}

async function registerValidSW(swUrl: string, resolve: (value: unknown) => void) {

    // TODO: as soon as possible change this to:
    // const isTwa = navigator.userAgent.includes('TWA');
    // window.addEventListener('beforeinstallprompt', (event) => {
    //   if (isTwa) {
    //     event.preventDefault();
    //   }
    // });

    window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
    });

    const registration = await navigator.serviceWorker.register(swUrl);

    if (registration.installing == null) {
        resolve(null);
    }

    registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
            resolve(null);
            return;
        }
        installingWorker.onstatechange = async () => {
            if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                    console.log(
                        'New content is available. Skipping waiting for update...'
                    );
                    sw.waiting?.postMessage({ type: 'SKIP_WAITING' });
                } else {
                    console.log('Content is cached for offline use.');
                }
                resolve(null);
            }
        };
    };

    const sw = await navigator.serviceWorker.ready;

}
