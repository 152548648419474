import React, { useState } from 'react';
import { ScrollView, View, TouchableOpacity, Image, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import { faChevronDown, faChevronUp, faLocationDot, faSearchPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Hoverable } from 'react-native-web-hover';
import { WindowInformation } from '../../../services/window-information';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

export default function OccurrenceFormSigrc({ occurrence }: { occurrence: Occurrence; }) {
    const { styles, theme } = useStyles(stylesheet);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const windowInfo = WindowInformation();
    const [showDetails, setShowDetails] = useState<boolean>(false);

    if (!occurrence.resume || occurrence.triggerType !== 'sigrc') {
        return <></>;
    }

    function addressFormat(address: { logradouro: string; numero: string; bairro: string; cidade: string; uf: string; cep: string; pontoReferencia: string | null; }) {
        const formattedCEP = address.cep.replace(/(\d{5})(\d{3})/, '$1-$2');
        let formattedAddress = `${address.logradouro}, ${address.numero} - ${address.bairro}, ${address.cidade} - ${address.uf}, ${formattedCEP}`;
        if (address.pontoReferencia) {
            formattedAddress += ` (${formattedAddress})`;
        }
        return formattedAddress;
    }

    function keyValue(key: string, value: string | boolean | number, isDate?: boolean) {
        if (value == '') {
            return;
        } else if (typeof value == 'boolean') {
            return <MyAppText style={styles.detectionInfoText}><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {translate(value ? 'yes' : 'no')}</MyAppText>;
        } else if (isDate) {
            return <MyAppText style={styles.detectionInfoText}><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {moment(value).format('DD/MM/YYYY')}</MyAppText>;
        }
        return <MyAppText style={styles.detectionInfoText}><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {value}</MyAppText>;
    }

    return (
        <ScrollView contentContainerStyle={{ rowGap: 10 }}>
            {occurrence.resume.imageUrl ? <Hoverable>
                {({ hovered }) => (<>
                    <View style={{ flexDirection: 'column' }} >
                        <MyAppText style={styles.imageText}>
                            {translate('detection')}
                        </MyAppText>
                        <View style={{ alignItems: 'center' }}>
                            <Image
                                style={{
                                    width: 180,
                                    height: 180,
                                    resizeMode: 'cover',
                                    borderRadius: 5,
                                    overflow: 'hidden'
                                }}
                                source={{ uri: `${occurrence.resume.imageUrl}` }}
                            />
                        </View>
                    </View>
                    {hovered &&
                        <View style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#00000080',
                        }}>
                            <TouchableOpacity
                                style={{
                                    flex: 1,
                                    justifyContent: 'center'
                                }}
                                onPress={async () => {
                                    setIsModalVisible(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faSearchPlus} fontSize={28} color={'#FFFFFF'} />
                            </TouchableOpacity>
                        </View>
                    }
                </>)}
            </Hoverable> : <></>}

            <View style={styles.generalInformation}>
                <TouchableOpacity
                    style={styles.generalInformationDropdown}
                    onPress={() => setShowDetails(!showDetails)}>
                    <MyAppText>{translate('general_info')}</MyAppText>
                    <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} color={theme.colors.iconColor} />
                </TouchableOpacity>
                <View style={styles.tableLine}></View>
            </View>

            {showDetails ? <>
                <View style={{ rowGap: 5 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }}>
                        <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 22 }} color={theme.colors.iconColor} />
                        <MyAppText style={styles.detectionInfoText}>
                            {moment(occurrence.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                        </MyAppText>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }}>
                        <FontAwesomeIcon icon={faLocationDot} style={{ width: 22 }} color={theme.colors.iconColor} />
                        <MyAppText style={styles.detectionInfoText}>
                            {addressFormat(occurrence.resume.content.endereco)}
                        </MyAppText>
                    </View>
                </View>
                <View style={{ rowGap: 5 }}>
                    <>{keyValue('type', occurrence.resume.content.tipoSolicitacao.descricao)}</>
                    <>{keyValue('description', occurrence.resume.content.descricao || '')}</>
                    <>{keyValue('subprefecture', occurrence.resume.content.endereco.prefeitura || '')}</>

                    {occurrence.resume.content.tipoSolicitacao.sla ?
                        <>
                            <>{keyValue('status', occurrence.resume.content.tipoSolicitacao.sla.status)}</>
                            <>{keyValue('completionTime', occurrence.resume.content.tipoSolicitacao.sla.nome)}</>
                        </>
                        : <></>
                    }

                    <>{keyValue('registeredAt', occurrence.resume.content.tipoSolicitacao.dataCadastro, true)}</>
                    <>{keyValue('criticality', occurrence.resume.content.tipoSolicitacao.criticidade)}</>

                    {occurrence.resume.content.solicitante ?
                        <View style={{ rowGap: 1 }}>
                            <MyAppText style={styles.requesterTitle}>
                                {translate('requester')}
                            </MyAppText>

                            <>{keyValue('name', occurrence.resume.content.solicitante.nome)}</>
                            <>{keyValue('document', occurrence.resume.content.solicitante.cpfCNPJ)}</>
                            {occurrence.resume.content.solicitante?.telefone?.length > 0 ? (
                                occurrence.resume.content.solicitante.telefone.map((tel) => (
                                    keyValue(`phone`, `(${tel.ddd}) ${tel.numero}`)
                                ))
                            ) : null}

                        </View>
                        : <></>
                    }
                </View>
            </> : <></>}

            {/* Zoom image modal */}
            <Modal
                animationType='fade'
                transparent={true}
                visible={isModalVisible}
                onRequestClose={() => setIsModalVisible(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredView}>
                        <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : null]}>
                            <View style={{ alignItems: 'flex-end' }}>
                                <TouchableOpacity
                                    style={{ width: 20, height: 20 }}
                                    onPress={() => setIsModalVisible(false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ width: 20 }} color={theme.colors.iconColor} />
                                </TouchableOpacity>
                            </View>

                            <View style={{ flexDirection: 'row', gap: 15 }} >
                                <View style={{ flex: 1 }}>
                                    <MyAppText style={styles.imageText}>
                                        {translate('detection')}
                                    </MyAppText>
                                    <Image
                                        style={{
                                            width: 360,
                                            height: 360,
                                            resizeMode: 'cover',
                                            borderRadius: 5,
                                            overflow: 'hidden'
                                        }}
                                        source={{ uri: `${occurrence.resume.imageUrl}` }}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </ScrollView >
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        padding: 16,
        backgroundColor: theme.colors.container.background,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        borderWidth: 1,
    },
    imageText: {
        color: theme.colors.cardDetailText,
        paddingBottom: 5,
        fontSize: 11,
    },
    detectionInfoText: {
        color: theme.colors.labelColor,
        fontSize: 12
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalBody: {
        backgroundColor: theme.colors.container.background,
        flexDirection: 'column',
        borderRadius: 8,
        rowGap: 20,
        padding: 30,
    },
    requesterTitle: {
        fontWeight: 'bold',
        fontSize: 15
    },
    tableLine: {
        borderBottomColor: theme.colors.cardBorder,
        borderBottomWidth: 1,
        flex: 1
    },
    generalInformation: {
        flexDirection: 'column',
        paddingHorizontal: 5,
        flex: 1,
        gap: 10
    },
    generalInformationDropdown: {
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1
    }
}));
