import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import CustomButton from '../../../components/CustomButton';
import { faArrowLeft, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import { Tabs } from '../../../components/Tabs';

import { CalendarValue } from '../../../components/DateTimePicker';
import { useAuth } from '../../../context/Auth';
import { dispatchService } from '../../../services/central-api/dispatch';
import moment from 'moment';
import Toast from 'react-native-toast-message';
import { centralAPI } from '../../../services/central-api';
import UnitFormDetails from './UnitFormDetails';
import UnitFormAgents from './UnitFormAgents';
import UnitFormHistoric from './UnitFormHistoric';
import UnitFormOccurrences from './UnitFormOccurrences';
import FinishTeamModal from './FinishTeamModal';
import { useIsFocused } from '@react-navigation/native';

export default function UnitForm({ navigation, route }: {
    navigation: Navigation;
    route: {
        params?: {
            id: number;
        };
    };
}) {
    const unitId = route.params?.id;

    const { styles, theme } = useStyles(stylesheet);

    const { user: authenticatedUser } = useAuth();

    const [unitNameId, setUnitNameId] = useState<number>(0);
    const [unitProgramId, setUnitProgramId] = useState<number>(0);
    const [description, setDescription] = useState<string>('');
    const [actingBodyId, setActingBodyId] = useState<number>(0);

    const [unit, setUnit] = useState<Unit>();
    const [agentsSelected, setAgentsSelected] = useState<number[]>([]);

    const [equipmentsSelected, setEquipmentsSelected] = useState<number[]>([]);

    const [modifiedAgentAssignments, setModifiedAgentAssignments] = useState<{ [agentId: number]: number; }>({});

    const [startInDateFilter, setStartInDateFilter] = useState<CalendarValue>(null);
    const [startInHourFilter, setStartInHourFilter] = useState<string>('');
    const [finishInDateFilter, setFinishInDateFilter] = useState<CalendarValue>(null);
    const [finishInHourFilter, setFinishInHourFilter] = useState<string>('');

    const [actingBodyUnitId, setActingBodyUnitId] = useState<number>(0);
    const [actingBodyCommandId, setActingBodyCommandId] = useState<number>(0);

    const [earlyFinishModal, setEarlyFinishModal] = useState(false);

    const isFocused = useIsFocused();
    useEffect(() => {
        if(isFocused) {
            loadPageInfo();
        }
    }, [route.params, isFocused]);


    async function getOwnUser() {
        try {
            const ownUser = await centralAPI.getOwnUser({ includeRole: true });
            return ownUser;
        } catch (err) {
            console.error(err);
        }
    }

    async function loadPageInfo() {
        try {
            const ownUser = await getOwnUser();
            if (unitId) {
                const unit = await dispatchService.getUnit(unitId);
                setUnit(unit);
                setStartInDateFilter(new Date(unit.estimatedStart));
                setStartInHourFilter(moment(unit.estimatedStart).format('HH:mm'));
                setFinishInDateFilter(new Date(unit.estimatedFinish));
                setFinishInHourFilter(moment(unit.estimatedFinish).format('HH:mm'));
                setUnitNameId(unit.unitNameId);
                setUnitProgramId(unit.unitProgramId);
                setDescription(unit.description || '');
                setActingBodyId(unit.actingBodyUnit?.actingBodyId || 0);
                setActingBodyUnitId(unit.actingBodyUnitId || 0);
                setActingBodyCommandId(unit.actingBodyCommandId || 0);
                setEquipmentsSelected(unit.equipments?.map(e => e.id) || []);
                setAgentsSelected(unit.UnitUsers?.map(uu => uu.id) || []);

            } else if (!ownUser?.isAdmin && ownUser?.role?.actingBody?.id) {
                setActingBodyId(ownUser.role.actingBody.id);
            }

        } catch (err) {
            console.error(err);
        }
    }

    async function save() {
        try {
            const start = moment(`${moment(startInDateFilter).format('DD/MM/YYYY')} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm').toLocaleString();
            const finish = moment(`${moment(finishInDateFilter).format('DD/MM/YYYY')} ${finishInHourFilter}`, 'DD/MM/YYYY HH:mm').toLocaleString();
            if (unitId) {
                await dispatchService.updateUnit({
                    unitId: unitId,
                    unitNameId,
                    unitProgramId,
                    actingBodyCommandId,
                    description,
                    usersToSet: agentsSelected,
                    equipmentsToSet: equipmentsSelected,
                    modifiedAgentAssignments,
                    estimatedStart: start,
                    estimatedFinish: finish
                });
            } else {
                await dispatchService.createUnit({
                    unitNameId,
                    unitProgramId,
                    actingBodyUnitId,
                    actingBodyCommandId,
                    description,
                    usersToSet: agentsSelected,
                    equipmentsToSet: equipmentsSelected,
                    modifiedAgentAssignments,
                    estimatedStart: start,
                    estimatedFinish: finish
                });
            }
            navigation.navigate('Units');

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function isFormValid(): boolean {

        if (!unitId && (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_acting_body_units) && !actingBodyUnitId) {
            return false;
        }
        if (startInDateFilter == null || finishInDateFilter == null) {
            return false;
        }
        const start = moment(`${moment(startInDateFilter).format('DD/MM/YYYY')} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm');
        const finish = moment(`${moment(finishInDateFilter).format('DD/MM/YYYY')} ${finishInHourFilter}`, 'DD/MM/YYYY HH:mm');

        if (!unitId && start.format('YYYY-MM-DD HH:mm') < moment().subtract(1, 'minute').format('YYYY-MM-DD HH:mm')) {
            return false;
        }
        return actingBodyCommandId !== 0 && unitNameId !== 0 && unitProgramId !== 0 && start.valueOf() <= finish.valueOf() && moment.duration(finish.diff(start)).asHours() >= 1;
    }

    return <View style={styles.container}>
        <View style={styles.header}>
            <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>
                <TouchableOpacity onPress={() => navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Units')}>
                    <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={theme.colors.primaryButton.background} />
                </TouchableOpacity>
                <MyAppText style={{ fontSize: 24, color: theme.colors.labelColor, fontWeight: 'bold' }}>
                    {translate('registerTeam')}
                </MyAppText>
                {unit?.definitiveFinish &&
                    <MyAppText style={{
                        fontSize: 16,
                        color: theme.colors.labelColor,
                    }}>
                        ({translate('DEACTIVATED_UNIT')})
                    </MyAppText>
                }
            </View>

            {!unit?.definitiveFinish &&
                <View style={{ justifyContent: 'flex-end', flexDirection: 'row', gap: 10 }}>
                    <CustomButton
                        text={translate('finish')}
                        onPress={() => {
                            setEarlyFinishModal(true);
                        }}
                        disabled={!isFormValid()}
                        type='secondary'
                        icon={faFlagCheckered}
                    />
                    <CustomButton text={translate('save')} onPress={save} disabled={!isFormValid()} />
                </View>
            }
        </View>

        <View style={styles.cardList}>
            <ScrollView contentContainerStyle={{ padding: 24, gap: 10 }}>
                <Tabs tabs={[{
                    key: 'details',
                    label: translate('details')
                }, {
                    key: 'agents',
                    label: translate('agents')
                }, {
                    key: 'occurrences',
                    label: translate('occurrences')
                }, {
                    key: 'historic',
                    label: translate('historic')
                }]}>
                    {({ selectedTab }) => {
                        if (selectedTab === 'details') {
                            return <UnitFormDetails
                                actingBodyCommandId={actingBodyCommandId}
                                actingBodyId={actingBodyId}
                                actingBodyUnitId={actingBodyUnitId}
                                description={description}
                                equipmentsSelected={equipmentsSelected}
                                finishInDateFilter={finishInDateFilter}
                                finishInHourFilter={finishInHourFilter}
                                isFinished={!!unit?.definitiveFinish}
                                setActingBodyCommandId={setActingBodyCommandId}
                                setActingBodyId={setActingBodyId}
                                setActingBodyUnitId={setActingBodyUnitId}
                                setDescription={setDescription}
                                setEquipmentsSelected={setEquipmentsSelected}
                                setFinishInDateFilter={setFinishInDateFilter}
                                setFinishInHourFilter={setFinishInHourFilter}
                                setStartInDateFilter={setStartInDateFilter}
                                setStartInHourFilter={setStartInHourFilter}
                                setUnitNameId={setUnitNameId}
                                setUnitProgramId={setUnitProgramId}
                                startInDateFilter={startInDateFilter}
                                startInHourFilter={startInHourFilter}
                                unitNameId={unitNameId}
                                unitProgramId={unitProgramId}
                            />;
                        } else if (selectedTab === 'agents') {
                            return <UnitFormAgents
                                unitUsers={unit?.UnitUsers}
                                actingBodyId={actingBodyId}
                                agentsSelected={agentsSelected}
                                setAgentsSelected={setAgentsSelected}
                                modifiedAgentAssignments={modifiedAgentAssignments}
                                setModifiedAgentAssignments={setModifiedAgentAssignments}
                                isFinished={!!unit?.definitiveFinish}
                            />;
                        } else if (selectedTab === 'occurrences') {
                            return <UnitFormOccurrences occurrences={unit?.OccurrenceUnits} />;
                        } else if (selectedTab === 'historic') {
                            return <UnitFormHistoric unitId={unitId} />;
                        }
                    }}
                </Tabs>
            </ScrollView>
        </View>
        <FinishTeamModal
            isModalVisible={earlyFinishModal}
            setModalVisible={setEarlyFinishModal}
            unit={{
                id: unit?.id || 0,
            }}
            onClose={() => {
                navigation.navigate('Units');
            }}
        />
    </View>;

}

const stylesheet = createStyleSheet(theme => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        flexWrap: 'wrap',
        minHeight: 65
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        backgroundColor: theme.colors.container.background,
        flex: 1,
    },
}));