import React, { useCallback, useEffect, useState } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { SelectableItem, SelectableList } from '../../../components/SelectableList';
import { Tabs } from '../../../components/Tabs';
import { MyCheckbox } from '../../../components/MyCheckbox';
import MyAppText from '../../../components/MyAppText';
import Toast from 'react-native-toast-message';
import { accessService } from '../../../services/central-api/access';
import { WindowInformation } from '../../../services/window-information';
import { ScrollView } from 'react-native-web-hover';
import { centralAPI } from '../../../services/central-api';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import ActingBodiesUnitDropdown from '../../dispatch/Units/ActingBodyUnitDropdown';
import ActingBodiesDropdown from '../../dispatch/Units/ActingBodiesDropdown';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: (success: boolean) => void;
    permissionLevelId?: number;
}

const defaultPermissionsValue: UserPermissions = {
    lpr_edit_denylist: false,
    lpr_see_denylist: false,
    lpr_module_access: false,
    lpr_edit_detection: false,
    facial_module_access: false,
    manage_allow_list_person: false,
    manage_missing_person: false,
    manage_wanted_person: false,
    dispatch_module_access: false,
    dispatch_see_resume: false,
    dispatch_manage_occurrence_tags: false,
    dispatch_manage_report_tags: false,
    dispatch_manage_units: false,
    dispatch_manage_vehicle_relations: false,
    dispatch_manage_person_relations: false,
    dispatch_manage_objects_categories: false,
    reopen_occurrence: false,
    transfer_occurrence: false,
    events_module_access: false,
    events_manage_wanted_face_occurrence: false,
    events_manage_missing_face_occurrence: false,
    events_manage_not_allowed__face_occurrence: false,
    events_manage_wanted_plate_occurrence: false,
    events_manage_irregular_vehicle_occurrence: false,
    events_manage_clone_suspect_occurrence: false,
    events_manage_area_invasion_occurrence: false,
    events_manage_mp_wanted_plate_occurrence: false,
    events_manage_mp_wanted_face_occurrence: false,
    events_manage_alarm_center_occurrence: false,
    events_manage_guardian_app_occurrence: false,
    events_manage_sigrc_occurrence: false,
    events_manage_cortex_plate_occurrence: false,
    events_manage_manual_occurrence: false,
    camera_management: false,
    camera_download_video: false,
    camera_download_report: false,
    camera_management_point_of_interest: false,
    camera_manage_tags: false,
    camera_manage_download_tags: false,
    create_camera_maintenance_requests: false,
    create_emergency_camera_maintenance_requests: false,
    manage_camera_alert_groups: false,
    silence_camera_events: false,
    camera_watch_download: false,
    dashboard_module_access: false,
    dashboard_see_occurrence: false,
    dashboard_see_cameras: false,
    dashboard_see_agents: false,
    dashboard_see_agents_acting_bodies: false,
    dashboard_see_agents_acting_body_units: false,
    smart_search_module_access: false,
    logs_module_access: false,
    crm_module_access: false,
    get_camera_password: false,
    search_people_by_picture: false,
    search_people_cortex: false,
    search_cortex_any_time: false,
    receive_occurrences: false,
    access_edit_platform_settings: false,
    access_edit_user_settings: false,
    access_edit_user_acting_body_units: false,
    access_management_user_tags: false,
    access_edit_group_settings: false,
    access_edit_permission_level_settings: false,
    access_edit_turnstile: false,
    dispatch_generate_report: false,
    dispatch_download_report: false,
    dispatch_manage_team_names: false,
    camera_video_analysis: false,
    camera_watch_video_analysis: false,
    dispatch_manage_acting_body_units: false,
    dispatch_manage_occurrences: false,
    dispatch_manage_all_acting_bodies_occurrences: false,
    dispatch_manage_all_acting_bodies_occurrence_types: false,
    dispatch_manage_all_acting_bodies_occurrence_tags: false,
    dispatch_manage_all_acting_bodies_equipments: false,
    dispatch_manage_all_acting_bodies_reports: false,
    dispatch_manage_all_acting_bodies_download_report: false,
    dispatch_manage_all_acting_bodies_report_tags: false,
    dispatch_manage_finish_categories: false,
    dispatch_pause_units: false,
    dispatch_manage_equipments: false,
    dispatch_manage_all_acting_bodies_skills: false,
    dispatch_manage_skills: false,
    dispatch_manage_all_acting_bodies_assignments: false,
    dispatch_manage_assignments: false,
    dispatch_manage_all_acting_bodies_objects_categories: false,
    dispatch_manage_all_acting_bodies_person_relations: false,
    dispatch_manage_all_acting_bodies_vehicle_relations: false,
    dispatch_manage_all_acting_bodies_unit_names: false,
    dispatch_manage_all_acting_bodies_finish_categories: false,
    guardian_app_module_access: false,
    cloned_plate_module_access: false,
    dashboard_see_vehicles: false,
    dashboard_see_vehicles_acting_bodies: false
};

export default function PermissionLevelModal({ onClose, permissionLevelId, isModalVisible, setModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [ownUser, setOwnUser] = useState<UserData>();
    const [actingBodyId, setActingBodyId] = useState<number>(0);
    const [unitId, setUnitId] = useState<number>(0);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selectableUsers, setSelectableUsers] = useState<SelectableItem[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<SelectableItem[]>([]);
    const [permissionLevelUserIds, setPermissionLevelUserIds] = useState<number[]>([]);
    const [permissions, setPermissions] = useState<UserPermissions>(defaultPermissionsValue);
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    function cleanValues() {
        setName('');
        setDescription('');
        setSelectedUsers([]);
        setSelectableUsers([]);
        setActingBodyId(0);
        setUnitId(0);
        setPermissions(defaultPermissionsValue);
    }

    async function getPermissionLevel(id: number) {
        const permissionLevel = await accessService.getPermissionLevel(id);
        setName(permissionLevel.name);
        setDescription(permissionLevel.description);
        setPermissions(permissionLevel.permissionObj);

        if (permissionLevel.actingBodyUnit?.actingBodyId) {
            setActingBodyId(permissionLevel.actingBodyUnit.actingBodyId);
            setUnitId(permissionLevel.actingBodyUnitId);

            const users = await loadUsers(permissionLevel.actingBodyUnit.id || 0);
            const selectedUsers = permissionLevel.Users.map((user) => {
                return {
                    id: user.id || 0,
                    name: user.name
                };
            });
            setSelectedUsers(selectedUsers);

            setSelectableUsers((users || [])
                .filter((user) => !selectedUsers.find((permissionLevelUser) => permissionLevelUser.id == user.id))
                .map((user) => {
                    return {
                        id: user.id || 0,
                        name: user.name
                    };
                })
            );
            setPermissionLevelUserIds(permissionLevel.Users.map((user) => user.id || 0));

        }
    }

    async function loadPageInfo() {
        try {
            const ownUser = await centralAPI.getOwnUser({ includeRole: true });
            setOwnUser(ownUser);
            if (permissionLevelId) {
                await getPermissionLevel(permissionLevelId);
            } else {
                if (!ownUser.isAdmin && ownUser.actingBodyUnitId) {
                    setActingBodyId(ownUser.role?.actingBodyId || 0);
                    const users = await loadUsers(ownUser.actingBodyUnitId);
                    setSelectableUsers((users || []).map((user) => {
                        return {
                            id: user.id || 0,
                            name: user.name
                        };
                    }));
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function loadUsers(actingBodyUnitId: number) {
        try {
            const users = await accessService.getAccessAllUsers({ actingBodyUnitId: actingBodyUnitId });
            return users;
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (isModalVisible) {
            loadPageInfo();
        }
    }, [isModalVisible]);

    function isEmpty(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isEmpty(name) && (!ownUser?.isAdmin || unitId !== 0);
    }

    async function changedUnit(unitId: number) {
        const users = await loadUsers(unitId);

        setSelectableUsers((users || []).map((user) => {
            return {
                id: user.id || 0,
                name: user.name
            };
        }));
    }

    useEffect(() => {
        if (actingBodyId !== 0) {
            setSelectedUsers([]);
            setSelectableUsers([]);
        }

        if (unitId !== 0 && !permissionLevelId) {
            changedUnit(unitId);
        }
    }, [actingBodyId, unitId]);

    async function formSubmit() {
        try {
            if (permissionLevelId) {
                const selectedUserIds = selectedUsers.map((user) => Number(user.id));
                const usersToAdd = selectedUserIds.filter((selectedUserId) => !permissionLevelUserIds.includes(selectedUserId));
                const usersToRm = permissionLevelUserIds.filter((permissionLevelUserId) => !selectedUserIds.includes(permissionLevelUserId));

                await accessService.updatePermissionLevel(permissionLevelId, {
                    name,
                    description,
                    usersToAdd,
                    usersToRm,
                    permissions,
                });
            } else {
                await accessService.createPermissionLevel({
                    name,
                    description,
                    usersToAdd: selectedUsers.map(item => Number(item.id)),
                    permissions,
                    unitId,
                });
            }
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose(true);
            cleanValues();
            return;

        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => {
            setModalVisible(false);
            onClose(false);
            cleanValues();
        }} transparent={true}>
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: 750 }]}>
                    <Tabs tabs={[{
                        key: 'details',
                        label: translate('details')
                    }, {
                        key: 'users',
                        label: translate('users')
                    }, {
                        key: 'permissions',
                        label: translate('permissions')
                    }]}>
                        {({ selectedTab }) => {
                            if (selectedTab == 'details') {
                                return (<>
                                    {ownUser?.isAdmin ?
                                        <View style={{ flexDirection: 'row', gap: 16, zIndex: 2 }}>
                                            <View style={{ flex: 1 }}>
                                                <ActingBodiesDropdown
                                                    value={actingBodyId}
                                                    setValue={setActingBodyId}
                                                    zIndex={3}
                                                    disabled={!!permissionLevelId}
                                                    includeAllOption={false} />
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <ActingBodiesUnitDropdown
                                                    actingBodyId={actingBodyId}
                                                    value={unitId}
                                                    setValue={setUnitId}
                                                    zIndex={2}
                                                    disabled={!!permissionLevelId} />
                                            </View>
                                        </View>
                                        : null
                                    }
                                    <FormInput
                                        label={translate('name')}
                                        placeholder={translate('name')}
                                        value={name}
                                        onChangeText={setName}
                                        invalid={isEmpty}
                                    />
                                    <FormInput
                                        label={translate('description')}
                                        placeholder={translate('description')}
                                        value={description}
                                        onChangeText={setDescription}
                                        invalid={() => false}
                                    />
                                </>);
                            } else if (selectedTab == 'users') {
                                return <SelectableList
                                    selectableLabel={translate('users')}
                                    selectableContent={selectableUsers}
                                    selectedLabel={translate('participants')}
                                    selectedContent={selectedUsers}
                                />;
                            } else if (selectedTab == 'permissions') {
                                return (
                                    <ScrollView contentContainerStyle={styles.permissionsContainer}>
                                        <View style={styles.permissionMainColumn}>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('facialModule')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('fullModuleAccess')}
                                                    checked={permissions.facial_module_access}
                                                    setChecked={(value) => {
                                                        permissions.facial_module_access = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageWantedPerson')}
                                                    checked={permissions.manage_wanted_person}
                                                    setChecked={(value) => {
                                                        permissions.manage_wanted_person = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageMissingPerson')}
                                                    checked={permissions.manage_missing_person}
                                                    setChecked={(value) => {
                                                        permissions.manage_missing_person = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageAllowListPerson')}
                                                    checked={permissions.manage_allow_list_person}
                                                    setChecked={(value) => {
                                                        permissions.manage_allow_list_person = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                            </View>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('dispatchModule')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('fullModuleAccessSameActingBody')}
                                                    checked={permissions.dispatch_module_access}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_module_access = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('seeDispatchResume')}
                                                    checked={permissions.dispatch_see_resume}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_see_resume = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageOccurrences')}
                                                    checked={permissions.dispatch_manage_occurrences}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_occurrences = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyOccurrences')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_occurrences}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_occurrences = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('reopenOccurrence')}
                                                    checked={permissions.reopen_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.reopen_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('transferOccurrence')}
                                                    checked={permissions.transfer_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.transfer_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageOccurrenceTags')}
                                                    checked={permissions.dispatch_manage_occurrence_tags}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_occurrence_tags = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyOccurrenceTags')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_occurrence_tags}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_occurrence_tags = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyOccurrenceTypes')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_occurrence_types}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_occurrence_types = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageEquipments')}
                                                    checked={permissions.dispatch_manage_equipments}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_equipments = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyEquipments')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_equipments}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_equipments = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('generateOccurrenceReport')}
                                                    checked={permissions.dispatch_generate_report}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_generate_report = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyReports')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_reports}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_reports = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('downloadOccurrenceReport')}
                                                    checked={permissions.dispatch_download_report}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_download_report = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('downloadActingBodyOccurrenceReport')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_download_report}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_download_report = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageReportTags')}
                                                    checked={permissions.dispatch_manage_report_tags}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_report_tags = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyReportTags')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_report_tags}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_report_tags = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageSkills')}
                                                    checked={permissions.dispatch_manage_skills}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_skills = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodySkills')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_skills}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_skills = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageAssignments')}
                                                    checked={permissions.dispatch_manage_assignments}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_assignments = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyAssignments')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_assignments}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_assignments = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageVehicleRelations')}
                                                    checked={permissions.dispatch_manage_vehicle_relations}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_vehicle_relations = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyVehicleRelations')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_vehicle_relations}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_vehicle_relations = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('managePersonRelations')}
                                                    checked={permissions.dispatch_manage_person_relations}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_person_relations = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyPersonRelations')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_person_relations}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_person_relations = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageObjectsCategories')}
                                                    checked={permissions.dispatch_manage_objects_categories}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_objects_categories = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyObjectsCategories')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_objects_categories}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_objects_categories = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageTeamNames')}
                                                    checked={permissions.dispatch_manage_team_names}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_team_names = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyTeamNames')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_unit_names}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_unit_names = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageUnits')}
                                                    checked={permissions.dispatch_manage_units}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_units = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyUnits')}
                                                    checked={permissions.dispatch_manage_acting_body_units}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_acting_body_units = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('managePauseUnits')}
                                                    checked={permissions.dispatch_pause_units}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_pause_units = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageFinishCategories')}
                                                    checked={permissions.dispatch_manage_finish_categories}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_finish_categories = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageActingBodyFinishCategories')}
                                                    checked={permissions.dispatch_manage_all_acting_bodies_finish_categories}
                                                    setChecked={(value) => {
                                                        permissions.dispatch_manage_all_acting_bodies_finish_categories = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                            </View>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('lprModule')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('fullModuleAccess')}
                                                    checked={permissions.lpr_module_access}
                                                    setChecked={(value) => {
                                                        permissions.lpr_module_access = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('editLprDetection')}
                                                    checked={permissions.lpr_edit_detection}
                                                    setChecked={(value) => {
                                                        permissions.lpr_edit_detection = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                            </View>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('cameraModule')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('cameraManagement')}
                                                    checked={permissions.camera_management}
                                                    setChecked={(value) => {
                                                        permissions.camera_management = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('cameraCreateDownload')}
                                                    checked={permissions.camera_download_video}
                                                    setChecked={(value) => {
                                                        permissions.camera_download_video = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('cameraDownloadVideo')}
                                                    checked={permissions.camera_watch_download}
                                                    setChecked={(value) => {
                                                        permissions.camera_watch_download = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('cameraDownloadReport')}
                                                    checked={permissions.camera_download_report}
                                                    setChecked={(value) => {
                                                        permissions.camera_download_report = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('managementPointsOfInterest')}
                                                    checked={permissions.camera_management_point_of_interest}
                                                    setChecked={(value) => {
                                                        permissions.camera_management_point_of_interest = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageTags')}
                                                    checked={permissions.camera_manage_tags}
                                                    setChecked={(value) => {
                                                        permissions.camera_manage_tags = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageDownloadTags')}
                                                    checked={permissions.camera_manage_download_tags}
                                                    setChecked={(value) => {
                                                        permissions.camera_manage_download_tags = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('createCameraMaintenanceRequests')}
                                                    checked={permissions.create_camera_maintenance_requests}
                                                    setChecked={(value) => {
                                                        permissions.create_camera_maintenance_requests = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('createEmergencyCameraMaintenanceRequests')}
                                                    checked={permissions.create_emergency_camera_maintenance_requests}
                                                    setChecked={(value) => {
                                                        permissions.create_emergency_camera_maintenance_requests = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('manageCameraAlertGroups')}
                                                    checked={permissions.manage_camera_alert_groups}
                                                    setChecked={(value) => {
                                                        permissions.manage_camera_alert_groups = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('silenceCameraEvents')}
                                                    checked={permissions.silence_camera_events}
                                                    setChecked={(value) => {
                                                        permissions.silence_camera_events = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('generateVideoAnalysis')}
                                                    checked={permissions.camera_video_analysis}
                                                    setChecked={(value) => {
                                                        permissions.camera_video_analysis = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('watchVideoAnalysis')}
                                                    checked={permissions.camera_watch_video_analysis}
                                                    setChecked={(value) => {
                                                        permissions.camera_watch_video_analysis = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                            </View>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('accessModule')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('editUserSettings')}
                                                    checked={permissions.access_edit_user_settings}
                                                    setChecked={(value) => {
                                                        permissions.access_edit_user_settings = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('editUserSettingActingBody')}
                                                    checked={permissions.access_edit_user_acting_body_units}
                                                    setChecked={(value) => {
                                                        permissions.access_edit_user_acting_body_units = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('managementUserTags')}
                                                    checked={permissions.access_management_user_tags}
                                                    setChecked={(value) => {
                                                        permissions.access_management_user_tags = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('editPermissionLevelSettings')}
                                                    checked={permissions.access_edit_permission_level_settings}
                                                    setChecked={(value) => {
                                                        permissions.access_edit_permission_level_settings = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('editGroupSettings')}
                                                    checked={permissions.access_edit_group_settings}
                                                    setChecked={(value) => {
                                                        permissions.access_edit_group_settings = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('editTurnstile')}
                                                    checked={permissions.access_edit_turnstile}
                                                    setChecked={(value) => {
                                                        permissions.access_edit_turnstile = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('editPlatformSettings')}
                                                    checked={permissions.access_edit_platform_settings}
                                                    setChecked={(value) => {
                                                        permissions.access_edit_platform_settings = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                            </View>
                                        </View>
                                        <View style={styles.permissionMainColumn}>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('dashboardModule')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('fullModuleAccess')}
                                                    checked={permissions.dashboard_module_access}
                                                    setChecked={(value) => {
                                                        permissions.dashboard_module_access = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('dashboardSeeOccurrences')}
                                                    checked={permissions.dashboard_see_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.dashboard_see_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('dashboardSeeCameras')}
                                                    checked={permissions.dashboard_see_cameras}
                                                    setChecked={(value) => {
                                                        permissions.dashboard_see_cameras = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('dashboardSeeAgents')}
                                                    checked={permissions.dashboard_see_agents}
                                                    setChecked={(value) => {
                                                        permissions.dashboard_see_agents = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('dashboardSeeAgentsActingBodyUnit')}
                                                    checked={permissions.dashboard_see_agents_acting_body_units}
                                                    setChecked={(value) => {
                                                        permissions.dashboard_see_agents_acting_body_units = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('dashboardSeeAgentsActingBody')}
                                                    checked={permissions.dashboard_see_agents_acting_bodies}
                                                    setChecked={(value) => {
                                                        permissions.dashboard_see_agents_acting_bodies = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('dashboardSeeVehicles')}
                                                    checked={permissions.dashboard_see_vehicles}
                                                    setChecked={(value) => {
                                                        permissions.dashboard_see_vehicles = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('dashboardSeeVehiclesActingBody')}
                                                    checked={permissions.dashboard_see_vehicles_acting_bodies}
                                                    setChecked={(value) => {
                                                        permissions.dashboard_see_vehicles_acting_bodies = value;
                                                        forceUpdate();
                                                    }}
                                                />

                                            </View>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('smartSearchModuleAccess')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('fullModuleAccess')}
                                                    checked={permissions.smart_search_module_access}
                                                    setChecked={(value) => {
                                                        permissions.smart_search_module_access = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                            </View>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('clonedPlateModuleAccess')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('fullModuleAccess')}
                                                    checked={permissions.cloned_plate_module_access}
                                                    setChecked={(value) => {
                                                        permissions.cloned_plate_module_access = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                            </View>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('logsModule')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('fullModuleAccess')}
                                                    checked={permissions.logs_module_access}
                                                    setChecked={(value) => {
                                                        permissions.logs_module_access = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                            </View>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('crmModule')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('fullModuleAccess')}
                                                    checked={permissions.crm_module_access}
                                                    setChecked={(value) => {
                                                        permissions.crm_module_access = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('getCamerasPassword')}
                                                    checked={permissions.get_camera_password}
                                                    setChecked={(value) => {
                                                        permissions.get_camera_password = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                            </View>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('appAgentModule')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('canSearchPeopleByPicture')}
                                                    checked={permissions.search_people_by_picture}
                                                    setChecked={(value) => {
                                                        permissions.search_people_by_picture = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('canSearchOnCortex')}
                                                    checked={permissions.search_people_cortex}
                                                    setChecked={(value) => {
                                                        permissions.search_people_cortex = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('canSearchOnCortexAtAnyTime')}
                                                    checked={permissions.search_cortex_any_time}
                                                    setChecked={(value) => {
                                                        permissions.search_cortex_any_time = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('canReceiveOccurrences')}
                                                    checked={permissions.receive_occurrences}
                                                    setChecked={(value) => {
                                                        permissions.receive_occurrences = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                            </View>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('eventsModule')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('fullModuleAccess')}
                                                    checked={permissions.events_module_access}
                                                    setChecked={(value) => {
                                                        permissions.events_module_access = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageWantedFaceOccurrence')}
                                                    checked={permissions.events_manage_wanted_face_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_wanted_face_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageMissingFaceOccurrence')}
                                                    checked={permissions.events_manage_missing_face_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_missing_face_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageNotAllowedFaceOccurrence')}
                                                    checked={permissions.events_manage_not_allowed__face_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_not_allowed__face_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageWantedPlateOccurrence')}
                                                    checked={permissions.events_manage_wanted_plate_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_wanted_plate_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageIrregularVehicleOccurrence')}
                                                    checked={permissions.events_manage_irregular_vehicle_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_irregular_vehicle_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageCloneSuspectOccurrence')}
                                                    checked={permissions.events_manage_clone_suspect_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_clone_suspect_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageAreaInvasionOccurrence')}
                                                    checked={permissions.events_manage_area_invasion_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_area_invasion_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageMpWantedPlateOccurrence')}
                                                    checked={permissions.events_manage_mp_wanted_plate_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_mp_wanted_plate_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageMpWantedFaceOccurrence')}
                                                    checked={permissions.events_manage_mp_wanted_face_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_mp_wanted_face_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageAlarmCenterOccurrence')}
                                                    checked={permissions.events_manage_alarm_center_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_alarm_center_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageGuardianAppOccurrence')}
                                                    checked={permissions.events_manage_guardian_app_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_guardian_app_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageSigrcOccurrence')}
                                                    checked={permissions.events_manage_sigrc_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_sigrc_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageCortexPlateDetectedOccurrence')}
                                                    checked={permissions.events_manage_cortex_plate_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_cortex_plate_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                                <MyCheckbox
                                                    label={translate('eventsManageManualOccurrence')}
                                                    checked={permissions.events_manage_manual_occurrence}
                                                    setChecked={(value) => {
                                                        permissions.events_manage_manual_occurrence = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                            </View>
                                            <View style={styles.permissionColumn}>
                                                <MyAppText style={styles.permissionSubTitle}>{translate('guardianAppModule')}</MyAppText>
                                                <MyCheckbox
                                                    label={translate('fullModuleAccess')}
                                                    checked={permissions.guardian_app_module_access}
                                                    setChecked={(value) => {
                                                        permissions.guardian_app_module_access = value;
                                                        forceUpdate();
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </ScrollView>);
                            }
                        }}
                    </Tabs>
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            onClose(false);
                            cleanValues();
                        }}
                        disabled={!isFormValid()} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        height: '80%',
        backgroundColor: theme.colors.cardBackground,
        borderRadius: 2,
        padding: 20,
        minHeight: 520,
        justifyContent: 'space-between',
        rowGap: 10
    },
    permissionsContainer: {
        rowGap: 20,
        flexDirection: 'row',
        flex: 1,
        flexWrap: 'wrap'
    },
    permissionMainColumn: {
        flex: 1,
        flexDirection: 'column',
        gap: 15,
        minWidth: 200
    },
    permissionColumn: {
        flexDirection: 'column',
        gap: 5,
    },
    permissionSubTitle: {
        color: theme.colors.labelColor,
        fontWeight: 'bold',
        fontSize: 14,
    },
}));
