import React, { useEffect, useState } from 'react';
import {
    View,
    TouchableOpacity,
    Modal,
    ActivityIndicator,
    Image,
    ScrollView,
    Switch,
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faArrowLeft, faSearchPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import { WindowInformation } from '../../../services/window-information';
import { Hoverable } from 'react-native-web-hover';
import { MoreThanOneFaceOnPhoto, NoFaceOnPhoto, PersonType, facialService } from '../../../services/central-api/facial';
import DeleteModal from '../../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomButton from '../../../components/CustomButton';
import AllowListGroupsDropdown from '../../../components/facial/AllowListGroupsDropdown';
import FormInput from '../../../components/formInput';
import MyCpfInput from '../../../components/MyCpfInput';
import TagsDropdown from '../../../components/facial/TagsDropdown';
import PersonTypeDropdown from '../../../components/facial/PersonTypeDropdown';
import { ClientError } from '../../../services/central-api/base-service';

interface PersonFormParams {
    navigation: Navigation;
    route: {
        params?: {
            id: number;
        };
    };
}

export default function PersonForm({ navigation, route }: PersonFormParams) {
    const { styles, theme } = useStyles(stylesheet);

    const windowInfo = WindowInformation();

    const [isLoading, setIsLoading] = useState(false);
    const [imageZoom, setImageZoom] = useState<FacialFacePicture>();
    const [files, setFiles] = useState<{ file: File, objectURI: string; key: string; }[]>([]);
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [isDeleteFacePictureModalVisible, setDeleteFacePictureModalVisible] = useState<boolean>(false);
    const [facePictureToDelete, setFacePictureToDelete] = useState<FacialFacePicture>();
    const [isCpfInvalid, setCpfInvalid] = useState(false);

    // person props
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [nickname, setNickname] = useState('');
    const [document, setDocument] = useState('');
    const [personMarkers, setPersonMarkers] = useState<number[]>([]);
    const [personType, setPersonType] = useState<PersonType | ''>('');
    const [facePictures, setFacePictures] = useState<FacialFacePicture[]>([]);
    const [register, setRegister] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [selectedAllowListGroupsIds, setSelectedAllowListGroupsIds] = useState<number[]>([]);

    // lawsuit props
    const [arrestWarrantNumber, setArrestWarrantNumber] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [issuingBody, setIssuingBody] = useState('');
    const [lawsuitCity, setLawsuitCity] = useState('');
    const [lawsuitNumber, setLawsuitNumber] = useState('');
    const [magistrate, setMagistrate] = useState('');
    const [prisonKind, setPrisonKind] = useState('');
    const [shippingDate, setShippingDate] = useState('');
    const [situation, setSituation] = useState('');

    async function getPerson(id: number) {
        try {
            setIsLoading(true);
            const person = await facialService.getFacialPerson(id);

            // person props
            setComment(person.comment || '');
            setIsActive(person.isActive);
            setName(person.name);
            setNickname(person.nickname || '');
            setDocument(person.document || '');
            setRegister(person.register || '');
            setState(person.state || '');
            setCity(person.city || '');
            setPersonMarkers(person.markers.map(p => p.id || 0));
            setFacePictures(person.facePictures);
            setPersonType(person.personType as PersonType);
            setSelectedAllowListGroupsIds(person.AllowListGroups.map(alg => alg.id));

            // lawsuit props
            setArrestWarrantNumber(person.lawsuits[0].arrestWarrantNumber || '');
            setExpirationDate(person.lawsuits[0].expirationDate || '');
            setIssuingBody(person.lawsuits[0].issuingBody || '');
            setLawsuitCity(person.lawsuits[0].lawsuitCity || '');
            setLawsuitNumber(person.lawsuits[0].lawsuitNumber || '');
            setMagistrate(person.lawsuits[0].magistrate || '');
            setPrisonKind(person.lawsuits[0].prisonKind || '');
            setShippingDate(person.lawsuits[0].shippingDate || '');
            setSituation(person.lawsuits[0].situation || '');

        } catch (err) {
            console.error(err);
        } finally {
            setAlreadyLoaded(true);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (route.params?.id && !alreadyLoaded) {
            getPerson(route.params.id);
        }
    }, [route.params]);


    async function deletePerson(personId: number) {
        try {
            setIsLoading(true);
            await facialService.deletePerson(personId);
            navigation.navigate('PeopleList');
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    async function deleteFacePicture(facePicture: FacialFacePicture) {
        try {
            setIsLoading(true);

            await facialService.deleteFacePicture({
                personId: route.params?.id || 0,
                facePictureId: facePicture.id
            });

            const index = facePictures.indexOf(facePicture);
            if (index !== -1) {
                facePictures.splice(index, 1);
            }
            setFacePictures([...facePictures]);

        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function save() {
        try {
            setIsLoading(true);

            if (!name) {
                Toast.show({
                    type: 'sentinelxError',
                    text1: translate('youNeedToProvideName'),
                });
                setIsLoading(false);
                return;
            }

            let personId: number;

            if (!personType) {
                return;
            }

            if (route.params?.id) {
                personId = route.params?.id;
                await facialService.updatePerson(route.params.id, {
                    name,
                    isActive,
                    nickname,
                    document,
                    comment,
                    register,
                    state,
                    city,
                    arrestWarrantNumber,
                    expirationDate,
                    issuingBody,
                    lawsuitCity,
                    lawsuitNumber,
                    magistrate,
                    prisonKind,
                    shippingDate,
                    situation,
                    markers: personMarkers,
                    allowListGroupsIds: selectedAllowListGroupsIds
                });
            } else {
                const res = await facialService.addPerson({
                    name,
                    isActive,
                    nickname,
                    document,
                    comment,
                    register,
                    state,
                    city,
                    arrestWarrantNumber,
                    expirationDate,
                    issuingBody,
                    lawsuitCity,
                    lawsuitNumber,
                    magistrate,
                    prisonKind,
                    shippingDate,
                    situation,
                    personType,
                    markers: personMarkers,
                    allowListGroupsIds: selectedAllowListGroupsIds
                });
                personId = res.id || 0;
            }

            for (const file of files) {
                await facialService.addFacePicture({
                    personId,
                    photo: file.file
                });
            }

            navigation.navigate('PeopleList');
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof NoFaceOnPhoto) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            if (err instanceof MoreThanOneFaceOnPhoto) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>
                        <TouchableOpacity onPress={() => navigation.canGoBack() ? navigation.goBack() : navigation.navigate('PeopleList')}>
                            <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={theme.colors.primaryButton.background} />
                        </TouchableOpacity>
                        <MyAppText style={{ fontSize: 24, color: theme.colors.labelColor, fontWeight: 'bold' }}>
                            {translate('registerPerson')}
                        </MyAppText>
                    </View>


                    {route.params?.id && <View style={{ justifyContent: 'flex-end' }}>
                        <CustomButton text={translate('delete')} onPress={() => setDeleteModalVisible(true)} type='secondary' />
                    </View>}

                    <View style={{ justifyContent: 'flex-end' }}>
                        <CustomButton text={translate('save')} onPress={save} disabled={!name || !personType || isCpfInvalid} />
                    </View>
                </View>

                <View style={styles.cardList}>
                    <ScrollView contentContainerStyle={{ padding: 24, gap: 10 }}>
                        {isLoading &&
                            <ActivityIndicator size='small' color={theme.colors.loadingColor} style={{ position: 'absolute', top: 30, right: 20 }} />
                        }
                        <View style={{ gap: 10, zIndex: 2 }}>
                            <View style={styles.subTitleContainer}>
                                <MyAppText style={styles.subTitleText}>
                                    {translate('general_info')}
                                </MyAppText>
                            </View>
                            <View style={{ flexDirection: windowInfo.isMobile ? 'column' : 'row', flexWrap: 'wrap', zIndex: 2 }}>
                                {/* image blocks */}
                                <View style={{
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    gap: 0,
                                    alignItems: 'flex-start',
                                    flex: 1
                                }}>
                                    {facePictures.map((facePicture) =>

                                        <View key={facePicture.id.toString()} style={{ marginRight: 10 }}>
                                            <Hoverable>
                                                {({ hovered }) => (<>
                                                    <Image
                                                        style={{
                                                            height: 136, width: 136,
                                                            borderRadius: 8,
                                                            overflow: 'hidden',
                                                        }}
                                                        source={{ uri: facePicture.imageUrl }}
                                                    />

                                                    {hovered &&
                                                        <View style={{
                                                            position: 'absolute',
                                                            width: '100%',
                                                            height: '100%',
                                                            backgroundColor: '#00000080',
                                                            borderRadius: 8
                                                        }}>
                                                            <TouchableOpacity
                                                                style={{
                                                                    height: 40,
                                                                    width: 40,
                                                                    position: 'absolute',
                                                                    top: 60,
                                                                    left: 20
                                                                }}
                                                                onPress={async () => {
                                                                    setImageZoom(facePicture);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faSearchPlus} fontSize={22} color={'#FFFFFF'} />
                                                            </TouchableOpacity>
                                                            <TouchableOpacity
                                                                style={{
                                                                    height: 40,
                                                                    width: 40,
                                                                    position: 'absolute',
                                                                    top: 60,
                                                                    right: 20
                                                                }}
                                                                onPress={() => {
                                                                    setFacePictureToDelete(facePicture);
                                                                    setDeleteFacePictureModalVisible(true);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} fontSize={22} color={'#FFFFFF'} />
                                                            </TouchableOpacity>
                                                        </View>
                                                    }

                                                </>)}
                                            </Hoverable>

                                        </View>

                                    )}

                                    {files.map((img) =>

                                        <Image
                                            key={img.key}
                                            style={{
                                                height: 136, width: 136,
                                                borderRadius: 8,
                                                overflow: 'hidden',
                                                marginRight: 10
                                            }}
                                            source={{
                                                uri: img.objectURI
                                            }}
                                        />

                                    )}
                                    <Hoverable>
                                        {({ hovered }) => (
                                            <TouchableOpacity style={{
                                                height: 136,
                                                width: 136,
                                                borderRadius: 8,
                                                overflow: 'hidden',
                                                backgroundColor: hovered ? theme.colors.primaryButton.hoverBackground : theme.colors.primaryButton.background,
                                                marginRight: 10,
                                            }} >
                                                <label style={{ width: '100%', height: '100%', display: 'grid' }}>
                                                    <input
                                                        style={{ display: 'none' }}
                                                        type='file'
                                                        onChange={(e) => {
                                                            if (!e.target.files) {
                                                                return;
                                                            }
                                                            const file = e.target.files[0];

                                                            if (!['image/jpeg', 'image/png'].includes(file.type)) {
                                                                return Toast.show({
                                                                    type: 'sentinelxError',
                                                                    text1: translate('imageFormatNotValid'),
                                                                });
                                                            }
                                                            setFiles(current => [...current, { file, objectURI: URL.createObjectURL(file), key: String(Math.random()) }]);
                                                        }}
                                                        accept='image/jpeg,image/png'
                                                    />
                                                    <FontAwesomeIcon fontSize={30} icon={faAdd} color={theme.colors.container.background} style={{ justifySelf: 'center', alignSelf: 'center' }} />

                                                </label>
                                            </TouchableOpacity>
                                        )}
                                    </Hoverable>

                                </View>

                                {/* personal data  */}
                                <View style={{ gap: 5, flex: 1 }}>
                                    <PersonTypeDropdown
                                        value={personType}
                                        setValue={setPersonType}
                                        zIndex={3}
                                        disabled={!!route.params?.id}
                                        includeEmptyItem={true}
                                    />
                                    <FormInput
                                        label={translate('name')}
                                        value={name}
                                        onChangeText={setName}
                                        invalid={() => name === ''}
                                    />
                                    <View style={{ height: 40, gap: 5, flexDirection: 'row', alignItems: 'center' }}>
                                        <MyAppText style={{
                                            fontSize: 16,
                                            height: 25,
                                            color: theme.colors.labelColor,
                                        }}>{translate('active')}</MyAppText>
                                        <Switch
                                            trackColor={{ false: '#767577', true: '#81b0ff' }}
                                            thumbColor={isActive ? '#f5dd4b' : '#f4f3f4'}
                                            onValueChange={(value) => {
                                                setIsActive(value);
                                            }}
                                            value={isActive}
                                            style={{ alignSelf: 'center' }}
                                        />
                                    </View>
                                    {personType == 'allow_list' ?
                                        <AllowListGroupsDropdown
                                            value={selectedAllowListGroupsIds}
                                            setValue={setSelectedAllowListGroupsIds}
                                            zIndex={2} />
                                        : null}
                                    <FormInput
                                        label={translate('nickname')}
                                        value={nickname}
                                        onChangeText={setNickname}
                                        invalid={() => false}
                                    />
                                    <MyCpfInput
                                        value={document}
                                        onChangeText={(value) => {
                                            setDocument(value);
                                            if (value === '') {
                                                setCpfInvalid(false);
                                            }
                                        }}
                                        viewStyle={{ flexGrow: 1, minWidth: 200 }}
                                        isInvalid={isCpfInvalid}
                                        setIsInvalid={setCpfInvalid}
                                    />
                                    <FormInput
                                        label={translate('personRegister')}
                                        value={register}
                                        onChangeText={setRegister}
                                        invalid={() => false}
                                    />
                                    <FormInput
                                        label={translate('state')}
                                        value={state}
                                        onChangeText={setState}
                                        invalid={() => false}
                                    />
                                    <FormInput
                                        label={translate('city')}
                                        value={city}
                                        onChangeText={setCity}
                                        invalid={() => false}
                                    />
                                    <TagsDropdown value={personMarkers} setValue={setPersonMarkers} zIndex={3} />
                                </View>
                            </View>
                        </View>
                        {
                            personType == PersonType.WANTED && <View style={{ gap: 10 }}>
                                <View style={styles.subTitleContainer}>
                                    <MyAppText style={styles.subTitleText}>
                                        {translate('arrestWarrants')}
                                    </MyAppText>
                                </View>
                                <View style={{ flexDirection: windowInfo.isMobile ? 'column' : 'row', gap: 10 }}>
                                    <View style={{ flexDirection: 'column', flex: 1, gap: 10 }}>
                                        <FormInput
                                            label={translate('situation')}
                                            value={situation}
                                            onChangeText={setSituation}
                                            invalid={() => false}
                                        />
                                        <FormInput
                                            label={translate('arrestWarrantNumber')}
                                            value={arrestWarrantNumber}
                                            onChangeText={setArrestWarrantNumber}
                                            invalid={() => false}
                                        />
                                        <FormInput
                                            label={translate('shippingDate')}
                                            value={shippingDate}
                                            onChangeText={setShippingDate}
                                            invalid={() => false}
                                        />
                                        <FormInput
                                            label={translate('expirationDate')}
                                            value={expirationDate}
                                            onChangeText={setExpirationDate}
                                            invalid={() => false}
                                        />
                                        <FormInput
                                            label={translate('lawsuitNumber')}
                                            value={lawsuitNumber}
                                            onChangeText={setLawsuitNumber}
                                            invalid={() => false}
                                        />
                                    </View>
                                    <View style={{ flexDirection: 'column', flex: 1, gap: 10 }}>
                                        <FormInput
                                            label={translate('prisonKind')}
                                            value={prisonKind}
                                            onChangeText={setPrisonKind}
                                            invalid={() => false}
                                        />
                                        <FormInput
                                            label={translate('magistrate')}
                                            value={magistrate}
                                            onChangeText={setMagistrate}
                                            invalid={() => false}
                                        />
                                        <FormInput
                                            label={translate('issuingBody')}
                                            value={issuingBody}
                                            onChangeText={setIssuingBody}
                                            invalid={() => false}
                                        />
                                        <FormInput
                                            label={translate('county')}
                                            value={lawsuitCity}
                                            onChangeText={setLawsuitCity}
                                            invalid={() => false}
                                        />
                                    </View>
                                </View>
                            </View>
                        }
                        <View style={{ gap: 10 }}>
                            <View style={styles.subTitleContainer}>
                                <MyAppText style={styles.subTitleText}>
                                    {translate('additionalComments')}
                                </MyAppText>
                            </View>
                            <FormInput
                                value={comment}
                                onChangeText={setComment}
                                invalid={() => false}
                                multiline={true}
                                numberOfLines={5}
                                maxLength={16777216}
                            />
                        </View>
                        {/* Zoom image modal */}
                        <Modal
                            animationType='fade'
                            transparent={true}
                            visible={imageZoom !== undefined}
                            onRequestClose={() => setImageZoom(undefined)}>
                            <View style={styles.modalContainer}>
                                <View style={{
                                    backgroundColor: theme.colors.container.background,
                                    borderRadius: 8,
                                    padding: 35,
                                    alignItems: 'center',
                                    width: 600,
                                    marginLeft: (windowInfo.width / 2) - 300,
                                    marginTop: (windowInfo.height / 2) - 300,
                                }}>
                                    <TouchableOpacity
                                        style={{ margin: 10, position: 'absolute', top: 10, left: 10 }}
                                        onPress={() => setImageZoom(undefined)}>
                                        <FontAwesomeIcon icon={faTimes} fontSize={15} color={theme.colors.textColor} />
                                    </TouchableOpacity>
                                    <Image
                                        style={{
                                            height: 600,
                                            width: '100%',
                                            borderRadius: 8
                                        }}
                                        resizeMode='contain'
                                        source={{
                                            uri: imageZoom?.imageUrl
                                        }}
                                    />
                                </View>
                            </View>
                        </Modal>
                    </ScrollView>
                </View>
            </View>

            <DeleteModal
                setModalVisible={setDeleteModalVisible}
                isModalVisible={isDeleteModalVisible}
                itemName={name}
                onSubmit={() => deletePerson(route.params?.id || 0)}
            />

            <DeleteModal
                setModalVisible={setDeleteFacePictureModalVisible}
                isModalVisible={isDeleteFacePictureModalVisible}
                onSubmit={() => facePictureToDelete && deleteFacePicture(facePictureToDelete)}
            />
        </>
    );
}


const stylesheet = createStyleSheet(theme => ({
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199'
    },
    subTitleContainer: {
        borderBottomWidth: 1,
        borderColor: theme.colors.labelColor,
    },
    subTitleText: {
        color: theme.colors.labelColor,
        fontWeight: 'bold',
        fontSize: 16
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        backgroundColor: theme.colors.container.background,
        flex: 1,
    },
    container: {
        flex: 1,
        rowGap: 15
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        flexWrap: 'wrap',
        minHeight: 65
    },
    error: {
        color: 'red',
        padding: 10,
    },
    image: {
        width: '100%',
        height: '100%',
        aspectRatio: 9 / 16,
    }
}));
