import React from 'react';
import LateralMenu from '../LateralMenu';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_smart_search_facial.png';
import collapsedLogo from '../../../assets/icon_smart_search_facial.png';

export default function SmartSearchFacialLateralMenu({ navigation, open, authenticatedUser, setOpen, selectedMenu }: LateralMenuProps) {

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={[{
                icon: faSearch,
                text: translate('searchByPhoto'),
                action: () => navigation.navigate('SmartSearchFacial', {
                    screen: 'search'
                }),
                selected: selectedMenu == 'search'
            }]}
        />
    );
}
