import React from 'react';
import LateralMenu from '../LateralMenu';
import { faCar, faClipboardList, faFolderOpen, faSearch, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_lpr.png';
import collapsedLogo from '../../../assets/icon_lpr.png';

export default function LprLateralMenu({ navigation, open, authenticatedUser, setOpen, selectedMenu }: LateralMenuProps) {
    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={[{
                icon: faClipboardList,
                text: translate('liveEvents'),
                action: () => navigation.navigate('LPR', { screen: 'LprLiveEvents' }),
                selected: selectedMenu == 'live-events'
            }, {
                icon: faTriangleExclamation,
                text: translate('liveAlerts'),
                action: () => navigation.navigate('LPR', { screen: 'LprLiveAlerts' }),
                selected: selectedMenu == 'live-alerts'
            }, {
                icon: faSearch,
                text: translate('search'),
                action: () => navigation.navigate('LPR', { screen: 'LprSearch' }),
                selected: selectedMenu == 'search'
            }, {
                icon: faCar,
                text: translate('plates'),
                action: () => navigation.navigate('LPR', { screen: 'Plates' }),
                selected: selectedMenu == 'plates'
            }, {
                icon: faFolderOpen,
                text: translate('reports'),
                action: () => navigation.navigate('LPR', { screen: 'LprReports' }),
                selected: selectedMenu == 'reports'
            }]}
        />
    );
}
