import { PaginatedResource } from '../../typings/Paginated';
import { Log } from './audit-logs';
import { ClientError, ExtendableError, InvalidValue, Unauthorized, UnexpectedError, BaseService, NotFound, GenericPaginatedParameters, PartialBy } from './base-service';

export interface GetVehiclesPaginatedParameters extends GenericPaginatedParameters {
    status?: string;
}

export enum TeamNameCategory {
    LIGHT_VEHICLE = 'light_vehicle',
    MOTORCYCLE = 'motorcycle',
    ON_FOOT = 'on_foot',
    BICYCLE = 'bicycle',
    BOAT = 'boat',
    HEAVY_VEHICLE = 'heavy_vehicle',
    TRACTOR_TRUCK = 'tractor_truck',
    VAN = 'van',
    JET_SKI = 'jet_ski',
    BUS = 'bus'
}

interface CreateUnitParameters {
    unitNameId: number;
    unitProgramId: number;
    actingBodyUnitId: number;
    actingBodyCommandId: number;
    description?: string;
    usersToSet: number[];
    equipmentsToSet: number[];
    estimatedStart: string;
    estimatedFinish: string;
    /**
     * Modifications on agents assignments. The key stands for the AgentId and the value for the AssignmentId
     */
    modifiedAgentAssignments: { [agentId: number]: number; };
}

interface UpdateUnitParameters {
    unitId: number;
    unitNameId: number;
    unitProgramId: number;
    actingBodyCommandId: number;
    description?: string;
    usersToSet: number[];
    equipmentsToSet: number[];
    estimatedStart: string;
    estimatedFinish: string;
    /**
     * Modifications on agents assignments. The key stands for the AgentId and the value for the AssignmentId
     */
    modifiedAgentAssignments: { [agentId: number]: number; };
}

export interface GetUnitsPaginatedParameters extends GenericPaginatedParameters {
    agentFilter?: string;
    beginIn?: number;
    endIn?: number;
    status: string;
    actingBodyId?: number;
    actingBodyUnitIds?: number[];
}

export class AlreadyWithThisUser extends ExtendableError {
    constructor() {
        super(`You selected the same user that already is the responsible for this occurrence.`);
    }
}

export class RecordNotLatestValues extends ExtendableError {
    userName: string;
    constructor(code: string, userName: string) {
        super(code);
        this.userName = userName;
    }
}

interface CreateReportParameters {
    name: string;
    isPdf: boolean;
    startDate: string;
    endDate: string;
    tags?: number[];
    actingBodyId?: number;
    assumedBy?: number;
    transferredBy?: number;
    receivedBy?: number;
    finishedBy?: number;
    actingBodyCommandIds?: number[];
}

interface CreateSkillParameters {
    name: string;
    description: string;
    actingBodyId?: number;
    /**
     * List of user IDs to be added
     */
    usersToAdd: number[];
}

interface CreateTeamName extends Omit<BaseTeamName, 'id' | 'actingBodyId'> {
    actingBodyId?: number;
}

interface CreateAssignmentParameters extends Omit<BaseAssignment, 'id' | 'actingBodyId'> {
    actingBodyId?: number;
}

interface UpdateSkillParameters extends CreateSkillParameters {
    /**
     * List of user IDs to be removed
     */
    usersToRm: number[];
}

export interface GetResourcePaginatedByActingBodyParameters extends GenericPaginatedParameters {
    actingBodyId?: number;
    actingBodyCommandIds?: number[];
}

export interface GetOccurrenceTypesPaginatedParameters extends GetResourcePaginatedByActingBodyParameters {
    isActive: boolean;
}

export interface GetEquipmentsPaginatedParameters extends GetResourcePaginatedByActingBodyParameters {
    unitId?: number;
}

interface CreateOccurrenceType extends Omit<BaseOccurrenceType, 'id' | 'actingBodyId'> {
    actingBodyId?: number;
    tags?: number[];
}

export interface SimplifiedOccurrenceType extends Omit<BaseOccurrenceType, 'active' | 'actingBodyId'> { }

export enum HandcuffReasonType {
    'RESISTANCE' = 'resistance',
    'FEAR_OF_ESCAPE' = 'fearOfEscape',
    'DANGER_TO_PHYSICAL_INTEGRITY' = 'dangerToPhysicalIntegrity',
}

export interface HandcuffReason {
    id: number;
    name: string;
    type: HandcuffReasonType;
}

export interface GetOccurrencesParameters extends GenericPaginatedParameters {
    triggerType?: TriggerType | 'manual';
    priority?: OccurrencePriorities;
    situation?: OccurrenceSituation;
    occurrenceTypeId?: number[];
    responsibleUserId?: number;
    unitNamesIds?: number[];
    tags?: number[];
    actingBodyId?: number;
    assumedBy?: number;
    transferredBy?: number;
    receivedBy?: number;
    finishedBy?: number;
    actingBodyCommandIds?: number[];
}

export const ONLINE_AGENT_TIME_MINUTES = 15;

export class DispatchService extends BaseService {
    async getOccurrences(params: GetOccurrencesParameters): Promise<PaginatedResource<PaginatedOccurrence>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrences${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getActiveUnits(): Promise<Unit[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/simplified-active-units`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getDropDownOccurrenceTypes(actingBodyId?: number): Promise<SimplifiedOccurrenceType[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/simplified-occurrence-types${this.encodeQueryParams({
            actingBodyId
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getDropDownUnitPrograms(actingBodyId?: number): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/unit-programs-simplified${this.encodeQueryParams({
            actingBodyId
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getDropDownProvidences(actingBodyId?: number): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/providences-simplified${this.encodeQueryParams({
            actingBodyId
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getDropDownCityProperties(actingBodyId?: number): Promise<DropdownCityProperty[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/city-properties-simplified${this.encodeQueryParams({
            actingBodyId
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getHandcuffReasons(actingBodyId?: number): Promise<HandcuffReason[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/handcuff-reasons${this.encodeQueryParams({
            actingBodyId
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrenceTypes(params: GetOccurrenceTypesPaginatedParameters): Promise<PaginatedResource<OccurrenceType>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence_types${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrenceVideoUrl(id: number): Promise<VideoUrl> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${id}/video-url`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAgentPositions(unitName?: string): Promise<UnitMapPosition[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/agent/positions${this.encodeQueryParams({ unitName })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getEquipments(params: GetEquipmentsPaginatedParameters): Promise<PaginatedResource<Equipment>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/equipments${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createPersonRelation(personRelation: PartialBy<Omit<BasePersonRelation, 'id'>, 'name'>): Promise<PersonRelation> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/person-relation`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(personRelation)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updatePersonRelation(personRelation: PartialBy<Omit<BasePersonRelation, 'actingBodyId'>, 'id'>): Promise<PersonRelation> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/person-relation/${personRelation.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(personRelation)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getPersonRelations(params: GetResourcePaginatedByActingBodyParameters): Promise<PaginatedResource<PersonRelation>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/person-relations${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedPersonRelations(actingBodyId: number): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/person-relations-simplified${this.encodeQueryParams({ actingBodyId })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deletePersonRelation(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/person-relation/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async createVehicleRelation(vehicleRelation: PartialBy<Omit<VehicleRelation, 'id'>, 'name'>): Promise<VehicleRelation> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle-relation`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(vehicleRelation)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateVehicleRelation(vehicleRelation: PartialBy<Omit<VehicleRelation, 'actingBodyId'>, 'id'>): Promise<VehicleRelation> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle-relation/${vehicleRelation.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(vehicleRelation)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getVehicleRelations(params: GetResourcePaginatedByActingBodyParameters): Promise<PaginatedResource<VehicleRelation>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle-relations${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedVehicleRelations(actingBodyId: number): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle-relations-simplified${this.encodeQueryParams({ actingBodyId })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteVehicleRelation(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle-relation/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getSimplifiedEquipments(actingBodyId: number): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/equipments-simplified${this.encodeQueryParams({ actingBodyId })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrencePositions(): Promise<OccurrencePosition[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrences/positions`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getActingBodies(): Promise<ActingBodySimplified[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/acting-bodies`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async getUnitNames(actingBodyId?: number): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/unit-names${this.encodeQueryParams({ actingBodyId })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    /**
     *
     * @param id
     * @throws {ClientError} CantFinishConcludedSituation
     */
    async finishOccurrence(id: number, category: number, subCategory: number, other?: string) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${id}/finish`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ category, subCategory, other })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    /**
     *
     * @param id
     * @throws {ClientError} CantReopenOpenedSituation
     * @throws {ClientError} MoreThan72Hours
     */
    async reopenOccurrence(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${id}/reopen`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createReport(params: CreateReportParameters) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/report`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getReports(params: GetResourcePaginatedByActingBodyParameters): Promise<PaginatedResource<ReportOccurrence>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/reports/paginated${this.encodeQueryParams({
            ...params,
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getReport(id: number): Promise<{ resultUrl: string; }> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/report/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAgents({ actingBodyId }: {
        actingBodyId?: number;
    }): Promise<SimplifiedUserForUnit[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/agents${this.encodeQueryParams({ actingBodyId })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSkills(params: GetResourcePaginatedByActingBodyParameters): Promise<PaginatedResource<Skill>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skills${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedSkills(actingBodyId: number): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skills-simplified${this.encodeQueryParams({ actingBodyId })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getEventOperators({ actingBodyId, occurrenceId }: {
        actingBodyId: number;
        occurrenceId: number;
    }): Promise<{ id: number; name: string; warName: string; }[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/event-operators${this.encodeQueryParams({ actingBodyId, occurrenceId })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSkillUsers(actingBodyId?: number): Promise<SimplifiedUser[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skill/users${this.encodeQueryParams({ actingBodyId })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSkill(id: number): Promise<Skill> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skill/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @param occurrenceId
     * @param params
     * @returns
     * @throws {AlreadyWithThisUser}
     * @throws {ClientError} CantEditConcludedSituation
     */
    async transferOccurrence(occurrenceId: number, params: {
        eventOperatorId: number;
    }): Promise<OccurrenceReport> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${occurrenceId}/transfer`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });
        if (res.status == 403) {
            throw new ClientError('ForbiddenToTransferOccurrence');
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'AlreadyWithThisUser') {
                throw new AlreadyWithThisUser();
            }
            throw new ClientError(resJson.code);
        }
        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createSkill({ name, description, usersToAdd, actingBodyId }: CreateSkillParameters): Promise<Skill> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skill`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ name, description, usersToAdd, actingBodyId }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 400) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateSkill(id: number, { name, description, usersToAdd, usersToRm }: UpdateSkillParameters): Promise<Skill> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skill/${id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ name, description, usersToAdd, usersToRm }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 400) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteSkill(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skill/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getAssignments(params: GetResourcePaginatedByActingBodyParameters): Promise<PaginatedResource<Assignment>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/assignments${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedAssignments(actingBodyId?: number): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/assignments-simplified${this.encodeQueryParams({ actingBodyId })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAssignment(id: number): Promise<Assignment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/assignment/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createAssignment({ name, description, actingBodyId }: CreateAssignmentParameters): Promise<Assignment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/assignment`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ name, description, actingBodyId }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 400) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateAssignment({ id, name, description }: PartialBy<Omit<BaseAssignment, 'actingBodyId'>, 'id'>): Promise<Assignment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/assignment/${id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ name, description }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 400) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteAssignment(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/assignment/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createOccurrence(occurrence: {
        phone: string;
        requester: string;
        latitude: number;
        longitude: number;
        priority: OccurrencePriorities;
        narrative: string;
        occurrenceTypeId: number;
        tags?: number[];
        involvedVehicles?: InvolvedVehicle[];
        involvedPeople?: InvolvedPerson[];
        involvedObjects?: InvolvedObject[];
        actingBodyId?: number;
        cityPropertyId?: number | null,
        policeReport: string,
    }): Promise<Occurrence> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(occurrence)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        } else if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @param occurrence
     * @returns
     * @throws {ClientError} CantEditConcludedSituation
     */
    async updateOccurrence(occurrence: {
        id: number;
        updatedAt: string;
        phone?: string;
        requester?: string;
        latitude?: number;
        longitude?: number;
        priority?: OccurrencePriorities;
        narrative?: string;
        occurrenceTypeId?: number;
        unitsToSet?: { id: number; isStarter: boolean; }[];
        tags?: number[];
        involvedVehicles?: InvolvedVehicle[];
        involvedPeople?: InvolvedPerson[];
        involvedObjects?: InvolvedObject[];
        cityPropertyId?: number | null;
        policeReport: string;
    }): Promise<Occurrence> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${occurrence.id}`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(occurrence)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            } else if (resJson.code == 'RecordNotLatestValues') {
                throw new RecordNotLatestValues(resJson.code, resJson.userName);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrence(id: number): Promise<Occurrence> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async generatePDFOccurrence(id: number, data: Record<string, string>) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${id}/generate-pdf`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                translate: data,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        const blob = res.blob();

        return blob;
    }

    /**
     *
     * @param id
     * @throws {ClientError}
     */
    async setOccurrenceInService(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${id}/in_service`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async getStatistics(): Promise<Statistic> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/statistics${this.encodeQueryParams({
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrencePriorities(): Promise<string[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence_priorities`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrenceType(id: number): Promise<OccurrenceType> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence_type/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteOccurrenceType(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence_type/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createOccurrenceType(occurrenceType: CreateOccurrenceType): Promise<OccurrenceType> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence_type`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(occurrenceType)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateOccurrenceType(occurrenceType: PartialBy<Omit<BaseOccurrenceType, 'actingBodyId'>, 'id'>): Promise<OccurrenceType> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence_type/${occurrenceType.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(occurrenceType)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getVehicleTypes(): Promise<string[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle_type`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getVehicleStatus(): Promise<VehicleStatus[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle_status`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createEquipment(equipment: CreateEquipment): Promise<Equipment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/equipment`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(equipment)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateEquipment(equipment: PartialBy<Omit<BaseEquipment, 'actingBodyId'>, 'id'>): Promise<Equipment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/equipment/${equipment.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(equipment)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteEquipment(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/equipment/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createOccurrenceAttachment(occurrenceId: number, file: File): Promise<OccurrenceReport> {
        const token = await this.getToken();
        const formData = new FormData();
        formData.append('file', file, file.name);

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${occurrenceId}/attachment`, {
            method: 'POST',
            headers: this.getHeaders(token),
            body: formData,
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createOccurrenceReport(occurrenceId: number, content: string): Promise<OccurrenceReport> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${occurrenceId}/report`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ 'content': content }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getCaptureHistoric(occurrenceId: number): Promise<Occurrence[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${occurrenceId}/capture-historic`, {
            method: 'GET',
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getPaginatedUnits(params: GetUnitsPaginatedParameters): Promise<PaginatedResource<PaginatedUnit>> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/units/paginated${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getUnits(params: { equipmentsIds: number[]; skillsIds: number[]; unitName?: string; dispatchedUnits: number[]; actingBodyUnitIds?: number[]; actingBodyId: number; }): Promise<Unit[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/units${this.encodeQueryParams(params)}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrenceDispatchedUnits(occurrenceId: number): Promise<Unit[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/${occurrenceId}/dispatched-units`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedUnits(): Promise<SimplifiedUnit[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/units-simplified`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createUnit(params: CreateUnitParameters): Promise<Unit> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/unit`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateUnit(params: UpdateUnitParameters): Promise<Unit> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/${params.unitId}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @returns
     * @throws {ClientError} CannotFinishUnitWithOccurrences
     */
    async finishUnit(id: number, reason?: string): Promise<void> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/${id}/finished`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ reason })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    /**
     *
     * @returns
     * @throws {ClientError} CurrentUnitNotActive | CannotPauseUnitWithOccurrences
     */
    async pauseUnpauseUnit(id: number, action: 'pause' | 'unpause', pauseReasonId?: number) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/${id}/pause`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ action, pauseReasonId })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getPauseReasons(): Promise<PauseReason[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/pause-reasons`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getDisplaceReasons(): Promise<DisplaceReason[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/displace-reasons`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getUnit(id: number): Promise<Unit> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getUnitHistoric(id: number): Promise<Log[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/${id}/finished-historic`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrenceTags(params: GetResourcePaginatedByActingBodyParameters): Promise<PaginatedResource<OccurrenceTag>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrences/paginated-tags${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createOccurrenceTag(params: CreateOccurrenceTag): Promise<OccurrenceTag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrences/tag`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateOccurrenceTag(params: PartialBy<Omit<BaseOccurrenceTag, 'actingBodyId'>, 'id'>): Promise<OccurrenceTag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrences/tag/${params.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getDropDownOccurrenceTags(actingBodyId?: number): Promise<SimplifiedOccurrenceTag[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/simplified-occurrence-tags${this.encodeQueryParams({
            actingBodyId
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteOccurrenceTag(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrences/tag/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async getDropDownReportTags(actingBodyId?: number): Promise<SimplifiedReportTag[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/simplified-report-tags${this.encodeQueryParams({
            actingBodyId
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getReportTags(params: GetResourcePaginatedByActingBodyParameters): Promise<PaginatedResource<ReportTag>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/reports/paginated-tags${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createReportTag(params: CreateOccurrenceTag): Promise<ReportTag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/reports/tag`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateReportTag(params: PartialBy<Omit<BaseOccurrenceTag, 'actingBodyId'>, 'id'>): Promise<ReportTag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/reports/tag/${params.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteReportTag(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/reports/tag/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async getPaginatedObjectsCategories(params: GetResourcePaginatedByActingBodyParameters): Promise<PaginatedResource<ObjectCategory>> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/objects-categories${this.encodeQueryParams({
            limit: params.limit,
            page: params.page,
            textFilter: params.textFilter,
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedObjectsCategories(actingBodyId: number): Promise<ObjectCategory[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/objects-categories-simplified${this.encodeQueryParams({ actingBodyId })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedFinishReasons(actingBodyId: number): Promise<FinishCategoryBase[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-reasons-simplified${this.encodeQueryParams({ actingBodyId })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedObjectSubCategories(id: number): Promise<{ id: number, name: string; }[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/object-sub-categories-simplified/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteObjectCategory(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/object-category/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createObjectCategory(params: CreateObjectCategory): Promise<ObjectCategory> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/object-category`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateObjectCategory(params: PartialBy<ObjectCategory, 'id'>): Promise<ObjectCategory> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/object-category/${params.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getObjectCategory(id: number): Promise<ObjectCategory> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/object-category/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteObjectSubCategory(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/object-sub-category/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async getTeamNames(params: GetResourcePaginatedByActingBodyParameters): Promise<PaginatedResource<TeamName>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/unit-names${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteTeamName(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/unit-name/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async createTeamName(teamName: CreateTeamName): Promise<TeamName> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/unit-name`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(teamName)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateTeamName(teamName: PartialBy<Omit<BaseTeamName, 'actingBodyId'>, 'id'>): Promise<TeamName> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/unit-name/${teamName.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(teamName)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteFinishCategory(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-category/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createFinishCategory(params: { name: string; finishSubCategories?: Partial<FinishSubCategory>[]; actingBodyId?: number; }): Promise<FinishCategory> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-category`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateFinishCategory(id: number, params: { name: string; finishSubCategories?: Partial<FinishSubCategory>[]; }): Promise<FinishCategory> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-category/${id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getFinishCategory(id: number): Promise<FinishCategory> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-category/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getPaginatedFinishCategories(params: GetResourcePaginatedByActingBodyParameters): Promise<PaginatedResource<FinishCategory>> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-categories${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteFinishSubCategory(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-sub-category/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }
}

export const dispatchService = new DispatchService();
