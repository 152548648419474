
import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../../services/translate';
import { facialService } from '../../../services/central-api/facial';
import DeleteModal from '../../../components/DeleteModal';
import CustomButton from '../../../components/CustomButton';
import { useStyles, createStyleSheet } from 'react-native-unistyles';
import { RouteProp, useRoute } from '@react-navigation/native';
import { FacialParamList } from '../../../typings/Params';
import useFilters from '../../../components/Filter/hooks/useFilters';
import Toast from 'react-native-toast-message';
import { Pagination } from '../../../components/Pagination';
import MyDataTable from '../../../components/MyDataTable';
import FilterInput from '../../../components/Filter/components/FilterInput';
import MarkerModal from './MarkerModal';
import Filter from './Filters';

interface TagsParams {
    navigation: Navigation;
}

export default function Markers({ navigation }: TagsParams) {
    const { styles, theme } = useStyles(styleSheet);

    const route = useRoute<RouteProp<FacialParamList, 'Markers'>>();
    const [filters, setFilters] = useFilters<FacialParamList['Markers']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });

    const [totalItems, setTotalItems] = useState(0);
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedTag, setSelectedTag] = useState<BaseFacialTag>();
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [tags, setTags] = useState<BaseFacialTag[]>([]);

    async function getMarkers() {
        try {
            setLoading(true);
            const response = await facialService.getFacialMarkers(filters);
            setTags(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getMarkers();
    }, [filters]);

    async function deleteMarker(tag: BaseFacialTag) {
        try {
            await facialService.deleteFacialMarker(tag.id);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            setIsDeleteModalVisible(false);
            setSelectedTag(undefined);
            getMarkers();
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function handleFormClose() {
        setSelectedTag(undefined);
        setModalVisible(false);
        getMarkers();
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            navigation.setParams({
                ...filters,
                textFilter
            });
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                    <FilterInput
                        label={translate('searchFacialTag')}
                        value={textFilter}
                        placeholder={translate('typeToSearch')}
                        onChange={text => {
                            setTextFilter(text);
                        }}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={{ flex: 1 }}></View>
                <View style={styles.buttonsContainer}>
                    <Filter
                        filters={filters}
                        setFilters={setFilters}
                        textFilter={textFilter}
                        setTextFilter={setTextFilter} />
                    <CustomButton icon={faPlus} text={translate('create')} onPress={() => {
                        setSelectedTag(undefined);
                        setModalVisible(true);
                    }} />
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        columns={[
                            {
                                name: 'ID',
                                selector: row => row.id,
                                grow: 0
                            },
                            {
                                name: translate('color'),
                                grow: 0,
                                cell: row => <View style={{ backgroundColor: row.color, height: 20, width: 20, borderRadius: 4 }}></View>
                            },
                            {
                                name: translate('name'),
                                selector: row => row.name,
                                wrap: true,
                                grow: 4
                            },
                            {
                                name: translate('actions'),
                                cell: row => (
                                    <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                        <TouchableOpacity onPress={() => {
                                            setSelectedTag(row);
                                            setModalVisible(true);
                                        }}>
                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => {
                                            setSelectedTag(row);
                                            setIsDeleteModalVisible(true);
                                        }}>
                                            <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                    </View>
                                ),
                                wrap: true,
                                button: true
                            }
                        ]}
                        data={tags}
                        progressPending={loading}
                    />
                </View>
                <Pagination
                    currentPage={filters.page}
                    totalItems={totalItems}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                />
            </View>
            <MarkerModal
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                marker={selectedTag}
                onClose={handleFormClose}
            />
            <DeleteModal onSubmit={async () => {
                try {
                    if (selectedTag) {
                        await deleteMarker(selectedTag);
                    }
                } catch (err) {
                    console.error(err);
                }
            }} isModalVisible={isDeleteModalVisible} itemName={selectedTag?.name} setModalVisible={() => setIsDeleteModalVisible(false)} />
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
}));
