import React, { useRef } from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import CustomButton, { ButtonTypes } from './CustomButton';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface FileUploadProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    accept?: string;
    text?: string;
    icon?: IconDefinition;
    type?: ButtonTypes;
    /**
     * button style
     */
    style?: StyleProp<ViewStyle>;
    /**
     * text style
     */
    textStyle?: TextStyle;
    /**
     * button style
    */
    contentContainerStyle?: StyleProp<ViewStyle>;

}

export default function FileUploader({ onChange, accept, text, icon, type, style, textStyle, contentContainerStyle }: FileUploadProps) {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { styles } = useStyles(stylesheet);

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <>
            <input
                type='file'
                style={{ display: 'none' }}
                onChange={onChange}
                ref={(input) => (fileInputRef.current = input)}
                accept={accept}
            />
            <View style={styles.buttonContainer}>
                <CustomButton text={text} icon={icon} type={type} onPress={handleUploadClick} style={style} textStyle={textStyle} contentContainerStyle={contentContainerStyle} />
            </View>
        </>
    );
}
const stylesheet = createStyleSheet(() => ({
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    }
}));

