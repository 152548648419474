import React from 'react';
import LateralMenu from '../LateralMenu';
import { faClipboardList, faSearch, faShield } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_app_agent.png';
import collapsedLogo from '../../../assets/icon_app_agent.png';

export default function GcmAgentLateralMenu({ navigation, open, authenticatedUser, setOpen, selectedMenu }: LateralMenuProps) {
    const items = [{
        icon: faClipboardList,
        text: translate('occurrences'),
        action: () => navigation.navigate('GcmAgent', { screen: 'AgentOccurrences' }),
        selected: selectedMenu == 'occurrences'
    }, {
        icon: faShield,
        text: translate('teams'),
        action: () => navigation.navigate('GcmAgent', { screen: 'AgentUnits' }),
        selected: selectedMenu == 'units'
    }];

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.search_people_cortex || authenticatedUser?.permissions.search_cortex_any_time) {
        items.push({
            icon: faSearch,
            text: translate('searchOnCortex'),
            action: () => navigation.navigate('GcmAgent', { screen: 'CortexFinder' }),
            selected: selectedMenu == 'cortex-finder'
        });
    }

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={items}
        />
    );
}
