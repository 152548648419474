import React from 'react';
import { Modal, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import FormActions from '../../../components/formActions';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';

interface Props {
    isModalVisible: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit: () => void;
    onClose: () => void;
}

function UpdateActingBodyUnitModal({ isModalVisible, setIsModalVisible, onSubmit, onClose }: Props) {
    const { styles } = useStyles(styleSheet);

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setIsModalVisible(false)}>
            <View style={styles.container}>
                <View style={styles.formContainer}>
                    <MyAppText>{translate('userChangeActingBodyUnitWarning')}</MyAppText>
                    <FormActions
                        onSubmit={async () => onSubmit()}
                        onClose={() => onClose()}
                        disabled={false}
                        confirmText='continue' />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        width: 350,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        gap: 20,
        maxHeight: 230
    },
}));


export default UpdateActingBodyUnitModal;
