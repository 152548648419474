import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import MyDataTable from '../../MyDataTable';
import DeleteModal from '../../DeleteModal';
import InvolvedObjectModal from './InvolvedObjectModal';
import CustomButton from '../../CustomButton';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface InvolvedObjectsParams {
    involvedObjects: InvolvedObject[];
    setInvolvedObjects: (value: InvolvedObject[]) => void;
    actingBodyId: number;
    occurrenceSituation: OccurrenceSituation;
}

export default function InvolvedObjects({ involvedObjects, setInvolvedObjects, actingBodyId, occurrenceSituation }: InvolvedObjectsParams) {
    const { styles, theme } = useStyles(styleSheet);
    const [selectedInvolvedObject, setSelectedInvolvedObject] = useState<InvolvedObject>(getBlankObject());
    const [objectModalOpen, setObjectModalOpen] = useState<boolean>(false);
    const [deleteObjectModalVisible, setDeleteObjectModalVisible] = useState(false);

    function getBlankObject(): InvolvedObject {
        return {
            amount: 0,
            objectCategoryId: 0,
            objectSubCategoryId: 0,
            description: ''
        };
    }

    async function createUpdateInvolvedObjects() {
        if (!selectedInvolvedObject.id) {
            selectedInvolvedObject.id = -Math.random();
            involvedObjects.push(selectedInvolvedObject);
        } else {
            const index = involvedObjects.findIndex(object => object.id === selectedInvolvedObject.id);
            involvedObjects[index] = { ...involvedObjects[index], ...selectedInvolvedObject };
        }

        setInvolvedObjects(involvedObjects);
        setSelectedInvolvedObject(getBlankObject());
    }

    return (
        <>
            <View style={styles.container}>
                {occurrenceSituation == 'concluded' ?
                    <></> : <View style={styles.header}>
                        <MyAppText style={styles.headerText}>{translate('involvedObjects')}</MyAppText>
                        <CustomButton
                            onPress={() => setObjectModalOpen(true)}
                            text={translate('attach')}
                            icon={faPlus}
                        />
                    </View>
                }
                <MyDataTable
                    columns={[
                        {
                            name: translate('category'),
                            selector: row => row.objectCategory?.name || '',
                            wrap: true,
                        },
                        {
                            name: translate('subCategory'),
                            selector: row => row.objectSubCategory?.name || '',
                            wrap: true,
                        },
                        {
                            name: translate('amount'),
                            selector: row => row.amount || '',
                            wrap: true,
                        },
                        {
                            name: translate('action'),
                            button: true,
                            cell: row =>
                                <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                    <TouchableOpacity onPress={() => {
                                        setSelectedInvolvedObject(row);
                                        setObjectModalOpen(true);
                                    }}>
                                        <FontAwesomeIcon icon={occurrenceSituation == 'concluded' ? faEye : faEdit} fontSize={16} color={theme.colors.iconColor} />
                                    </TouchableOpacity>
                                    {occurrenceSituation == 'concluded' ? <></> :
                                        <TouchableOpacity onPress={() => {
                                            setSelectedInvolvedObject(row);
                                            setDeleteObjectModalVisible(true);
                                        }}>
                                            <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                    }
                                </View>
                        }
                    ]}
                    data={involvedObjects}
                />
            </View>
            <DeleteModal
                setModalVisible={setDeleteObjectModalVisible}
                isModalVisible={deleteObjectModalVisible}
                itemName={translate('object')}
                onSubmit={() => {
                    const index = involvedObjects.findIndex(object => object.id === selectedInvolvedObject.id);
                    if (index >= 0) {
                        involvedObjects.splice(index, 1);
                        setInvolvedObjects(involvedObjects);
                        setSelectedInvolvedObject(getBlankObject());
                    }
                }}
                onCancel={() => {
                    setSelectedInvolvedObject(getBlankObject());
                }}
            />
            <InvolvedObjectModal
                involvedObject={selectedInvolvedObject}
                setInvolvedObject={setSelectedInvolvedObject}
                isModalVisible={objectModalOpen}
                actingBodyId={actingBodyId}
                setModalVisible={(value) => {
                    if (!value) {
                        setSelectedInvolvedObject(getBlankObject());
                    }
                    setObjectModalOpen(value);
                }}
                onSubmit={createUpdateInvolvedObjects}
                occurrenceSituation={occurrenceSituation}
            />
        </>
    );
}

const styleSheet = createStyleSheet(() => ({
    container: {
        flex: 1,
        rowGap: 20
    },
    headerText: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    }
}));
