import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { translate } from '../../../services/translate';
import { Pagination } from '../../../components/Pagination';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PlatesModal from './PlatesModal';
import DeleteModal from '../../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import { ClientError } from '../../../services/central-api/base-service';
import { MyCheckbox } from '../../../components/MyCheckbox';
import MyDataTable from '../../../components/MyDataTable';
import { RouteProp, useRoute } from '@react-navigation/native';
import { LPRParamList } from '../../../typings/Params';
import CustomButton from '../../../components/CustomButton';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import TableActions from '../../../components/TableActions';

export default function Plates({ navigation }: { navigation: Navigation; }) {
    const { styles } = useStyles(styleSheet);

    const route = useRoute<RouteProp<LPRParamList, 'Plates'>>();

    const [filters, setFilters] = useState<{ page: number; limit: number; }>({
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });
    const [totalItems, setTotalItems] = useState(0);

    const [plates, setPlates] = useState<PlateData[]>([]);
    const [selectedPlate, setSelectedPlate] = useState<PlateData>();
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [onlyActiveFilter, setOnlyActiveFilter] = useState(route.params.onlyActive);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function getPlates() {
        try {
            setIsLoading(true);
            const response = await licensePlateRecognitionService.getPlates({ ...filters, onlyActive: onlyActiveFilter });
            setPlates(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getPlates();
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View>
                    <MyCheckbox
                        style={{ height: 40, width: '100%' }}
                        label={translate('onlyActive')}
                        checked={onlyActiveFilter}
                        setChecked={checked => {
                            setOnlyActiveFilter(checked);

                            navigation.setParams({
                                ...route.params,
                                onlyActive: checked
                            });
                        }}
                    />
                </View>
                <CustomButton
                    onPress={() => {
                        setSelectedPlate(undefined);
                        setModalVisible(true);
                    }}
                    text={translate('create')}
                    icon={faPlus}
                />
            </View>
            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        columns={[
                            {
                                name: translate('plates'),
                                selector: row => row.plate,
                                grow: 0
                            },
                            {
                                name: translate('description'),
                                selector: row => row.description,
                                grow: 1,
                                wrap: true
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row => <TableActions
                                    onEdit={() => {
                                        setSelectedPlate(row);
                                        setModalVisible(true);
                                    }}
                                    onDelete={() => {
                                        setSelectedPlate(row);
                                        setDeleteModalVisible(true);
                                    }} />
                            }
                        ]}
                        data={plates}
                        progressPending={isLoading}
                    />
                </View>
                <Pagination
                    totalItems={totalItems}
                    currentPage={filters.page}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                />
            </View>

            <DeleteModal
                setModalVisible={setDeleteModalVisible}
                isModalVisible={isDeleteModalVisible}
                itemName={selectedPlate?.plate || ''}
                onSubmit={async () => {
                    try {
                        if (!selectedPlate?.id) {
                            return;
                        }
                        await licensePlateRecognitionService.deletePlate(selectedPlate.id || 0);
                        Toast.show({
                            type: 'sentinelxSuccess',
                            text1: translate('ActionSuccessfully'),
                        });

                        getPlates();
                    } catch (err) {
                        if (err instanceof ClientError) {
                            return Toast.show({
                                type: 'sentinelxError',
                                text1: translate(err.message),
                            });
                        }

                        console.error(err);
                        Toast.show({
                            type: 'sentinelxError',
                            text1: translate('unexpectedError'),
                        });
                    }
                }}
            />

            {/* create/edit plate modal */}
            <PlatesModal
                onClose={() => {
                    setSelectedPlate(undefined);
                    setModalVisible(false);
                    getPlates();
                }}
                isModalVisible={isModalVisible}
                plateData={selectedPlate}
            />
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        minHeight: 65,
        flexDirection: 'row',
        columnGap: 10,
        flexWrap: 'wrap',
        rowGap: 10,
        zIndex: 2,
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
}));
