import { faAdd, faArrowLeft, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextInput, TouchableOpacity, View, Image, ScrollView, DimensionValue, ActivityIndicator } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import CustomButton from '../../../components/CustomButton';
import FileUploader from '../../../components/FileUploader';
import { gcmAgentService } from '../../../services/central-api/gcm-agent';
import { ClientError } from '../../../services/central-api/base-service';
import Toast from 'react-native-toast-message';

interface OccurrenceDetailParams {
    navigation: Navigation;
    route: {
        params: {
            occurrenceId: string;
        };
    };
}

export default function AgentOccurrencePoliceReport({ navigation, route }: OccurrenceDetailParams) {

    const { styles, theme } = useStyles(styleSheet);

    const [policeReportNumber, setPoliceReportNumber] = useState({ dirty: false, value: '' });
    const [files, setFiles] = useState<{ file: File, objectURI: string; key: string; }[]>([]);
    const [occurrence, setOccurrence] = useState<Occurrence>();
    const [policeReports, setPoliceReports] = useState<OccurrenceReport[]>([]);

    async function getOccurrence(occurrenceId: string) {
        try {
            const occurrence = await gcmAgentService.gcmAgentOccurrence(Number(occurrenceId));
            setOccurrence(occurrence);
            setPoliceReportNumber({ dirty: false, value: occurrence.policeReport || '' });

            const policeReports = await gcmAgentService.getPoliceReports({ occurrenceId: Number(occurrenceId) });
            setPoliceReports(policeReports);

        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getOccurrence(route.params.occurrenceId);
    }, [route]);

    function navigateBack() {
        if (navigation.canGoBack()) {
            return navigation.goBack();
        }

        navigation.navigate('GcmAgent', {
            screen: 'AgentOccurrenceDetails',
            params: { occurrenceId: route.params.occurrenceId }
        });
    }

    async function submit() {
        try {
            await gcmAgentService.attachPoliceReport({
                occurrenceId: Number(route.params.occurrenceId),
                files: files.map(f => f.file),
                policeReport: policeReportNumber.value,
            });

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });

            navigateBack();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    if (!occurrence) {
        return <View style={styles.loadingView}>
            <MyAppText style={styles.loadingText}>
                {translate('loading')}...
            </MyAppText>
            <ActivityIndicator
                animating={true}
                style={styles.loading}
                size='large'
                color='#0071bc'
            >
            </ActivityIndicator>
        </View>;
    }

    return <View style={styles.container} >
        <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            <TouchableOpacity onPress={async () => {
                navigateBack();
            }}>
                <FontAwesomeIcon fontSize={30} icon={faArrowLeft} color={theme.colors.textColor} />
            </TouchableOpacity>
        </View>

        <View style={{ gap: 5 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <MyAppText style={styles.label}>
                    {translate('policeReport')}
                </MyAppText>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <TextInput
                    placeholder={translate('policeReport')}
                    style={[
                        styles.input,
                        policeReportNumber.value == '' && policeReportNumber.dirty ? styles.invalid : null,
                        { color: policeReportNumber.value ? theme.colors.field.text : theme.colors.placeholder }
                    ]}
                    value={policeReportNumber.value}
                    onBlur={() => {
                        setPoliceReportNumber({ dirty: true, value: policeReportNumber.value });
                    }}
                    onChangeText={(value) => {
                        setPoliceReportNumber({ dirty: true, value });
                    }}
                />
            </View>
        </View>
        <ScrollView contentContainerStyle={styles.scrollContent}>
            <View style={styles.blockSize}>
                <FileUploader
                    icon={faAdd}
                    style={[styles.blockSize, styles.blockStyle, { width: '100%' }]}
                    contentContainerStyle={{ flex: 1 }}
                    textStyle={{
                        fontSize: 30,
                        color: '#181A1B',
                    }}
                    onChange={async (e) => {
                        if (!e.target.files) {
                            return;
                        }
                        const file = e.target.files[0];

                        setFiles(current => [{ file, objectURI: URL.createObjectURL(file), key: String(Math.random()) }, ...current]);
                    }}
                />
            </View>
            {files.map((file) =>
                file.file.type.includes('image') ?

                    <Image
                        key={file.key}
                        style={[styles.blockSize, {
                            overflow: 'hidden',
                        }]}
                        source={{
                            uri: file.objectURI
                        }}
                    /> : <View key={Math.random()} style={[styles.blockSize, {
                        backgroundColor: theme.colors.primaryButton.background,
                        justifyContent: 'center'
                    }]}>
                        <FontAwesomeIcon
                            fontSize={30}
                            icon={faFile}
                            color={theme.colors.primaryButton.text}
                            style={{
                                justifySelf: 'center',
                                alignSelf: 'center'
                            }}
                        />
                    </View>
            )}
            {policeReports.map((policeReport) => {
                const content = JSON.parse(policeReport.content);

                if (content.type.includes('image')) {
                    return <Image
                        key={'police-report-' + policeReport.id}
                        style={[styles.blockSize, {
                            overflow: 'hidden',
                        }]}
                        source={{
                            uri: content.url
                        }}
                    />;
                }
                return <View key={Math.random()} style={[styles.blockSize, {
                    backgroundColor: theme.colors.primaryButton.background,
                    justifyContent: 'center'
                }]}>
                    <FontAwesomeIcon
                        fontSize={30}
                        icon={faFile}
                        color={theme.colors.primaryButton.text}
                        style={{
                            justifySelf: 'center',
                            alignSelf: 'center'
                        }}
                    />
                </View>;
            })}
        </ScrollView>

        <CustomButton
            onPress={submit}
            text={translate(!occurrence.policeReport ? 'addPoliceReport' : 'updatePoliceReport').toUpperCase()}
            disabled={policeReportNumber.value == '' || (files.length + policeReports.length) == 0}
        />
    </View>;
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        padding: 16,
        gap: 20
    },
    label: {
        color: theme.colors.labelColor,
        fontSize: 16,
        fontWeight: 'bold',
    },
    loading: {
        marginHorizontal: 'auto',
        transform: [{ scaleX: 2 }, { scaleY: 2 }],
        width: '20%',
        height: '20%',
    },
    loadingText: {
        color: theme.colors.textColor,
        textAlign: 'center',
        fontSize: 16
    },
    loadingView: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center'
    },
    input: {
        backgroundColor: theme.colors.field.background,
        borderColor: theme.colors.field.border,
        borderWidth: 1,
        width: '100%',
        height: 40,
        padding: 10,
        borderRadius: 4,
    },
    invalid: {
        borderColor: 'red',
    },
    scrollContent: {
        rowGap: 20,
        columnGap: 16,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    },
    blockSize: {
        width: 'calc(33.33% - 11px)' as DimensionValue,
        height: 97,
        borderRadius: 8,
    },
    blockStyle: {
        backgroundColor: '#C6C6CE',
    },
}));