import React, { useEffect, useRef, useState } from 'react';
import { View, StyleProp, ViewStyle, Animated, Dimensions } from 'react-native';
import { WindowInformation } from '../services/window-information';
import FacialLateralMenu from './facial/FacialLateralMenu';
import CamerasLateralMenu from './cameras/CamerasLateralMenu';
import LprLateralMenu from './lpr/LprLateralMenu';
import DispatchLateralMenu from './dispatch/DispatchLateralMenu';
import GuardianAppLateralMenu from './guardian-app/GuardianAppLateralMenu';
import AccessLateralMenu from './access/AccessLateralMenu';
import EventsLateralMenu from './events/EventsLateralMenu';
import ContentContainer from './ContentContainer';
import GcmAgentLateralMenu from './gcm-agent/GcmAgentLateralMenu';
import DashboardLateralMenu from './dashboard/DashboardLateralMenu';
import LogsLateralMenu from './logs/LogsLateralMenu';
import CrmLateralMenu from './crm/CrmLateralMenu';
import SmartSearchFacialLateralMenu from './smart-search-facial/SmartSearchFacialLateralMenu';
import UserLateralMenu from './user/UserLateralMenu';
import ClonedPlateLateralMenu from './cloned-plate/ClonedPlateLateralMenu';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import TopMenu from './TopMenu';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { NavigationContainerRefWithCurrent, NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../typings/Params';
import { useAuth } from '../context/Auth';
import { pages } from '../Routes';

type DefaultPageLayoutProps = {
    children: React.ReactNode | undefined;
    contentContainerStyle?: StyleProp<ViewStyle>;
    navigationRef: NavigationContainerRefWithCurrent<RootStackParamList>;
};

const LATERAL_MENU_MAP: Record<string, React.FC<LateralMenuProps>> = {
    'cameras': CamerasLateralMenu,
    'facial': FacialLateralMenu,
    'smart-search-facial': SmartSearchFacialLateralMenu,
    'LPR': LprLateralMenu,
    'lpr-detections': LprLateralMenu,
    'events': EventsLateralMenu,
    'dispatch': DispatchLateralMenu,
    'gcm-agent': GcmAgentLateralMenu,
    'guardian-app': GuardianAppLateralMenu,
    'access': AccessLateralMenu,
    'CRM': CrmLateralMenu,
    'logs': LogsLateralMenu,
    'dashboard': DashboardLateralMenu,
    'user': UserLateralMenu,
    'cloned-plate': ClonedPlateLateralMenu
};

const screenWidth = Dimensions.get('window').width;

export default function DefaultPageLayout({ children, contentContainerStyle, navigationRef }: DefaultPageLayoutProps) {
    const { styles } = useStyles(stylesheet);
    const insets = useSafeAreaInsets();
    const windowInfo = WindowInformation();
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const navigation = useNavigation<NavigationProp<RootStackParamList>>();

    const { user: authenticatedUser } = useAuth();

    navigationRef.addListener('state', () => {
        forceUpdate();
    });

    const pageName = navigationRef.getCurrentRoute()?.name;
    let page;

    if (pageName && pageName in pages) {
        page = pages[pageName];
    }

    const [showLateralMenu, setShowLateralMenu] = useState<boolean>(localStorage.getItem('HAMBURGER_OPEN') == 'true');

    const animationOpacity = useRef(new Animated.Value(0)).current;
    const animationTranslateX = useRef(new Animated.Value(-screenWidth)).current;

    useEffect(() => {
        Animated.timing(animationOpacity, {
            toValue: showLateralMenu ? 0.6 : 0,
            duration: 200,
            useNativeDriver: true
        }).start();

        Animated.timing(animationTranslateX, {
            toValue: showLateralMenu ? 0 : -screenWidth,
            duration: 0,
            useNativeDriver: true
        }).start();
    }, [showLateralMenu]);

    useEffect(() => {
        if (windowInfo.isMobile) {
            setShowLateralMenu(!windowInfo.isMobile);
        } else {
            getStorage();
        }
    }, [windowInfo.isMobile]);

    useEffect(() => {
        if (!windowInfo.isMobile) {
            setStorage(showLateralMenu);
        }
    }, [showLateralMenu, windowInfo.isMobile]);

    async function getStorage() {
        try {
            const hamburgerOpen = await AsyncStorage.getItem('HAMBURGER_OPEN');
            if (hamburgerOpen) {
                setShowLateralMenu(JSON.parse(hamburgerOpen));
            }
        } catch (e) {
            console.error('Error on parse Storage: ', e);
        }
    }

    async function setStorage(showLateralMenu: boolean) {
        await AsyncStorage.setItem('HAMBURGER_OPEN', String(showLateralMenu));
    }

    let LateralMenu;

    if (page && page.module) {
        LateralMenu = LATERAL_MENU_MAP[page.module];
    }

    const routeName = navigationRef.getCurrentRoute()?.name;

    return (
        <View style={styles.container}>
            {routeName !== 'Login' && routeName !== 'ResetPassword' &&
                <View style={styles.topBarContainer}>
                    <TopMenu navigation={navigation} setOpen={setShowLateralMenu} showDropDownOnMobile={page == undefined} />
                </View>}

            <View style={{ flexDirection: 'row' }}>

                <View style={{ zIndex: 2, height: windowInfo.height - 64 - insets.top - insets.bottom }}>
                    {
                        LateralMenu && page &&
                        <LateralMenu navigation={navigation} authenticatedUser={authenticatedUser} open={showLateralMenu} setOpen={setShowLateralMenu} selectedMenu={page.selectedItem || ''} />
                    }
                </View>

                <View style={styles.contentContainer}>
                    <ContentContainer style={contentContainerStyle}>
                        {children}
                    </ContentContainer>
                </View>

                {
                    windowInfo.isMobile &&
                    <Animated.View onTouchEnd={() => setShowLateralMenu(false)} style={{
                        position: 'absolute',
                        top: 0,
                        transform: [{
                            translateX: animationTranslateX
                        }],
                        height: '100%',
                        backgroundColor: '#313131',
                        opacity: animationOpacity,
                        width: '100%',
                    }}
                    />
                }
            </View>


        </View >
    );
}


const stylesheet = createStyleSheet(() => ({
    container: {
        flex: 1,
        flexDirection: 'column',
    },

    contentContainer: {
        flex: 1,
    },

    topBarContainer: {
        height: 64,
    }
}));
