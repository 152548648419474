import React, { useEffect, useState } from 'react';
import { Action } from '../../../services/central-api/audit-logs';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';

interface Props {
    value: Action | '';
    setValue: React.Dispatch<React.SetStateAction<Action | ''>>;
    zIndex: number;
}

function ActionsDropdown({ value, setValue, zIndex }: Props) {
    const [selectableItems, setSelectableItems] = useState<{ label: string, value: string; }[]>([]);

    function getActions() {
        const actions: { label: string, value: Action | ''; }[] = Object.keys(Action).map((item) => {
            const action = Action[item as keyof typeof Action];
            return {
                label: translate(item),
                value: action
            };
        });
        actions.push({ label: '', value: '' });
        setSelectableItems(actions.sort((a, b) => b.label > a.label ? -1 : 1));
    }

    useEffect(() => {
        getActions();
    }, []);

    return (
        <FilterDropdown
            label={translate('actions')}
            items={selectableItems}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default ActionsDropdown;
