import React, { useEffect, useState } from 'react';
import { translate } from '../../services/translate';
import FilterDropdown from '../../components/Filter/components/FilterDropdown';
import { useAuth } from '../../context/Auth';

interface Props {
    value: (TriggerType | 'manual')[];
    setValue: React.Dispatch<React.SetStateAction<(TriggerType | 'manual')[]>>;
    zIndex: number;
}

function TypeDropdown({ value, setValue, zIndex }: Props) {
    const [items, setItems] = useState<{ label: string, value: string; }[]>([]);
    const { user } = useAuth();

    useEffect(() => {
        if (user) {
            getOccurrenceTypes();
        }
    }, [user]);

    function getOccurrenceTypes() {
        const occurrenceTypes = [];

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_wanted_face_occurrence) {
            occurrenceTypes.push('face_detected');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_missing_face_occurrence) {
            occurrenceTypes.push('missing_face_detected');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_not_allowed__face_occurrence) {
            occurrenceTypes.push('not_allowed_face_detected');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_wanted_plate_occurrence) {
            occurrenceTypes.push('plate_detected');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_irregular_vehicle_occurrence) {
            occurrenceTypes.push('irregular_vehicle');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_clone_suspect_occurrence) {
            occurrenceTypes.push('clone_suspect');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_area_invasion_occurrence) {
            occurrenceTypes.push('area_invasion');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_mp_wanted_plate_occurrence) {
            occurrenceTypes.push('mp_plate_detected');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_mp_wanted_face_occurrence) {
            occurrenceTypes.push('mp_face_detected');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_alarm_center_occurrence) {
            occurrenceTypes.push('alarm_center');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_guardian_app_occurrence) {
            occurrenceTypes.push('guardian_app');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_sigrc_occurrence) {
            occurrenceTypes.push('sigrc');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_cortex_plate_occurrence) {
            occurrenceTypes.push('cortex_plate_detected');
        }

        if (user?.isAdmin || user?.permissions.events_module_access || user?.permissions.events_manage_manual_occurrence) {
            occurrenceTypes.push('manual');
        }

        const items = occurrenceTypes.map((occurrenceType) => {
            return {
                value: occurrenceType,
                label: translate(occurrenceType)
            };
        });


        setItems(items);
    }

    return (
        <FilterDropdown
            label={translate('type')}
            items={items}
            multiple={true}
            mode='BADGE'
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default TypeDropdown;
