import React, { Dispatch, SetStateAction } from 'react';
import { Switch, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../MyAppText';

interface Props {
    label: string;
    value: boolean;
    onChange: Dispatch<SetStateAction<boolean>>;
    direction: 'row' | 'column';
    disabled?: boolean;
}

function FilterSwitch({ label, value, onChange, direction, disabled = false }: Props) {
    const { styles } = useStyles(stylesheet);

    return (
        <View style={styles.inputContainer(direction)}>
            <MyAppText style={styles.filterText}>
                {label}
            </MyAppText>
            <Switch
                trackColor={{ false: '#767577', true: '#81b0ff' }}
                thumbColor={value ? '#f5dd4b' : '#f4f3f4'}
                onValueChange={onChange}
                value={value}
                disabled={disabled}
            />
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    inputContainer: (direction: 'row' | 'column') => ({
        flex: 1,
        flexDirection: direction,
        gap: 5
    }),
    filterText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },

}));

export default FilterSwitch;
