import React, { createContext, useContext } from 'react';
import { MyMessage } from '../components/MyMessage';
import { centralAPI } from '../services/central-api';

type AuthContextType = {
    user?: AuthenticatedUser;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export async function checkSessionStatus(): Promise<{type: 'success' | 'error' | 'notAccepted', user?: AuthenticatedUser}> {
    try {
        const user = await centralAPI.checkAuthentication();

        if (!user.acceptedTermsOfUse) {
            return {
                type: 'notAccepted',
                user
            };
        }

        return {
            type: 'success',
            user
        };
    } catch (e) {
        MyMessage.show({ type: 'invalidAuth' });

        return {
            type: 'error'
        };
    }
}

function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be inside AuthProvider');
    }

    return context;
}


const AuthProvider = (props: { children: React.ReactNode; user?: AuthenticatedUser }): React.ReactElement => {
    return <AuthContext.Provider {...props} value={{ user: props.user }} />;
};


export { AuthProvider, useAuth };