import React from 'react';
import { Modal, View } from 'react-native';
import FormActions from '../formActions';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import EventOperatorDropdown from './EventOperatorDropdown';
import { AlreadyWithThisUser, dispatchService } from '../../services/central-api/dispatch';
import Toast from 'react-native-toast-message';
import { translate } from '../../services/translate';
import { ClientError } from '../../services/central-api/base-service';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    isModalVisible: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
    setHistoricList: React.Dispatch<React.SetStateAction<OccurrenceReport[]>>;
    historicList: OccurrenceReport[];
    occurrenceId: number;
    actingBodyId: number;
}

function EventOperatorModal({ isModalVisible, setIsModalVisible, value, setValue, occurrenceId, actingBodyId, onClose, setHistoricList, historicList }: Props) {
    const { styles } = useStyles(styleSheet);

    async function transferOccurrence() {
        try {
            const occurrenceReport = await dispatchService.transferOccurrence(occurrenceId, {
                eventOperatorId: value
            });

            setValue(0);
            setIsModalVisible(false);
            setHistoricList([occurrenceReport, ...historicList]);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof AlreadyWithThisUser) {
                return Toast.show({
                    type: 'sentinelxWarning',
                    text1: translate('AlreadyWithThisUser'),
                });
            }

            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setIsModalVisible(false)}>
            <View style={styles.container}>
                <View style={styles.formContainer}>
                    <EventOperatorDropdown
                        value={value}
                        setValue={setValue}
                        occurrenceId={occurrenceId}
                        actingBodyId={actingBodyId}
                        zIndex={2} />
                    <FormActions
                        onSubmit={transferOccurrence}
                        onClose={onClose}
                        disabled={!value} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        gap: 20,
        maxHeight: 230
    },
}));


export default EventOperatorModal;
