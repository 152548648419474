import React, { Dispatch, SetStateAction, useState } from 'react';
import { View } from 'react-native';
import DateTimePicker, { CalendarValue } from '../../../components/DateTimePicker';
import UnitProgramDropdown from '../../../components/dispatch/UnitProgramDropdown';
import ActingBodiesCommandDropdown from '../../../components/Dropdowns/ActingBodiesCommandDropdown';
import FormInput from '../../../components/formInput';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import ActingBodiesUnitDropdown from './ActingBodyUnitDropdown';
import EquipmentsDropdown from './EquipmentDropDown';
import UnitNamesDropdown from './UnitNamesDropdown';
import { useAuth } from '../../../context/Auth';
import { translate } from '../../../services/translate';
import { WindowInformation } from '../../../services/window-information';

interface UnitFormDetailsProps {
    unitId?: number;
    actingBodyId: number;
    setActingBodyId: Dispatch<SetStateAction<number>>;
    isFinished: boolean;
    actingBodyUnitId: number;
    setActingBodyUnitId: Dispatch<SetStateAction<number>>;
    actingBodyCommandId: number;
    setActingBodyCommandId: Dispatch<SetStateAction<number>>;
    unitNameId: number;
    setUnitNameId: Dispatch<SetStateAction<number>>;
    unitProgramId: number;
    setUnitProgramId: Dispatch<SetStateAction<number>>;
    description: string;
    setDescription: Dispatch<SetStateAction<string>>;
    equipmentsSelected: number[];
    setEquipmentsSelected: Dispatch<SetStateAction<number[]>>;
    startInDateFilter: CalendarValue;
    setStartInDateFilter: Dispatch<SetStateAction<CalendarValue>>;
    finishInDateFilter: CalendarValue;
    setFinishInDateFilter: Dispatch<SetStateAction<CalendarValue>>;
    startInHourFilter: string;
    setStartInHourFilter: Dispatch<SetStateAction<string>>;
    finishInHourFilter: string;
    setFinishInHourFilter: Dispatch<SetStateAction<string>>;
}

export default function UnitFormDetails({
    unitId,
    actingBodyId,
    setActingBodyId,
    isFinished,
    actingBodyCommandId,
    actingBodyUnitId,
    setActingBodyCommandId,
    setActingBodyUnitId,
    setUnitNameId,
    setUnitProgramId,
    unitNameId,
    unitProgramId,
    description,
    setDescription,
    equipmentsSelected,
    setEquipmentsSelected,
    finishInDateFilter,
    finishInHourFilter,
    setFinishInDateFilter,
    setFinishInHourFilter,
    setStartInDateFilter,
    setStartInHourFilter,
    startInDateFilter,
    startInHourFilter,
}: UnitFormDetailsProps) {
    const { user: authenticatedUser } = useAuth();
    const windowInfo = WindowInformation();

    const [estimatedStartZIndex, setEstimatedStartZIndex] = useState<number>(2);
    const [estimatedFinishZIndex, setEstimatedFinishZIndex] = useState<number>(1);


    return <View style={{ rowGap: 10, flex: 1 }}>
        {authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_acting_body_units ?
            <View style={{
                flexDirection: 'row',
                gap: 16,
                zIndex: 6,
                flexWrap: 'wrap',
            }}>
                <View style={[{ zIndex: 5 }, windowInfo.isMobile ? { width: '100%' } : { flex: 1 }]}>
                    <ActingBodiesDropdown
                        value={actingBodyId}
                        setValue={setActingBodyId}
                        includeAllOption={false}
                        disabled={!!unitId || !authenticatedUser?.isAdmin || isFinished}
                        zIndex={5}
                    />
                </View>
                <View style={[{ zIndex: 4 }, windowInfo.isMobile ? { width: '100%' } : { flex: 1 }]}>
                    <ActingBodiesUnitDropdown
                        value={actingBodyUnitId}
                        actingBodyId={actingBodyId}
                        setValue={setActingBodyUnitId}
                        disabled={!!unitId || isFinished}
                        zIndex={4}
                    />
                </View>
            </View>
            : null
        }
        <ActingBodiesCommandDropdown
            value={actingBodyCommandId}
            actingBodyId={actingBodyId}
            setValue={setActingBodyCommandId}
            disabled={isFinished}
            zIndex={5}
        />
        <UnitNamesDropdown
            authenticatedUser={authenticatedUser}
            value={unitNameId}
            setValue={setUnitNameId}
            actingBodyId={actingBodyId}
            disabled={!!unitId || isFinished}
            zIndex={4}
        />
        <UnitProgramDropdown
            actingBodyId={actingBodyId}
            setValue={setUnitProgramId}
            value={unitProgramId}
            zIndex={3}
            disabled={isFinished}
        />
        <View style={{ minWidth: 250, flexDirection: 'row', gap: 20, flexWrap: 'wrap', zIndex: estimatedStartZIndex }}>
            <DateTimePicker
                date={startInDateFilter}
                onChangeDate={setStartInDateFilter}
                time={startInHourFilter}
                onChangeTime={setStartInHourFilter}
                label={translate('estimatedStart')}
                minDate={new Date()}
                labelFontSize={16}
                onCalendarOpen={() => setEstimatedStartZIndex(99999)}
                onCalendarClose={() => setEstimatedStartZIndex(2)}
                disabled={isFinished}
            />
        </View>
        <View style={{ minWidth: 250, flexDirection: 'row', gap: 20, flexWrap: 'wrap', zIndex: estimatedFinishZIndex }}>
            <DateTimePicker
                date={finishInDateFilter}
                onChangeDate={setFinishInDateFilter}
                time={finishInHourFilter}
                onChangeTime={setFinishInHourFilter}
                label={translate('estimatedFinish')}
                minDate={new Date()}
                labelFontSize={16}
                onCalendarOpen={() => setEstimatedFinishZIndex(99999)}
                onCalendarClose={() => setEstimatedFinishZIndex(1)}
                disabled={isFinished}
            />
        </View>
        <FormInput
            label={translate('description')}
            placeholder={translate('description')}
            value={description}
            onChangeText={setDescription}
            multiline={true}
            numberOfLines={5}
            invalid={() => false}
            viewStyle={{ flex: 1 }}
            disabled={isFinished}
        />
        <EquipmentsDropdown
            value={equipmentsSelected}
            setValue={setEquipmentsSelected}
            actingBodyId={actingBodyId}
            disabled={isFinished}
            zIndex={0}
        />
    </View>;
}