import React, { useState } from 'react';
import { View } from 'react-native';
import Filters from '../../../components/Filter';
import { DashBoardParamList } from '../../../typings/Params';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import ActingBodiesDropdown from '../../../components/Dropdowns/ActingBodiesDropdown';
import ActingBodiesUnitsDropdown from '../../../components/Dropdowns/ActingBodiesUnitsDropdown';
import MyPhoneInput from '../../../components/MyPhoneInput';
import FilterCheckBox from '../../../components/Filter/components/FilterCheckBox';
import UnitsDropdown from '../../dispatch/Occurrences/UnitsDropdown';

interface Props {
    filters: DashBoardParamList['DashboardAgents'];
    setFilters: React.Dispatch<React.SetStateAction<DashBoardParamList['DashboardAgents']>>;
    authenticatedUser?: AuthenticatedUser;
}

function Filter({ filters, setFilters, authenticatedUser }: Props) {
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');
    const [selectedActingBodyId, setSelectedActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [selectedActingBodyUnitId, setSelectedActingBodyUnitId] = useState<number>(filters.actingBodyIdUnitId ?? 0);
    const [phone, setPhone] = useState<string>(filters.phone ?? '');
    const [offlineFilter, setOfflineFilter] = useState(filters.offline ?? false);
    const [onlineFilter, setOnlineFilter] = useState(filters.online ?? false);
    const [unitIdsFilter, setUnitIdsFilter] = useState<number[]>(filters.unitIds ?? []);

    const navigation = useNavigation<NavigationProp<DashBoardParamList, 'DashboardAgents'>>();

    const handleCleanFilter = () => {
        setTextFilter('');
        setPhone('');
        setSelectedActingBodyUnitId(0);
        setSelectedActingBodyId(0);
        setOfflineFilter(false);
        setOnlineFilter(false);
        setSelectedActingBodyUnitId(0);
        setSelectedActingBodyId(0);
        setUnitIdsFilter([]);
    };

    const handleFilter = () => {
        setFilters({
            ...filters,
            textFilter,
            phone: phone !== '' ? phone : undefined,
            actingBodyId: selectedActingBodyId !== 0 ? selectedActingBodyId : undefined,
            actingBodyIdUnitId: selectedActingBodyUnitId !== 0 ? selectedActingBodyUnitId : undefined,
            offline: offlineFilter ? offlineFilter : undefined,
            online: onlineFilter ? onlineFilter : undefined,
            unitIds: unitIdsFilter.length ? unitIdsFilter : undefined
        });

        navigation.setParams({
            actingBodyId: selectedActingBodyId !== 0 ? selectedActingBodyId : undefined,
            actingBodyIdUnitId: selectedActingBodyUnitId !== 0 ? selectedActingBodyUnitId : undefined,
            textFilter,
            phone: phone !== '' ? phone : undefined,
            offline: offlineFilter ? offlineFilter : undefined,
            online: onlineFilter ? onlineFilter : undefined,
            unitIds: unitIdsFilter.length ? unitIdsFilter : undefined,
            page: 1
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('searchUser')}
                value={textFilter}
                placeholder={translate('userFilterPlaceholder')}
                onChange={text => {
                    setTextFilter(text);
                }}
            />

            <MyPhoneInput
                label={translate('phone')}
                placeholder={translate('phone')}
                value={phone}
                onChangeText={setPhone}
                invalid={() => false}
                viewStyle={{ flexGrow: 1, minWidth: 200 }}
                labelStyle={{ fontSize: 16 }}
            />

            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <FilterCheckBox
                    label={translate('offline')}
                    value={offlineFilter}
                    onChange={(value) => {
                        if (value) {
                            setOnlineFilter(false);
                        }
                        setOfflineFilter(value);
                    }}
                />
                <FilterCheckBox
                    label={translate('online')}
                    value={onlineFilter}
                    onChange={(value) => {
                        if (value) {
                            setOfflineFilter(false);
                        }
                        setOnlineFilter(value);
                    }}
                />
            </View>

            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dashboard_see_agents_acting_bodies) &&
                <ActingBodiesDropdown value={selectedActingBodyId} setValue={setSelectedActingBodyId} zIndex={4} />
            }

            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dashboard_see_agents_acting_bodies || authenticatedUser?.permissions.dashboard_see_agents_acting_body_units) &&
                <ActingBodiesUnitsDropdown
                    value={selectedActingBodyUnitId}
                    actingBodyId={(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dashboard_see_agents_acting_bodies) ? selectedActingBodyId : authenticatedUser?.actingBodyUnit.actingBodyId ?? 0}
                    setValue={setSelectedActingBodyUnitId}
                    zIndex={3}
                />
            }

            <UnitsDropdown value={unitIdsFilter} setValue={setUnitIdsFilter} zIndex={2} />
        </Filters>
    );
}

export default Filter;
