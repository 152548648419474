import React, { useCallback, useContext, useState } from 'react';
import { View, StyleSheet, TextInput, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from '../../services/translate';
import MyAppText from '../../components/MyAppText';
import { Pagination } from '../../components/Pagination';
import moment from 'moment';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteProp, useFocusEffect, useIsFocused, useRoute } from '@react-navigation/native';
import { crmService } from '../../services/central-api/crm';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import MyDataTable from '../../components/MyDataTable';
import { CRMParamList } from '../../typings/Params';
import { GenericPaginatedParameters } from '../../services/central-api/base-service';
import CustomButton from '../../components/CustomButton';

export default function AlarmCentersList({ navigation }: { navigation: Navigation; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [alarmCenters, setAlarmCenters] = useState<AlarmCenter[]>([]);

    const route = useRoute<RouteProp<CRMParamList, 'AlarmCentersList'>>();

    // filters
    const [filters, setFilters] = useState<GenericPaginatedParameters>({
        textFilter: route.params.textFilter ?? '',
        page: Math.max(route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });

    const [textFilter, setTextFilter] = useState(filters.textFilter);
    const [totalItems, setTotalItems] = useState(0);

    const isFocused = useIsFocused();
    useFocusEffect(useCallback(() => {
        if (isFocused) {
            getAlarmCentersList();
        }
    }, [isFocused, navigation, filters]));

    async function getAlarmCentersList() {
        try {
            setIsLoading(true);
            const response = await crmService.getAlarmCenterPaginated(filters);
            setAlarmCenters(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    function handleFilters() {
        setFilters({
            ...filters,
            textFilter,
            page: 0
        });

        navigation.setParams({
            textFilter,
            page: 1
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            handleFilters();
        }
    }

    return (
        <View style={{ flex: 1, rowGap: 15 }}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 300 }}>
                    <MyAppText style={styles.filterText}>{translate('searchAlarmCenter')}</MyAppText>
                    <TextInput
                        style={[styles.filterInput, {
                            color: textFilter ? getThemedColor(theme, '#222222') : '#888888'
                        }]}
                        value={textFilter}
                        onChangeText={setTextFilter}
                        placeholder={translate('cameraTitleIdOrSerialNumber')}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={{ flexGrow: 1 }}></View>
                <View style={{ justifyContent: 'flex-end' }}>
                    <CustomButton
                        onPress={() => navigation.navigate('AlarmCenterDetails')}
                        text={translate('registerAlarmCenter')}
                        icon={faPlus}
                    />
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        columns={[
                            {
                                name: translate('title'),
                                selector: row => row.title,
                                grow: 3,
                                wrap: true,
                            },
                            {
                                name: translate('installationCompany'),
                                selector: row => row.installationCompany?.name || '',
                            },
                            {
                                name: translate('internetType'),
                                selector: row => row.internetType == '5g' ? '5G' : row.internetType == '4g' ? '4G' : row.internetType == 'fiber' ? translate('fiber') : translate('radio'),
                            },
                            {
                                name: translate('serialNo'),
                                selector: row => row.serialNo,
                            },
                            {
                                name: translate('registeredAt'),
                                selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
                                wrap: true
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row =>
                                    <TouchableOpacity onPress={() => navigation.navigate('AlarmCenterDetails', { id: row.id })}>
                                        <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                    </TouchableOpacity>
                            }
                        ]}
                        data={alarmCenters}
                        progressPending={isLoading}
                    />
                </View>
                <Pagination
                    totalItems={totalItems}
                    currentPage={filters.page}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                />
            </View>
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        headerContent: {
            minHeight: 65,
            flexDirection: 'row',
            columnGap: 10,
            flexWrap: 'wrap',
            rowGap: 10,
        },
        cell: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B'),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            minHeight: 40,
            paddingHorizontal: 30,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            columnGap: 10
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
            paddingBottom: 5
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#888',
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        checkbox: {
            paddingHorizontal: 8,
            height: 40
        },
        cardList: {
            borderWidth: 1,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            // borderColor: theme.colors.dataTable.border,
            // backgroundColor: theme.colors.dataTable.background,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1,
        },
    });
}
