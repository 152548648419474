import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface Props {
    onEdit: () => void;
    onDelete: () => void;
}

function TableActions({ onEdit, onDelete }: Props) {
    const { styles, theme } = useStyles(stylesheet);

    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={onEdit}>
                <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
            </TouchableOpacity>
            <TouchableOpacity onPress={onDelete}>
                <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
            </TouchableOpacity>
        </View>
    );
}

const stylesheet = createStyleSheet(() => ({
    container: {
        flexDirection: 'row',
        gap: 10
    },

}));

export default TableActions;
