import React, { useEffect, useState } from 'react';
import { dispatchService } from '../../services/central-api/dispatch';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';
import { useAuth } from '../../context/Auth';
import { centralAPI } from '../../services/central-api';

type SetStateCallback<S> = ((prevState: S) => S);

interface Props {
    value: number;
    setValue: React.Dispatch<SetStateCallback<number>>;
    zIndex?: number;
    disabled?: boolean;
    labelFontSize?: number;
    label?: string;
    actingBodyId: number;
    setPin?: (pos: LatLng) => void;
    setCurrentAddress?: (value: string) => void;
    includeEmptyValue?: boolean;
}

export default function CityPropertyDropdown({ value, setValue, zIndex, disabled, labelFontSize, label, actingBodyId, setPin, setCurrentAddress, includeEmptyValue }: Props) {
    const [items, setItems] = useState<{ label: string, value: number; latitude: string; longitude: string; }[]>([]);
    const { user: authenticatedUser } = useAuth();

    async function getCityProperties() {
        try {
            const actingBody = getActingBodyId();
            if (actingBody === 0) {
                setItems([]);
                return;
            }
            const response = await dispatchService.getDropDownCityProperties(actingBody);
            if (includeEmptyValue) {
                response.unshift({
                    code: '',
                    id: 0,
                    name: '',
                    type: '',
                    latitude: '0',
                    longitude: '0',
                });
            }
            setItems(response.map(cityProperty => ({
                label: `${cityProperty.code ? cityProperty.code + ' - ' : ''}${cityProperty.type ? cityProperty.type + ' - ' : ''}${cityProperty.name}`,
                value: cityProperty.id,
                latitude: cityProperty.latitude,
                longitude: cityProperty.longitude,
            })));
        } catch (err) {
            console.error(err);
        }
    }

    function getActingBodyId() {
        if (!authenticatedUser || !authenticatedUser.actingBodyUnit.actingBodyId) {
            return 0;
        }

        if (authenticatedUser.isAdmin) {
            return actingBodyId;
        } else {
            return authenticatedUser.actingBodyUnit.actingBodyId;
        }
    }

    useEffect(() => {
        if (!authenticatedUser) {
            return;
        }
        getCityProperties();
    }, [actingBodyId, authenticatedUser]);

    async function loadCurrentAddress(pin: LatLng) {
        try {
            if (!setCurrentAddress) {
                return;
            }
            const currentAddress = await centralAPI.getAddressByPlace({
                latitude: pin.lat,
                longitude: pin.lng,
            });
            setCurrentAddress(currentAddress);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (!value || !setPin) {
            return;
        }
        const row = items.find(cp => cp.value === value);
        if (!row) {
            return;
        }
        setPin({ lat: Number(row.latitude), lng: Number(row.longitude) });
        loadCurrentAddress({ lat: Number(row.latitude), lng: Number(row.longitude) });

    }, [value, setPin]);

    return (
        <FilterDropdown
            label={label ?? translate('cityProperty')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
            disabled={disabled}
            labelFontSize={labelFontSize}
        />
    );
}