import React, { useEffect, useState } from 'react';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';
import { auditLogsService } from '../../../services/central-api/audit-logs';


interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    zIndex: number;
}

function UsersDropdown({ value, setValue, zIndex }: Props) {
    const [selectableUsers, setSelectableUsers] = useState<{ label: string, value: number; }[]>([]);

    async function getUsers() {
        try {
            const users = await auditLogsService.getAllUsers();
            setSelectableUsers([
                ...users.map((user) => {
                    return {
                        value: user.id,
                        label: user.name
                    };
                })
            ]);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <FilterDropdown
            label={translate('user')}
            items={selectableUsers}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default UsersDropdown;
