import React, { useEffect, useState } from 'react';
import { dispatchService } from '../../services/central-api/dispatch';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    zIndex: number;
    occurrenceId: number;
    actingBodyId: number;
}

function EventOperatorDropdown({ value, setValue, zIndex, occurrenceId, actingBodyId }: Props) {
    const [selectableItems, setSelectableItems] = useState<{ label: string, value: number; }[]>([]);

    async function getDisplaceReasons() {
        try {
            const equipments = await dispatchService.getEventOperators({
                actingBodyId: actingBodyId,
                occurrenceId: occurrenceId,
            });
            setSelectableItems(equipments.map((item: DropdownResource) => {
                return {
                    label: item.name, value: item.id
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getDisplaceReasons();
    }, []);

    return (
        <FilterDropdown
            label={translate('eventOperators')}
            items={selectableItems}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default EventOperatorDropdown;
