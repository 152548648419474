import React from 'react';
import { View, Modal } from 'react-native';

import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import CustomButton from '../../../components/CustomButton';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import { cameraService } from '../../../services/central-api/cameras';

interface SilenceCameraModalProps {
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    isModalVisible: boolean;
    onConfirm: () => void;
    cameraId: string;
}

export default function SilenceCameraModal({ isModalVisible, setModalVisible, onConfirm, cameraId }: SilenceCameraModalProps) {
    const { styles } = useStyles(stylesheet);

    async function silenceCamera() {
        try {
            await cameraService.silenceCamera(cameraId);
            setModalVisible(false);
            onConfirm();
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal
            transparent={true}
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => setModalVisible(false)}
        >
            <View style={styles.container}>
                <View style={styles.body}>
                    <MyAppText>{translate('silenceCameraText')}</MyAppText>
                    <View style={styles.buttonsView}>
                        <CustomButton
                            onPress={() => setModalVisible(false)}
                            text={translate('cancel')}
                            type='secondary' />
                        <CustomButton
                            onPress={silenceCamera}
                            text={translate('silence')} />
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const stylesheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    body: {
        backgroundColor: theme.colors.cardBackground,
        flexDirection: 'column',
        borderRadius: 4,
        padding: 25,
        rowGap: 20,
        maxWidth: 350
    },
    buttonsView: {
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    }
}));
