import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { useStyles, createStyleSheet } from 'react-native-unistyles';
import MyRadio from '../../../components/MyRadio';
import { translate } from '../../../services/translate';
import CustomTextFilter from '../../../components/CustomTextFilter';
import CustomButton from '../../../components/CustomButton';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { gcmAgentService } from '../../../services/central-api/gcm-agent';
import moment from 'moment';
import { ClientError, NotFound } from '../../../services/central-api/base-service';
import Toast from 'react-native-toast-message';
import MyCpfInput from '../../../components/MyCpfInput';
import MyDateInput from '../../../components/MyDateInput';
import PersonContext from './PersonContext';
import WarrantContext from './WarrantContext';
import PlateContext from './PlateContext';
import ChassisContext from './ChassisContext';

export default function CortexFinder() {
    const { styles, theme } = useStyles(styleSheet);

    const [searchContext, setSearchContext] = useState<'person' | 'warrant' | 'vehicle'>('person');
    const [personSearchType, setPersonSearchType] = useState<'document' | 'motherName' | 'birthDate'>('document');
    const [vehicleSearchType, setVehicleSearchType] = useState<'plate' | 'chassis'>('plate');
    const [document, setDocument] = useState<string>('');
    const [isDocumentInvalid, setIsDocumentInvalid] = useState(false);
    const [name, setName] = useState<string>('');
    const [motherName, setMotherName] = useState<string>('');
    const [birthDate, setBirthDate] = useState(moment(new Date().getTime()).format('DD/MM/YYYY'));
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [plate, setPlate] = useState<string>('');
    const [chassis, setChassis] = useState<string>('');

    const [personFromDocument, setPersonFromDocument] = useState<PersonFromDocument>();
    const [personFromMotherName, setPersonFromMotherName] = useState<PersonFromMotherName[]>([]);
    const [personFromBirthDate, setPersonFromBirthDate] = useState<PersonFromBirthDate[]>([]);

    const [warrantInformation, setWarrantInformation] = useState<BasicWarrantInformation[]>([]);

    const [carFromPlate, setCarFromPlate] = useState<CarFromPlate>();
    const [carFromChassis, setCarFromChassis] = useState<CarFromChassis[]>([]);

    function isValidForm() {
        if (searchContext == 'person' || searchContext == 'warrant') {
            if (personSearchType == 'document' && (isDocumentInvalid || document == '')) {
                return false;
            } else if (personSearchType == 'motherName' && (!name || !motherName)) {
                return false;
            } else if (personSearchType == 'birthDate' && (!name || !moment(birthDate, 'DD/MM/YYYY').isValid() || moment(birthDate, 'DD/MM/YYYY').valueOf() <= moment(`01/01/1900`, 'DD/MM/YYYY').valueOf())) {
                return false;
            }
        } else if (searchContext == 'vehicle') {
            if (vehicleSearchType == 'plate') {
                const regex = /^[A-Za-z]{3}-?\d([A-Ja-j]|\d)\d{2}$/;
                return regex.test(plate);
            } else if (vehicleSearchType == 'chassis') {
                const regex = /^[A-HJ-NPR-Z0-9]{17}$/;
                return regex.test(chassis);
            }
        }

        return true;
    }

    async function findPerson() {
        try {
            setIsLoading(true);
            if (personSearchType == 'document') {
                const response = await gcmAgentService.findPersonFromDocument(document);
                setPersonFromDocument(response);
            } else if (personSearchType == 'motherName') {
                const response = await gcmAgentService.findPersonFromNameAndMotherName(name, motherName);
                setPersonFromMotherName(response);
            } else if (personSearchType == 'birthDate') {
                const response = await gcmAgentService.findPersonFromNameAndBirthDate(name, moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                setPersonFromBirthDate(response);
            }
        } catch (err) {
            if (err instanceof NotFound) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate('documentNotFound'),
                });
            } else if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    async function findWarrant() {
        try {
            setIsLoading(true);
            if (personSearchType == 'document') {
                const response = await gcmAgentService.findWarrantFromDocument(document);
                setWarrantInformation(response);
            } else if (personSearchType == 'motherName') {
                const response = await gcmAgentService.findWarrantFromNameAndMotherName(name, motherName);
                setWarrantInformation(response);
            } else if (personSearchType == 'birthDate') {
                const response = await gcmAgentService.findWarrantFromNameAndBirthDate(name, moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                setWarrantInformation(response);
            }
        } catch (err) {
            if (err instanceof NotFound) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate('documentNotFound'),
                });
            } else if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    async function findPlate() {
        try {
            setIsLoading(true);
            if (vehicleSearchType == 'plate') {
                setPlate(plate.replace(/-/g, '').toUpperCase());
                const response = await gcmAgentService.findCarFromPlate(plate);
                setCarFromPlate(response);
            } else if (vehicleSearchType == 'chassis') {
                const response = await gcmAgentService.findCarFromChassis(chassis);
                setCarFromChassis(response);
            }
        } catch (err) {
            if (err instanceof NotFound) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate('plateNotFound'),
                });
            } else if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        setName('');
        setDocument('');
        setMotherName('');
        setPlate('');
        setChassis('');
    }, [searchContext]);

    return (
        <View style={styles.container}>
            <View style={styles.cardList}>
                <MyRadio
                    selected={searchContext}
                    setSelected={setSearchContext}
                    options={[
                        { label: translate('person'), value: 'person' },
                        { label: translate('warrant'), value: 'warrant' },
                        { label: translate('normalVehicle'), value: 'vehicle' },
                    ]}
                />

                {['person', 'warrant'].includes(searchContext) ? (
                    <>
                        <MyRadio
                            selected={personSearchType}
                            setSelected={setPersonSearchType}
                            options={[
                                { label: translate('document'), value: 'document' },
                                { label: translate('motherName'), value: 'motherName' },
                                { label: translate('birth'), value: 'birthDate' },
                            ]}
                        />

                        {personSearchType === 'document' ? (
                            <MyCpfInput
                                value={document}
                                onChangeText={setDocument}
                                isInvalid={isDocumentInvalid}
                                setIsInvalid={setIsDocumentInvalid}
                            />
                        ) : personSearchType === 'motherName' ? (
                            <View style={{ gap: 15 }}>
                                <CustomTextFilter
                                    textFilter={name}
                                    setTextFilter={setName}
                                    title='name'
                                    style={styles.inputText}
                                />
                                <CustomTextFilter
                                    textFilter={motherName}
                                    setTextFilter={setMotherName}
                                    title='motherName'
                                    style={styles.inputText}
                                />
                            </View>
                        ) : personSearchType === 'birthDate' ? (
                            <View style={{ gap: 15 }}>
                                <CustomTextFilter
                                    textFilter={name}
                                    setTextFilter={setName}
                                    title='name'
                                    style={styles.inputText}
                                />
                                <MyDateInput
                                    label={translate('birth')}
                                    date={birthDate}
                                    onChangeDate={setBirthDate}
                                />
                            </View>
                        ) : null}
                    </>
                ) : searchContext === 'vehicle' ? (
                    <>
                        <MyRadio
                            selected={vehicleSearchType}
                            setSelected={setVehicleSearchType}
                            options={[
                                { label: translate('plate'), value: 'plate' },
                                { label: translate('chassis'), value: 'chassis' },
                            ]}
                        />
                        {vehicleSearchType == 'plate' ?
                            <CustomTextFilter
                                textFilter={plate}
                                setTextFilter={setPlate}
                                title='plate'
                                style={styles.inputText}
                            /> : null
                        }
                        {vehicleSearchType == 'chassis' ?
                            <CustomTextFilter
                                textFilter={chassis}
                                setTextFilter={setChassis}
                                title='chassis'
                                style={styles.inputText}
                            /> : null
                        }
                    </>

                ) : null}
                <CustomButton
                    icon={faSearch}
                    onPress={async () => {
                        if (!isValidForm()) {
                            return;
                        }

                        if (searchContext == 'person') {
                            await findPerson();
                        } else if (searchContext == 'warrant') {
                            await findWarrant();
                        } else if (searchContext == 'vehicle') {
                            await findPlate();
                        }
                    }}
                    text={translate('toSearch')}
                    disabled={!isValidForm()}
                />

                {isLoading ?
                    <ActivityIndicator size='large' color={theme.colors.iconColor} /> :
                    searchContext == 'person' ? (
                        <PersonContext
                            searchType={personSearchType}
                            isLoading={isLoading}
                            personFromBirthDate={personFromBirthDate}
                            personFromDocument={personFromDocument}
                            personFromMotherName={personFromMotherName} />
                    ) : (
                        searchContext == 'warrant' ? (
                            <WarrantContext
                                isLoading={isLoading}
                                warrantInformation={warrantInformation}
                            />
                        ) : (
                            searchContext == 'vehicle' && vehicleSearchType == 'plate' ? (
                                <PlateContext
                                    isLoading={isLoading}
                                    carFromPlate={carFromPlate}
                                />
                            ) : searchContext == 'vehicle' && vehicleSearchType == 'chassis' ?
                                <ChassisContext isLoading={isLoading} carFromChassis={carFromChassis} />
                                : <></>)
                    )
                }
            </View>
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        backgroundColor: theme.colors.container.background,
        padding: 10,
        flex: 1,
        gap: 20
    },
    inputText: {
        minWidth: 300
    },
}));
