import React from 'react';
import LateralMenu from '../LateralMenu';
import { faFileContract, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_logs.png';
import collapsedLogo from '../../../assets/icon_logs.png';

export default function LogsLateralMenu({ navigation, open, authenticatedUser, setOpen, selectedMenu }: LateralMenuProps) {

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={[{
                icon: faFileContract,
                text: translate('logRegistries'),
                action: () => navigation.navigate('Logs', { screen: 'LogList' }),
                selected: selectedMenu == 'search'
            }, {
                icon: faFolderOpen,
                text: translate('reports'),
                action: () => navigation.navigate('Logs', { screen: 'LogReports' }),
                selected: selectedMenu == 'reports'
            }]}
        />
    );
}
