import React from 'react';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { FacialParamList } from '../../../typings/Params';

interface Props {
    filters: FacialParamList['Markers'];
    setFilters: React.Dispatch<React.SetStateAction<FacialParamList['Markers']>>;
    textFilter: string;
    setTextFilter: (value: string) => void;
}

function Filter({ filters, setFilters, textFilter, setTextFilter }: Props) {
    const navigation = useNavigation<NavigationProp<RouteProp<FacialParamList, 'Markers'>>>();

    const handleCleanFilter = () => {
        setTextFilter('');
    };

    const handleFilter = () => {
        navigation.setParams({
            ...filters,
            page: 1,
            textFilter,
        });

        setFilters({
            ...filters,
            page: 1,
            textFilter,
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('typeToSearch')}
                value={textFilter}
                placeholder={translate('typeToSearch')}
                onChange={setTextFilter}
            />
        </Filters>
    );
}


export default Filter;
