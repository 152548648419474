import React, { useEffect, useState } from 'react';
import { View, ScrollView } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { Tabs } from '../../../components/Tabs';
import { SelectableItem, SelectableList } from '../../../components/SelectableList';
import Toast from 'react-native-toast-message';
import { accessService } from '../../../services/central-api/access';
import MyPhoneInput from '../../../components/MyPhoneInput';
import { WindowInformation } from '../../../services/window-information';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import MyAppText from '../../../components/MyAppText';
import { ClientError } from '../../../services/central-api/base-service';
import { dispatchService } from '../../../services/central-api/dispatch';
import { centralAPI } from '../../../services/central-api';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import ProfilePicture from '../../../components/user/ProfilePicture';
import stringToFileImage from '../../../services/stringToFileImage';
import MyCpfInput from '../../../components/MyCpfInput';
import FilterCheckBox from '../../../components/Filter/components/FilterCheckBox';
import { useAuth } from '../../../context/Auth';
import UpdateActingBodyUnitModal from './UpdateActingBodyUnitModal';


export default function UserModal({ onClose, userId }: { onClose: (success: boolean) => void, userId?: number; }) {
    const { styles } = useStyles(stylesheet);

    const windowInfo = WindowInformation();
    const { user: authenticatedUser } = useAuth();

    const [ownUser, setOwnUser] = useState<UserData>();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [registry, setRegistry] = useState('');
    const [phone, setPhone] = useState('');
    const [patent, setPatent] = useState('');
    const [warName, setWarName] = useState('');
    const [document, setDocument] = useState('');
    const [isDocumentInvalid, setIsDocumentInvalid] = useState(false);
    const [selectableGroups, setSelectableGroups] = useState<SelectableItem[]>([]);
    const [selectablePermissionLevels, setSelectablePermissionLevels] = useState<SelectableItem[]>([]);
    const [selectableSkills, setSelectableSkills] = useState<SelectableItem[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<SelectableItem[]>([]);
    const [selectedPermissionLevels, setSelectedPermissionLevels] = useState<SelectableItem[]>([]);
    const [selectedSkills, setSelectedSkills] = useState<SelectableItem[]>([]);
    const [userGroupIds, setUserGroupIds] = useState<number[]>([]);
    const [userPermissionLevelIds, setUserPermissionLevelIds] = useState<number[]>([]);
    const [userSkillsIds, setUserSkillsIds] = useState<number[]>([]);
    const [isOpenDepartment, setIsOpenDepartment] = useState(false);
    const [isOpenRole, setIsOpenRole] = useState(false);
    const [isOpenUnit, setIsOpenUnit] = useState(false);
    const [selectableActingBodies, setSelectableActingBodies] = useState<{ label: string, value: number; }[]>([]);
    const [selectableRoles, setSelectableRoles] = useState<{ label: string, value: number; }[]>([]);
    const [selectableUnits, setSelectableUnits] = useState<{ label: string, value: number; }[]>([]);
    const [actingBodyId, setActingBodyId] = useState<number>(0);
    const [roleId, setRoleId] = useState<number>(0);
    const [unitId, setUnitId] = useState<number>(0);
    const [profilePicture, setProfilePicture] = useState<string>('');
    const [fieldAgent, setFieldAgent] = useState<boolean>(false);

    const [isOpenTags, setIsOpenTags] = useState<boolean>(false);
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>([]);
    const [selectableTags, setSelectableTags] = useState<{ label: string, value: number; }[]>([]);

    const [updateUserModal, setUpdateUserModal] = useState<boolean>(false);
    const [tmpActingBodyUnitId, setTmpActingBodyUnitId] = useState<number>(0);

    async function getUser(id: number, skills: Skill[], actingBodies: ActingBody[]) {
        const user = await accessService.getUser(id);
        setName(user.name);
        setEmail(user.email);
        setRegistry(user.registry);
        setPhone(user.phone);
        setPatent(user.patent || '');
        setWarName(user.warName || '');
        setDocument(user.document || '');
        setFieldAgent(user.fieldAgent || false);
        setProfilePicture(user.facePictureImageUrl ?? '');

        if (user.role?.actingBodyId) {
            setActingBodyId(user.role.actingBodyId);
            await loadRolesAndUnits(user.role?.actingBodyId);
            setRoleId(user.roleId || 0);
            setUnitId(user.actingBodyUnitId || 0);

            const [groups, permissionLevels] = await Promise.all([
                loadGroups(user.actingBodyUnitId || 0),
                loadPermissionLevels(user.actingBodyUnitId || 0),
            ]);

            const selectedGroups = user.Groups.map((group) => {
                return {
                    id: group.id || 0,
                    name: group.name
                };
            });
            setSelectedGroups(selectedGroups);
            setUserGroupIds(user.Groups.map((group) => group.id || 0));
            setSelectableGroups((groups?.rows || [])
                .filter((group) => !selectedGroups.find((userGroup) => userGroup.id == group.id))
                .map((group) => {
                    return {
                        id: group.id || 0,
                        name: group.name
                    };
                })
            );

            const selectedPermissionLevels = user.PermissionLevels.map((permissionLevel) => {
                return {
                    id: permissionLevel.id || 0,
                    name: permissionLevel.name
                };
            });
            setSelectedPermissionLevels(selectedPermissionLevels);
            setUserPermissionLevelIds(user.PermissionLevels.map((permissionLevel) => permissionLevel.id || 0));
            setSelectablePermissionLevels((permissionLevels?.rows || [])
                .filter((permissionLevel) => !selectedPermissionLevels.find((userPermissionLevel) => userPermissionLevel.id == permissionLevel.id))
                .map((permissionLevel) => {
                    return {
                        id: permissionLevel.id || 0,
                        name: permissionLevel.name
                    };
                })
            );
        }

        const selectedSkills = user.Skills.map((skill) => {
            return {
                id: skill.id || 0,
                name: skill.name
            };
        });
        setSelectedSkills(selectedSkills);
        setUserSkillsIds(user.Skills.map((skill) => skill.id || 0));
        setSelectableSkills(skills
            .filter((skill) => !selectedSkills.find((userSkill) => userSkill.id == skill.id))
            .map((skill) => {
                return {
                    id: skill.id || 0,
                    name: skill.name
                };
            })
        );

        setSelectableActingBodies(actingBodies.map((actingBody) => {
            return {
                value: actingBody.id || 0,
                label: actingBody.name
            };
        }));

        if (user.tags.length > 0) {
            setSelectedTagsIds(user.tags.map(tag => tag.id || 0));
        }

    }

    async function loadGroups(actingBodyUnitId: number) {
        try {
            setSelectedGroups([]);
            const groups = await accessService.getGroups({ page: 0, limit: 9999999, actingBodyUnitIds: [actingBodyUnitId] });
            setSelectableGroups(groups.rows.map((group) => {
                return {
                    id: group.id || 0,
                    name: group.name
                };
            }));
            return groups;
        } catch (err) {
            console.error(err);
        }
    }

    async function loadPermissionLevels(actingBodyUnitId: number) {
        try {
            setSelectedPermissionLevels([]);
            const permissionLevels = await accessService.getPermissionLevels({ page: 0, limit: 9999999, actingBodyUnitIds: [actingBodyUnitId] });
            setSelectablePermissionLevels(permissionLevels.rows.map((permissionLevel) => {
                return {
                    id: permissionLevel.id || 0,
                    name: permissionLevel.name
                };
            }));
            return permissionLevels;
        } catch (err) {
            console.error(err);
        }
    }

    async function loadRolesAndUnits(actingBodyId: number) {
        try {
            setRoleId(0);
            setUnitId(0);
            const actingBody = await accessService.getActingBody(actingBodyId);
            if (!actingBody.roles) {
                setSelectableRoles([]);
            } else {
                setSelectableRoles(actingBody.roles.map((role) => {
                    return {
                        value: role.id || 0,
                        label: role.name
                    };
                }));
            }
            if (!actingBody.actingBodyUnits) {
                setSelectableUnits([]);
            } else {
                setSelectableUnits(actingBody.actingBodyUnits.map((role) => {
                    return {
                        value: role.id || 0,
                        label: role.name
                    };
                }));
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function loadPageInfo() {
        try {
            const [skills, actingBodies, ownUser, tags] = await Promise.all([
                dispatchService.getSkills({ page: 0, limit: 9999999 }),
                accessService.getActingBodies({ page: 0, limit: 9999999 }),
                centralAPI.getOwnUser({ includeRole: true }),
                accessService.getUserTags()
            ]);

            setOwnUser(ownUser);
            setSelectableTags(tags.map((tag) => {
                return {
                    value: tag.id || 0,
                    label: tag.name
                };
            }));

            if (userId) {
                await getUser(userId, skills.rows, actingBodies.rows);
            } else {
                setSelectableActingBodies(actingBodies.rows.map((actingBody) => {
                    return {
                        value: actingBody.id || 0,
                        label: actingBody.name
                    };
                }));

                if (!ownUser.isAdmin && ownUser.actingBodyUnitId) {
                    setActingBodyId(ownUser.role?.actingBodyId || 0);
                    loadRolesAndUnits(ownUser.role?.actingBodyId || 0);
                    loadGroups(ownUser.actingBodyUnitId);
                    loadPermissionLevels(ownUser.actingBodyUnitId);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        loadPageInfo();
    }, []);

    function isEmpty(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isEmpty(name) &&
            !isEmpty(email) &&
            (userId || !isEmpty(password)) &&
            !isEmpty(registry) &&
            !isEmpty(phone) &&
            roleId != 0 &&
            (!ownUser?.isAdmin || unitId != 0) &&
            !isEmpty(document) && !isDocumentInvalid;
    }

    async function formSubmit() {
        try {
            if (userId) {
                const selectedGroupIds = selectedGroups.map((group) => Number(group.id));
                const groupsToAdd = selectedGroupIds.filter((selectedGroup) => !userGroupIds.includes(selectedGroup));
                const groupsToRm = userGroupIds.filter((userGroup) => !selectedGroupIds.includes(userGroup));

                const selectedPermissionLevelIds = selectedPermissionLevels.map((permissionLevel) => Number(permissionLevel.id));
                const permissionLevelsToAdd = selectedPermissionLevelIds.filter((selectedGroup) => !userPermissionLevelIds.includes(selectedGroup));
                const permissionLevelsToRm = userPermissionLevelIds.filter((userGroup) => !selectedPermissionLevelIds.includes(userGroup));

                const selectedSkillsIds = selectedSkills.map((skill) => Number(skill.id));
                const skillsToAdd = selectedSkillsIds.filter((selectedSkill) => !userSkillsIds.includes(selectedSkill));
                const skillsToRm = userSkillsIds.filter((userSkill) => !selectedSkillsIds.includes(userSkill));


                await accessService.updateUser(userId, {
                    name,
                    email,
                    document,
                    password,
                    registry,
                    phone,
                    groupsToAdd,
                    permissionLevelsToAdd,
                    skillsToAdd,
                    groupsToRm,
                    permissionLevelsToRm,
                    skillsToRm,
                    roleId,
                    unitId,
                    patent,
                    warName,
                    actingBodyId,
                    fieldAgent,
                    tags: selectedTagsIds
                });

                if (profilePicture && !profilePicture.startsWith('http')) {
                    await centralAPI.updateUserPicture({
                        userId,
                        photo: stringToFileImage(profilePicture),
                    });
                }

                onClose(true);
                return;
            }

            const user = await accessService.createUser({
                name,
                email,
                password,
                registry,
                phone,
                document,
                groupsToAdd: selectedGroups.map(item => Number(item.id)),
                permissionLevelsToAdd: selectedPermissionLevels.map(item => Number(item.id)),
                skillsToAdd: selectedSkills.map(item => Number(item.id)),
                roleId,
                unitId,
                patent,
                warName,
                actingBodyId,
                fieldAgent,
                tags: selectedTagsIds
            });

            if (user.id && profilePicture) {
                await centralAPI.updateUserPicture({
                    userId: user.id,
                    photo: stringToFileImage(profilePicture),
                });
            }
            onClose(true);
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '80%' }]}>
            <Tabs tabs={[{
                key: 'personal_data',
                label: translate('personalData')
            }, {
                key: 'groups',
                label: translate('groups')
            }, {
                key: 'permissionLevels',
                label: translate('permissionLevels')
            }, {
                key: 'skills',
                label: translate('skills')
            }]}>
                {({ selectedTab }) => {
                    if (selectedTab == 'personal_data') {
                        return (
                            <ScrollView contentContainerStyle={styles.personalDataContainer}>
                                <View style={{ flex: 1 }}>
                                    <ProfilePicture photo={profilePicture} setProfilePicture={setProfilePicture} />
                                </View>
                                <View style={{ flex: 2, rowGap: 10 }}>
                                    <FormInput
                                        label={translate('name')}
                                        placeholder={translate('name')}
                                        value={name}
                                        onChangeText={setName}
                                        invalid={isEmpty}
                                    />
                                    <View style={{ flexDirection: 'row', gap: 16 }}>
                                        <FormInput
                                            label={translate('email')}
                                            placeholder={translate('email')}
                                            value={email}
                                            onChangeText={setEmail}
                                            invalid={() => false}
                                            viewStyle={{ flex: 1 }}
                                        />
                                        <MyCpfInput
                                            value={document}
                                            onChangeText={setDocument}
                                            isInvalid={isDocumentInvalid}
                                            setIsInvalid={setIsDocumentInvalid}
                                            viewStyle={{ flex: 1 }} />
                                    </View>
                                    {!userId ?
                                        <FormInput
                                            label={translate('password')}
                                            placeholder={translate('password')}
                                            value={password}
                                            onChangeText={setPassword}
                                            invalid={() => false}
                                            secureTextEntry
                                        /> : null
                                    }
                                    <View style={{ flexDirection: 'row', gap: 16 }}>
                                        <FormInput
                                            label={translate('registration')}
                                            placeholder={translate('registration')}
                                            value={registry}
                                            onChangeText={setRegistry}
                                            invalid={() => false}
                                            viewStyle={{ flex: 1 }}
                                        />
                                        <MyPhoneInput
                                            label={translate('phone')}
                                            placeholder={translate('phone')}
                                            value={phone}
                                            onChangeText={setPhone}
                                            invalid={() => false}
                                            viewStyle={{ flex: 1 }}
                                            labelStyle={{
                                                fontSize: 16
                                            }}
                                        />
                                    </View>
                                    <View style={{ flexDirection: 'row', gap: 16, zIndex: 12 }}>
                                        {ownUser?.isAdmin ?
                                            <View style={{ flex: 1, gap: 5 }}>
                                                <MyAppText>{translate('actingBody')}</MyAppText>
                                                <MyDropDownPicker
                                                    items={selectableActingBodies}
                                                    multiple={false}
                                                    value={actingBodyId}
                                                    setValue={async (value) => {
                                                        const val = value(0);
                                                        setActingBodyId(val);
                                                        loadRolesAndUnits(val);
                                                    }}
                                                    setOpen={setIsOpenDepartment}
                                                    open={isOpenDepartment}
                                                />
                                            </View>
                                            : null
                                        }
                                        {ownUser?.isAdmin || authenticatedUser?.permissions.access_edit_user_acting_body_units ?
                                            <View style={{ flex: 1, gap: 5, zIndex: 12 }}>
                                                <MyAppText>{translate('unit')}</MyAppText>
                                                <MyDropDownPicker
                                                    items={selectableUnits}
                                                    multiple={false}
                                                    value={unitId}
                                                    setValue={(value) => {
                                                        const val = value(0);
                                                        setTmpActingBodyUnitId(unitId);
                                                        setUnitId(val);

                                                        if (val !== unitId && (selectedGroups.length || selectedPermissionLevels.length)) {
                                                            setUpdateUserModal(true);
                                                        } else {
                                                            loadGroups(val);
                                                            loadPermissionLevels(val);
                                                        }
                                                    }}
                                                    setOpen={setIsOpenUnit}
                                                    open={isOpenUnit}
                                                />
                                            </View> : <></>
                                        }
                                    </View>
                                    <View style={{ flexDirection: 'row', gap: 16, zIndex: 11 }}>
                                        <View style={{ flex: 1, gap: 5 }}>
                                            <MyAppText>{translate('role')}</MyAppText>
                                            <MyDropDownPicker
                                                items={selectableRoles}
                                                multiple={false}
                                                value={roleId}
                                                setValue={setRoleId}
                                                setOpen={setIsOpenRole}
                                                open={isOpenRole}
                                            />
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', gap: 16, zIndex: 10 }}>
                                        <View style={{ flex: 1, gap: 5 }}>
                                            <MyAppText >{translate('tags')}</MyAppText>
                                            <MyDropDownPicker
                                                items={selectableTags}
                                                multiple={true}
                                                mode='BADGE'
                                                value={selectedTagsIds}
                                                setValue={setSelectedTagsIds}
                                                setOpen={setIsOpenTags}
                                                open={isOpenTags}
                                            />
                                        </View>
                                    </View>
                                    <FormInput
                                        label={translate('warName')}
                                        placeholder={translate('warName')}
                                        value={warName}
                                        onChangeText={setWarName}
                                        invalid={() => false}
                                    />
                                    <FormInput
                                        label={translate('patent')}
                                        placeholder={translate('patent')}
                                        value={patent}
                                        onChangeText={setPatent}
                                        invalid={() => false}
                                    />
                                    <FilterCheckBox
                                        label={translate('fieldAgent')}
                                        value={fieldAgent}
                                        onChange={setFieldAgent}
                                    />
                                </View>

                            </ScrollView>
                        );
                    }
                    if (selectedTab == 'groups') {
                        return <SelectableList
                            selectableLabel={translate('groups')}
                            selectableContent={selectableGroups}
                            selectedLabel={translate('participants')}
                            selectedContent={selectedGroups}
                        />;
                    }
                    if (selectedTab == 'permissionLevels') {
                        return <SelectableList
                            selectableLabel={translate('permissionLevels')}
                            selectableContent={selectablePermissionLevels}
                            selectedLabel={translate('participants')}
                            selectedContent={selectedPermissionLevels}
                        />;
                    }
                    if (selectedTab == 'skills') {
                        return <SelectableList
                            selectableLabel={translate('skills')}
                            selectableContent={selectableSkills}
                            selectedLabel={translate('participants')}
                            selectedContent={selectedSkills}
                        />;
                    }
                }}
            </Tabs>
            <FormActions
                onSubmit={formSubmit}
                onClose={() => onClose(false)}
                disabled={!isFormValid()} />
            <UpdateActingBodyUnitModal
                setIsModalVisible={setUpdateUserModal}
                isModalVisible={updateUserModal}
                onSubmit={() => {
                    loadGroups(unitId);
                    loadPermissionLevels(unitId);
                    setUpdateUserModal(false);
                }}
                onClose={() => {
                    setUnitId(tmpActingBodyUnitId);
                    setUpdateUserModal(false);
                }} />

        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    formContainer: {
        minWidth: 300,
        height: '90%',
        backgroundColor: theme.colors.container.background,
        borderRadius: 2,
        padding: 20,
        minHeight: 650,
        justifyContent: 'space-between',
        rowGap: 10
    },

    personalDataContainer: {
        flexDirection: {
            xs: 'column',
            md: 'row'
        }
    }
}));
