import React, { useEffect, useState, useCallback } from 'react';
import { View, Image } from 'react-native';


import { translate } from '../../services/translate';
import MyAppText from '../../components/MyAppText';
import EventList from '../../components/facial/EventList';
import { Slider } from '@miblanchard/react-native-slider';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../components/Tooltip';
import { MoreThanOneFaceOnPhoto, NoFaceOnPhoto } from '../../services/central-api/facial';
import { smartSearchFacialService } from '../../services/central-api/smart-search-facial';
import Toast from 'react-native-toast-message';
import FileUploader from '../../components/FileUploader';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface SmartSearchFacialParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}


export default function SmartSearchFacial({ navigation }: SmartSearchFacialParams) {
    const { styles, theme } = useStyles(stylesheet);

    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    const [file, setFile] = useState<{ file: File, objectURI: string; }>();
    const [thresholdSlider, setThresholdSlider] = useState(834);
    const [threshold, setThreshold] = useState(thresholdSlider / 1000);
    const [slidingTimeout, setSlidingTimeout] = useState<NodeJS.Timeout>();


    const [isLoading, setIsLoading] = useState(false);
    const [detections, setDetections] = useState<FacialDetection[]>([]);

    async function getFacialDetections(file: File) {
        try {
            setIsLoading(true);

            setDetections([]);
            const response = await smartSearchFacialService.findDetectionByPicture({ photo: file, threshold });
            setDetections(response.detections);
            forceUpdate();
        } catch (err) {
            if (err instanceof NoFaceOnPhoto || err instanceof MoreThanOneFaceOnPhoto) {
                return Toast.show({
                    type: 'sentinelxWarning',
                    text1: translate(err.message),
                });
            }
            console.error(err);
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        // debounce
        if (slidingTimeout) {
            clearTimeout(slidingTimeout);
        }

        const timeout = setTimeout(() => {
            setThreshold(thresholdSlider / 1000);
        }, 1000);

        setSlidingTimeout(timeout);

    }, [thresholdSlider]);

    useEffect(() => {
        if (file) {
            getFacialDetections(file.file);
        }
    }, [threshold, file]);


    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <FileUploader
                    onChange={(e) => {
                        if (!e.target.files) {
                            return;
                        }
                        const file = e.target.files[0];
                        setFile({ file, objectURI: URL.createObjectURL(file) });
                    }}
                    accept='image/*'
                    text={translate('chooseFile')}
                    icon={faFileUpload}
                />

                {file ?
                    <Image
                        style={styles.imageContainer}
                        source={{
                            uri: file.objectURI
                        }}
                    />
                    :
                    <View style={[styles.imageContainer, {
                        backgroundColor: theme.colors.primaryButton.background,
                    }]} />
                }
                <Tooltip tooltipDirection='right' tooltipContent={translate('thresholdDetail')} width={300}>
                    <View style={styles.sliderContainer}>
                        <View style={styles.rowSlider}>
                            <Slider
                                animateTransitions
                                minimumValue={0}
                                maximumValue={1000}
                                minimumTrackTintColor={theme.colors.primaryButton.background}
                                thumbTintColor={theme.colors.primaryButton.background}
                                value={thresholdSlider}
                                step={1}
                                onValueChange={(value) => setThresholdSlider(value[0])}
                            />
                        </View>
                        <MyAppText>
                            {(thresholdSlider / 1000).toFixed(3)}
                        </MyAppText>
                    </View>
                </Tooltip>
            </View>
            <EventList
                events={detections}
                navigation={navigation}
                hasMoreResults={false}
                isLoading={isLoading}
                isPolling={false}
                module='smart-search-facial'
                page='search'
            />
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    rowSlider: {
        width: 150,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        flexWrap: 'wrap'
    },
    imageContainer: {
        height: 100,
        width: 90,
        borderRadius: 5,
        overflow: 'hidden',
    },
    sliderContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: theme.colors.primaryButton.background,
        paddingHorizontal: 3,
        height: 40,
        width: 256,
        columnGap: 20
    }
}));
