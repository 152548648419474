import React, { useEffect, useState, useCallback, useRef } from 'react';
import { View } from 'react-native';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import EventList from '../../../components/lpr/EventList';
import { RouteProp, useRoute } from '@react-navigation/native';
import { LPRParamList } from '../../../typings/Params';
import useFilters from '../../../components/Filter/hooks/useFilters';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CamerasDropdown from '../../../components/lpr/CamerasDropdown';

interface LiveEventsParams {
    navigation: Navigation;
}

export default function LiveEvents({ navigation }: LiveEventsParams) {
    const { styles } = useStyles(styleSheet);

    const route = useRoute<RouteProp<LPRParamList, 'LprLiveEvents'>>();
    const [filters, setFilters] = useFilters<LPRParamList['LprLiveEvents']>({
        ...route.params,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [occurrences, setOccurrences] = useState<LprDetection[]>([]);
    const loaders = useRef({ didSelectedCamerasLoad: false });
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    const [selectedCameraFilter, setSelectedCameraFilter] = useState<string[]>(filters.cameras ?? []);

    useEffect(() => {
        if (!loaders.current.didSelectedCamerasLoad) {
            loaders.current.didSelectedCamerasLoad = true;
            return;
        }
        setFilters({
            ...filters,
            cameras: selectedCameraFilter,
        });
        navigation.setParams({
            ...filters,
            cameras: selectedCameraFilter,
        });
    }, [selectedCameraFilter]);

    useEffect(() => {
        getDetections();

        const interval = setInterval(() => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will only work when the page is active
            if (!window.location.href.endsWith('LPR/live-events')) {
                return;
            }

            getDetections();
        }, 2000);
        return () => clearInterval(interval);
    }, [filters]);

    async function getDetections() {
        try {
            setIsLoading(true);

            const newOccurrences = await licensePlateRecognitionService.getDetections({
                ...filters,
                page: 0,
                limit: 50
            });
            occurrences.length = 0;
            occurrences.push(...newOccurrences);
            setOccurrences(occurrences);
            forceUpdate();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }

    }

    return (
        <View style={styles.container}>
            <View style={{ minHeight: 65, zIndex: 2 }}>
                <CamerasDropdown setValue={setSelectedCameraFilter} value={selectedCameraFilter} zIndex={2} />
            </View>
            <EventList
                events={occurrences}
                navigation={navigation}
                hasMoreResults={false}
                isLoading={isLoading}
                isPolling={true}
                page='live-events'
            />
        </View>
    );
}

const styleSheet = createStyleSheet(() => ({
    container: {
        flex: 1,
        rowGap: 15
    },
}));
