import React from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { LPRParamList } from '../../../typings/Params';
import Filters from '../../../components/Filter';
import { useState } from 'react';
import moment from 'moment';
import FilterDatePicker from '../../../components/Filter/components/FilterDateInput';
import { translate } from '../../../services/translate';
import CamerasDropdown from '../../../components/lpr/CamerasDropdown';
import { View } from 'react-native';
import FilterCheckBox from '../../../components/Filter/components/FilterCheckBox';
import FilterInput from '../../../components/Filter/components/FilterInput';

interface Props {
    filters: LPRParamList['LprSearch'];
    setFilters: React.Dispatch<React.SetStateAction<LPRParamList['LprSearch']>>;
    plateFilter: string;
    setPlateFilter: React.Dispatch<React.SetStateAction<string>>;
}

export default function LprSearchFilter({ filters, setFilters, setPlateFilter, plateFilter }: Props) {
    const navigation = useNavigation<NavigationProp<LPRParamList, 'LprSearch'>>();

    const [beginInDateFilter, setBeginInDateFilter] = useState(filters.beggingIn ? new Date(filters.beggingIn) : null);
    const [beginInHourFilter, setBeginInHourFilter] = useState(filters.beggingIn ? moment(filters.beggingIn).format('HH:mm') : '');
    const [endInDateFilter, setEndInDateFilter] = useState(filters.endIn ? new Date(filters.endIn) : null);
    const [endInHourFilter, setEndInHourFilter] = useState(filters.endIn ? moment(filters.endIn).format('HH:mm') : '');
    const [selectedCamerasIds, setSelectedCamerasIds] = useState<string[]>(filters.cameras ?? []);
    const [irregularSituationFilter, setIrregularSituationFilter] = useState(filters.irregularSituation);
    const [isMotorcycleFilter, setIsMotorcycleFilter] = useState(filters.isMotorcycle);

    const handleCleanFilter = () => {
        setBeginInDateFilter(null);
        setBeginInHourFilter('');
        setEndInDateFilter(null);
        setEndInHourFilter('');
        setSelectedCamerasIds([]);
        setPlateFilter('');
        setIrregularSituationFilter(false);
        setIsMotorcycleFilter(false);
    };

    const handleFilter = () => {
        const formatDateTime = (date: Date | null, time: string) =>
            date ? moment(`${moment(date).format('DD/MM/YYYY')} ${time}`, 'DD/MM/YYYY HH:mm:ss').valueOf() : undefined;
        const beginIn = formatDateTime(beginInDateFilter, beginInHourFilter ? `${beginInHourFilter}:00` : '00:00:00');
        const endIn = formatDateTime(endInDateFilter, endInHourFilter ? `${endInHourFilter}:59` : '23:59:59');

        setFilters({
            ...filters,
            page: 0,
            cameras: selectedCamerasIds.length ? selectedCamerasIds : undefined,
            beggingIn: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            endIn: endIn && !isNaN(endIn) ? endIn : undefined,
            plate: plateFilter !== '' ? plateFilter.toUpperCase() : undefined,
            irregularSituation: irregularSituationFilter,
            isMotorcycle: isMotorcycleFilter
        });

        navigation.setParams({
            page: 1,
            cameras: selectedCamerasIds.length ? selectedCamerasIds : undefined,
            beggingIn: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            endIn: endIn && !isNaN(endIn) ? endIn : undefined,
            plate: plateFilter !== '' ? plateFilter.toUpperCase() : undefined,
            irregularSituation: irregularSituationFilter,
            isMotorcycle: isMotorcycleFilter
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('plate')}
                value={plateFilter}
                placeholder={translate('typeToSearch')}
                onChange={setPlateFilter}
            />
            <FilterDatePicker
                date={beginInDateFilter}
                onChangeDate={setBeginInDateFilter}
                time={beginInHourFilter}
                onChangeTime={setBeginInHourFilter}
                label={translate('beginIn')}
                zIndex={6}
            />
            <FilterDatePicker
                date={endInDateFilter}
                onChangeDate={setEndInDateFilter}
                time={endInHourFilter}
                onChangeTime={setEndInHourFilter}
                label={translate('endIn')}
                zIndex={5}
            />
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <FilterCheckBox
                    label={translate('irregularSituation')}
                    value={irregularSituationFilter}
                    onChange={setIrregularSituationFilter}
                />
                <FilterCheckBox
                    label={translate('motorcycleOnly')}
                    value={isMotorcycleFilter}
                    onChange={setIsMotorcycleFilter}
                />
            </View>
            <CamerasDropdown setValue={setSelectedCamerasIds} value={selectedCamerasIds} zIndex={4} />
        </Filters>
    );
}
