import React, { useState } from 'react';
import { translate, translateWithoutErrors } from '../../services/translate';
import { FlatList, View, Image, TouchableOpacity, Modal } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomButton from '../CustomButton';
import MyAppText from '../MyAppText';
import moment from 'moment';
import Toast from 'react-native-toast-message';
import FormInput from '../formInput';
import { dispatchService } from '../../services/central-api/dispatch';
import { ClientError } from '../../services/central-api/base-service';
import { faSearchPlus, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import FileUploader from '../FileUploader';
import { Hoverable } from 'react-native-web-hover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { WindowInformation } from '../../services/window-information';

interface Props {
    historicList: OccurrenceReport[];
    setHistoricList: React.Dispatch<React.SetStateAction<OccurrenceReport[]>>;
    renderFlatList: boolean;
    occurrenceSituation: OccurrenceSituation;
    occurrenceId: number;
}

function OccurrenceHistoricList({ historicList, setHistoricList, renderFlatList, occurrenceSituation, occurrenceId }: Props) {
    const { styles, theme } = useStyles(styleSheet);
    const [historicNarrative, setHistoricNarrative] = useState({ dirty: false, value: '' });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [zoomedImage, setZoomedImage] = useState('');
    const windowInfo = WindowInformation();


    function renderAttachment(data: { url: string; type: string; additionalInfo?: { reportPolice?: string; }; }) {
        if (data.type.includes('image/')) {
            return <Hoverable>
                {({ hovered }) => (
                    <>
                        <Image source={{ uri: data.url }} style={styles.imagePreview} />
                        {hovered &&
                            <View style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#00000080',
                            }}>
                                <TouchableOpacity
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center'
                                    }}
                                    onPress={async () => {
                                        setZoomedImage(data.url);
                                        setIsModalVisible(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faSearchPlus} fontSize={28} color={'#FFFFFF'} />
                                </TouchableOpacity>
                            </View>
                        }
                    </>
                )
                }
            </Hoverable>;
        }
        return <View style={{ alignItems: 'center' }}>
            <CustomButton onPress={() => window.open(data.url)} text={translate('downloadAttachment')} />
            <MyAppText style={{ fontSize: 11 }}>{translate('type')}: {data.type}</MyAppText>
        </View>;
    }

    function renderHistoric({ item }: { item: OccurrenceReport; }) {
        const content = ['report', 'final_report', 'handcuff_explanation'].includes(item.occurrenceReportType) ? item.content : JSON.parse(item.content);
        return (
            <View style={styles.card}>

                {item.occurrenceReportType == 'attachment' ?
                    <View style={{ gap: 10, flexDirection: 'row' }}>
                        <View>
                            <MyAppText style={styles.cardHeader}>{translate('addedAttachment')}</MyAppText>
                            <MyAppText style={styles.cardSubtitle}>
                                {item.user ? (item.user.warName || item.user.name) + ' • ' : ''}
                                {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                        <View style={{ flex: 1, alignItems: 'flex-end' }}>
                            {renderAttachment(content)}
                        </View>
                    </View>
                    : null
                }

                {['report', 'final_report', 'handcuff_explanation'].includes(item.occurrenceReportType) ?
                    <View style={{ gap: 10 }}>
                        <View>
                            <MyAppText style={styles.cardHeader}>
                                {item.occurrenceReportType === 'report' ? translate('addedComment') : null}
                                {item.occurrenceReportType === 'final_report' ? translate('final_report') : null}
                                {item.occurrenceReportType === 'handcuff_explanation' ? translate('handcuff_explanation') : null}
                            </MyAppText>
                            <MyAppText style={styles.cardSubtitle}>
                                {item.user ? (item.user.warName || item.user.name) + ' • ' : ''}
                                {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                        <MyAppText style={styles.cardContent}>{item.content}</MyAppText>
                    </View>
                    : null
                }

                {item.occurrenceReportType == 'historic' ?
                    <View style={{ gap: 10 }}>
                        <View>
                            <MyAppText style={styles.cardHeader}>{translate(content.title)}</MyAppText>
                            <MyAppText style={styles.cardSubtitle}>
                                {item.user ? (item.user.warName || item.user.name) + ' • ' : ''}
                                {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                        <MyAppText style={styles.cardContent}>
                            {
                                Object.keys(content).filter((key) => !['title', 'userId', 'actingBodyId', 'actingBodyCommandId', 'unitId'].includes(key)).map((key) => {
                                    if (['occurrence_updated_involved_object', 'occurrence_updated_involved_person', 'occurrence_updated_involved_vehicle'].includes(content.title)) {
                                        try {
                                            const [oldValue, newValue] = JSON.parse(content[key]);
                                            if (['gender', 'stolen', 'currency', 'arrested'].includes(key) && content[key]) {
                                                return `${translateWithoutErrors(key)}: ${translateWithoutErrors(String(oldValue))} => ${translateWithoutErrors(String(newValue))}`;
                                            }
                                            return `${translateWithoutErrors(key)}: ${oldValue} => ${newValue}`;
                                        } catch (err) {
                                            return `${translateWithoutErrors(key)}: ${content[key]}`;
                                        }
                                    }
                                    return `${translateWithoutErrors(key)}: ${translateWithoutErrors(String(content[key]))}`;
                                }
                                ).join('\n')
                            }
                        </MyAppText>
                    </View>
                    : null
                }
            </View >
        );
    }

    async function sendHistoricNarrative(content: string) {
        try {

            const occurrenceReport = await dispatchService.createOccurrenceReport(occurrenceId, content);
            setHistoricList([occurrenceReport, ...historicList]);
            setHistoricNarrative({ dirty: false, value: '' });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function createAttachment(e: React.ChangeEvent<HTMLInputElement>) {
        try {
            if (!e.target.files || !occurrenceId) {
                return;
            }

            const file = e.target.files[0];
            const occurrenceReport = await dispatchService.createOccurrenceAttachment(occurrenceId, file);
            setHistoricList([occurrenceReport, ...historicList]);
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <View style={styles.historicList}>
            <FlatList
                extraData={renderFlatList}
                data={historicList}
                keyExtractor={(item) => item?.id?.toString() || '0'}
                renderItem={renderHistoric}
                contentContainerStyle={{ gap: 10 }}
                style={{ flex: 1 }}
            />
            {occurrenceSituation != 'concluded'
                ? <View style={styles.uploadContent}>
                    <View style={styles.uploadContentInput}>
                        <FormInput
                            labelFontSize={14}
                            placeholder={translate('historic')}
                            value={historicNarrative.value}
                            onChangeText={(value) => {
                                setHistoricNarrative({ dirty: true, value });
                            }}
                            multiline
                            numberOfLines={6}
                        />
                    </View>
                    <View style={styles.historicBody}>
                        <CustomButton
                            disabled={historicNarrative.value == ''}
                            text={translate('send')}
                            onPress={() => sendHistoricNarrative(historicNarrative.value)}
                        />
                        <FileUploader
                            text={translate('attach')}
                            icon={faUpload}
                            onChange={(e) => createAttachment(e)}
                        />
                    </View>
                </View>
                : null
            }
            <Modal
                animationType='fade'
                transparent={true}
                visible={isModalVisible}
                onRequestClose={() => setIsModalVisible(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredView}>
                        <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : null]}>
                            <View style={{ alignItems: 'flex-end' }}>
                                <TouchableOpacity
                                    style={{ width: 20, height: 20 }}
                                    onPress={() => setIsModalVisible(false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ width: 20 }} color={theme.colors.iconColor} />
                                </TouchableOpacity>
                            </View>

                            <TransformWrapper>
                                <TransformComponent>
                                    <Image source={{ uri: zoomedImage }} style={{
                                        width: windowInfo.width - 200,
                                        height: windowInfo.height - 200,
                                        resizeMode: 'contain',
                                    }} />
                                </TransformComponent>
                            </TransformWrapper>
                        </View>
                    </View>
                </View>
            </Modal>
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    cardHeader: {
        fontWeight: 'bold',
        fontSize: 16,
        color: theme.colors.labelColor,
    },
    card: {
        backgroundColor: theme.colors.cardBackground,
        borderWidth: 1,
        borderColor: theme.colors.cardBorder,
        borderRadius: 4,
        paddingVertical: 10,
        paddingHorizontal: 20,
        flex: 1
    },
    cardSubtitle: {
        fontSize: 14,
        color: theme.colors.disabledText,
    },
    cardContent: {
        fontSize: 16,
        color: theme.colors.labelColor,
    },
    historicList: {
        flex: 1,
        gap: 20
    },
    historicBody: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    uploadContent: {
        maxHeight: 150,
        flexDirection: 'row',
        flex: 1,
        gap: 5
    },
    uploadContentInput: {
        width: '50%',
        justifyContent: 'flex-end'
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199',
    },
    imagePreview: {
        width: 150,
        height: 150,
        resizeMode: 'cover',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalBody: {
        backgroundColor: theme.colors.container.background,
        flexDirection: 'column',
        borderRadius: 8,
        rowGap: 20,
        padding: 30,
    },
}));


export default OccurrenceHistoricList;
