import React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import MyAppText from '../MyAppText';
import { translate } from '../../services/translate';
import { faCalendarCheck, faLocationDot, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

export function SigrcOccurrence({ occurrence, navigation }: { occurrence: Occurrence, navigation: Navigation; }) {
    const { styles, theme } = useStyles(styleSheet);

    if (occurrence.triggerType !== 'sigrc') {
        return <></>;
    }

    function addressFormat(address: { logradouro: string; numero: string; bairro: string; cidade: string; uf: string; cep: string; pontoReferencia: string | null; }) {
        const formattedCEP = address.cep.replace(/(\d{5})(\d{3})/, '$1-$2');
        let formattedAddress = `${address.logradouro}, ${address.numero} - ${address.bairro}, ${address.cidade} - ${address.uf}, ${formattedCEP}`;
        if (address.pontoReferencia) {
            formattedAddress += ` (${formattedAddress})`;
        }
        return formattedAddress;
    }

    function keyValue(key: string, value: string | boolean | number, isDate?: boolean) {
        if (value == '') {
            return;
        } else if (typeof value == 'boolean') {
            return <MyAppText style={styles.detectionInfoText}><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {translate(value ? 'yes' : 'no')}</MyAppText>;
        } else if (isDate) {
            return <MyAppText style={styles.detectionInfoText}><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {moment(value).format('DD/MM/YYYY')}</MyAppText>;
        }
        return <MyAppText style={styles.detectionInfoText}><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {value}</MyAppText>;
    }


    return (
        <View style={{ gap: 20 }}>
            <View style={{ gap: 5 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <MyAppText style={{ fontSize: 16, color: theme.colors.textColor, fontWeight: 'bold' }}>
                            {translate('nProtocol')}: {occurrence?.id}
                        </MyAppText>
                        <MyAppText> </MyAppText>
                        <MyAppText style={{ fontSize: 14, color: theme.colors.textColor }}>
                            ({translate('team')} {translate(occurrence.OccurrenceUnits?.length && occurrence.OccurrenceUnits[0].OccurrenceUnit.isStarter ? 'starter' : 'support').toLowerCase()})
                        </MyAppText>
                    </View>
                    <TouchableOpacity onPress={() => navigation.navigate('GcmAgent', { screen: 'AgentOccurrences' })}>
                        <FontAwesomeIcon color={theme.colors.iconColor} fontSize={30} icon={faTimes} />
                    </TouchableOpacity>
                </View>
                <MyAppText style={styles.alertText}>
                    {translate('sigrcAlert')}
                </MyAppText>
                {occurrence.resume.imageUrl ?
                    <View style={styles.imageBox}>
                        <Image source={{ uri: occurrence.resume.imageUrl }} style={styles.image} />
                    </View> : <></>
                }
            </View>
            <View style={styles.card}>
                <View style={{ gap: 15 }}>
                    <View style={{ gap: 5 }}>
                        <View style={{ rowGap: 5 }}>
                            <>{keyValue('description', occurrence.resume.content.tipoSolicitacao.descricao)}</>
                            {occurrence.resume.content.tipoSolicitacao.sla ?
                                <>
                                    <>{keyValue('status', occurrence.resume.content.tipoSolicitacao.sla.status)}</>
                                    <>{keyValue('completionTime', occurrence.resume.content.tipoSolicitacao.sla.nome)}</>
                                </>
                                : <></>
                            }
                            <>{keyValue('registeredAt', occurrence.resume.content.tipoSolicitacao.dataCadastro, true)}</>
                            <>{keyValue('criticality', occurrence.resume.content.tipoSolicitacao.criticidade)}</>
                        </View>
                    </View>
                    <View style={{ gap: 3 }}>
                        <MyAppText style={styles.text}>{translate('location')}</MyAppText>
                        <View style={styles.visualizedRow} >
                            <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 14 }} color={theme.colors.iconColor} />
                            <MyAppText style={styles.detectionInfoText}>
                                {moment(new Date(occurrence.createdAt)).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                        <View style={styles.visualizedRow} >
                            <FontAwesomeIcon icon={faLocationDot} style={{ width: 14 }} color={theme.colors.iconColor} />
                            <MyAppText style={styles.detectionInfoText}>
                                {addressFormat(occurrence.resume.content.endereco)}
                            </MyAppText>
                        </View>
                    </View>
                </View>
                <View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('occurrenceId')}:</MyAppText>
                        <MyAppText style={styles.cardText}>{occurrence.id}</MyAppText>
                    </View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('priority')}:</MyAppText>
                        <MyAppText style={[styles.cardText, occurrence.priority === 'maximum' ? { color: '#FF0027' } : undefined]}>{occurrence.priority ? translate(occurrence.priority) : ''}</MyAppText>
                    </View>
                    <View>
                        <View style={{ paddingBottom: 30 }}>
                            <MyAppText style={styles.cardBoldText}>{translate('narrative')}:</MyAppText>
                            <MyAppText style={styles.cardText}>{occurrence.narrative}</MyAppText>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    card: {
        gap: 20,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        backgroundColor: theme.colors.container.background,
        padding: 14,
        display: 'flex',
        alignItems: 'flex-start',
    },
    visualizedRow: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
    detectionInfoText: {
        color: theme.colors.textColor,
        fontSize: 12
    },
    text: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 16
    },
    alertText: {
        color: theme.colors.textColor,
        fontSize: 16,
        fontWeight: 'bold',
    },
    cardText: {
        color: theme.colors.textColor,
        fontSize: 12
    },
    cardBoldText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 14
    },
    imageBox: {
        height: 314,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        height: 314,
        width: 311,
        objectFit: 'contain',
        borderRadius: 8,
    },
}));
