import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import MyAppText from '../MyAppText';
import { translate } from '../../services/translate';
import { faCalendarCheck, faLocationDot, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import PlateImage from '../lpr/PlateImage';

export function PlateDetectedOccurrence({ occurrence, event, navigation }: { occurrence: Occurrence, event: LprDetection; navigation: Navigation; }) {
    const { styles, theme } = useStyles(styleSheet);

    if (!event) {
        return <></>;
    }

    function keyValue(key: string, value: string | boolean | number | null, isDate?: boolean) {
        if (value == '' || value == null) {
            return;
        } else if (typeof value == 'boolean') {
            return <MyAppText style={styles.detectionInfoText}><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {translate(value ? 'yes' : 'no')}</MyAppText>;
        } else if (isDate) {
            return <MyAppText style={styles.detectionInfoText}><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {moment(value).format('DD/MM/YYYY')}</MyAppText>;
        }
        return <MyAppText style={styles.detectionInfoText}><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {value}</MyAppText>;
    }

    return (
        <View style={{ gap: 20 }}>
            <View style={{ gap: 5 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <MyAppText style={{ fontSize: 16, color: theme.colors.textColor, fontWeight: 'bold' }}>
                            {translate('nProtocol')}: {occurrence?.id}
                        </MyAppText>
                        <MyAppText> </MyAppText>
                        <MyAppText style={{ fontSize: 14, color: theme.colors.textColor }}>
                            ({translate('team')} {translate(occurrence.OccurrenceUnits?.length && occurrence.OccurrenceUnits[0].OccurrenceUnit.isStarter ? 'starter' : 'support').toLowerCase()})
                        </MyAppText>
                    </View>
                    <TouchableOpacity onPress={() => navigation.navigate('GcmAgent', { screen: 'AgentOccurrences' })}>
                        <FontAwesomeIcon color={theme.colors.iconColor} fontSize={30} icon={faTimes} />
                    </TouchableOpacity>
                </View>
                <MyAppText style={styles.alertText}>
                    {occurrence.triggerType == 'clone_suspect' ?
                        translate('cloneSuspectAlert') :
                        occurrence.triggerType == 'irregular_vehicle' ? translate('irregularVehicleAlert')
                            : translate('plateDetectedAlert')}
                </MyAppText>
                <MyAppText style={styles.alertText}>{event.plate}</MyAppText>
                <View style={{ alignItems: 'center' }}>
                    <PlateImage src={event.imageUrl} platePosition={event.platePosition} width={300} height={150} />
                </View>
            </View>
            <View style={styles.card}>
                <View style={{ gap: 15 }}>
                    <View style={{ gap: 5 }}>
                        <View style={{ rowGap: 5 }}>
                            <>{keyValue('brand', event['vehicleDescription.brand'])}</>
                            <>{keyValue('model', event['vehicleDescription.model'])}</>
                            <>{keyValue('color', event['vehicleDescription.color'])}</>
                            <>{keyValue('vehicleType', event['vehicleDescription.type'])}</>
                            <>{keyValue('origin', event['vehicleDescription.city'])}</>
                            <>{keyValue('year', event['vehicleDescription.modelYear'])}</>
                        </View>
                    </View>
                    <View style={{ gap: 3 }}>
                        <MyAppText style={styles.text}>{translate('location')}</MyAppText>
                        <View style={styles.visualizedRow} >
                            <FontAwesomeIcon icon={faLocationDot} style={{ width: 14 }} color={theme.colors.iconColor} />
                            <MyAppText style={styles.detectionInfoText}>
                                {event.camera.title}
                            </MyAppText>
                        </View>
                        <View style={styles.visualizedRow} >
                            <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 14 }} color={theme.colors.iconColor} />
                            <MyAppText style={styles.detectionInfoText}>
                                {moment(new Date(event.time)).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                    </View>
                </View>
                <View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('occurrenceId')}:</MyAppText>
                        <MyAppText style={styles.cardText}>{occurrence.id}</MyAppText>
                    </View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('priority')}:</MyAppText>
                        <MyAppText style={[styles.cardText, occurrence.priority === 'maximum' ? { color: '#FF0027' } : undefined]}>{occurrence.priority ? translate(occurrence.priority) : ''}</MyAppText>
                    </View>
                    <View>
                        <View style={{ paddingBottom: 30 }}>
                            <MyAppText style={styles.cardBoldText}>{translate('narrative')}:</MyAppText>
                            <MyAppText style={styles.cardText}>{occurrence.narrative}</MyAppText>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    card: {
        gap: 20,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        backgroundColor: theme.colors.container.background,
        padding: 14,
        display: 'flex',
        alignItems: 'flex-start',
    },
    visualizedRow: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
    detectionInfoText: {
        color: theme.colors.textColor,
        fontSize: 12
    },
    text: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 16
    },
    alertText: {
        color: theme.colors.textColor,
        fontSize: 16,
        fontWeight: 'bold',
    },
    cardText: {
        color: theme.colors.textColor,
        fontSize: 12
    },
    cardBoldText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 14
    }
}));
