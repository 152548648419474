import React, { useMemo } from 'react';
import { View, ViewStyle, StyleProp } from 'react-native';
import MyAppText from './MyAppText';
import { TextInputMask } from 'react-native-masked-text';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { translate } from '../services/translate';


interface MyCpfInputProps {
    value: string;
    onChangeText: (value: string) => void;
    viewStyle?: StyleProp<ViewStyle>;
    isInvalid: boolean;
    setIsInvalid: (value: boolean) => void;
}

export default function MyCpfInput({ value, onChangeText, viewStyle, isInvalid, setIsInvalid }: MyCpfInputProps) {
    const { styles } = useStyles(stylesheet);
    const isPlaceholder = useMemo(() => !value && value.length === 0, [value]);

    function invalidCpf(cpf: string) {
        if (cpf === '' || cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
            return true;
        }

        let sum = 0;
        for (let i = 0; i < 9; i++) {
            sum += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let checkDigit1 = 11 - (sum % 11);
        if (checkDigit1 >= 10) {
            checkDigit1 = 0;
        }
        if (checkDigit1 !== parseInt(cpf.charAt(9))) {
            return true;
        }

        sum = 0;
        for (let i = 0; i < 10; i++) {
            sum += parseInt(cpf.charAt(i)) * (11 - i);
        }
        let checkDigit2 = 11 - (sum % 11);
        if (checkDigit2 >= 10) {
            checkDigit2 = 0;
        }
        if (checkDigit2 !== parseInt(cpf.charAt(10))) {
            return true;
        }

        return false;
    }

    return (
        <View style={[{ rowGap: 5 }, viewStyle]}>
            <MyAppText style={styles.filterText}>CPF</MyAppText>
            <TextInputMask
                value={value}
                includeRawValueInChangeText={true}
                onChangeText={(maskedText, rawText) => {
                    onChangeText(rawText || '');
                    if (rawText) {
                        setIsInvalid(invalidCpf(rawText));
                    }
                }}
                type={'cpf'}
                style={styles.filterInput(isPlaceholder, isInvalid && value !== '')}
                placeholder='000.000.000-00'
            />
            {isInvalid && value !== '' ?
                <MyAppText style={styles.error}>{translate('invalidDocument')}</MyAppText> : null}

        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    inputContainer: {
        flex: 1,
        minWidth: 200,
    },
    filterText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },

    filterInput: (isPlaceholder: boolean, isInvalid: boolean) => ({
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.field.background,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: isInvalid ? '#FF0000' : theme.colors.field.border,
        height: 40,
        minHeight: 40,
        fontSize: 16,
        color: isPlaceholder ? theme.colors.placeholder : theme.colors.field.text,
        padding: 10
    }),
    error: {
        color: '#FF0000',
        fontSize: 10
    },
}));
