import React, { useEffect, useState } from 'react';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';
import { licensePlateRecognitionService } from '../../services/central-api/license-plate-recognition';

interface Props {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    zIndex: number;
    loadValues: boolean;
}

export default function ColorsDropdown({ value, setValue, zIndex, loadValues }: Props) {
    const [items, setItems] = useState<{ label: string, value: string; }[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    async function getItems() {
        try {
            setIsLoading(true);
            const items = await licensePlateRecognitionService.getColors();
            setItems(items.map((item) => {
                return {
                    value: item.color,
                    label: item.color
                };
            }));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (loadValues) {
            getItems();
        }
    }, [loadValues]);

    return (
        <FilterDropdown
            label={translate('color')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
            disabled={!loadValues}
            loading={isLoading}
        />
    );
}
