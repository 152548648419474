import React, { useEffect, useState, useCallback } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData, View } from 'react-native';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import EventList from '../../../components/lpr/EventList';
import { RouteProp, useRoute } from '@react-navigation/native';
import { LPRParamList } from '../../../typings/Params';
import LprSearchFilter from './Filters';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import useFilters from '../../../components/Filter/hooks/useFilters';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';

interface SearchParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

export default function Search({ navigation }: SearchParams) {
    const { styles } = useStyles(stylesheet);

    const route = useRoute<RouteProp<LPRParamList, 'LprSearch'>>();

    const [hasMoreResults, setHasMoreResults] = useState(false);
    const [filters, setFilters] = useFilters<LPRParamList['LprSearch']>({
        ...route.params,
        page: Math.max(route.params?.page - 1, 0),
    });

    const [plateFilter, setPlateFilter] = useState(filters.plate ?? '');
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [isLoading, setIsLoading] = useState(true);
    const [occurrences, setOccurrences] = useState<LprDetection[]>([]);

    useEffect(() => {
        getDetections();
    }, [filters]);

    async function getDetections() {
        try {
            setIsLoading(true);

            const newOccurrences = await licensePlateRecognitionService.getDetections({
                ...filters,
                limit: 100
            });
            if (newOccurrences.length < 100) {
                setHasMoreResults(false);
            } else {
                setHasMoreResults(true);
            }
            if (filters.page == 0) {
                occurrences.length = 0;
            }

            occurrences.push(...newOccurrences);
            setOccurrences(occurrences);
            forceUpdate();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }

    }

    function handleFilters() {
        setFilters({
            ...filters,
            plate: plateFilter !== '' ? plateFilter.toUpperCase() : undefined,
        });

        navigation.setParams({
            plate: plateFilter !== '' ? plateFilter.toUpperCase() : undefined,
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            handleFilters();
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, minWidth: 350, maxWidth: 500 }}>
                    <FilterInput
                        label={translate('searchPlate')}
                        value={plateFilter}
                        onChange={setPlateFilter}
                        placeholder={translate('typeToSearch')}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={styles.buttonsContainer}>
                    <LprSearchFilter filters={filters} setFilters={setFilters} plateFilter={plateFilter} setPlateFilter={setPlateFilter} />
                </View>
            </View>
            <EventList
                events={occurrences}
                navigation={navigation}
                loadMore={() => setFilters({ ...filters, page: filters.page + 1 })}
                hasMoreResults={hasMoreResults}
                isLoading={isLoading}
                isPolling={false}
                page='search'
            />
        </View>
    );
}

const stylesheet = createStyleSheet(() => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    }
}));
