import React, { useEffect, useRef, useState } from 'react';
import { View, Modal, Image as ReactImage, ActivityIndicator } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { clonedPlateService } from '../../../services/central-api/cloned-plate';
import MyAppText from '../../../components/MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCarSide, faClock, faGaugeHigh } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { translate } from '../../../services/translate';
import CustomButton from '../../../components/CustomButton';
import { ScrollView } from 'react-native-web-hover';
import Toast from 'react-native-toast-message';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

interface Props {
    setModalVisible: (value: boolean) => void;
    isModalVisible: boolean;
    cloneEventId: number | undefined;
    onClose: () => void;
}

export default function CloneSuspicionModal({ isModalVisible, setModalVisible, cloneEventId, onClose }: Props) {
    const { styles, theme } = useStyles(styleSheet);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [cloneEvent, setCloneEvent] = useState<CloneEvent>();
    const canvasRefFirstDetection = useRef<HTMLCanvasElement>(null);
    const canvasRefSecondDetection = useRef<HTMLCanvasElement>(null);

    async function getCloneEvent() {
        if (!cloneEventId) {
            return;
        }
        try {
            setIsLoading(true);
            const response = await clonedPlateService.getCloneEventById(cloneEventId);
            setCloneEvent(response);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    async function cloneEventReviewed(isValidClone: boolean) {
        if (!cloneEventId) {
            return;
        }
        try {
            await clonedPlateService.cloneEventReviewed(cloneEventId, isValidClone);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            setModalVisible(false);
            onClose();
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function drawImageOnCanvas(canvasRef: React.RefObject<HTMLCanvasElement>, detection: LprDetection) {
        const canvas = canvasRef.current;
        if (!canvas) {
            return;
        }

        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return;
        }

        if (!cloneEvent) {
            return;
        }

        const image = new Image();

        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 4;

            const rect = {
                minX: Infinity,
                maxX: 0,
                minY: Infinity,
                maxY: 0
            };

            if (detection.platePosition) {
                for (const position of detection.platePosition) {
                    if (position.x < rect.minX) {
                        rect.minX = position.x;
                    }

                    if (position.x > rect.maxX) {
                        rect.maxX = position.x;
                    }

                    if (position.y < rect.minY) {
                        rect.minY = position.y;
                    }

                    if (position.y > rect.maxY) {
                        rect.maxY = position.y;
                    }
                }

                ctx.beginPath();
                ctx.moveTo(rect.minX, rect.minY);
                ctx.lineTo(rect.minX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.minY);
                ctx.closePath();
                ctx.stroke();
            }

        };

        image.src = detection.imageUrl;
    }

    useEffect(() => {
        if (cloneEventId) {
            getCloneEvent();
        }
    }, [cloneEventId]);

    useEffect(() => {
        if (!cloneEvent) {
            return;
        }
        drawImageOnCanvas(canvasRefFirstDetection, cloneEvent.firstDetection);
        drawImageOnCanvas(canvasRefSecondDetection, cloneEvent.secondDetection);
    }, [cloneEvent]);

    if (!cloneEvent) {
        return <></>;
    }

    return (
        <Modal
            transparent={true}
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                setModalVisible(false);
                onClose();
            }}
        >
            <View style={styles.modalContainer}>
                <View style={styles.centeredView}>
                    {
                        isLoading ?
                            <View style={{ flexDirection: 'row', height: '80%', width: '100%', justifyContent: 'center', maxHeight: 700 }}>
                                <ActivityIndicator size='large' color={theme.colors.iconColor} />
                            </View> :
                            <View style={{ flexDirection: 'row', gap: 80, height: '80%', width: '100%', justifyContent: 'center', maxHeight: 700 }}>
                                <View style={styles.formContainer}>
                                    <View>
                                        <TransformWrapper>
                                            <TransformComponent>
                                                <canvas style={{ width: '100%' }} ref={canvasRefFirstDetection} />
                                            </TransformComponent>
                                        </TransformWrapper>
                                        <View style={styles.detectionPlate}>
                                            <View style={styles.detectionPlateTopBar}>
                                                <ReactImage style={styles.plateBrazilFlag} source={require(`../../../../assets/brazil-flag.png`)} />
                                            </View>
                                            <View style={styles.plateContent}>
                                                <MyAppText style={styles.plateLetters}>
                                                    {cloneEvent.firstDetection.plate}
                                                </MyAppText>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.detectionIconsContainer}>
                                        <View style={styles.detectionIcon}>
                                            <FontAwesomeIcon size='2x' color={theme.colors.secondaryButton.text} icon={faCarSide} />
                                            <MyAppText style={{ color: theme.colors.secondaryButton.text }}>{cloneEvent.firstDetection['vehicleDescription.type']}</MyAppText>
                                        </View>
                                        <View style={styles.detectionIcon}>
                                            <FontAwesomeIcon size='2x' color={theme.colors.secondaryButton.text} icon={faGaugeHigh} />
                                            <MyAppText style={{ color: theme.colors.secondaryButton.text }}>{`${cloneEvent.speed}km/h`}</MyAppText>
                                        </View>
                                        <View style={styles.detectionIcon}>
                                            <FontAwesomeIcon size='2x' color={theme.colors.secondaryButton.text} icon={faCalendar} />
                                            <MyAppText style={{ color: theme.colors.secondaryButton.text }}>{moment(cloneEvent.firstDetection.time).format('DD/MM/YYYY')}</MyAppText>
                                        </View>
                                        <View style={styles.detectionIcon}>
                                            <FontAwesomeIcon size='2x' color={theme.colors.secondaryButton.text} icon={faClock} />
                                            <MyAppText style={{ color: theme.colors.secondaryButton.text }}>{moment(cloneEvent.firstDetection.time).format('hh:mm:ss')}</MyAppText>
                                        </View>
                                    </View>
                                    <ScrollView contentContainerStyle={styles.detectionInformationContainer}>
                                        <View>
                                            <MyAppText style={styles.detectionTitle}>{translate('captureLocation')}</MyAppText>
                                            <MyAppText style={styles.detectionValue}>{cloneEvent.firstDetection.camera.title}</MyAppText>
                                        </View>
                                        <View>
                                            <MyAppText style={styles.detectionTitle}>{translate('brand')}</MyAppText>
                                            <MyAppText style={styles.detectionValue}>{cloneEvent.firstDetection['vehicleDescription.brand']}</MyAppText>
                                        </View>
                                        <View>
                                            <MyAppText style={styles.detectionTitle}>{translate('model')}</MyAppText>
                                            <MyAppText style={styles.detectionValue}>{cloneEvent.firstDetection['vehicleDescription.model']}</MyAppText>
                                        </View>
                                        <View>
                                            <MyAppText style={styles.detectionTitle}>{translate('color')}</MyAppText>
                                            <MyAppText style={styles.detectionValue}>{cloneEvent.firstDetection['vehicleDescription.color']}</MyAppText>
                                        </View>
                                        <View>
                                            <MyAppText style={styles.detectionTitle}>{translate('modelYear')}</MyAppText>
                                            <MyAppText style={styles.detectionValue}>{cloneEvent.firstDetection['vehicleDescription.modelYear']}</MyAppText>
                                        </View>
                                    </ScrollView>
                                </View>
                                <View style={styles.formContainer}>
                                    <View>
                                        <TransformWrapper>
                                            <TransformComponent>
                                                <canvas style={{ width: '100%' }} ref={canvasRefSecondDetection} />
                                            </TransformComponent>
                                        </TransformWrapper>
                                        <View style={styles.detectionPlate}>
                                            <View style={styles.detectionPlateTopBar}>
                                                <ReactImage style={styles.plateBrazilFlag} source={require(`../../../../assets/brazil-flag.png`)} />
                                            </View>
                                            <View style={styles.plateContent}>
                                                <MyAppText style={styles.plateLetters}>
                                                    {cloneEvent.secondDetection.plate}
                                                </MyAppText>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.detectionIconsContainer}>
                                        <View style={styles.detectionIcon}>
                                            <FontAwesomeIcon size='2x' color={theme.colors.secondaryButton.text} icon={faCarSide} />
                                            <MyAppText style={{ color: theme.colors.secondaryButton.text }}>{cloneEvent.secondDetection['vehicleDescription.type']}</MyAppText>
                                        </View>
                                        <View style={styles.detectionIcon}>
                                            <FontAwesomeIcon size='2x' color={theme.colors.secondaryButton.text} icon={faGaugeHigh} />
                                            <MyAppText style={{ color: theme.colors.secondaryButton.text }}>{`${cloneEvent.speed}km/h`}</MyAppText>
                                        </View>
                                        <View style={styles.detectionIcon}>
                                            <FontAwesomeIcon size='2x' color={theme.colors.secondaryButton.text} icon={faCalendar} />
                                            <MyAppText style={{ color: theme.colors.secondaryButton.text }}>{moment(cloneEvent.secondDetection.time).format('DD/MM/YYYY')}</MyAppText>
                                        </View>
                                        <View style={styles.detectionIcon}>
                                            <FontAwesomeIcon size='2x' color={theme.colors.secondaryButton.text} icon={faClock} />
                                            <MyAppText style={{ color: theme.colors.secondaryButton.text }}>{moment(cloneEvent.secondDetection.time).format('hh:mm:ss')}</MyAppText>
                                        </View>
                                    </View>
                                    <ScrollView contentContainerStyle={styles.detectionInformationContainer}>
                                        <View>
                                            <MyAppText style={styles.detectionTitle}>{translate('captureLocation')}</MyAppText>
                                            <MyAppText style={styles.detectionValue}>{cloneEvent.secondDetection.camera.title}</MyAppText>
                                        </View>
                                        <View>
                                            <MyAppText style={styles.detectionTitle}>{translate('brand')}</MyAppText>
                                            <MyAppText style={styles.detectionValue}>{cloneEvent.secondDetection['vehicleDescription.brand']}</MyAppText>
                                        </View>
                                        <View>
                                            <MyAppText style={styles.detectionTitle}>{translate('model')}</MyAppText>
                                            <MyAppText style={styles.detectionValue}>{cloneEvent.secondDetection['vehicleDescription.model']}</MyAppText>
                                        </View>
                                        <View>
                                            <MyAppText style={styles.detectionTitle}>{translate('color')}</MyAppText>
                                            <MyAppText style={styles.detectionValue}>{cloneEvent.secondDetection['vehicleDescription.color']}</MyAppText>
                                        </View>
                                        <View>
                                            <MyAppText style={styles.detectionTitle}>{translate('modelYear')}</MyAppText>
                                            <MyAppText style={styles.detectionValue}>{cloneEvent.secondDetection['vehicleDescription.modelYear']}</MyAppText>
                                        </View>
                                    </ScrollView>
                                </View>
                            </View>
                    }
                    {!cloneEvent.reviewed ?
                        <CustomButton
                            style={{ width: 820 }}
                            text={translate('confirmClone')}
                            onPress={() => cloneEventReviewed(true)}
                        /> : null}
                    {!cloneEvent.reviewed ?
                        <CustomButton
                            style={{ width: 820 }}
                            type='secondary'
                            text={translate('ignoreDuplication')}
                            onPress={() => cloneEventReviewed(false)}
                        /> : null}
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet(theme => ({
    formContainer: {
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 8,
        maxWidth: 370,
        gap: 20
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16
    },
    detectionInformationContainer: {
        gap: 16,
        paddingHorizontal: 8
    },
    detectionTitle: {
        color: theme.colors.sidebar.item.selectedBackground,
        fontWeight: 'bold',
        fontSize: 16
    },
    detectionValue: {
        color: theme.colors.disabledText,
        fontSize: 14
    },
    detectionIconsContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        gap: 24,
        flexDirection: 'row'
    },
    detectionIcon: {
        flexDirection: 'column',
        gap: 3,
        justifyContent: 'center',
        alignItems: 'center'
    },
    detectionPlate: {
        borderColor: 'black',
        borderWidth: 2,
        borderRadius: 8,
        minHeight: 62
    },
    detectionPlateTopBar: {
        height: 14,
        width: '100%',
        backgroundColor: '#0F3489',
        alignItems: 'flex-end',
        justifyContent: 'center',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        paddingHorizontal: 15
    },
    plateBrazilFlag: {
        height: 10,
        width: 14,
        overflow: 'hidden',
        borderRadius: 1
    },
    plateContent: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    },
    plateLetters: {
        fontWeight: '700',
        fontSize: 24,
        letterSpacing: 8
    }
}));
