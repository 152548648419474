import { PaginatedResource } from '../../typings/Paginated';
import { BaseService, GenericPaginatedParameters, Unauthorized, UnexpectedError } from './base-service';

export class ClonedPlateService extends BaseService {
    async getPaginatedCloneEvents(params: GenericPaginatedParameters): Promise<PaginatedResource<BaseCloneEvent>> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/clone-events${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async getCloneEventById(id: number): Promise<CloneEvent> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/clone-event/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async cloneEventReviewed(id: number, isValidClone: boolean): Promise<void> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/clone-event/${id}/reviewed`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ isValidClone })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getPaginatedClonePlates(params: GenericPaginatedParameters): Promise<PaginatedResource<BaseClonedPlate>> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/cloned-plates${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async deleteClonedPlate(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/cloned-plate/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async updateClonedPlate(id: number, { description, isActive }: { description: string; isActive: boolean; }) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/cloned-plate/${id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ description, isActive }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getClonedPlateById(id: number): Promise<ClonedPlate> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/cloned-plate/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }
}

export const clonedPlateService = new ClonedPlateService();
