import React, { useEffect, useState } from 'react';
import { dispatchService } from '../../services/central-api/dispatch';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';
import { useAuth } from '../../context/Auth';

interface Props {
    value: number;
    setValue: (value: number, item: DropdownResource | undefined) => void;
    zIndex?: number;
    disabled?: boolean;
    labelFontSize?: number;
    label?: string;
    actingBodyId: number;
}

export default function PersonRelationDropdown({ value, setValue, zIndex, disabled, labelFontSize, label, actingBodyId }: Props) {
    const [items, setItems] = useState<{ label: string, value: number; }[]>([]);
    const { user: authenticatedUser } = useAuth();

    async function getPersonRelations() {
        try {
            const actingBody = getActingBodyId();
            if (actingBody === 0) {
                setItems([]);
                return;
            }
            const response = await dispatchService.getSimplifiedPersonRelations(actingBody);
            setItems(response.map(unitProgram => ({
                label: unitProgram.name, value: unitProgram.id!
            })));
        } catch (err) {
            console.error(err);
        }
    }

    function getActingBodyId() {
        if (!authenticatedUser || !authenticatedUser.actingBodyUnit.actingBodyId) {
            return 0;
        }

        if (authenticatedUser.isAdmin) {
            return actingBodyId;
        } else {
            return authenticatedUser.actingBodyUnit.actingBodyId;
        }
    }

    useEffect(() => {
        if (!authenticatedUser) {
            return;
        }
        getPersonRelations();
    }, [actingBodyId, authenticatedUser]);

    return (
        <FilterDropdown
            label={label ?? translate('relation')}
            items={items}
            multiple={false}
            setValue={(v) => {
                const itemId = v(-1);
                const item = items.find(i => i.value == itemId);
                setValue(itemId, item ? {
                    id: item.value,
                    name: item.label
                } : undefined);
            }}
            value={value}
            zIndex={zIndex}
            disabled={disabled}
            labelFontSize={labelFontSize}
        />
    );
}