import { PaginatedResource } from '../../typings/Paginated';
import { DashBoardParamList } from '../../typings/Params';
import { BaseService, GenericPaginatedParameters, Unauthorized, UnexpectedError } from './base-service';

export interface GetCamerasSummary {
    lprOnline: number;
    lprOffline: number;
    pinnedOnline: number;
    pinnedOffline: number;
    ptzOnline: number;
    ptzOffline: number;
    panoramicOnline: number;
    panoramicOffline: number;
    online: number;
    offline: number;
    createdToday: number;
    groupedCoordinatedCount: number;
}

export interface GetOccurrencesSummary {
    total: number;
    pendingOccurrences: number;
    inServiceOccurrences: number;
    onGoingOccurrences: number;
    concludedOccurrences: number;
    onFootTeamsTotal: number;
    fourWheelsTeamsTotal: number;
    twoWheelsTeamsTotal: number;
    activesTotal: number;
    onFootTeamsCurrent: number;
    fourWheelsTeamsCurrent: number;
    twoWheelsTeamsCurrent: number;
    onFootTeamsNameTotal: number;
    fourWheelsTeamsNameTotal: number;
    twoWheelsTeamsNameTotal: number;
    activesCurrent: number;
    posList: LatLng[];
}

export interface VehicleDashboardActingBody {
    id: number;
    name: string;
}

export interface VehicleDashboardActingBodyCommand {
    id: number;
    name: string;
}

export interface VehicleDashboard {
    id: number;
    name: string;
    category: string;
    actingBody: VehicleDashboardActingBody;
    actingBodyCommand: VehicleDashboardActingBodyCommand;
    isActive: boolean;
}


interface PaginatedAgentsParams extends GenericPaginatedParameters {
    phone?: string;
    actingBodyId?: number;
    actingBodyIdUnitId?: number;
    offline?: boolean;
    online?: boolean;
    unitIds?: number[];
}


export interface DashboardAgent {
    id: number;
    name: string;
    warName: string | null;
    actingBodyId: number;
    phone: string;
    agentPosition: {
        positionUpdatedAt?: string;
        latitude: number;
        longitude: number;
        id: number;
    };
    unit: {
        id: number;
        unitName: {
            name: string;
            category: null;
        };
    };
}

interface ExtendedPagination extends PaginatedResource<DashboardAgent> {
    activeAgents: number;
}


class DashboardSummaryService extends BaseService {

    async getCamerasSummary(today: number): Promise<GetCamerasSummary> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dashboard-summary/cameras${this.encodeQueryParams({
            today
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrencesSummary(params: DashBoardParamList['DashboardOccurrences']): Promise<GetOccurrencesSummary> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dashboard-summary/occurrences${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAgentsSummary(params: PaginatedAgentsParams): Promise<ExtendedPagination> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dashboard-summary/agents${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getVehiclesSummary(params: DashBoardParamList['DashboardVehicles']): Promise<VehicleDashboard[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dashboard-summary/vehicles${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }
}

export const dashboardSummaryService = new DashboardSummaryService();
