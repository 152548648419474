import React, { useState } from 'react';
import { View, TextInput, KeyboardTypeOptions, NativeSyntheticEvent, TextInputKeyPressEventData, ViewStyle, StyleProp } from 'react-native';
import { translate } from '../services/translate';
import MyAppText from './MyAppText';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface FormInputProps {
    label?: string,
    placeholder?: string,
    errorMessage?: string,
    value: string,
    onChangeText: (value: string) => void,
    invalid?: (value: string) => boolean;
    disabled?: boolean;
    secureTextEntry?: boolean;
    keyboardType?: KeyboardTypeOptions;
    onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
    maxLength?: number;
    viewStyle?: StyleProp<ViewStyle>;
    multiline?: boolean;
    numberOfLines?: number;
    labelFontSize?: number;
}

export default function FormInput({ label, labelFontSize, placeholder, errorMessage, value, onChangeText, invalid, disabled, secureTextEntry, maxLength = 255, keyboardType, onKeyPress, viewStyle, multiline, numberOfLines }: FormInputProps) {
    const { styles, theme } = useStyles(stylesheet);
    const [isDirty, setIsDirty] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);

    disabled = !!disabled;

    return (
        <View style={[{ rowGap: 5 }, viewStyle]}>
            {label && <MyAppText style={styles.label(labelFontSize ?? 16)}>{label}</MyAppText>}
            <TextInput
                editable={!disabled}
                placeholder={placeholder ? placeholder : label}
                style={[styles.input, {
                    color: value == '' ? theme.colors.placeholder : theme.colors.textColor
                }, disabled ? styles.disabled : (isInvalid ? styles.invalid : null), !multiline ? { height: 40 } : {}]}
                value={value}
                onBlur={() => {
                    if (invalid) {
                        setIsInvalid(isDirty && invalid(value));
                    }
                }}
                onChangeText={(value) => {
                    if (invalid && !invalid(value)) {
                        setIsInvalid(false);
                    }
                    setIsDirty(true);
                    onChangeText(value);
                }}
                secureTextEntry={secureTextEntry}
                keyboardType={keyboardType}
                onKeyPress={onKeyPress}
                maxLength={maxLength}
                multiline={multiline}
                numberOfLines={numberOfLines}
            />
            {isInvalid ?
                <MyAppText style={styles.error}>{errorMessage ? errorMessage : translate('required_field')}</MyAppText> : null}
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    label: (fontSize: number) => ({
        color: theme.colors.labelColor,
        fontSize,
        height: 25,
    }),
    input: {
        fontSize: 16,
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.field.background,
        borderColor: theme.colors.field.border,
        borderWidth: 1,
        padding: 10,
        borderRadius: 4,
    },
    error: {
        color: '#FF0000',
        fontSize: 10
    },
    invalid: {
        borderColor: '#FF0000',
    },
    disabled: {
        backgroundColor: theme.colors.field.disabledBackground,
    }
}));
