import React, { useEffect } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData, TouchableOpacity, View } from 'react-native';
import { useState } from 'react';
import MyDataTable from '../../../components/MyDataTable';
import { translate } from '../../../services/translate';
import { Pagination } from '../../../components/Pagination';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { RouteProp, useRoute } from '@react-navigation/native';
import { ClonedPlateParamList } from '../../../typings/Params';
import { GenericPaginatedParameters } from '../../../services/central-api/base-service';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { clonedPlateService } from '../../../services/central-api/cloned-plate';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import CloneSuspicionModal from './CloneSuspicionModal';

interface Props {
    navigation: Navigation;
}

function CloneSuspicions({ navigation }: Props) {
    const { styles, theme } = useStyles(styleSheet);

    const [data, setData] = useState<BaseCloneEvent[]>([]);

    const [isLoading, setIsLoading] = useState(true);
    const [selectedCloneEvent, setSelectedCloneEvent] = useState<BaseCloneEvent>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const route = useRoute<RouteProp<ClonedPlateParamList, 'CloneSuspicions'>>();

    const [filters, setFilters] = useState<GenericPaginatedParameters>({
        page: Math.max(route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit: Number(route.params.limit) || 25,
    });
    const [totalItems, setTotalItems] = useState(0);
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');

    const getCloneEvents = async () => {
        try {
            setIsLoading(true);
            const data = await clonedPlateService.getPaginatedCloneEvents(filters);
            setTotalItems(data.count);
            setData(data.rows);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            navigation.setParams({
                ...filters,
                textFilter
            });
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    useEffect(() => {
        getCloneEvents();
    }, [filters]);

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                    <FilterInput
                        label={translate('searchPlate')}
                        value={textFilter ?? ''}
                        onChange={text => {
                            setTextFilter(text);
                        }}
                        placeholder={translate('typeToSearch')}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={{ flex: 1 }}></View>
            </View>

            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        progressPending={isLoading}
                        columns={[
                            {
                                name: 'ID',
                                selector: row => row.id,
                                grow: 0
                            },
                            {
                                name: translate('plate'),
                                selector: row => row.plate,
                                wrap: true,
                            },
                            {
                                name: translate('model'),
                                selector: row => row.model ?? '',
                                wrap: true,
                            },
                            {
                                name: translate('registeredSpeed'),
                                selector: row => `${row.speed}km/h`,
                                wrap: true,
                            },
                            {
                                name: translate('captureDate'),
                                selector: row => moment(row.detectedOn).format('DD/MM/YYYY'),
                                wrap: true,
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row =>
                                    <TouchableOpacity onPress={() => {
                                        setSelectedCloneEvent(row);
                                        setIsModalVisible(true);
                                    }}>
                                        <FontAwesomeIcon icon={faEye} fontSize={16} color={theme.colors.iconColor} />
                                    </TouchableOpacity>
                            }
                        ]}
                        data={data}
                    />
                </View>
                <Pagination
                    totalItems={totalItems}
                    currentPage={filters.page}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                />
            </View>
            <CloneSuspicionModal
                cloneEventId={selectedCloneEvent?.id}
                isModalVisible={isModalVisible}
                setModalVisible={setIsModalVisible}
                onClose={async () => {
                    setIsModalVisible(false);
                    setSelectedCloneEvent(undefined);
                    await getCloneEvents();
                }} />

        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        minHeight: 65,
        flexDirection: 'row',
        columnGap: 10,
        flexWrap: 'wrap',
        rowGap: 10,
        zIndex: 2
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
}));

export default CloneSuspicions;
