import React from 'react';
import LateralMenu from '../LateralMenu';
import { translate } from '../../services/translate';
import collapsedLogo from '../../../assets/icon_cloned_plate.png';
import { faCar } from '@fortawesome/free-solid-svg-icons';
import logo from '../../../assets/logo_cloned_plate.png';

export default function ClonedPlateLateralMenu({ navigation, authenticatedUser, open, setOpen, selectedMenu }: LateralMenuProps) {
    const items = [];

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.cloned_plate_module_access) {
        // items.push({
        //     icon: faClipboard,
        //     text: translate('cloneSuspicions'),
        //     action: () => navigation.navigate('ClonedPlate', { screen: 'CloneSuspicions' }),
        //     selected: selectedMenu == 'clone-suspicions'
        // });

        items.push({
            icon: faCar,
            text: translate('clonedPlates'),
            action: () => navigation.navigate('ClonedPlate', { screen: 'ClonedPlates' }),
            selected: selectedMenu == 'cloned-plates'
        });
    }

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            items={items}
        />
    );
}
