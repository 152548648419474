import React, { useState } from 'react';
import Filters from '../../../components/Filter';
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { LogsParamList } from '../../../typings/Params';
import { Action } from '../../../services/central-api/audit-logs';
import moment from 'moment';
import FilterDatePicker from '../../../components/Filter/components/FilterDateInput';
import { translate } from '../../../services/translate';
import ActionsDropdown from './ActionsDropdown';
import FilterInput from '../../../components/Filter/components/FilterInput';
import UsersDropdown from './UsersDropdown';

interface Props {
    sortBy: 'asc' | 'desc';
    setSortBy: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
    filters: LogsParamList['LogList'];
    setFilters: React.Dispatch<React.SetStateAction<LogsParamList['LogList']>>;
}

function Filter({ filters, setFilters, sortBy, setSortBy }: Props) {
    const navigation = useNavigation<NavigationProp<RouteProp<LogsParamList, 'LogList'>>>();
    const [selectedUser, setSelectedUser] = useState<number>(filters.userId || 0);
    const [selectedAction, setSelectedAction] = useState<Action | ''>(filters.action as Action || '');
    const [descriptionText, setDescriptionText] = useState<string>(filters.queryString || '');
    const [beginInDateFilter, setBeginInDateFilter] = useState(filters.dateGte ? new Date(filters.dateGte) : null);
    const [beginInHourFilter, setBeginInHourFilter] = useState(filters.dateGte ? moment(filters.dateGte).format('HH:mm') : '');
    const [endInDateFilter, setEndInDateFilter] = useState(filters.dateLte ? new Date(filters.dateLte) : null);
    const [endInHourFilter, setEndInHourFilter] = useState(filters.dateLte ? moment(filters.dateLte).format('HH:mm') : '');

    const handleCleanFilter = () => {
        setSelectedAction('');
        setSelectedUser(0);
        setSortBy('desc');
        setDescriptionText('');
        setBeginInDateFilter(null);
        setBeginInHourFilter('');
        setEndInDateFilter(null);
        setEndInHourFilter('');
    };

    const handleFilter = () => {
        const formatDateTime = (date: Date | null, time: string) =>
            date ? moment(`${moment(date).format('DD/MM/YYYY')} ${time}`, 'DD/MM/YYYY HH:mm:ss').valueOf() : undefined;
        const beginIn = formatDateTime(beginInDateFilter, beginInHourFilter ? `${beginInHourFilter}:00` : '00:00:00');
        const endIn = formatDateTime(endInDateFilter, endInHourFilter ? `${endInHourFilter}:59` : '23:59:59');

        navigation.setParams({
            ...filters,
            userId: selectedUser == 0 ? undefined : selectedUser,
            action: selectedAction,
            queryString: descriptionText,
            dateGte: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            dateLte: endIn && !isNaN(endIn) ? endIn : undefined,
            sort: sortBy
        });

        setFilters({
            ...filters,
            action: !selectedAction ? undefined : selectedAction,
            userId: selectedUser == 0 ? undefined : selectedUser,
            queryString: descriptionText,
            dateGte: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            dateLte: endIn && !isNaN(endIn) ? endIn : undefined,
            sort: sortBy
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterDatePicker
                date={beginInDateFilter}
                onChangeDate={setBeginInDateFilter}
                time={beginInHourFilter}
                onChangeTime={setBeginInHourFilter}
                label={translate('start')}
                zIndex={6}
                maxDate={moment().toDate()}
            />
            <FilterDatePicker
                date={endInDateFilter}
                onChangeDate={setEndInDateFilter}
                time={endInHourFilter}
                onChangeTime={setEndInHourFilter}
                label={translate('finish')}
                maxDate={moment().toDate()}
                zIndex={5}
            />
            <ActionsDropdown setValue={setSelectedAction} value={selectedAction} zIndex={4} />
            <UsersDropdown
                value={selectedUser}
                setValue={setSelectedUser}
                zIndex={3} />
            <FilterInput
                label={translate('description')}
                value={descriptionText}
                placeholder={translate('typeToSearch')}
                onChange={setDescriptionText}
            />
        </Filters>
    );
}


export default Filter;
