import React, { useEffect, useState } from 'react';
import { View, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import { WindowInformation } from '../../../services/window-information';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import FilterSwitch from '../../../components/Filter/components/FilterSwitch';

const regPlate = new RegExp(/[A-Z]{3}[-]?[0-9][0-9A-Z][0-9]{2}/);

interface ModalProps {
    isModalVisible: boolean;
    onClose: () => void;
    plateData?: PlateData;
}

export default function PlatesModal({ onClose, plateData, isModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [plate, setPlate] = useState('');
    const [description, setDescription] = useState('');
    const [isActive, setIsActive] = useState(true);

    function clearValues() {
        setPlate('');
        setDescription('');
        setIsActive(true);
    }

    function isPlateInvalid(value: string) {
        return value == '' || value.length > 7 || !regPlate.test(value);
    }

    function isDescriptionInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isPlateInvalid(plate) && !isDescriptionInvalid(description);
    }

    async function formSubmit() {
        try {
            if (plateData?.id) {
                await licensePlateRecognitionService.updatePlate(plateData.id, {
                    description,
                    isActive
                });
            } else {
                await licensePlateRecognitionService.createPlate({
                    plate,
                    description,
                    isActive
                });
            }
            onClose();
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (plateData) {
            setPlate(plateData.plate);
            setDescription(plateData.description);
            setIsActive(plateData.isActive);
        }
    }, [plateData]);

    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                onClose();
                clearValues();
            }}
            transparent={true}
        >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
                    <View style={{ rowGap: 25 }}>
                        <FormInput
                            label={translate('plate')}
                            placeholder={translate('plate')}
                            value={plate}
                            onChangeText={(value) => {
                                if (value.length > 7) {
                                    return;
                                }
                                setPlate(value.toUpperCase());
                            }}
                            invalid={isPlateInvalid}
                            disabled={plateData?.id ? true : false}
                        />
                        <FormInput
                            label={translate('description')}
                            placeholder={translate('description')}
                            value={description}
                            onChangeText={setDescription}
                            invalid={isDescriptionInvalid}
                            multiline={true}
                            numberOfLines={3}
                        />
                        <FilterSwitch value={isActive} label={translate('active')} direction='row' onChange={setIsActive} />
                    </View>
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={onClose}
                        disabled={!isFormValid()} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    }
}));
