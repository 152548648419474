import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, View, TouchableOpacity, Modal, TextInput } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import { faCheck, faChevronDown, faChevronUp, faLocationDot, faPencil, faSearchPlus, faTimes, faVideo, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { Hoverable } from 'react-native-web-hover';
import { WindowInformation } from '../../../services/window-information';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';

const regPlate = new RegExp(/[A-Z]{3}[-]?[0-9][0-9A-Z][0-9]{2}/);

export default function OccurrenceFormLprDetected({
    event, navigation, setEvent, authenticatedUser
}: {
    event: LprDetection,
    setEvent: (LprDetection: LprDetection) => void,
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}) {
    const { styles, theme } = useStyles(stylesheet);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const windowInfo = WindowInformation();
    const [editingPlate, setEditingPlate] = useState(false);
    const [newPlate, setNewPlate] = useState(event.plate);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        if (!event) return;

        const image = new Image();

        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 4;

            const rect = {
                minX: Infinity,
                maxX: 0,
                minY: Infinity,
                maxY: 0
            };

            if (event.platePosition) {
                for (const position of event.platePosition) {
                    if (position.x < rect.minX) {
                        rect.minX = position.x;
                    }

                    if (position.x > rect.maxX) {
                        rect.maxX = position.x;
                    }

                    if (position.y < rect.minY) {
                        rect.minY = position.y;
                    }

                    if (position.y > rect.maxY) {
                        rect.maxY = position.y;
                    }
                }

                ctx.beginPath();
                ctx.moveTo(rect.minX, rect.minY);
                ctx.lineTo(rect.minX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.minY);
                ctx.closePath();
                ctx.stroke();
            }

        };

        image.src = event.imageUrl;
    });

    function isPlateInvalid(value: string) {
        return value == '' || value.length > 7 || !regPlate.test(value);
    }

    async function saveNewPlate() {

        if (isPlateInvalid(newPlate)) {
            return Toast.show({
                type: 'sentinelxError',
                text1: translate('invalidPlate'),
            });
        }

        try {
            const detection = await licensePlateRecognitionService.editLprDetection(event.id, newPlate);
            setEvent(detection);
            setEditingPlate(false);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <ScrollView contentContainerStyle={{ rowGap: 10 }}>
            <View>
                <MyAppText style={styles.plateText}>
                    {translate('plate')}
                </MyAppText>
                <View style={{ flexDirection: 'row', gap: 5 }}>
                    {editingPlate ?
                        <>
                            <TextInput
                                placeholder=''
                                style={{
                                    fontSize: 24,
                                    fontWeight: 'bold',
                                    width: 120
                                }}
                                value={newPlate}
                                onChangeText={(value) => {
                                    if (value.length > 7) {
                                        return;
                                    }
                                    setNewPlate(value.toUpperCase());
                                }}
                            />
                            <TouchableOpacity
                                onPress={() => {
                                    setNewPlate(event.plate);
                                    setEditingPlate(false);
                                }}
                            >
                                <FontAwesomeIcon color={theme.colors.iconColor} icon={faXmark} fontSize={20} />
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={saveNewPlate}
                            >
                                <FontAwesomeIcon color={theme.colors.iconColor} icon={faCheck} fontSize={20} />
                            </TouchableOpacity>
                        </>
                        :
                        <>
                            <MyAppText style={styles.plateInfoText}>
                                {event.plate}
                            </MyAppText>
                            {authenticatedUser?.isAdmin || authenticatedUser?.permissions.lpr_edit_detection ?
                                <TouchableOpacity
                                    onPress={() => setEditingPlate(true)}
                                >
                                    <FontAwesomeIcon color={theme.colors.iconColor} icon={faPencil} fontSize={16} />
                                </TouchableOpacity>
                                : null
                            }
                        </>
                    }
                </View>
                <Hoverable>
                    {({ hovered }) => (<>
                        <canvas style={{ width: '100%' }} ref={canvasRef} />

                        {hovered &&
                            <View style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#00000080',
                            }}>
                                <TouchableOpacity
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center'
                                    }}
                                    onPress={async () => {
                                        setIsModalVisible(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faSearchPlus} fontSize={28} color={'#FFFFFF'} />
                                </TouchableOpacity>
                            </View>
                        }
                    </>)}
                </Hoverable>
            </View>

            <View style={styles.generalInformation}>
                <TouchableOpacity
                    style={styles.generalInformationDropdown}
                    onPress={() => setShowDetails(!showDetails)}>
                    <MyAppText>{translate('general_info')}</MyAppText>
                    <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} color={theme.colors.iconColor} />
                </TouchableOpacity>
                <View style={styles.tableLine}></View>
            </View>
            {showDetails ? <>
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.plateText}>
                        {translate('vehicleData')}
                    </MyAppText>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('brand')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {event['vehicleDescription.brand']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('model')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {event['vehicleDescription.model']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('color')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {event['vehicleDescription.color']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('vehicleType')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {event['vehicleDescription.type']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('origin')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {event['vehicleDescription.city']}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('year')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {event['vehicleDescription.modelYear']}
                        </MyAppText>
                    </View>
                </View>
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.plateText}>
                        {translate('visualizedOn')}
                    </MyAppText>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faVideo} style={{ width: 14 }} color={theme.colors.iconColor} />
                        <TouchableOpacity onPress={() => {
                            navigation.navigate('CameraPlayer', { id: event.camera.id });
                        }}>
                            <MyAppText style={[styles.detectionInfoText, { textDecorationLine: 'underline' }]}>
                                {event.camera.title}
                            </MyAppText>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 14 }} color={theme.colors.iconColor} />
                        <MyAppText style={styles.detectionInfoText}>
                            {moment(new Date(event.time)).format('DD/MM/YYYY HH:mm:ss')}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faLocationDot} style={{ width: 14 }} color={theme.colors.iconColor} />
                        <MyAppText style={styles.detectionInfoText}>
                            {event.camera.address}
                        </MyAppText>
                    </View>
                </View>
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.plateText}>
                        {translate('description')}
                    </MyAppText>
                    <MyAppText style={styles.detectionInfoText}>
                        {event.description}
                    </MyAppText>
                </View></> : <></>}

            {/* Zoom image modal */}
            <Modal
                animationType='fade'
                transparent={true}
                visible={isModalVisible}
                onRequestClose={() => setIsModalVisible(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredView}>
                        <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : { width: '60%' }]}>
                            <View style={{ alignItems: 'flex-end' }}>
                                <TouchableOpacity
                                    style={{ width: 20, height: 20 }}
                                    onPress={() => setIsModalVisible(false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ width: 20 }} color={theme.colors.iconColor} />
                                </TouchableOpacity>
                            </View>

                            <TransformWrapper>
                                <TransformComponent>
                                    <canvas style={{ width: '100%' }} ref={canvasRef} />
                                </TransformComponent>
                            </TransformWrapper>
                        </View>
                    </View>
                </View>
            </Modal>
        </ScrollView >
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        padding: 16,
        backgroundColor: theme.colors.container.background,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        borderWidth: 1,
    },
    imageText: {
        color: theme.colors.cardDetailText,
        paddingBottom: 5,
        fontSize: 11,
    },
    detectionInfoText: {
        color: theme.colors.labelColor,
        fontSize: 12
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalBody: {
        backgroundColor: theme.colors.container.background,
        flexDirection: 'column',
        borderRadius: 8,
        rowGap: 20,
        padding: 30,
    },
    plateText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 14
    },
    plateInfoText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 24
    },
    tableLine: {
        borderBottomColor: theme.colors.cardBorder,
        borderBottomWidth: 1,
        flex: 1
    },
    generalInformation: {
        flexDirection: 'column',
        paddingHorizontal: 5,
        flex: 1,
        gap: 10
    },
    generalInformationDropdown: {
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1
    },
    visualizedRow: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
}));

