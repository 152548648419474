import { BaseService, ClientError, ExtendableError, InvalidValue, NotFound, Unauthorized, UnexpectedError } from './base-service';
import { RecordNotLatestValues } from './dispatch';

export class AlreadyActiveUnitWithName extends ExtendableError {
    actingBody: string | undefined;
    constructor(code: string, actingBody: string) {
        super(code);
        this.actingBody = actingBody;
    }
}


export class GcmAgentService extends BaseService {
    /**
     *
     * @throws {ClientError} CantFinishConcludedSituation
     * @throws {InvalidValue}
     */
    async finishOccurrence({ occurrenceId, report, handcuffExplanation, category, subCategory }: { occurrenceId: number; report: string; handcuffExplanation: string; category?: number; subCategory?: number; }) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrenceId}/finish`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ report, handcuffExplanation, category, subCategory })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createOccurrenceAttachment({ occurrenceId, file }: { occurrenceId: number; file: File; }): Promise<OccurrenceReport> {
        const token = await this.getToken();
        const formData = new FormData();
        formData.append('file', file, file.name);

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrenceId}/attachment`, {
            method: 'POST',
            headers: this.getHeaders(token),
            body: formData,
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async gcmAgentOccurrence(id: number): Promise<Occurrence> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async gcmAgentOccurrences(): Promise<Occurrence[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrences`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getPoliceReports({ occurrenceId }: { occurrenceId: number; }): Promise<OccurrenceReport[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrenceId}/attach-police-report`, {
            method: 'GET',
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async attachPoliceReport({ occurrenceId, files, policeReport }: { occurrenceId: number; files: File[]; policeReport: string; }): Promise<void> {
        const token = await this.getToken();

        const formData = new FormData();
        for (const file of files) {
            formData.append('files', file);
        }

        formData.append('policeReport', policeReport);

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrenceId}/attach-police-report`, {
            method: 'POST',
            headers: this.getHeaders(token),
            body: formData,
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

    }

    async addOccurrenceReport({ occurrenceId, report }: { occurrenceId: number; report: string; }) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrenceId}/report`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ report })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }

            throw new ClientError(resJson.code);
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async getOccurrenceVideoUrl(occurrenceId: number): Promise<VideoUrl> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrenceId}/video-url`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAgentUnits(): Promise<SimplifiedUnit[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/agent-units-simplified`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAssignments(): Promise<DropdownResource[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/agent-assignments`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getFinishReasons(): Promise<DropdownResource[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/finish-reasons`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedVehicleRelations(): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/vehicle-relations-simplified`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedPersonRelations(): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/person-relations-simplified`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getHandcuffReasons(): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/handcuff-reasons`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getDropDownProvidences(): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/providences-simplified`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedObjectCategories(): Promise<ObjectCategory[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/object-categories-simplified`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedObjectSubCategories(id: number): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/object-sub-categories-simplified/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedOccurrenceTypes(): Promise<DropdownResource[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence-types-simplified`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAgentActiveUnit(): Promise<{ unit: SimplifiedUnit | null; assignment: Assignment; }> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/agent-active-unit`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getUnitDisplaceReasonById(displaceReasonId: number): Promise<DisplaceReason> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/displace-reason/${displaceReasonId}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrenceReports(occurrenceId: number): Promise<OccurrenceReport[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrenceId}/report-historic`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {ClientError} RequiredToFinishCurrentUnit | AlreadyActiveUnitWithName
     * @returns
     */
    async setAgentActiveUnit(params: { unitId: number, assignmentId: number; }) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/set-agent-unit`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            } else if (resJson.code == 'AlreadyActiveUnitWithName') {
                throw new AlreadyActiveUnitWithName(resJson.code, resJson.actingBody);
            }

            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async setAgentActiveAssignmentUnit(params: { assignmentId: number; }) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/set-agent-assignment`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    /**
     *
     * @returns
     * @throws {ClientError} CannotExitUnitWithOccurrences
     */
    async resetAgentActiveUnit(reason?: string) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/reset-agent-unit`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ reason })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async updateOccurrence(occurrence: {
        id: number;
        involvedVehicles?: InvolvedVehicle[];
        involvedPeople?: InvolvedPerson[];
        involvedObjects?: InvolvedObject[];
        updatedAt: string;
    }) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/gcm-agent/occurrence/${occurrence.id}`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(occurrence)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            } else if (resJson.code == 'RecordNotLatestValues') {
                throw new RecordNotLatestValues(resJson.code, resJson.userName);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async findPersonFromDocument(document: string): Promise<PersonFromDocument> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-from-document/${document}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findPersonFromNameAndMotherName(name: string, motherName: string): Promise<PersonFromMotherName[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-from-name${this.encodeQueryParams({ name, motherName })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findPersonFromNameAndBirthDate(name: string, birthDate: string): Promise<PersonFromBirthDate[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-from-name-and-birth-date${this.encodeQueryParams({ name, birthDate })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findWarrantFromDocument(document: string): Promise<BasicWarrantInformation[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-warrant-from-document/${document}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findWarrantFromNameAndMotherName(name: string, motherName: string): Promise<BasicWarrantInformation[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-warrant-from-name${this.encodeQueryParams({ name, motherName })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findWarrantFromNameAndBirthDate(name: string, birthDate: string): Promise<BasicWarrantInformation[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-warrant-from-name-and-birth-date${this.encodeQueryParams({ name, birthDate })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findWarrantMoreInformation(id: number): Promise<CompleteWarrantInformation> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-warrant-by-id/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findCarFromPlate(plate: string): Promise<CarFromPlate> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-car-from-plate/${plate}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async findCarFromChassis(chassis: string): Promise<CarFromChassis[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/gcm-agent/cortex/find-car-from-chassis/${chassis}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status == 404) {
            throw new NotFound();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }
}

export const gcmAgentService = new GcmAgentService();
