import React from 'react';
import { ActivityIndicator, StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { Hoverable } from 'react-native-web-hover';
import MyAppText from './MyAppText';

export type ButtonTypes = 'primary' | 'secondary';

interface Props {
    icon?: IconDefinition,
    text?: string;
    onPress: VoidFunction;
    /**
     * button style
     */
    style?: StyleProp<ViewStyle>;
    /**
     * text style
     */
    textStyle?: TextStyle;
    /**
     * button style
     */
    contentContainerStyle?: StyleProp<ViewStyle>;
    isLoading?: boolean;
    disabled?: boolean;
    type?: ButtonTypes;
}

function CustomButton({ icon, text, onPress, style, textStyle, isLoading = false, disabled = false, type = 'primary', contentContainerStyle }: Props) {
    const { styles } = useStyles(stylesheet);


    function buttonContent(hovered: boolean, disabled: boolean) {
        if (isLoading) {
            return <ActivityIndicator size='small' color={styles.loadingColor.color} />;
        }
        return (
            <React.Fragment>
                {icon && <FontAwesomeIcon style={styles.buttonIcon(type, hovered, disabled, textStyle)} fontSize={textStyle?.fontSize || 16} icon={icon} />}
                {text && <MyAppText style={[styles.buttonText(type, hovered, disabled), textStyle]}>{text}</MyAppText>}
            </React.Fragment>
        );
    }

    return (
        <TouchableOpacity
            style={contentContainerStyle}
            onPress={onPress}
            disabled={disabled || isLoading}
        >
            <Hoverable>
                {({ hovered }) => (
                    <View style={[styles.buttonContainer(type, hovered, disabled || isLoading), style]}>
                        {buttonContent(hovered, disabled)}
                    </View>
                )}
            </Hoverable>
        </TouchableOpacity>
    );
}

const stylesheet = createStyleSheet(theme => ({
    buttonContainer: (type: ButtonTypes, hovered: boolean, disabled: boolean) => {

        let backgroundColor: string | undefined;
        let borderColor: string | undefined;
        let borderWidth = 0;

        if (type == 'primary') {
            backgroundColor = disabled ? theme.colors.primaryButton.disabledBackground : hovered ? theme.colors.primaryButton.hoverBackground : theme.colors.primaryButton.background;
            borderWidth = 0;
            borderColor = undefined;
        } else if (type == 'secondary') {
            backgroundColor = disabled ? theme.colors.secondaryButton.disabledBackground : hovered ? theme.colors.secondaryButton.hoverBackground : theme.colors.secondaryButton.background;
            borderWidth = 1;
            borderColor = disabled ? theme.colors.secondaryButton.disabledBorderColor : hovered ? theme.colors.secondaryButton.hoverBorderColor : theme.colors.secondaryButton.borderColor;
        }

        return {
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: 5,
            borderWidth: borderWidth,
            borderColor: borderColor,
            paddingHorizontal: 30,
            width: '100%',
            columnGap: 10,
            height: 40,
            backgroundColor: backgroundColor,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            justifyContent: 'center',
            gap: 10
        };
    },

    buttonText: (type: ButtonTypes, hovered: boolean, disabled: boolean) => {

        let color: string | undefined;

        if (type == 'primary') {
            color = disabled ? theme.colors.primaryButton.disabledText : hovered ? theme.colors.primaryButton.hoverText : theme.colors.primaryButton.text;
        } else if (type == 'secondary') {
            color = disabled ? theme.colors.secondaryButton.disabledText : hovered ? theme.colors.secondaryButton.hoverText : theme.colors.secondaryButton.text;
        }

        return {
            color: color,
            fontSize: 16,
        };
    },

    buttonIcon: (type: ButtonTypes, hovered: boolean, disabled: boolean, textStyle: TextStyle | undefined) => {
        let color: string | undefined;

        if (type == 'primary') {
            color = disabled ? theme.colors.primaryButton.disabledText : hovered ? theme.colors.primaryButton.hoverText : theme.colors.primaryButton.text;
        } else if (type == 'secondary') {
            color = disabled ? theme.colors.secondaryButton.disabledText : hovered ? theme.colors.secondaryButton.hoverText : theme.colors.secondaryButton.text;
        }

        if (textStyle?.color) {
            color = String(textStyle?.color);
        }

        return {
            color,
        };
    },

    loadingColor: {
        color: theme.colors.loadingColor
    }
}));

export default CustomButton;
