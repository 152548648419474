import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import MyAppText from '../MyAppText';
import { translate } from '../../services/translate';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextMask } from 'react-native-masked-text';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

export function DefaultOccurrence({ occurrence, navigation }: { occurrence: Occurrence, navigation: Navigation; }) {
    const { styles, theme } = useStyles(styleSheet);

    if (occurrence.triggerType !== null) {
        return <></>;
    }

    return (
        <View style={{ gap: 20 }}>
            <View style={{ gap: 5 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 10 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <MyAppText style={{ fontSize: 16, color: theme.colors.labelColor, fontWeight: 'bold' }}>
                            {translate('nProtocol')}: {occurrence?.id}
                        </MyAppText>
                        <MyAppText> </MyAppText>
                        <MyAppText style={{ fontSize: 14, color: theme.colors.cardDetailText }}>
                            ({translate('team')} {translate(occurrence.OccurrenceUnits?.length && occurrence.OccurrenceUnits[0].OccurrenceUnit.isStarter ? 'starter' : 'support').toLowerCase()})
                        </MyAppText>
                    </View>
                    <TouchableOpacity onPress={() => navigation.navigate('GcmAgent', { screen: 'AgentOccurrences' })}>
                        <FontAwesomeIcon color={theme.colors.iconColor} fontSize={30} icon={faTimes} />
                    </TouchableOpacity>
                </View>
                <MyAppText style={styles.alertText}>
                    {translate('occurrence')}:
                </MyAppText>
                <MyAppText style={styles.alertName}>{occurrence.occurrenceType?.name}</MyAppText>
            </View>
            <View style={styles.card}>
                <View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('occurrenceId')}:</MyAppText>
                        <MyAppText style={styles.cardText}>{occurrence.id}</MyAppText>
                    </View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('address')}:</MyAppText>
                        <MyAppText style={styles.cardText}>{occurrence.address}</MyAppText>
                    </View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('requester')}:</MyAppText>
                        <MyAppText style={styles.cardText}>{occurrence.requester}</MyAppText>
                    </View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('phone')}:</MyAppText>
                        <TextMask
                            style={{ color: theme.colors.textColor, fontFamily: 'Open Sans' }}
                            type={'cel-phone'}
                            value={occurrence.phone} />
                    </View>
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('priority')}:</MyAppText>
                        <MyAppText style={[styles.cardText, occurrence.priority === 'maximum' ? { color: '#FF0027' } : undefined]}>{occurrence.priority ? translate(occurrence.priority) : ''}</MyAppText>
                    </View>
                    <View>
                        <View style={{ paddingBottom: 30 }}>
                            <MyAppText style={styles.cardBoldText}>{translate('narrative')}:</MyAppText>
                            <MyAppText style={styles.cardText}>{occurrence.narrative}</MyAppText>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
}


const styleSheet = createStyleSheet((theme) => ({
    alertText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        fontWeight: 'bold',
    },
    alertName: {
        color: theme.colors.textColor,
        fontSize: 18,
        fontWeight: 'bold',
    },
    card: {
        gap: 20,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        backgroundColor: theme.colors.container.background,
        padding: 14,
        display: 'flex',
        alignItems: 'flex-start',
    },
    visualizedRow: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
    cardText: {
        color: theme.colors.labelColor,
        fontSize: 12
    },
    cardBoldText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 14
    },
    plateInfoText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 24
    }
}));
