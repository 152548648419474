import React, { useEffect, useState } from 'react';
import { dispatchService } from '../../services/central-api/dispatch';
import { translate } from '../../services/translate';
import { useAuth } from '../../context/Auth';
import FilterDropdown from '../Filter/components/FilterDropdown';


interface Props {
    value: number[];
    setValue: React.Dispatch<React.SetStateAction<number[]>>;
    zIndex?: number;
    disabled?: boolean;
    label?: string;
    actingBodyId: number;
}

function OccurrenceTypesDropdown({ value, setValue, zIndex, disabled, label, actingBodyId }: Props) {
    const [items, setItems] = useState<{ label: string, value: number; }[]>([]);
    const { user: authenticatedUser } = useAuth();

    async function getOccurrenceTypes() {
        try {
            const actingBody = getActingBodyId();
            if (actingBody === 0) {
                setItems([]);
                return;
            }
            const response = await dispatchService.getDropDownOccurrenceTypes(actingBody);
            setItems(response.map(occurrenceType => ({
                label: occurrenceType.name, value: occurrenceType.id!
            })));
        } catch (err) {
            console.error(err);
        }
    }

    function getActingBodyId() {
        if (!authenticatedUser || !authenticatedUser.actingBodyUnit.actingBodyId) {
            return 0;
        }

        if (authenticatedUser.isAdmin || authenticatedUser.permissions.dispatch_manage_all_acting_bodies_occurrences) {
            return actingBodyId;
        } else {
            return authenticatedUser.actingBodyUnit.actingBodyId;
        }
    }

    useEffect(() => {
        getOccurrenceTypes();
    }, [actingBodyId]);

    return (
        <FilterDropdown
            label={label ?? translate('occurrenceType')}
            items={items}
            multiple={true}
            mode='BADGE'
            setValue={setValue}
            value={value}
            zIndex={zIndex}
            disabled={disabled}
        />
    );
}

export default OccurrenceTypesDropdown;
