import React, { useEffect } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData, View } from 'react-native';
import { useState } from 'react';
import MyDataTable from '../../../components/MyDataTable';
import { translate } from '../../../services/translate';
import { Pagination } from '../../../components/Pagination';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { RouteProp, useRoute } from '@react-navigation/native';
import { ClonedPlateParamList } from '../../../typings/Params';
import { ClientError, GenericPaginatedParameters } from '../../../services/central-api/base-service';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { clonedPlateService } from '../../../services/central-api/cloned-plate';
import moment from 'moment';
import TableActions from '../../../components/TableActions';
import ClonedPlateModal from './ClonedPlateModal';
import Toast from 'react-native-toast-message';
import DeleteModal from '../../../components/DeleteModal';

interface Props {
    navigation: Navigation;
}

function ClonedPlates({ navigation }: Props) {
    const { styles } = useStyles(styleSheet);
    const route = useRoute<RouteProp<ClonedPlateParamList, 'ClonedPlates'>>();

    const [filters, setFilters] = useState<GenericPaginatedParameters>({
        page: Math.max(route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit: Number(route.params.limit) || 25,
    });

    const [data, setData] = useState<BaseClonedPlate[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');
    const [selectedClonedPlate, setClonedPlate] = useState<BaseClonedPlate>();
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

    const getClonedPlates = async () => {
        try {
            setIsLoading(true);
            const data = await clonedPlateService.getPaginatedClonePlates(filters);
            setTotalItems(data.count);
            setData(data.rows);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteClonedPlate = async (clonedPlate: BaseClonedPlate) => {
        try {
            await clonedPlateService.deleteClonedPlate(clonedPlate.id);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });

            await getClonedPlates();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    };

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            navigation.setParams({
                ...filters,
                textFilter
            });
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    useEffect(() => {
        getClonedPlates();
    }, [filters]);

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                    <FilterInput
                        label={translate('searchPlate')}
                        value={textFilter ?? ''}
                        onChange={text => {
                            setTextFilter(text);
                        }}
                        placeholder={translate('typeToSearch')}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={{ flex: 1 }}></View>
            </View>

            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        progressPending={isLoading}
                        columns={[
                            {
                                name: 'ID',
                                selector: row => row.id,
                                grow: 0
                            },
                            {
                                name: translate('plate'),
                                selector: row => row.plate,
                                wrap: true,
                            },
                            {
                                name: translate('createdAt'),
                                selector: row => moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss'),
                                wrap: true,
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row => <TableActions
                                    onEdit={() => {
                                        setClonedPlate(row);
                                        setModalVisible(true);
                                    }}
                                    onDelete={() => {
                                        setClonedPlate(row);
                                        setDeleteModalVisible(true);
                                    }} />
                            }
                        ]}
                        data={data}
                    />
                </View>
                <Pagination
                    totalItems={totalItems}
                    currentPage={filters.page}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                />
            </View>

            {
                selectedClonedPlate ? <DeleteModal
                    setModalVisible={setDeleteModalVisible}
                    isModalVisible={isDeleteModalVisible}
                    itemName={selectedClonedPlate.plate}
                    onSubmit={() => deleteClonedPlate(selectedClonedPlate)}
                /> : <></>
            }

            {
                selectedClonedPlate ? <ClonedPlateModal
                    onClose={() => {
                        setClonedPlate(undefined);
                        setModalVisible(false);
                        getClonedPlates();
                    }}
                    isModalVisible={isModalVisible}
                    plateData={selectedClonedPlate}
                /> : <></>
            }
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        minHeight: 65,
        flexDirection: 'row',
        columnGap: 10,
        flexWrap: 'wrap',
        rowGap: 10,
        zIndex: 2
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    }
}));

export default ClonedPlates;
