import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { ScrollView } from 'react-native-web-hover';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import { DashBoardParamList } from '../../../typings/Params';
import useFilters from '../../../components/Filter/hooks/useFilters';
import { RouteProp, useRoute } from '@react-navigation/native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import Filter from './Filters';
import { dashboardSummaryService, VehicleDashboard } from '../../../services/central-api/dashboard-summary';
import MyDataTable from '../../../components/MyDataTable';

export default function DashboardVehicles({ authenticatedUser }: { authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<DashBoardParamList, 'DashboardVehicles'>>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [filters, setFilters] = useFilters<DashBoardParamList['DashboardVehicles']>({
        ...route.params
    });

    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');
    const [vehicles, setVehicles] = useState<VehicleDashboard[]>([]);

    async function getVehiclesDashboardData() {
        try {
            setIsLoading(true);
            const vehicles = await dashboardSummaryService.getVehiclesSummary(filters);
            setVehicles(vehicles);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getVehiclesDashboardData();
    }, [filters]);

    const groupVehiclesByActingBody = (vehicles: VehicleDashboard[]) => {
        return vehicles.reduce((acc, vehicle) => {
            const actingBodyId = vehicle.actingBody.id;
            const actingBodyName = vehicle.actingBody.name;
            const commandId = vehicle.actingBodyCommand.id;
            const commandName = vehicle.actingBodyCommand.name;

            if (!acc[actingBodyId]) {
                acc[actingBodyId] = { name: actingBodyName, commands: {} };
            }

            if (!acc[actingBodyId].commands[commandId]) {
                acc[actingBodyId].commands[commandId] = { name: commandName, vehicles: [] };
            }

            acc[actingBodyId].commands[commandId].vehicles.push(vehicle);

            return acc;
        }, {} as {
            [actingBodyId: number]: {
                name: string;
                commands: {
                    [commandId: number]: {
                        name: string;
                        vehicles: VehicleDashboard[];
                    };
                };
            };
        });
    };

    const vehicleGroups = groupVehiclesByActingBody(vehicles);

    const getActiveCount = (vehicles: VehicleDashboard[]) => {
        const activeVehicles = vehicles.filter((vehicle) => vehicle.isActive).length;
        const totalVehicles = vehicles.length;
        return { activeVehicles, totalVehicles };
    };

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flex: 1 }}>
                    <MyAppText style={styles.title}>{translate('vehicles')}</MyAppText>
                </View>
                <Filter
                    authenticatedUser={authenticatedUser}
                    filters={filters}
                    setFilters={setFilters}
                    textFilter={textFilter}
                    setTextFilter={setTextFilter}
                />
            </View>

            {isLoading ? (
                <ActivityIndicator size="large" style={{ minHeight: 190 }} color={theme.colors.iconColor} />
            ) : (
                <ScrollView contentContainerStyle={{ paddingBottom: 20 }} style={{ flex: 1 }}>
                    <View style={styles.cardList}>
                        {Object.entries(vehicleGroups).map(([actingBodyId, { name: actingBodyName, commands }]) => (
                            <View key={actingBodyId}>
                                <MyAppText style={styles.actingBodyTitle}>{actingBodyName}</MyAppText>

                                <View style={styles.actingBodyGroup}>
                                    {Object.entries(commands).map(([commandId, { name: commandName, vehicles }]) => {
                                        const { activeVehicles, totalVehicles } = getActiveCount(vehicles);

                                        return (
                                            <View key={commandId} style={styles.card}>
                                                <View style={styles.cardHeader}>
                                                    <MyAppText style={styles.cardTitle}>{commandName}</MyAppText>
                                                    <View style={styles.cardCounter}>
                                                        <MyAppText
                                                            style={{
                                                                fontSize: 20,
                                                                fontWeight: 'bold',
                                                                color: activeVehicles > 0 ? 'green' : 'red',
                                                                maxWidth: 120,
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            {activeVehicles}/{totalVehicles}
                                                        </MyAppText>
                                                    </View>
                                                </View>

                                                <View style={styles.vehicleList}>
                                                    <MyDataTable
                                                        columns={[
                                                            {
                                                                name: translate('name'),
                                                                selector: row => row.name,
                                                                wrap: true,
                                                                minWidth: '40%',
                                                                maxWidth: '40%',

                                                            },
                                                            {
                                                                name: translate('category'),
                                                                selector: row => translate(row.category),
                                                                minWidth: '35%',
                                                                maxWidth: '35%',

                                                            },
                                                            {
                                                                name: translate('status'),
                                                                minWidth: '25%',
                                                                maxWidth: '25%',
                                                                cell: row =>
                                                                    <MyAppText
                                                                        style={{
                                                                            color: row.isActive ? 'green' : 'red'
                                                                        }}
                                                                    >
                                                                        {row.isActive ? translate('active') : translate('inactive')}
                                                                    </MyAppText>
                                                            },
                                                        ]}
                                                        data={vehicles}
                                                        progressPending={isLoading}
                                                    />
                                                </View>
                                            </View>
                                        );
                                    })}
                                </View>
                            </View>
                        ))}

                    </View>
                </ScrollView>
            )}
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    title: {
        color: theme.colors.labelColor,
        fontSize: 20,
        fontWeight: 'bold',
    },
    container: {
        flex: 1,
        rowGap: 15,
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 40,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between',
    },
    cardList: {
        flexDirection: 'column',
        gap: 10
    },
    actingBodyGroup: {
        flexDirection: 'row',
        padding: 5,
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: 20,
    },
    actingBodyTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        color: theme.colors.labelColor,
        marginBottom: 10,
    },
    card: {
        flex: 1,
        minWidth: 400,
        maxWidth: 400,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        padding: 20,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: theme.colors.container.background,
    },
    cardHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        alignItems: 'center',
    },
    cardTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    },
    cardCounter: {
        alignItems: 'center',
    },
    vehicleList: {
        flexDirection: 'column',
        maxHeight: 400
    }
}));