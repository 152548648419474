import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import { Pagination } from '../../../components/Pagination';
import moment from 'moment';
import { faDownload, faEdit, faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteProp, useIsFocused, useRoute } from '@react-navigation/native';
import { cameraService } from '../../../services/central-api/cameras';
import { crmService } from '../../../services/central-api/crm';
import MyDataTable from '../../../components/MyDataTable';
import { CRMParamList } from '../../../typings/Params';
import Tooltip from '../../../components/Tooltip';
import Toast from 'react-native-toast-message';
import CustomButton from '../../../components/CustomButton';
import { useAuth } from '../../../context/Auth';
import useFilters from '../../../components/Filter/hooks/useFilters';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import Filter from './Filters';

export default function Management({ navigation }: { navigation: Navigation; }) {
    const { styles, theme } = useStyles(styleSheet);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { user: authenticatedUser } = useAuth();

    const [cameras, setCameras] = useState<CrmPaginatedCamera[]>([]);

    const route = useRoute<RouteProp<CRMParamList, 'Management'>>();

    const [filters, setFilters] = useFilters<CRMParamList['Management']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });

    const [totalItems, setTotalItems] = useState(0);
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');

    async function getCamerasList() {
        try {
            setIsLoading(true);
            const response = await crmService.getCamerasPaginated({
                ...filters,
                showDisabled: true,
                hideChildren: false,
            });
            setCameras(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            navigation.setParams({
                ...filters,
                textFilter
            });
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    const isFocused = useIsFocused();
    useEffect(() => {
        if (isFocused) {
            getCamerasList();
        }
    }, [isFocused, filters]);

    return (
        <View style={{ flex: 1, rowGap: 15 }}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                    <FilterInput
                        label={translate('searchCamera')}
                        value={textFilter}
                        placeholder={translate('typeToSearch')}
                        onChange={text => {
                            setTextFilter(text);
                        }}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={styles.buttonsContainer}>
                    <Filter
                        filters={filters}
                        setFilters={setFilters}
                        textFilter={textFilter}
                        setTextFilter={setTextFilter}
                    />
                    {window.location.href.includes('hidden-export') && (
                        authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_download_report
                    )
                        ? <CustomButton
                            onPress={async () => {
                                try {
                                    const csv = await cameraService.downloadCameraReport(filters.offline || false, true);
                                    if (!(csv instanceof Blob)) {
                                        throw new Error();
                                    }
                                    const url = window.URL.createObjectURL(csv);
                                    const a = document.createElement('a');
                                    a.href = url;
                                    a.download = `cameras-${moment(new Date()).format('YYYY-MM-DD')}.csv`;
                                    a.click();
                                    window.URL.revokeObjectURL(url);
                                } catch (error) {
                                    Toast.show({
                                        type: 'sentinelxError',
                                        text1: translate('unexpectedError'),
                                    });
                                }
                            }}
                            icon={faDownload}
                            text={translate('downloadReport')} />
                        : null
                    }
                    <CustomButton onPress={() => navigation.navigate('ManagementDetails')} text={translate('registerCamera')} />
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        columns={[
                            {
                                name: translate('title'),
                                selector: row => row.title,
                                grow: 3,
                                wrap: true,
                            },
                            {
                                name: translate('installationCompany'),
                                selector: row => row.installationCompany?.name || '',
                            },
                            {
                                name: translate('internetType'),
                                selector: row => row.internetType == '5g' ? '5G' : row.internetType == '4g' ? '4G' : row.internetType == 'fiber' ? translate('fiber') : translate('radio'),
                            },
                            {
                                name: translate('serialNo'),
                                selector: row => row.serialNo || '',
                            },
                            {
                                name: translate('type'),
                                selector: row => translate(row.type),
                            },
                            {
                                name: translate('integration'),
                                selector: row => row.thirdPartyCamera?.origin ?? '',
                                wrap: true
                            },
                            {
                                name: translate('status'),
                                cell: row => {
                                    return row.lastTimeOnline ?
                                        <Tooltip tooltipDirection='right' tooltipContent={translate('offlineSince', {
                                            lastTimeOnline: moment(row.lastTimeOnline).format('DD/MM/YYYY HH:mm')
                                        })} width={300}>
                                            <MyAppText style={[styles.cell, row.disabledAt ? { color: theme.colors.disabledText } : row.isConnected ? {} : { color: '#FF0027' }]}>
                                                {row.disabledAt ? translate('disabled') : row.isConnected ? translate('online') : translate('offline')}
                                            </MyAppText>
                                        </Tooltip>
                                        : <MyAppText style={[styles.cell, row.disabledAt ? { color: theme.colors.disabledText } : row.isConnected ? {} : { color: '#FF0027' }]}>
                                            {row.disabledAt ? translate('disabled') : row.isConnected ? translate('online') : translate('offline')}
                                        </MyAppText>;
                                }
                            },
                            {
                                name: translate('registeredAt'),
                                selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
                                wrap: true
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row =>
                                    <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                        <TouchableOpacity onPress={() => navigation.navigate('ManagementDetails', { id: row.id })}>
                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => navigation.navigate('CameraHistory', {
                                            id: row.id,
                                        })}>
                                            <FontAwesomeIcon icon={faPlug} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                    </View>
                            }
                        ]}
                        data={cameras}
                        progressPending={isLoading}
                    />
                </View>
                <Pagination
                    totalItems={totalItems}
                    currentPage={filters.page}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                />
            </View>
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15,
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.container.border,
        backgroundColor: theme.colors.container.background,
        paddingHorizontal: 10,
        flex: 1
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
    cell: {
        fontSize: 14,
        color: theme.colors.textColor,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
}));

