import React from 'react';
import { translate } from '../../services/translate';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../MyAppText';
import moment from 'moment';
import MyDataTable from '../MyDataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faUser } from '@fortawesome/free-solid-svg-icons';
import { ONLINE_AGENT_TIME_MINUTES } from '../../services/central-api/dispatch';
import { TextMask } from 'react-native-masked-text';
import { UnitAndDistance, UserAndDistances } from './OccurrenceDetailsDispatchTab';

interface UnitRow extends UnitAndDistance {
    equipmentExpanded?: boolean;
}

interface UserRow extends UserAndDistances {
    expanded?: boolean;
}

interface Props {
    unit: UnitRow;
    assignments: DropdownResource[];
    occurrenceSituation?: OccurrenceSituation;
}

function OccurrenceUnitContent({ unit, assignments, occurrenceSituation }: Props) {
    const { styles, theme } = useStyles(styleSheet);

    const renderAgentInformation = (user: User, unit: Unit) => {
        const userSkills = user.Skills.map(skill => skill.name);
        let userAssignment;
        if (unit) {
            const unitUser = unit.UnitUsers?.find(uu => uu.id == user.id);
            if (unitUser) {
                userAssignment = assignments.find((assignment) => assignment.id == unitUser.UnitUser.assignmentId);
            }
        }

        return <View style={{ paddingLeft: 96 }}>
            <View style={styles.agentRow}>
                <MyAppText style={{ fontWeight: 'bold' }}>{translate('skills')}:</MyAppText>
                {userSkills.length ?
                    <MyAppText>{userSkills.join(',')}</MyAppText>
                    : <MyAppText>{translate('noSkills')}</MyAppText>
                }
            </View>
            <View style={styles.agentRow}>
                <MyAppText style={{ fontWeight: 'bold' }}>{translate('assignment')}:</MyAppText>
                {userAssignment ?
                    <MyAppText>{userAssignment.name}</MyAppText>
                    : <MyAppText>{translate('notDefined')}</MyAppText>
                }
            </View>
            <View style={styles.agentRow}>
                <MyAppText style={{ fontWeight: 'bold' }}>{translate('role')}:</MyAppText>
                {user.role ?
                    <MyAppText>{user.role.name}</MyAppText>
                    : <MyAppText>{translate('notDefined')}</MyAppText>
                }
            </View>
            <View style={styles.agentRow}>
                <MyAppText style={{ fontWeight: 'bold' }}>{translate('phone')}:</MyAppText>
                {user.phone ?
                    <TextMask
                        style={{ color: theme.colors.labelColor, fontFamily: 'Open Sans' }}
                        type={'cel-phone'}
                        value={user.phone} />
                    : <MyAppText>{translate('dontHave')}</MyAppText>
                }
            </View>
        </View>;
    };

    const renderEquipments = (equipments: Equipment[]) => {
        return <View style={{ paddingLeft: 80 }}>
            <MyDataTable
                dense={true}
                noTableHead={true}
                columns={[{
                    selector: row => row.name,
                    grow: 2
                }]}
                data={equipments}
            />
        </View>;
    };

    return (
        <View style={{ paddingVertical: 10, flex: 1 }}>
            <MyDataTable
                dense={true}
                noTableHead={true}
                columns={[{
                    cell: function (row) {
                        return (
                            <View style={styles.unitRow}>
                                <FontAwesomeIcon
                                    icon={faUser}
                                    fontSize={16}
                                    color={
                                        occurrenceSituation == 'concluded' ? theme.colors.iconColor
                                            : row.agentPosition &&
                                                moment(row.agentPosition.positionUpdatedAt) >
                                                moment().subtract(ONLINE_AGENT_TIME_MINUTES, 'minutes')
                                                ? 'green'
                                                : 'red'
                                    }
                                />
                                <MyAppText>{row.warName || row.name}</MyAppText>
                            </View>
                        );
                    },
                    grow: 3
                },
                {
                    cell: function (row) {
                        return (
                            <View style={styles.unitRow}>
                                <MyAppText>{row.distance}</MyAppText>
                            </View>
                        );
                    },
                    width: '100px'
                },
                {
                    cell: function () {
                        return <View style={styles.unitRow}></View>;
                    },
                    width: '76px'
                }
                ]}
                expandableRows
                expandableRowExpanded={function (row) {
                    return row.expanded || false;
                }}
                onRowExpandToggled={function (bool, row) {
                    row.expanded = bool;
                }}
                expandableRowsComponent={function ({ data }) {
                    return renderAgentInformation(data || [], unit);
                }}
                data={(unit.users || []) as UserRow[]}
            />
            {unit.equipments?.length ? (
                <MyDataTable
                    dense={true}
                    noTableHead={true}
                    columns={[{
                        cell: function (row) {
                            return (
                                <View style={styles.unitRow}>
                                    <FontAwesomeIcon icon={faBriefcase} fontSize={16} color={theme.colors.iconColor} />
                                    <MyAppText>{row.name}</MyAppText>
                                </View>
                            );
                        }
                    }]}
                    expandableRows
                    expandableRowExpanded={function () {
                        return unit.equipmentExpanded || false;
                    }}
                    onRowExpandToggled={function (bool) {
                        unit.equipmentExpanded = bool;
                    }}
                    expandableRowsComponent={function ({ data }) {
                        return renderEquipments(data.equipments || []);
                    }}
                    data={[{ name: translate('equipments'), equipments: unit.equipments }]}
                />
            ) : null}
        </View>
    );
}

const styleSheet = createStyleSheet(() => ({
    unitRow: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 10
    },
    agentRow: {
        flexDirection: 'row',
        columnGap: 5,
        minHeight: 32,
        alignItems: 'center'
    }
}));


export default OccurrenceUnitContent;
