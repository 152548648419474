import React, { useEffect, useState } from 'react';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';
import { gcmAgentService } from '../../services/central-api/gcm-agent';

type SetStateCallback<S> = ((prevState: S) => S);

interface Props {
    value: number;
    setValue: React.Dispatch<SetStateCallback<number>>;
    zIndex?: number;
    disabled?: boolean;
    labelFontSize?: number;
    label?: string;
}

export default function HandcuffReasonDropdown({ value, setValue, zIndex, disabled, labelFontSize, label }: Props) {
    const [items, setItems] = useState<{ label: string, value: number; }[]>([]);

    async function getHandcuffReasons() {
        try {
            const response = await gcmAgentService.getHandcuffReasons();
            setItems(response.map(unitProgram => ({
                label: unitProgram.name, value: unitProgram.id!
            })));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getHandcuffReasons();
    }, []);

    return (
        <FilterDropdown
            label={label ?? translate('handcuffReason')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
            disabled={disabled}
            labelFontSize={labelFontSize}
        />
    );
}