import React, { useState, useCallback, Dispatch, SetStateAction, useEffect } from 'react';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { View, TouchableOpacity, Modal } from 'react-native';
import MyAppText from '../../../components/MyAppText';
import MyDataTable from '../../../components/MyDataTable';
import AgentsDropdown from './AgentsDropDown';
import { translate } from '../../../services/translate';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import FormActions from '../../../components/formActions';
import { dispatchService } from '../../../services/central-api/dispatch';

interface UnitFormAgentsProps {
    agentsSelected: number[];
    setAgentsSelected: Dispatch<SetStateAction<number[]>>;
    actingBodyId: number;
    unitUsers?: SimplifiedUserForUnit[];
    modifiedAgentAssignments: { [agentId: number]: number; };
    setModifiedAgentAssignments: Dispatch<SetStateAction<{ [agentId: number]: number; }>>;
    isFinished: boolean;
}

export default function UnitFormAgents({
    agentsSelected,
    setAgentsSelected,
    actingBodyId,
    unitUsers,
    modifiedAgentAssignments,
    setModifiedAgentAssignments,
    isFinished,
}: UnitFormAgentsProps) {
    const { styles, theme } = useStyles(stylesheet);

    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    const [selectableAgents, setSelectableAgents] = useState<{ label: string, value: number; }[]>([]);
    const [currentAgentAssignment, setCurrentAgentAssignment] = useState<number>(0);
    const [isAssignmentModalVisible, setIsAssignmentModalVisible] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState<number>(0);
    const [modalSaveDisabled, setModalSaveDisabled] = useState(true);
    const [selectableAssignments, setSelectableAssignments] = useState<{ label: string, value: number; }[]>([]);

    useEffect(() => {
        setSelectedAssignment(0);
        setModalSaveDisabled(true);
    }, [isAssignmentModalVisible]);

    useEffect(() => {
        if (selectedAssignment) {
            setModalSaveDisabled(false);
        }
    }, [selectedAssignment]);

    useEffect(() => {
        if (actingBodyId) {
            getAssignments(actingBodyId);
        }
    }, [actingBodyId]);



    async function getAssignments(actingBodyId: number) {
        try {
            const assignments = await dispatchService.getSimplifiedAssignments(actingBodyId);
            setSelectableAssignments([
                ...assignments.map((assignment) => {
                    return {
                        value: assignment.id,
                        label: assignment.name
                    };
                })
            ]);
        } catch (err) {
            console.error(err);
        }
    }

    return <>
        <View style={{ rowGap: 5, flex: 1, minWidth: 250 }}>
            {!isFinished &&
                <AgentsDropdown
                    value={agentsSelected}
                    setValue={setAgentsSelected}
                    actingBodyId={actingBodyId}
                    disabled={false}
                    zIndex={2}
                    selectableAgents={selectableAgents}
                    setSelectableAgents={setSelectableAgents}
                    forceUpdate={forceUpdate}
                />
            }
            <MyDataTable
                columns={[
                    {
                        name: translate('name'),
                        cell: row => {
                            if (isFinished && unitUsers) {
                                const agent = unitUsers.find(agent => agent.id == row.id);
                                return <View style={{ width: '100%' }}>
                                    <MyAppText >{agent?.name}{agent?.warName ? ' - ' + agent?.warName : ''}: {agent?.registry}</MyAppText>
                                </View>;
                            }
                            const agent = selectableAgents.find(agent => agent.value == row.id);
                            return <View style={{ width: '100%' }}>
                                <MyAppText >{agent?.label}</MyAppText>
                            </View>;
                        },
                        grow: 2,
                        wrap: true
                    },
                    {
                        grow: 2,
                        name: translate('assignment'),
                        cell: row => {
                            if (isFinished && unitUsers) {
                                const agent = unitUsers.find(agent => agent.id == row.id);

                                const assignment = selectableAssignments.find((assignment) => assignment.value == agent?.UnitUser.assignmentId);

                                return <View style={{ width: '100%' }}>
                                    <MyAppText >{assignment?.label}</MyAppText>
                                </View>;
                            }

                            const defaultButton = <TouchableOpacity onPress={() => {
                                setCurrentAgentAssignment(row.id);
                                setIsAssignmentModalVisible(true);
                            }} style={{ width: '100%' }}>
                                <MyAppText style={{
                                    textDecorationLine: 'underline',
                                    color: theme.colors.labelColor,
                                    textDecorationColor: theme.colors.labelColor,
                                }}>{translate('selectAssignment')}</MyAppText>
                            </TouchableOpacity>;

                            // first of all check if there's already a modification on user assignment on the page
                            if (row.id in modifiedAgentAssignments) {
                                const assignment = selectableAssignments.find((assignment) => assignment.value == modifiedAgentAssignments[row.id]);

                                if (!assignment?.label) {
                                    return defaultButton;
                                }

                                return <TouchableOpacity onPress={() => {
                                    setCurrentAgentAssignment(row.id);
                                    setIsAssignmentModalVisible(true);
                                }}>
                                    <MyAppText style={{ textDecorationLine: 'underline' }}>{assignment.label}</MyAppText>
                                </TouchableOpacity>;
                            }

                            // then try to get the current database information
                            if (!unitUsers) {
                                return defaultButton;
                            }
                            const unitUser = unitUsers.find((unitUser) => unitUser.id == row.id);

                            if (!unitUser) {
                                return defaultButton;
                            }

                            const assignment = selectableAssignments.find((assignment) => assignment.value == unitUser.UnitUser.assignmentId);

                            if (!assignment?.label) {
                                return defaultButton;
                            }

                            return <TouchableOpacity onPress={() => {
                                setCurrentAgentAssignment(row.id);
                                setIsAssignmentModalVisible(true);
                            }} style={{ width: '100%' }}>
                                <MyAppText style={{ textDecorationLine: 'underline' }}>{assignment.label}</MyAppText>
                            </TouchableOpacity>;
                        },
                    },
                    !isFinished ? {
                        name: translate('action'),
                        button: true,
                        grow: 0,
                        cell: row => {
                            return <TouchableOpacity onPress={() => {
                                const idx = agentsSelected.findIndex((el) => el == row.id);
                                agentsSelected.splice(idx, 1);
                                setAgentsSelected([...agentsSelected]);
                            }}>
                                <FontAwesomeIcon fontSize={20} icon={faTrashCan} color={theme.colors.iconColor} />
                            </TouchableOpacity>;
                        }
                    } : {}
                ]}
                data={agentsSelected.map(agent => ({ id: agent }))}
            />
        </View>

        <Modal
            transparent={true}
            visible={isAssignmentModalVisible}
            animationType='fade'
            onRequestClose={() => setIsAssignmentModalVisible(false)}
        >
            <View style={styles.modalContainer}>
                <View style={[styles.formContainer, { maxHeight: 160 }]}>
                    <View style={{ rowGap: 5, flex: 1 }}>
                        <FilterDropdown
                            label={translate('assignments')}
                            items={selectableAssignments}
                            multiple={false}
                            setValue={setSelectedAssignment}
                            value={selectedAssignment}
                            disabled={false}
                            zIndex={2}
                        />
                    </View>
                    <FormActions
                        onSubmit={() => {
                            if (selectedAssignment) {
                                setModifiedAgentAssignments({ ...modifiedAgentAssignments, [currentAgentAssignment]: selectedAssignment });
                            }
                            setIsAssignmentModalVisible(false);
                        }}
                        onClose={() => setIsAssignmentModalVisible(false)}
                        disabled={modalSaveDisabled}
                        confirmText='select' />
                </View>
            </View>
        </Modal>
    </>;
}

const stylesheet = createStyleSheet(theme => ({
    modalContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        flex: 1,
        height: 705
    },
}));