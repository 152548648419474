import React, { useEffect, useState } from 'react';
import FilterDropdown from './Filter/components/FilterDropdown';
import { translate } from '../services/translate';
import { polygonService } from '../services/central-api/polygons';
import { DropDownDirectionType } from 'react-native-dropdown-picker';

interface Props {
    setPolygons: React.Dispatch<React.SetStateAction<MapPolygon[]>>;
    dropDownDirection?: DropDownDirectionType;
}

export default function MapPolygonDropdown({ setPolygons, dropDownDirection }: Props) {

    const [selectedPolygonsType, setSelectedPolygonsType] = useState<string | null>(null);
    const [polygonsType, setPolygonsType] = useState<{ type: string; }[]>([]);

    async function getPolygonsType() {
        try {
            const polygonsType = await polygonService.getPolygonsType();
            setPolygonsType(polygonsType);
        } catch (err) {
            console.error(err);
        }
    }

    async function getPolygonsByType(type: string) {
        try {
            const polygons = await polygonService.getPolygonsByType(type);
            setPolygons(polygons);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (selectedPolygonsType) {
            getPolygonsByType(selectedPolygonsType);
        } else {
            setPolygons([]);
        }
    }, [selectedPolygonsType]);

    useEffect(() => {
        getPolygonsType();
    }, []);

    return (
        <FilterDropdown
            items={[
                { label: '', value: undefined },
                ...polygonsType.map((polygon) => {
                    return {
                        label: polygon.type,
                        value: polygon.type
                    };
                })
            ]}
            multiple={false}
            value={selectedPolygonsType}
            setValue={setSelectedPolygonsType}
            dropDownDirection={dropDownDirection ?? 'TOP'}
            placeholder={translate('map')}
        />
    );
}