import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import MyDataTable from '../../MyDataTable';
import DeleteModal from '../../DeleteModal';
import InvolvedPersonModal from './InvolvedPersonModal';
import CustomButton from '../../CustomButton';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface InvolvedPeopleParams {
    involvedPeople: InvolvedPerson[];
    setInvolvedPeople: (value: InvolvedPerson[]) => void;
    actingBodyId: number;
    occurrenceSituation: OccurrenceSituation;
}

export default function InvolvedPeople({ involvedPeople, setInvolvedPeople, actingBodyId, occurrenceSituation }: InvolvedPeopleParams) {
    const { styles, theme } = useStyles(styleSheet);
    const [selectedInvolvedPerson, setSelectedInvolvedPerson] = useState<InvolvedPerson>(getBlankPerson());
    const [deletePersonModalVisible, setDeletePersonModalVisible] = useState(false);
    const [personModalOpen, setPersonModalOpen] = useState<boolean>(false);

    function getBlankPerson(): InvolvedPerson {
        return {
            id: undefined,
            name: '',
            motherName: '',
            birth: '',
            documentType: 'rg',
            document: '',
            gender: 'male',
            personRelationId: 0,
            arrestWarrantNumber: '',
            publicBody: '',
            arrested: false,
            policeReport: '',
            policeReportNatureId: null,
            handcuffReasonId: 0,
            handcuffUsed: false,
            usedBat: false,
            usedElectricGun: false,
            usedFireGun: false,
            usedSpreaderGas: false,
            movedToFirstAidStation: false,
            movedToHospital: false,
            movedToLegalMedicalInstitute: false,
            movedToPoliceStation: false,
            providenceId: 0,
            documentSeries: '',
            documentState: '',
            address: '',
            neighborhood: '',
            phoneNumber: '',
            company: '',
            companyRole: '',
            companyAddress: '',
            companyNeighborhood: '',
            companyPhoneNumber: '',
        };
    }

    async function createUpdateInvolvedPeople() {
        const copy = [...involvedPeople];

        if (!selectedInvolvedPerson.id) {
            selectedInvolvedPerson.id = -Math.random();
            copy.push(selectedInvolvedPerson);
        } else {
            const index = copy.findIndex(person => person.id === selectedInvolvedPerson.id);
            copy[index] = { ...copy[index], ...selectedInvolvedPerson };
        }

        setInvolvedPeople(copy);
        setSelectedInvolvedPerson(getBlankPerson());
    }

    return (
        <>
            <View style={styles.container}>
                {occurrenceSituation == 'concluded' ?
                    <></> : <View style={styles.header}>
                        <MyAppText style={styles.headerText}>{translate('involvedPeople')}</MyAppText>
                        <CustomButton
                            onPress={() => setPersonModalOpen(true)}
                            text={translate('attach')}
                            icon={faPlus}
                        />
                    </View>
                }

                <MyDataTable
                    columns={[
                        {
                            name: translate('name'),
                            selector: row => row.name,
                            wrap: true,
                        },
                        {
                            name: translate('motherName'),
                            selector: row => row.motherName,
                            wrap: true,
                        },
                        {
                            name: translate('birth'),
                            selector: row => row.birth,
                            wrap: true,
                        },
                        {
                            name: translate('document'),
                            selector: row => row.document,
                            wrap: true,
                        },
                        {
                            name: translate('gender'),
                            selector: row => translate(row.gender),
                            wrap: true,
                        },
                        {
                            name: translate('relation'),
                            selector: row => row.personRelation?.name || '',
                            wrap: true,
                        },
                        {
                            name: translate('arrestWarrantNumber'),
                            selector: row => row.arrestWarrantNumber || '',
                            wrap: true,
                        },
                        {
                            name: translate('action'),
                            button: true,
                            cell: row =>
                                <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                    <TouchableOpacity onPress={() => {
                                        setSelectedInvolvedPerson(row);
                                        setPersonModalOpen(true);
                                    }}>
                                        <FontAwesomeIcon icon={occurrenceSituation == 'concluded' ? faEye : faEdit} fontSize={16} color={theme.colors.iconColor} />
                                    </TouchableOpacity>
                                    {occurrenceSituation == 'concluded' ? <></> :
                                        <TouchableOpacity onPress={() => {
                                            setSelectedInvolvedPerson(row);
                                            setDeletePersonModalVisible(true);
                                        }}>
                                            <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                    }
                                </View>
                        }
                    ]}
                    data={involvedPeople}
                />
            </View>
            <DeleteModal
                setModalVisible={setDeletePersonModalVisible}
                isModalVisible={deletePersonModalVisible}
                itemName={translate('person')}
                onSubmit={() => {
                    const index = involvedPeople.findIndex(person => person.id === selectedInvolvedPerson.id);
                    if (index >= 0) {
                        involvedPeople.splice(index, 1);
                        setInvolvedPeople(involvedPeople);
                        setSelectedInvolvedPerson(getBlankPerson());
                    }
                }}
                onCancel={() => {
                    setSelectedInvolvedPerson(getBlankPerson());
                }}
            />
            <InvolvedPersonModal
                involvedPerson={selectedInvolvedPerson}
                setInvolvedPerson={setSelectedInvolvedPerson}
                isModalVisible={personModalOpen}
                actingBodyId={actingBodyId}
                setModalVisible={(value) => {
                    if (!value) {
                        setSelectedInvolvedPerson(getBlankPerson());
                    }
                    setPersonModalOpen(value);
                }}
                onSubmit={createUpdateInvolvedPeople}
                occurrenceSituation={occurrenceSituation}
            />
        </>
    );
}

const styleSheet = createStyleSheet(() => ({
    container: {
        flex: 1,
        rowGap: 20
    },
    headerText: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    }
}));
