import React from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from './MyAppText';

interface Props {
    label?: string;
    children: React.ReactNode | undefined;
}

export default function GroupContainer({ label, children }: Props) {
    const { styles } = useStyles(stylesheet);

    return <View style={styles.groupContainer}>
        {label &&
            <MyAppText style={styles.inputLabel}>
                {label}:
            </MyAppText>}
        {children}
    </View>;
}

const stylesheet = createStyleSheet(theme => ({
    groupContainer: {
        borderColor: theme.colors.container.border,
        borderWidth: 1,
        borderRadius: 8,
        padding: 15,
    },
    inputLabel: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
        position: 'absolute',
        top: -14,
        left: 28,
        backgroundColor: theme.colors.container.background,
    }
}));