import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { translate } from '../../services/translate';
import Toast from 'react-native-toast-message';
import { centralAPI } from '../../services/central-api';
import { ClientError } from '../../services/central-api/base-service';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomButton from '../../components/CustomButton';
import { RichTextEditor } from '../../components/RichTextEditor';


export default function Notes() {
    const { styles } = useStyles(stylesheet);
    const [loading, setLoading] = useState(true);

    const [annotation, setAnnotation] = useState({ dirty: false, value: '' });

    async function getUserNotes() {
        try {
            const note = await centralAPI.getOwnUserNotes();
            setAnnotation({ dirty: false, value: note.note });
        } catch (err) {
            console.error(err);
        }
        setLoading(false);
    }

    useEffect(() => {
        getUserNotes();
    }, []);


    async function save() {
        setLoading(true);
        try {
            await centralAPI.updateOwnUserNotes(annotation.value);

            setAnnotation({ value: annotation.value, dirty: false });

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
        setLoading(false);

    }

    return (
        <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.header}>
                <CustomButton
                    onPress={save}
                    disabled={!annotation.dirty}
                    text={translate('save')}
                />
            </View>

            <View style={styles.cardList}>

                <View style={[styles.child, { rowGap: 15 }]}>
                    {loading ? <></>
                        : <RichTextEditor text={annotation.value} onChangeText={(text) => setAnnotation({ dirty: true, value: text })} />
                    }
                </View>

            </View>
        </ScrollView>
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        flex: 1,
        rowGap: 15
    },

    header: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        columnGap: 10,
        minHeight: 65,
        justifyContent: 'flex-end',
    },

    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        padding: 24,
        backgroundColor: theme.colors.container.background,
        flexGrow: 1,
        flexDirection: {
            xs: 'column-reverse',
            md: 'row'
        },
    },

    child: {
        flex: {
            xs: undefined,
            md: 1
        }
    },

    buttonDisabled: {
        borderWidth: 0,
        backgroundColor: theme.colors.primaryButton.disabledBackground
    },

    textDisabled: {
        color: theme.colors.disabledText
    },
}));

