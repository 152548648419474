import React, { useState } from 'react';
import { ScrollView, View, Image, TouchableOpacity, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import { faChevronDown, faChevronUp, faLocationDot, faSearchPlus, faTimes, } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Hoverable } from 'react-native-web-hover';
import { WindowInformation } from '../../../services/window-information';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

export default function OccurrenceFormMpPlateDetected({
    resume
}: { resume: MpPlateDetectedResumeData; }) {
    const { styles, theme } = useStyles(stylesheet);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const windowInfo = WindowInformation();
    const [showDetails, setShowDetails] = useState<boolean>(false);

    return (
        <ScrollView contentContainerStyle={{ rowGap: 10 }}>
            <View>
                <MyAppText style={styles.plateText}>
                    {translate('plate')}
                </MyAppText>
                <MyAppText style={styles.plateInfoText}>
                    {resume.plate}
                </MyAppText>
            </View>
            <Hoverable>
                {({ hovered }) => (<>
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} >
                        <Image
                            style={{
                                width: 360,
                                height: 240,
                                resizeMode: 'contain',
                                borderRadius: 5,
                                overflow: 'hidden',
                            }}
                            source={{ uri: `${resume.imageUrl}` }}
                        />
                    </View>
                    {hovered &&
                        <View style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#00000080',
                        }}>
                            <TouchableOpacity
                                style={{
                                    flex: 1,
                                    justifyContent: 'center'
                                }}
                                onPress={async () => {
                                    setIsModalVisible(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faSearchPlus} fontSize={28} color={'#FFFFFF'} />
                            </TouchableOpacity>
                        </View>
                    }
                </>)}
            </Hoverable>

            <View style={styles.generalInformation}>
                <TouchableOpacity
                    style={styles.generalInformationDropdown}
                    onPress={() => setShowDetails(!showDetails)}>
                    <MyAppText>{translate('general_info')}</MyAppText>
                    <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} color={theme.colors.iconColor} />
                </TouchableOpacity>
                <View style={styles.tableLine}></View>
            </View>

            {showDetails ? <>
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.plateText}>
                        {translate('vehicleData')}
                    </MyAppText>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('model')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.modelBrand}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('color')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.color}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('origin')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.plateMunicipality} / {resume.plateFederativeUnit}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('manufacturingYear')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.manufacturingYear}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('modelYear')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.modelYear}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('origination')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.origin}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('chassis')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.chassis}
                        </MyAppText>
                    </View>
                </View>
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.plateText}>
                        {translate('visualizedOn')}
                    </MyAppText>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 14 }} color={theme.colors.iconColor} />
                        <MyAppText style={styles.detectionInfoText}>
                            {moment(new Date(resume.time)).format('DD/MM/YYYY HH:mm:ss')}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <FontAwesomeIcon icon={faLocationDot} style={{ width: 14 }} color={theme.colors.iconColor} />
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.location}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('city')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.city}
                        </MyAppText>
                    </View>
                    <View style={styles.visualizedRow} >
                        <MyAppText style={styles.detectionInfoText}>
                            {translate('federativeUnit')}:
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.federativeUnit}
                        </MyAppText>
                    </View>
                </View>
                {(resume.occurrenceHistory) ?
                    <View style={{ gap: 3 }}>
                        <MyAppText style={styles.plateText}>
                            {translate('description')}
                        </MyAppText>
                        <MyAppText style={styles.detectionInfoText}>
                            {resume.occurrenceHistory}
                        </MyAppText>
                    </View>
                    : null}
                <View style={{ gap: 3 }}>
                    <MyAppText style={styles.plateText}>
                        {translate('systemData')}
                    </MyAppText>
                    {(resume.occurrenceSystem) ?
                        <View style={styles.visualizedRow} >
                            <MyAppText style={styles.detectionInfoText}>
                                {translate('occurrenceSystem')}:
                            </MyAppText>
                            <MyAppText style={styles.detectionInfoText}>
                                {resume.occurrenceSystem}
                            </MyAppText>
                        </View>
                        : null}
                    {(resume.natureOfOccurrence) ?
                        <View style={styles.visualizedRow} >
                            <MyAppText style={styles.detectionInfoText}>
                                {translate('natureOfOccurrence')}:
                            </MyAppText>
                            <MyAppText style={styles.detectionInfoText}>
                                {resume.natureOfOccurrence}
                            </MyAppText>
                        </View>
                        : null}
                    {(resume.policeReportNumber) ?
                        <View>
                            <View style={styles.visualizedRow} >
                                <MyAppText style={styles.detectionInfoText}>
                                    {translate('policeReportNumber')}:
                                </MyAppText>
                                <MyAppText style={styles.detectionInfoText}>
                                    {resume.policeReportNumber}
                                </MyAppText>
                            </View>
                            <View style={styles.visualizedRow} >
                                <MyAppText style={styles.detectionInfoText}>
                                    {translate('policeReportYear')}:
                                </MyAppText>
                                <MyAppText style={styles.detectionInfoText}>
                                    {resume.policeReportYear}
                                </MyAppText>
                            </View>
                            <View style={styles.visualizedRow} >
                                <MyAppText style={styles.detectionInfoText}>
                                    {translate('policeReportLocation')}:
                                </MyAppText>
                                <MyAppText style={styles.detectionInfoText}>
                                    {resume.policeReportMunicipality} / {resume.policeReportFederativeUnit}
                                </MyAppText>
                            </View>
                        </View>
                        : null}
                    {(resume.declarantName) ?
                        <View style={styles.visualizedRow} >
                            <MyAppText style={styles.detectionInfoText}>
                                {translate('declarantName')}:
                            </MyAppText>
                            <MyAppText style={styles.detectionInfoText}>
                                {resume.declarantName}
                            </MyAppText>
                        </View>
                        : null}
                    {(resume.contactAreaCode && resume.contactPhone) ?
                        <View style={styles.visualizedRow} >
                            <MyAppText style={styles.detectionInfoText}>
                                {translate('phone')}:
                            </MyAppText>
                            <MyAppText style={styles.detectionInfoText}>
                                ({resume.contactAreaCode}) {resume.contactPhone} {resume.contactBranch ? ` / ${resume.contactBranch}` : ''}
                            </MyAppText>
                        </View>
                        : null}
                    {(resume.occurrenceDate) ?
                        <View style={styles.visualizedRow} >
                            <MyAppText style={styles.detectionInfoText}>
                                {translate('occurrenceDate')}:
                            </MyAppText>
                            <MyAppText style={styles.detectionInfoText}>
                                {moment(new Date(resume.occurrenceDate)).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                        : null}
                    {(resume.policeUnit) ?
                        <View style={styles.visualizedRow} >
                            <MyAppText style={styles.detectionInfoText}>
                                {translate('policeUnit')}:
                            </MyAppText>
                            <MyAppText style={styles.detectionInfoText}>
                                {resume.policeUnit}
                            </MyAppText>
                        </View>
                        : null}
                </View>
            </> : <></>}

            {/* Zoom image modal */}
            <Modal
                animationType='fade'
                transparent={true}
                visible={isModalVisible}
                onRequestClose={() => setIsModalVisible(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredView}>
                        <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : null]}>
                            <View style={{ alignItems: 'flex-end' }}>
                                <TouchableOpacity
                                    style={{ width: 20, height: 20 }}
                                    onPress={() => setIsModalVisible(false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ width: 20 }} color={theme.colors.iconColor} />
                                </TouchableOpacity>
                            </View>

                            <View style={{ flexDirection: 'row', gap: 15 }} >
                                <View style={{ flex: 1 }}>
                                    <Image
                                        style={{
                                            width: 1080,
                                            height: 720,
                                            resizeMode: 'contain',
                                            borderRadius: 5,
                                            overflow: 'hidden'
                                        }}
                                        source={{ uri: `${resume.imageUrl}` }}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </ScrollView>
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        padding: 16,
        backgroundColor: theme.colors.container.background,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        borderWidth: 1,
    },
    imageText: {
        color: theme.colors.cardDetailText,
        paddingBottom: 5,
        fontSize: 11,
    },
    detectionInfoText: {
        color: theme.colors.labelColor,
        fontSize: 12
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalBody: {
        backgroundColor: theme.colors.container.background,
        flexDirection: 'column',
        borderRadius: 8,
        rowGap: 20,
        padding: 30,
    },
    plateText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 14
    },
    plateInfoText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 24
    },
    tableLine: {
        borderBottomColor: theme.colors.cardBorder,
        borderBottomWidth: 1,
        flex: 1
    },
    generalInformation: {
        flexDirection: 'column',
        paddingHorizontal: 5,
        flex: 1,
        gap: 10
    },
    generalInformationDropdown: {
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1
    },
    visualizedRow: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
    confidenceText: {
        color: theme.colors.cardDetailText,
        fontSize: 14,
        paddingVertical: 10
    },
    nameText: {
        color: theme.colors.labelColor,
        fontWeight: 'bold',
        fontSize: 20
    },
}));
