import React, { useState } from 'react';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { DashBoardParamList } from '../../../typings/Params';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import ActingBodiesCommandsDropdown from '../../../components/Dropdowns/ActingBodiesCommandsDropdown';
import { TeamNameCategory } from '../../../services/central-api/dispatch';
import CategoriesDropdown from './CategoriesDropdown';

interface Props {
    filters: DashBoardParamList['DashboardVehicles'];
    setFilters: React.Dispatch<React.SetStateAction<DashBoardParamList['DashboardVehicles']>>;
    authenticatedUser?: AuthenticatedUser;
    textFilter: string;
    setTextFilter: (value: string) => void;
}

function Filter({ authenticatedUser, filters, setFilters, textFilter, setTextFilter }: Props) {
    const navigation = useNavigation<NavigationProp<RouteProp<DashBoardParamList, 'DashboardVehicles'>>>();

    const [actingBodyId, setActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [actingBodyCommandIds, setActingBodyCommandIds] = useState<number[]>(filters.actingBodyCommandIds ?? []);
    const [categoryList, setCategoryList] = useState<TeamNameCategory[]>(filters.categoryList ?? []);

    const handleCleanFilter = () => {
        setTextFilter('');
        setActingBodyId(0);
        setActingBodyCommandIds([]);
        setCategoryList([]);
    };

    const handleFilter = () => {
        navigation.setParams({
            ...filters,
            textFilter,
            actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
            actingBodyCommandIds: actingBodyCommandIds.length ? actingBodyCommandIds : undefined,
            categoryList: categoryList.length ? categoryList : undefined
        });

        setFilters({
            ...filters,
            textFilter,
            actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
            actingBodyCommandIds: actingBodyCommandIds.length ? actingBodyCommandIds : undefined,
            categoryList: categoryList.length ? categoryList : undefined
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('typeToSearch')}
                value={textFilter}
                placeholder={translate('typeToSearch')}
                onChange={setTextFilter}
            />

            <CategoriesDropdown value={categoryList} setValue={setCategoryList} zIndex={4} />


            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dashboard_see_vehicles_acting_bodies) &&
                <ActingBodiesDropdown includeAllOption={true} value={actingBodyId} setValue={setActingBodyId} zIndex={3} />
            }

            <ActingBodiesCommandsDropdown
                value={actingBodyCommandIds}
                actingBodyId={actingBodyId}
                setValue={setActingBodyCommandIds}
                zIndex={2}
            />

        </Filters>
    );
}


export default Filter;
