import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { WindowInformation } from '../../../services/window-information';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { HexColorPicker } from 'react-colorful';
import Toast from 'react-native-toast-message';
import { facialService } from '../../../services/central-api/facial';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    onClose: () => void;
    marker?: BaseFacialTag;
}

export default function MarkerModal({ onClose, marker, isModalVisible, setModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [color, setColor] = useState('#aabbcc');

    function isNameInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isNameInvalid(name);
    }

    function clearValues() {
        setName('');
    }

    useEffect(() => {
        if (isModalVisible) {
            setColor(`#${Math.floor(Math.random() * 0x1000000).toString(16).padStart(6, '0')}`);
        }
    }, [isModalVisible]);

    useEffect(() => {
        if (!marker) {
            return;
        }
        setName(marker.name);
        setColor(marker.color);
    }, [marker]);

    async function formSubmit() {
        try {
            if (marker?.id) {
                await facialService.updateFacialMarker({
                    id: marker.id,
                    name: name,
                    color: color
                });
            } else {
                await facialService.createFacialMarker({
                    name: name,
                    color: color,
                });
            }

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            clearValues();
            onClose();
        }
    }

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => {
            setModalVisible(false);
            onClose();
            clearValues();
        }} transparent={true}>
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
                    <FormInput
                        label={translate('name')}
                        placeholder={translate('name')}
                        value={name}
                        onChangeText={setName}
                        invalid={isNameInvalid}
                    />
                    <View>
                        <HexColorPicker color={color} onChange={setColor} />
                    </View>

                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            clearValues();
                            onClose();
                        }}
                        disabled={!isFormValid()} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        minHeight: 150,
        backgroundColor: theme.colors.cardBackground,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    },
}));
