import React, { useCallback, useEffect, useState } from 'react';
import { View, ScrollView, ActivityIndicator, TouchableOpacity } from 'react-native';
import { Hoverable } from 'react-native-web-hover';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import { Log, auditLogsService } from '../../../services/central-api/audit-logs';
import { LogsParamList } from '../../../typings/Params';
import { RouteProp, useRoute } from '@react-navigation/native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import Filter from './Filters';


export default function LogList() {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<LogsParamList, 'LogList'>>();

    const [filters, setFilters] = useState<LogsParamList['LogList']>({
        ...route.params,
        page: 0,
        limit: 100,
    });

    const [logs, setLogs] = useState<Log[]>([]);
    const [hasMoreResults, setHasMoreResults] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<'asc' | 'desc'>(filters.sort || 'desc');

    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    async function getLogs() {
        try {
            setIsLoading(true);
            const newLogs = await auditLogsService.getLogs(filters);
            if (newLogs.length < filters.limit) {
                setHasMoreResults(false);
            } else {
                setHasMoreResults(true);
            }
            if (filters.page == 0) {
                logs.length = 0;
            }
            logs.push(...newLogs);
            setLogs(logs);
            forceUpdate();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getLogs();
    }, [filters]);

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={styles.buttonsContainer}>
                    <Filter setSortBy={setSortBy} sortBy={sortBy} filters={filters} setFilters={setFilters} />
                </View>
            </View>
            <View style={styles.cardList}>
                <View style={styles.listHeader}>
                    <View style={{ flexBasis: '10%' }}>
                        <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => {
                            const sort = filters.sort === 'asc' ? 'desc' : 'asc';
                            setSortBy(sort);
                            setFilters({ ...filters, sort, page: 0 });
                        }}>
                            <MyAppText style={styles.headerCell}>{translate('date')}</MyAppText>
                            {filters.sort === 'asc' ?
                                <FontAwesomeIcon icon={faAngleUp}
                                    style={{ ...styles.headerCell, alignSelf: 'center', position: 'absolute', right: 25 }}
                                    color={theme.colors.iconColor}
                                />
                                :
                                <FontAwesomeIcon icon={faAngleDown}
                                    style={{ ...styles.headerCell, alignSelf: 'center', position: 'absolute', right: 25 }}
                                    color={theme.colors.iconColor}
                                />
                            }
                        </TouchableOpacity>
                    </View>
                    <View style={{ flexBasis: '10%' }}>
                        <MyAppText style={styles.headerCell}>{translate('user')}</MyAppText>
                    </View>
                    <View style={{ flexBasis: '20%' }}>
                        <MyAppText style={styles.headerCell}>{translate('ipAddress')}</MyAppText>
                    </View>
                    <View style={{ flexBasis: '10%' }}>
                        <MyAppText style={styles.headerCell}>{translate('action')}</MyAppText>
                    </View>
                    <View style={{ flexBasis: '50%' }}>
                        <MyAppText style={styles.headerCell}>{translate('description')}</MyAppText>
                    </View>
                </View>
                <ScrollView>
                    <View style={{ gap: 10 }}>
                        {isLoading ?
                            <View>
                                <ActivityIndicator size='small' color={theme.colors.loadingColor} />
                            </View>
                            : null
                        }
                        {logs.length ?
                            logs.map((log, logIndex) => (
                                <View key={logIndex} style={{ rowGap: 9 }}>
                                    <View style={styles.row} >
                                        <View style={{ flexBasis: '10%' }}>
                                            <MyAppText style={styles.cell}>
                                                {moment(log.date).format('DD/MM/YYYY HH:mm:ss')}
                                            </MyAppText>
                                        </View>
                                        <View style={{ flexBasis: '10%' }}>
                                            <MyAppText style={styles.cell}>
                                                {log.userId == -1 && log.userName ? translate(log.userName) : log.userId ? `${log.userId} - ${log.userName}` : translate('notApplicable')}
                                            </MyAppText>
                                        </View>
                                        <View style={{ flexBasis: '20%' }}>
                                            <MyAppText style={styles.cell}>{log.ip}</MyAppText>
                                        </View>
                                        <View style={{ flexBasis: '10%' }}>
                                            <MyAppText style={styles.cell}>{translate(log.action)}</MyAppText>
                                        </View>
                                        <View style={{ flexBasis: '50%' }}>
                                            <MyAppText style={styles.cell}>{JSON.stringify(log.detail)}</MyAppText>
                                        </View>
                                    </View >
                                    <View style={styles.tableLine}></View>
                                </View>
                            )) :
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <MyAppText>{translate('noResults')}.</MyAppText>
                            </View>
                        }
                    </View>
                    {logs.length && hasMoreResults && !isLoading ?
                        <Hoverable style={styles.loadMore}>
                            {({ hovered }) => (
                                <TouchableOpacity style={[styles.loadMore, {
                                    borderBottomWidth: hovered ? 1 : 0
                                }]} onPress={() => setFilters({ ...filters, page: filters.page + 1 })}>
                                    <MyAppText style={styles.loadMoreText}>{translate('loadMore')}...</MyAppText>
                                </TouchableOpacity>

                            )}
                        </Hoverable>
                        : null
                    }
                </ScrollView>
            </View>
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'flex-end'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        padding: 24,
        flex: 1,
        gap: 20,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
    cell: {
        fontSize: 14
    },
    row: {
        flexDirection: 'row',
        rowGap: 15
    },
    listHeader: {
        flexDirection: 'row',
        rowGap: 7
    },
    tableLine: {
        borderBottomColor: theme.colors.cardBorder,
        borderBottomWidth: 1,
    },
    headerCell: {
        fontSize: 13
    },
    loadMoreText: {
        fontSize: 16,
    },
    loadMore: {
        alignItems: 'center',
        justifyContent: 'center'
    },
}));
