import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, ScrollView, ActivityIndicator } from 'react-native';
import { translate } from '../../services/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Map from '../../components/Map';
import { faArrowLeft, faBookOpen, faCar, faCube, faMapMarkedAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../../components/MyAppText';
import OccurrenceFormDefault from '../dispatch/occurrenceForm/OccurrenceFormDefault';
import OccurrenceFormFaceDetected from '../../components/dispatch/occurrenceForm/faceDetected';
import SceneChangeDetection from '../../components/dispatch/occurrenceForm/scenechangedetection';
import moment from 'moment';
import Toast from 'react-native-toast-message';
import { dispatchService, RecordNotLatestValues } from '../../services/central-api/dispatch';
import { Tabs } from '../../components/Tabs';
import DeleteModal from '../../components/DeleteModal';
import OccurrenceFormLprDetected from '../../components/dispatch/occurrenceForm/OccurrenceFormLprDetected';
import OccurrenceAreaInvasion from '../../components/dispatch/occurrenceForm/OccurrenceAreaInvasion';
import { ClientError } from '../../services/central-api/base-service';
import OccurrenceDetailsDispatchTab from './OccurrenceDetailsDispatchTab';
import FinishReasonModal from '../dispatch/occurrenceForm/FinishReason';
import OccurrenceFormMpPlateDetected from '../dispatch/occurrenceForm/OccurrenceFormMpPlateDetected';
import OccurrenceFormMpFaceDetected from '../dispatch/occurrenceForm/OccurrenceFormMpFaceDetected';
import InvolvedVehicles from '../dispatch/occurrenceForm/InvolvedVehicles';
import InvolvedPeople from '../dispatch/occurrenceForm/InvolvedPeople';
import InvolvedObjects from '../dispatch/occurrenceForm/InvolvedObjects';
import OccurrenceFormAlarmCentral from '../dispatch/occurrenceForm/OccurrenceFormAlarmCenterDetected';
import ExportPDFOccurrenceDetails from './ExportPDFOccurrenceDetails';
import OccurrenceFormGuardianApp from '../dispatch/occurrenceForm/OccurrenceFormGuardianAppDetected';
import CustomButton from '../CustomButton';
import { useAuth } from '../../context/Auth';
import OccurrenceFormNotAllowedFaceDetected from '../dispatch/occurrenceForm/notAllowedFaceDetected';
import ConflictUpdateModal from '../ConflictUpdateModal';
import OccurrenceFormSigrc from '../dispatch/occurrenceForm/OccurrenceSigrc';
import OccurrenceFormCortexPlateDetected from '../dispatch/occurrenceForm/OccurrenceCortexPlateDetected';
import SilenceCameraModal from '../../pages/cameras/Cameras/SilenceCameraModal';
import OccurrenceTypeDropdown from '../dispatch/OccurrenceTypeDropdown';
import FormInput from '../formInput';
import OccurrenceTagDropdown from '../dispatch/OccurrenceTagDropdown';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import EventOperatorModal from './EventOperatorModal';
import PriorityDropdown from '../dispatch/PriorityDropdown';
import OccurrenceHistoricList from './OccurrenceHistoricList';
import { WindowInformation } from '../../services/window-information';
import { useIsFocused } from '@react-navigation/native';

interface OccurrenceDetailParams {
    navigation: Navigation;
    occurrenceId?: string;
    page: 'dispatch' | 'events';
}

export function OccurrenceDetail({ navigation, occurrenceId, page }: OccurrenceDetailParams) {
    const { styles, theme } = useStyles(styleSheet);
    const { user: authenticatedUser } = useAuth();
    const windowInfo = WindowInformation();
    const [isContentLoading, setIsContentLoading] = useState<boolean>(true);

    const [involvedPeople, setInvolvedPeople] = useState<InvolvedPerson[]>([]);
    const [involvedObjects, setInvolvedObjects] = useState<InvolvedObject[]>([]);
    const [involvedVehicles, setInvolvedVehicles] = useState<InvolvedVehicle[]>([]);
    const [isSilenceModalVisible, setSilenceModalVisible] = useState<boolean>(false);

    const [title, setTitle] = useState('');
    const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
    const [conflictModalVisible, setConflictModalVisible] = useState(false);
    const [conflictUpdateUser, setConflictUpdateUser] = useState<string>('');
    const [isTransferModalVisible, setTransferModalVisible] = useState(false);
    const [selectedEventOperator, setSelectedEventOperator] = useState<number>(0);
    const [occurrence, setOccurrence] = useState<Occurrence>();
    const [requester, setRequester] = useState<string>(occurrence && occurrence.triggerType == null ? occurrence.requester : '');
    const [address, setAddress] = useState<string>(occurrence?.address || '');
    const [phone, setPhone] = useState<string>(occurrence && occurrence.triggerType == null ? occurrence.phone : '');
    const [occurrenceTypeId, setOccurrenceTypeId] = useState<number>(occurrence?.occurrenceTypeId || 0);
    const [cityPropertyId, setCityPropertyId] = useState<number>(occurrence?.cityPropertyId || 0);
    const [policeReport, setPoliceReport] = useState<string>(occurrence ? occurrence.policeReport || '' : '');

    const [occurrencePriorityId, setOccurrencePriority] = useState<OccurrencePriorities>(occurrence?.priority || 'low');
    const [narrative, setNarrative] = useState({ dirty: false, value: occurrence?.narrative || '' });
    const [renderFlatList, setRenderFlatList] = useState<boolean>(false);
    const [pin, setPin] = useState<LatLng>();
    const [historicList, setHistoricList] = useState<OccurrenceReport[]>([]);
    const [dispatchedUnits, setDispatchedUnits] = useState<Unit[]>([]);
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>([]);
    const [actingBodyId, setActingBodyId] = useState<number>(occurrence ? occurrence.actingBodyId : 0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [updatedAt, setUpdatedAt] = useState('');

    // finish occurrence
    const [finishCategory, setFinishCategory] = useState<number>(0);
    const [finishSubCategory, setFinishSubCategory] = useState<number>(0);
    const [isFinishModalVisible, setFinishModalVisible] = useState(false);

    function isPhoneInvalid(value: string) {
        return value == '' || value.length < 10;
    }

    function isTypeInvalid(value: number) {
        return isNaN(value) || value <= 0;
    }

    function isDefaultFormValid() {
        return requester !== '' && !isPhoneInvalid(phone);
    }

    function isFormValid() {
        const condition = narrative.value !== '' && occurrencePriorityId !== null && selectedTagsIds.length > 0 && !isTypeInvalid(occurrenceTypeId || 0);

        if (occurrence?.triggerType) {
            return condition;
        }
        if (occurrence && occurrence.id && dispatchedUnits.length > 0 && (dispatchedUnits as (Unit & { isStarter: boolean; })[]).findIndex(unit => unit.isStarter) == -1) {
            return false;
        }

        return condition && isDefaultFormValid();
    }

    async function getOccurrence(occurrenceId: string) {
        try {
            setIsContentLoading(true);
            const occurrence = await dispatchService.getOccurrence(Number(occurrenceId));
            setOccurrence(occurrence);
            setHistoricList(occurrence.occurrenceReports || []);
            setRenderFlatList(!renderFlatList);
            setInvolvedVehicles(occurrence.involvedVehicles);
            setInvolvedPeople(occurrence.involvedPeople);
            setInvolvedObjects(occurrence.involvedObjects);
            setActingBodyId(occurrence.actingBodyId);
            setUpdatedAt(occurrence.updatedAt);

            if (occurrence.tags?.length) {
                setSelectedTagsIds(occurrence.tags.map(tag => tag.id || 0));
            }

            if (occurrence.triggerType == 'face_detected') {
                setTitle(translate('faceDetectedAlert'));
            } else if (occurrence.triggerType == 'missing_face_detected') {
                setTitle(translate('missingFaceDetectedAlert'));
            } else if (occurrence.triggerType == 'mp_face_detected') {
                setTitle(translate('mpFaceDetectedAlert'));
            } else if (['scenechangedetection', 'defocus'].includes(occurrence.triggerType || '')) {
                setTitle(translate('cameraDepredationSuspect'));
            } else if (occurrence.triggerType == 'plate_detected') {
                setTitle(translate('plateDetectedAlert'));
            } else if (occurrence.triggerType == 'clone_suspect') {
                setTitle(translate('cloneSuspectAlert'));
            } else if (occurrence.triggerType == 'mp_plate_detected') {
                setTitle(translate('mpPlateDetectedAlert'));
            } else if (occurrence.triggerType == 'irregular_vehicle') {
                setTitle(translate('irregularVehicleAlert'));
            } else if (occurrence.triggerType == 'area_invasion') {
                setTitle(translate('areaInvasionAlert'));
            } else if (occurrence.triggerType == 'alarm_center') {
                setTitle(translate('alarmCenterAlert'));
            } else if (occurrence.triggerType == 'guardian_app') {
                setTitle(translate('guardianAppAlert'));
            } else if (occurrence.triggerType == 'not_allowed_face_detected') {
                setTitle(translate('notAllowedFaceDetectedAlert'));
            } else if (occurrence.triggerType == 'sigrc') {
                setTitle(translate('sigrcAlert'));
            } else if (occurrence.triggerType == 'cortex_plate_detected') {
                setTitle(translate('cortexPlateDetectedAlert'));
            } else if (occurrence.triggerType == null && occurrence.occurrenceType?.name) {
                setTitle(occurrence.occurrenceType.name);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsContentLoading(false);
        }
    }

    const isFocused = useIsFocused();
    useEffect(() => {
        if (isFocused) {
            if (occurrenceId) {
                getOccurrence(occurrenceId);
            } else {
                setTitle(translate('newOccurrence'));
            }
        }
    }, [occurrenceId, isFocused]);

    function navigateBack() {
        if (navigation.canGoBack()) {
            return navigation.goBack();
        } else if (page === 'events') {
            return navigation.navigate('Events', { screen: 'EventsBoard' });
        }
        return navigation.navigate('Dispatch', { screen: 'Occurrences' });
    }

    function castToInt(value: string | number): number {
        if (typeof value === 'number') {
            return value;
        }
        if (value) {
            value = value.replace(',', '.');
        }
        return Number(value);
    }

    function getPageContent() {
        if (occurrence?.latitude && occurrence?.longitude) {
            setPin({ lat: castToInt(occurrence.latitude), lng: castToInt(occurrence.longitude) });
            setAddress(occurrence.address || '');
        }
        if (occurrence?.id) {
            setRequester(occurrence.triggerType == null ? occurrence.requester : '');
            setPhone(occurrence.triggerType == null ? occurrence.phone : '');
            setOccurrenceTypeId(occurrence.occurrenceTypeId);
            setCityPropertyId(occurrence.cityPropertyId || 0);
            setPoliceReport(occurrence.policeReport || '');
            setOccurrencePriority(occurrence.priority || 'low');
            setNarrative({ dirty: false, value: occurrence?.narrative || '' });
        } else {
            setIsContentLoading(false);
        }
    }

    useEffect(() => {
        if (!authenticatedUser) {
            return;
        }
        getPageContent();

    }, [occurrence, authenticatedUser]);

    async function finishEvent() {
        try {
            if (occurrence?.id) {
                await dispatchService.finishOccurrence(occurrence.id, finishCategory, finishSubCategory);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                setFinishCategory(0);
                setFinishSubCategory(0);
                navigateBack();
            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function reopenEvent() {
        try {
            if (occurrence?.id) {
                await dispatchService.reopenOccurrence(occurrence.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                if (page == 'events') {
                    return navigation.navigate('Events', { screen: 'EventsOccurrenceDetail', params: { occurrenceId, page } });
                }
                return navigation.navigate('DispatchOccurrenceDetail', { occurrenceId, page });
            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function formSubmit() {
        try {
            if (!isFormValid() || isLoading) {
                return;
            }

            if (!occurrencePriorityId) {
                return;
            }
            setIsLoading(true);

            if (occurrence?.id && occurrenceId) {
                const updateOccurrence = await dispatchService.updateOccurrence({
                    id: occurrence.id,
                    requester: requester,
                    phone: phone,
                    narrative: narrative?.value,
                    occurrenceTypeId: occurrenceTypeId,
                    priority: occurrencePriorityId,
                    latitude: pin?.lat,
                    longitude: pin?.lng,
                    unitsToSet: (dispatchedUnits as (Unit & { isStarter: boolean; displaced: boolean; displaceReasonId?: number; })[]).map(unit => {
                        return { id: unit.id, isStarter: unit.isStarter, displaced: unit.displaced, displaceReasonId: unit.displaceReasonId };
                    }),
                    tags: selectedTagsIds,
                    involvedVehicles: involvedVehicles.map(involvedVehicle => involvedVehicle.id && involvedVehicle.id < 0 ? { ...involvedVehicle, id: undefined } : involvedVehicle),
                    involvedPeople: involvedPeople.map(involvedPerson => involvedPerson.id && involvedPerson.id < 0 ? { ...involvedPerson, id: undefined } : involvedPerson),
                    involvedObjects: involvedObjects.map(involvedObject => involvedObject.id && involvedObject.id < 0 ? { ...involvedObject, id: undefined } : involvedObject),
                    updatedAt: updatedAt,
                    cityPropertyId: cityPropertyId || null,
                    policeReport,
                });

                getOccurrence(updateOccurrence.id!.toString());

                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                if (page == 'events') {
                    return navigation.navigate('Events', { screen: 'EventsOccurrenceDetail', params: { occurrenceId, page } });
                }
                return navigation.navigate('DispatchOccurrenceDetail', { occurrenceId, page });
            }

            if (!pin || !occurrenceTypeId) {
                return;
            }

            const createdOccurrence = await dispatchService.createOccurrence({
                requester: requester,
                phone: phone,
                narrative: narrative?.value,
                occurrenceTypeId: occurrenceTypeId,
                priority: occurrencePriorityId,
                latitude: pin.lat,
                longitude: pin.lng,
                tags: selectedTagsIds,
                involvedVehicles: involvedVehicles.map(involvedVehicle => involvedVehicle.id && involvedVehicle.id < 0 ? { ...involvedVehicle, id: undefined } : involvedVehicle),
                involvedPeople: involvedPeople.map(involvedPerson => involvedPerson.id && involvedPerson.id < 0 ? { ...involvedPerson, id: undefined } : involvedPerson),
                involvedObjects: involvedObjects.map(involvedObject => involvedObject.id && involvedObject.id < 0 ? { ...involvedObject, id: undefined } : involvedObject),
                actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
                cityPropertyId: cityPropertyId || null,
                policeReport,
            });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            return navigation.navigate('DispatchOccurrenceDetail', { occurrenceId: createdOccurrence.id, page });
        } catch (err) {
            if (err instanceof RecordNotLatestValues) {
                setConflictModalVisible(true);
                setConflictUpdateUser(err.userName);
                return;
            } else if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (<View style={styles.container}>
        <View style={styles.mainHeader}>
            <View style={{
                alignItems: 'center',
                flexDirection: 'row',
                columnGap: 10,
                flexGrow: 1,
            }}>
                <TouchableOpacity onPress={navigateBack} >
                    <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={theme.colors.iconColor} />
                </TouchableOpacity>
                <View style={{
                    flexDirection: windowInfo.isMobile ? 'column' : 'row',
                    columnGap: 10,
                }}>
                    <MyAppText style={[{
                        fontSize: 24,
                        color: theme.colors.iconColor,
                        fontWeight: 'bold'
                    }, windowInfo.isMobile ? { width: 255, } : { maxWidth: 500, }]}>
                        {title}
                    </MyAppText>
                    {occurrence?.id && !isContentLoading ?
                        <MyAppText style={{
                            fontSize: 20,
                            color: theme.colors.labelColor,
                            fontWeight: 'bold',
                        }}>
                            ({translate('nProtocol')}: {occurrence?.id})
                        </MyAppText>
                        : undefined
                    }
                </View>
            </View>
            {
                (authenticatedUser?.permissions.silence_camera_events || authenticatedUser?.isAdmin)
                && occurrence
                && occurrence.triggerType == 'area_invasion'
                && occurrence.situation !== 'concluded'
                && !isContentLoading &&
                <CustomButton
                    text={translate('silence')}
                    onPress={() => setSilenceModalVisible(true)}
                    disabled={(occurrence.externalReference?.cameraSilencePeriod && moment(occurrence.externalReference.cameraSilencePeriod.silencedUntil).isAfter(moment())) || false}
                    type='secondary' />
            }
            {occurrence && !isContentLoading &&
                <ExportPDFOccurrenceDetails occurrence={occurrence} />
            }
            {(occurrence?.id && !occurrence.finishedAt && !isContentLoading && (
                authenticatedUser?.id == occurrence.responsibleUserId
                || authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.transfer_occurrence
            ))
                ? <CustomButton
                    onPress={() => setTransferModalVisible(true)}
                    text={translate('transfer')}
                    type='secondary'
                />
                : null
            }
            {occurrence?.id && !occurrence.finishedAt && !isContentLoading ?
                <CustomButton onPress={() => setFinishModalVisible(true)} text={translate('finish')} />
                : null}
            {(
                occurrence?.id
                && occurrence.finishedAt
                && (authenticatedUser?.isAdmin || authenticatedUser?.permissions.reopen_occurrence))
                && !isContentLoading ?
                <CustomButton onPress={() => setConfirmModalVisible(true)} text={translate('reopen')} /> : null}
            {(!occurrence || occurrence.situation != 'concluded') && !isContentLoading
                ? <CustomButton
                    onPress={formSubmit}
                    text={translate('save')}
                    disabled={!isFormValid()}
                    isLoading={isLoading}
                    style={{ minWidth: 105 }}
                />
                : null
            }
        </View>
        <ScrollView contentContainerStyle={{
            flex: 1,
            flexDirection: 'row',
            gap: 16,
            flexWrap: 'wrap',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            {isContentLoading ?
                <ActivityIndicator size='large' color={theme.colors.dataTable.loading} /> : <>
                    <ScrollView style={[styles.leftArea, !windowInfo.isMobile ? { height: '100%' } : undefined]} contentContainerStyle={{ rowGap: 10 }}>
                        {(occurrence?.id && (
                            occurrence.triggerType == 'face_detected' ||
                            occurrence.triggerType == 'missing_face_detected'
                        ) && occurrence.externalReference) ?
                            <OccurrenceFormFaceDetected navigation={navigation}
                                event={occurrence.externalReference}
                                triggerType={occurrence.triggerType}
                                imageUrl={occurrence.resume.imageUrl}
                                occurrenceId={occurrence.id} />
                            : null}
                        {(occurrence?.id && occurrence.triggerType == 'scenechangedetection') ?
                            <SceneChangeDetection navigation={navigation} occurrence={occurrence} />
                            : null}
                        {(occurrence?.id && occurrence.triggerType == 'defocus') ?
                            <SceneChangeDetection navigation={navigation} occurrence={occurrence} />
                            : null}
                        {(occurrence?.id &&
                            (occurrence.triggerType == 'plate_detected'
                                || occurrence.triggerType == 'irregular_vehicle'
                                || occurrence.triggerType == 'clone_suspect')
                            && occurrence.externalReference) ?
                            <OccurrenceFormLprDetected
                                navigation={navigation}
                                event={occurrence.externalReference}
                                authenticatedUser={authenticatedUser}
                                setEvent={(lprDetection) => {
                                    occurrence.externalReference = lprDetection;
                                    setOccurrence({ ...occurrence });
                                }} />
                            : null}
                        {(occurrence?.id && occurrence.triggerType == 'area_invasion' && occurrence.externalReference) ?
                            <OccurrenceAreaInvasion navigation={navigation} occurrence={occurrence} />
                            : null}
                        {(occurrence?.id && occurrence.triggerType == 'mp_plate_detected') ?
                            <OccurrenceFormMpPlateDetected resume={occurrence.resume} />
                            : null}
                        {(occurrence?.id && occurrence.triggerType == 'mp_face_detected') ?
                            <OccurrenceFormMpFaceDetected resume={occurrence.resume} />
                            : null}
                        {(occurrence?.id && occurrence.triggerType == 'alarm_center' && occurrence.externalReference) ?
                            <OccurrenceFormAlarmCentral occurrence={occurrence} />
                            : null}
                        {(occurrence?.id && occurrence.triggerType == 'guardian_app' && occurrence.externalReference) ?
                            <OccurrenceFormGuardianApp occurrence={occurrence} />
                            : null}
                        {(occurrence?.id && occurrence.triggerType == 'not_allowed_face_detected' && occurrence.externalReference) ?
                            <OccurrenceFormNotAllowedFaceDetected
                                navigation={navigation}
                                occurrence={occurrence}
                                event={occurrence.externalReference}
                            />
                            : null}
                        {(occurrence?.id && occurrence.triggerType == 'sigrc') ?
                            <OccurrenceFormSigrc occurrence={occurrence} />
                            : null}
                        {(occurrence?.id && occurrence.triggerType == 'cortex_plate_detected') ?
                            <OccurrenceFormCortexPlateDetected occurrence={occurrence} />
                            : null}
                        {(!occurrence?.triggerType) ?
                            <View style={{ zIndex: 4 }}>
                                <OccurrenceFormDefault
                                    occurrenceId={occurrence?.id}
                                    setPin={setPin}
                                    phone={phone}
                                    currentAddress={address}
                                    setCurrentAddress={setAddress}
                                    requester={requester}
                                    occurrenceTypeId={occurrenceTypeId}
                                    setRequester={setRequester}
                                    setPhone={setPhone}
                                    setOccurrenceTypeId={setOccurrenceTypeId}
                                    setActingBodyId={setActingBodyId}
                                    actingBodyId={actingBodyId}
                                    authenticatedUser={authenticatedUser}
                                    cityPropertyId={cityPropertyId}
                                    setCityPropertyId={setCityPropertyId}
                                />
                            </View>
                            : <OccurrenceTypeDropdown
                                value={occurrenceTypeId}
                                setValue={setOccurrenceTypeId}
                                zIndex={4}
                                labelFontSize={14}
                                label={translate('type')}
                                actingBodyId={occurrence.actingBodyId}
                            />
                        }
                        <PriorityDropdown
                            value={occurrencePriorityId}
                            setValue={setOccurrencePriority}
                            zIndex={3} />
                        <OccurrenceTagDropdown
                            zIndex={2}
                            actingBodyId={actingBodyId}
                            setValue={setSelectedTagsIds}
                            value={selectedTagsIds}
                            labelFontSize={14}
                        />
                        {occurrence?.id ?
                            <FormInput
                                label={translate('policeReport')}
                                placeholder={translate('policeReport')}
                                value={policeReport}
                                labelFontSize={14}
                                onChangeText={setPoliceReport}
                            /> : null}
                        <FormInput
                            labelFontSize={14}
                            label={translate('narrative')}
                            placeholder={translate('narrative')}
                            value={narrative.value}
                            onChangeText={(value) => {
                                setNarrative({ dirty: true, value });
                            }}
                            invalid={(value: string) => {
                                return value == '';
                            }}
                            multiline
                            numberOfLines={6}
                            maxLength={16777216}
                        />
                    </ScrollView>
                    <View style={[windowInfo.isMobile ? {
                        width: '100%',
                    } : {
                        flex: 3,
                        height: '100%',
                    }]}>
                        {occurrence?.id ?
                            <Tabs tabs={[{
                                key: 'dispatch',
                                label: translate('dispatch'),
                                icon: faMapMarkedAlt
                            }, {
                                key: 'involvedVehicles',
                                label: translate('involvedVehicles'),
                                icon: faCar
                            },
                            {
                                key: 'involvedPeople',
                                label: translate('involvedPeople'),
                                icon: faUser
                            },
                            {
                                key: 'involvedObjects',
                                label: translate('involvedObjects'),
                                icon: faCube
                            }, {
                                key: 'historic',
                                label: translate('historic'),
                                icon: faBookOpen
                            }]}>
                                {({ selectedTab }) => {
                                    if (!occurrence?.id) {
                                        return <></>;
                                    }
                                    if (selectedTab == 'dispatch') {
                                        return <OccurrenceDetailsDispatchTab
                                            occurrence={occurrence}
                                            pin={pin}
                                            dispatchedUnits={dispatchedUnits}
                                            setDispatchedUnits={setDispatchedUnits} />;
                                    } else if (selectedTab == 'historic') {
                                        return occurrence && occurrence.id ? <OccurrenceHistoricList
                                            occurrenceSituation={occurrence.situation}
                                            renderFlatList={renderFlatList}
                                            historicList={historicList}
                                            setHistoricList={setHistoricList}
                                            occurrenceId={occurrence.id} /> : <></>;
                                    } else if (selectedTab === 'involvedVehicles') {
                                        return <InvolvedVehicles
                                            involvedVehicles={involvedVehicles}
                                            setInvolvedVehicles={setInvolvedVehicles}
                                            actingBodyId={occurrence.actingBodyId}
                                            occurrenceSituation={occurrence.situation} />;
                                    } else if (selectedTab === 'involvedPeople') {
                                        return <InvolvedPeople
                                            involvedPeople={involvedPeople}
                                            setInvolvedPeople={setInvolvedPeople}
                                            actingBodyId={occurrence.actingBodyId}
                                            occurrenceSituation={occurrence.situation} />;
                                    } else if (selectedTab === 'involvedObjects') {
                                        return <InvolvedObjects
                                            involvedObjects={involvedObjects}
                                            setInvolvedObjects={setInvolvedObjects}
                                            actingBodyId={occurrence.actingBodyId}
                                            occurrenceSituation={occurrence.situation} />;
                                    }
                                }}
                            </Tabs>
                            : <View style={styles.card(windowInfo.isMobile)}>
                                <Map
                                    dynamicMarker={pin}
                                    markerChanged={setPin}
                                />
                            </View>
                        }
                    </View>
                </>}
        </ScrollView>
        <FinishReasonModal
            setModalVisible={setFinishModalVisible}
            isModalVisible={isFinishModalVisible}
            finishCategory={finishCategory}
            finishSubCategory={finishSubCategory}
            setFinishCategory={setFinishCategory}
            setFinishSubCategory={setFinishSubCategory}
            actingBodyId={actingBodyId}
            onSubmit={async () => {
                if (!occurrence?.finishedAt) {
                    await finishEvent();
                }
            }}
        />
        <ConflictUpdateModal
            setModalVisible={setConflictModalVisible}
            isModalVisible={conflictModalVisible}
            onSubmit={() => {
                window.open(`/dispatch/occurrences/detail/${occurrenceId}`);
            }}
            userName={conflictUpdateUser}
            onCancel={() => setConflictUpdateUser('')}
            customConfirmText='openNewTab'
        />
        <DeleteModal
            setModalVisible={setConfirmModalVisible}
            isModalVisible={isConfirmModalVisible}
            customMessage={occurrence?.finishedAt ? translate('confirmReopen') : translate('confirm_finish')}
            customConfirm={occurrence?.finishedAt ? translate('reopen') : translate('finish')}
            itemName={String(occurrence?.id) || ''}
            onSubmit={async () => {
                if (occurrence?.finishedAt) {
                    await reopenEvent();
                } else {
                    await finishEvent();
                }
            }}
        />
        {occurrence && occurrence.id &&
            <EventOperatorModal
                isModalVisible={isTransferModalVisible}
                setIsModalVisible={setTransferModalVisible}
                onClose={() => {
                    setSelectedEventOperator(0);
                    setTransferModalVisible(false);
                }}
                setHistoricList={setHistoricList}
                historicList={historicList}
                setValue={setSelectedEventOperator}
                value={selectedEventOperator}
                actingBodyId={occurrence.actingBodyId}
                occurrenceId={occurrence.id}
            />}
        <SilenceCameraModal
            isModalVisible={isSilenceModalVisible}
            setModalVisible={setSilenceModalVisible}
            cameraId={occurrence?.triggerType == 'area_invasion' && occurrence.externalReferenceId ? occurrence.externalReferenceId : ''}
            onConfirm={() => navigation.navigate('DispatchOccurrenceDetail', { occurrenceId: occurrence?.id ?? '' })} />
    </View>);
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        gap: 5
    },
    card: (isMobile: boolean) => ({
        backgroundColor: theme.colors.cardBackground,
        borderWidth: 1,
        borderColor: theme.colors.cardBorder,
        borderRadius: 4,
        paddingVertical: 10,
        paddingHorizontal: 20,
        flex: !isMobile ? 1 : undefined,
        height: isMobile ? 200 : undefined
    }),
    leftArea: {
        borderWidth: 1,
        paddingHorizontal: 15,
        paddingVertical: 5,
        borderColor: theme.colors.cardBorder,
        overflowY: 'scroll',
        backgroundColor: theme.colors.cardBackground,
        borderRadius: 4,
        flex: 1,
    },
    mainHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        flexWrap: 'wrap',
        minHeight: 65
    },
}));
