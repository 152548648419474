import React, { useEffect, useState } from 'react';
import moment from 'moment';
import MyDataTable from '../../../components/MyDataTable';
import { translate } from '../../../services/translate';
import { Log } from '../../../services/central-api/audit-logs';
import { dispatchService } from '../../../services/central-api/dispatch';
import { View, TouchableOpacity } from 'react-native';
import MyAppText from '../../../components/MyAppText';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface UnitFormHistoricProps {
    unitId?: number;
}

export default function UnitFormHistoric({ unitId }: UnitFormHistoricProps) {
    const { styles } = useStyles(stylesheet);

    const [logs, setLogs] = useState<Log[]>([]);
    const [loadingHistoric, setLoadingHistoric] = useState<boolean>();


    useEffect(() => {
        if (unitId) {
            loadHistoric(unitId);
        }
    }, [unitId]);

    async function loadHistoric(unitId: number) {
        try {
            setLoadingHistoric(true);

            // This endpoint takes way more time to load, should be the last
            const logs = await dispatchService.getUnitHistoric(unitId);
            setLogs(logs);
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingHistoric(false);
        }
    }

    function renderHistoricContent(log: Log) {
        if (!log.detail) {
            return <></>;
        }
        return <View style={{ paddingVertical: 10, paddingHorizontal: 30 }}>
            {Object.keys(log.detail).map((key, index) => {
                if (log.detail) {
                    if (key == 'occurrenceId') {
                        const occurrenceId = log.detail[key];
                        return <TouchableOpacity key={index} onPress={() => window.open(`/dispatch/occurrences/detail/${occurrenceId}`)}>
                            <MyAppText style={[styles.cardContent, { textDecorationLine: 'underline' }]}>
                                {translate(key)}: {occurrenceId}
                            </MyAppText>
                        </TouchableOpacity>;
                    } else if (['definitiveStart', 'definitiveFinish'].includes(key)) {
                        return <MyAppText key={index} style={styles.cardContent}>{translate(key)}: {moment(log.detail[key] as string).format('DD/MM/YYYY HH:mm')}</MyAppText>;
                    } else {
                        return <MyAppText key={index} style={styles.cardContent}>{translate(key)}: {log.detail[key]}</MyAppText>;
                    }
                }
            })}
        </View>;
    }

    return <MyDataTable
        columns={[{
            name: translate('date'),
            selector: row => moment(row.date).format('DD/MM/YYYY HH:mm'),
            maxWidth: '170px',
            wrap: true
        },
        {
            name: translate('user'),
            selector: row => row.userName || '',
            wrap: true
        },
        {
            name: translate('ipAddress'),
            selector: row => row.ip || '',
            wrap: true
        },
        {
            name: translate('action'),
            selector: row => translate(row.action),
            grow: 1,
            wrap: true
        }]}
        data={logs}
        expandableRowsComponent={({ data }) => renderHistoricContent(data)}
        expandableRows
        progressPending={loadingHistoric}
    />;
}

const stylesheet = createStyleSheet(theme => ({

    cardContent: {
        fontSize: 14,
        color: theme.colors.labelColor,
    },
}));