import React, { useEffect, useRef, useState } from 'react';
import {
    View,
    TouchableOpacity,
    ScrollView,
    ActivityIndicator,
    Image,
    NativeSyntheticEvent,
    TextInputKeyPressEventData,
} from 'react-native';
import moment from 'moment';
import { faFileUpload, faPlus } from '@fortawesome/free-solid-svg-icons';
import personDefaultPicture from '../../../../assets/person-default-picture.png';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import { Pagination } from '../../../components/Pagination';
import { facialService, GetFacialPeopleParameters } from '../../../services/central-api/facial';
import { RouteProp, useRoute } from '@react-navigation/native';
import { FacialParamList } from '../../../typings/Params';
import CustomButton from '../../../components/CustomButton';
import PeopleFilter from './Filters';
import FilterInput from '../../../components/Filter/components/FilterInput';
import FileUploader from '../../../components/FileUploader';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import useFilters from '../../../components/Filter/hooks/useFilters';

interface PeopleListParams {
    navigation: Navigation;
}

export default function PeopleList({ navigation }: PeopleListParams) {
    const { styles, theme } = useStyles(stylesheet);
    const loaders = useRef({ didPageLoad: false, didFilterNameLoad: false, didFileLoad: false });

    const route = useRoute<RouteProp<FacialParamList, 'PeopleList'>>();

    const [loading, setLoading] = useState(false);
    const [people, setPeople] = useState<FacialPerson[]>([]);

    const [filters, setFilters] = useFilters<FacialParamList['PeopleList'] & { photo?: File; }>({
        ...route.params,
        page: Math.max(route.params?.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });
    const [filterName, setFilterName] = useState(filters.nameOrDocument ?? '');


    const [totalItems, setTotalItems] = useState(0);
    const [showPagination, setShowPagination] = useState(true);

    const [file, setFile] = useState<File | undefined>(filters.photo);

    useEffect(() => {
        if (!loaders.current.didFilterNameLoad) {
            loaders.current.didFilterNameLoad = true;
            return;
        }
        if (filterName == '' && file) {
            return;
        }
    }, [filterName, filters.onlyActive]);

    useEffect(() => {
        getPeople(filters);
    }, [filters]);

    useEffect(() => {
        if (!loaders.current.didFileLoad) {
            loaders.current.didFileLoad = true;
            return;
        }
        if (file === undefined && filterName) {
            return;
        }
        setFilterName('');
        setFilters({ ...filters, nameOrDocument: '', photo: file, page: 0, onlyActive: filters.onlyActive });
    }, [file, filters.onlyActive]);


    async function getPeople(filters: GetFacialPeopleParameters & { photo?: File; }) {
        try {
            setLoading(true);

            if (filters.photo) {
                const response = await facialService.findPeopleByPicture({
                    photo: filters.photo,
                    onlyActive: filters.onlyActive ?? true
                });
                setPeople(response.people);
                setShowPagination(false);
            } else {
                const response = await facialService.getFacialPeople(filters);
                setPeople(response.rows);
                setTotalItems(response.count);
                setShowPagination(true);
            }
        } catch (err) {
            // TODO: add proper error treatment
            console.error(err);
        } finally {
            setLoading(false);
        }

    }

    function handleFilters() {
        setFilters({
            ...filters,
            nameOrDocument: filterName,
            page: 0
        });

        navigation.setParams({
            nameOrDocument: filterName,
            page: 1
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            handleFilters();
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 300, minWidth: 200 }}>
                    <FilterInput
                        label={translate('searchNameDocument')}
                        value={filterName}
                        onChange={setFilterName}
                        placeholder={translate('typeToSearch')}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={{ minWidth: 200 }}>
                    <MyAppText style={styles.filterText}>{translate('searchByPicture')}</MyAppText>
                    <FileUploader
                        accept='image/*'
                        text={translate('chooseFile')}
                        icon={faFileUpload}
                        type='secondary'
                        onChange={(e) => {
                            if (!e.target.files) {
                                return;
                            }
                            const file = e.target.files[0];
                            setFile(file);
                        }}
                    />
                </View>
                <View style={{ flex: 1 }} />
                <View style={styles.buttonsContainer}>
                    <PeopleFilter
                        filters={filters}
                        setFilters={setFilters}
                        textFilter={filterName}
                        setTextFilter={setFilterName}
                    />
                    <CustomButton onPress={() => navigation.navigate('PersonForm')} icon={faPlus} text={translate('newRegister')} />
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <View style={[styles.cardList, { borderRadius: !showPagination ? 8 : undefined }]}>
                    <ScrollView>
                        <View style={{ gap: 10 }}>
                            {loading ?
                                <ActivityIndicator size='small' color={theme.colors.loadingColor} />
                                : <View style={[{
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    columnGap: 14
                                }]}>
                                    {people.map((person) =>
                                        <TouchableOpacity
                                            key={JSON.stringify(person)}
                                            onPress={async () => {
                                                navigation.navigate('PersonForm', { id: person.id });
                                            }}
                                        >
                                            <View style={[{
                                                width: 150,
                                                height: 250,
                                                borderRadius: 5,
                                                rowGap: 10
                                            }]}>
                                                <Image
                                                    style={{
                                                        height: 168, width: 144,
                                                        borderRadius: 8,
                                                        overflow: 'hidden',
                                                    }}
                                                    source={person.facePictures.length ? { uri: person.facePictures[0].imageUrl } : personDefaultPicture}
                                                />
                                                <View>
                                                    <MyAppText style={{ color: theme.colors.textColor, fontWeight: '500', fontSize: 14 }}>
                                                        {person.name}
                                                    </MyAppText>
                                                    <MyAppText style={{ color: theme.colors.textColor, fontWeight: '500', fontSize: 9 }}>
                                                        {translate('addedOn')} {moment(person.createdAt ? new Date(person.createdAt) : undefined).format('DD/MM/YYYY HH:mm')}
                                                    </MyAppText>
                                                    <MyAppText style={{ color: theme.colors.textColor, fontWeight: '500', fontSize: 11 }}>
                                                        {person.isActive ? translate('active') : translate('inactive')}
                                                    </MyAppText>
                                                </View>
                                            </View>
                                        </TouchableOpacity>
                                    )}
                                </View>
                            }
                        </View>
                    </ScrollView>
                </View>
                {showPagination
                    ? <Pagination
                        totalItems={totalItems}
                        currentPage={filters.page}
                        pageSize={filters.limit}
                        setPageSize={pageSize => {
                            setFilters(old => ({
                                ...old,
                                limit: pageSize,
                                page: 0,
                            }));

                            navigation.setParams({
                                ...route.params,
                                limit: pageSize,
                                page: 0,
                            });
                        }}
                        setPage={page => {
                            setFilters(old => ({
                                ...old,
                                page
                            }));

                            navigation.setParams({
                                ...route.params,
                                page: page + 1
                            });
                        }}
                    />
                    : null
                }
            </View>
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        padding: 10,
        flex: 1,
    },
    filterText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    }
}));
