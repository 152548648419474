import { RouteProp, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { CRMParamList } from '../../../typings/Params';
import { crmService } from '../../../services/central-api/crm';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { PaginatedResource } from '../../../typings/Paginated';
import MyDataTable from '../../../components/MyDataTable';
import { Pagination } from '../../../components/Pagination';
import { translate } from '../../../services/translate';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../../../components/MyAppText';
import CameraHistoryModal from './CameraHistoryModal';
import { useAuth } from '../../../context/Auth';

interface ManagementDetailsParameters {
    navigation: Navigation;
}

function CameraHistory({ navigation }: ManagementDetailsParameters) {
    const route = useRoute<RouteProp<CRMParamList, 'CameraHistory'>>();

    const { styles, theme } = useStyles(stylesheet);

    const { user: authenticatedUser } = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<PaginatedResource<CameraHistoryPage>>({
        count: 0,
        rows: []
    });

    const [filters, setFilters] = useState<{ page: number; textFilter?: string, limit: number; }>({
        textFilter: '',
        page: Math.max(route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });

    const [camera, setCamera] = useState<Camera>();
    const [totalItems, setTotalItems] = useState(0);

    const [isVisible, setIsVisible] = useState(false);
    const [modalData, setModalData] = useState<number>();

    const getCameraHistory = async () => {
        try {
            setIsLoading(true);

            const data = await crmService.getCameraHistoryPaginated(route.params.id, filters);

            setTotalItems(data.count);

            const camera = await crmService.getCamera(route.params.id);

            setCamera(camera);
            setData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCameraHistory();
    }, []);

    return (
        <>
            <View style={styles.header}>
                <TouchableOpacity onPress={() => navigation.canGoBack() ? navigation.goBack() : window.location.href = '/CRM/cameras'}>
                    <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={theme.colors.iconColor} />
                </TouchableOpacity>

                <MyAppText style={{ fontSize: 24, fontWeight: 'bold' }}>
                    {translate('camera')}
                </MyAppText>
            </View>

            {!isLoading &&
                <View style={{ marginVertical: 10 }}>
                    <MyAppText style={{ fontSize: 18 }}>
                        {translate('historic')} {camera?.title}
                    </MyAppText>
                </View>
            }

            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        progressPending={isLoading}
                        columns={[
                            {
                                name: 'ID',
                                selector: row => row.id,
                                grow: 0
                            },
                            {
                                name: translate('beginIn'),
                                selector: row => `${moment(row.start).format('DD/MM/YYYY HH:mm:ss')}`,
                                wrap: true
                            },
                            {
                                name: translate('endIn'),
                                selector: row => row.finish ? `${moment(row.finish).format('DD/MM/YYYY HH:mm:ss')}` : '',
                                wrap: true
                            },
                            {
                                name: translate('reason'),
                                selector: row => row.reason ? translate(row.reason) : '',
                                wrap: true
                            },

                            {
                                name: translate('actions'),
                                cell: row => (
                                    <>
                                        {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_watch_video_analysis) ?
                                            <TouchableOpacity
                                                onPress={() => {
                                                    setIsVisible(!isVisible);
                                                    setModalData(row.id);
                                                }}
                                                style={{ flexBasis: '5%', alignItems: 'flex-start', justifyContent: 'center' }}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                            </TouchableOpacity> : undefined}
                                    </>
                                ),
                                wrap: true,
                                button: true
                            }
                        ]}
                        data={data.rows}
                    />
                </View>

                <Pagination
                    totalItems={totalItems}
                    currentPage={filters.page}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                />
            </View>

            <CameraHistoryModal
                historyId={modalData}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                onSubmit={() => {
                    getCameraHistory();
                }}
            />
        </>
    );
}

const stylesheet = createStyleSheet(theme => ({
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },

    header: {
        alignItems: 'center',
        flexDirection: 'row',
        minWidth: 200,
        columnGap: 10
    }
}));

export default CameraHistory;
