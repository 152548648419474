import React from 'react';
import LateralMenu from '../LateralMenu';
import { faClipboardList, faIdBadge, faSearch, faTag, faTriangleExclamation, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_facial.png';
import collapsedLogo from '../../../assets/icon_facial.png';

export default function FacialLateralMenu({ navigation, open, authenticatedUser, setOpen, selectedMenu }: LateralMenuProps) {

    const items = [];

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.facial_module_access) {
        items.push({
            icon: faClipboardList,
            text: translate('liveEvents'),
            action: () => navigation.navigate('Facial', { screen: 'LiveEvents' }),
            selected: selectedMenu == 'live-events'
        }, {
            icon: faTriangleExclamation,
            text: translate('liveAlerts'),
            action: () => navigation.navigate('Facial', { screen: 'LiveAlerts' }),
            selected: selectedMenu == 'live-alerts'
        }, {
            icon: faSearch,
            text: translate('search'),
            action: () => navigation.navigate('Facial', { screen: 'Search' }),
            selected: selectedMenu == 'search'
        });
    }

    if (
        authenticatedUser?.isAdmin
        || authenticatedUser?.permissions.facial_module_access
        || authenticatedUser?.permissions.manage_allow_list_person
        || authenticatedUser?.permissions.manage_missing_person
        || authenticatedUser?.permissions.manage_wanted_person
    ) {
        items.push({
            icon: faIdBadge,
            text: translate('register'),
            action: () => navigation.navigate('Facial', { screen: 'PeopleList' }),
            selected: selectedMenu == 'people'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.facial_module_access) {
        items.push({
            icon: faTag,
            text: translate('tags'),
            action: () => navigation.navigate('Facial', { screen: 'Markers' }),
            selected: selectedMenu == 'markers'
        });
    }

    if (
        authenticatedUser?.isAdmin
        || authenticatedUser?.permissions.facial_module_access
        || authenticatedUser?.permissions.manage_allow_list_person
    ) {
        items.push({
            icon: faUserGroup,
            text: translate('allowListGroup'),
            action: () => navigation.navigate('Facial', { screen: 'AllowListGroup' }),
            selected: selectedMenu == 'allow-list-group'
        });
    }

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            authenticatedUser={authenticatedUser}
            setOpen={setOpen}
            items={items}
        />
    );
}
