import React, { useState, useEffect } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { translate } from '../../../services/translate';
import Map from '../../../components/Map';
import MyAppText from '../../../components/MyAppText';
import { dispatchService } from '../../../services/central-api/dispatch';
import { MyCheckbox } from '../../../components/MyCheckbox';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MapPolygonDropdown from '../../../components/MapPolygonDropdown';
import { useIsFocused } from '@react-navigation/native';


export default function Resume() {
    const { styles, theme } = useStyles(styleSheet);
    const [agentPositions, setAgentPositions] = useState<UnitMapPosition[]>([]);
    const [occurrencePositions, setOccurrencePositions] = useState<OccurrencePosition[]>([]);
    const [showMapPoi, setShowMapPoi] = useState<boolean>(false);
    const [showOccurrences, setShowOccurrences] = useState<boolean>(true);
    const [showAgents, setShowAgents] = useState<boolean>(true);
    const [statisticIsLoading, setStatisticIsLoading] = useState<boolean>(true);

    const [polygons, setPolygons] = useState<MapPolygon[]>([]);

    const [statistics, setStatistics] = useState<Statistic>({
        teams2Wheels: 0,
        teams4Wheels: 0,
        teamsOnFoot: 0,
        allActiveTeams: 0,
        otherTeams: 0,
        pendingOccurrences: 0,
        inServiceOccurrences: 0,
        onGoingOccurrences: 0,
        concludedOccurrences: 0,
    });

    async function getOccurrencePositions() {
        try {
            const occurrencePositions = await dispatchService.getOccurrencePositions();
            setOccurrencePositions(occurrencePositions);
        } catch (err) {
            console.error(err);
        }
    }

    async function getAgentPositions() {
        try {
            const agentPositions = await dispatchService.getAgentPositions();
            setAgentPositions(agentPositions);
        } catch (err) {
            console.error(err);
        }
    }

    async function getStatistics() {
        try {
            setStatisticIsLoading(true);
            const statistics = await dispatchService.getStatistics();
            setStatistics(statistics);
        } catch (err) {
            console.error(err);
        } finally {
            setStatisticIsLoading(false);
        }
    }

    const isFocused = useIsFocused();
    useEffect(() => {
        if (isFocused) {
            getStatistics();
            getOccurrencePositions();
            getAgentPositions();
        }

        const interval = setInterval(() => {
        // idk why but this effect is being called even when accessing others pages
        // this is a guarantee that the interval will work only when the page is active
            if (!window.location.href.endsWith('dispatch/resume')) {
                return;
            }
            getOccurrencePositions();
            getAgentPositions();
        }, 30000);

        return () => clearInterval(interval);
    }, [isFocused]);

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                {!statisticIsLoading ? <>
                    <View style={styles.statisticsContainer}>
                        <MyAppText style={styles.statisticsHeader}>{translate('teamsResume')}</MyAppText>
                        <View style={styles.statisticsList}>
                            <View style={styles.statistic}>
                                <MyAppText style={styles.statisticNumber}>{statistics.allActiveTeams}</MyAppText>
                                <MyAppText style={styles.statisticLabel}>{translate('actives')}</MyAppText>
                            </View>
                            <View style={styles.statistic}>
                                <MyAppText style={styles.statisticNumber}>{statistics.teams2Wheels}</MyAppText>
                                <MyAppText style={styles.statisticLabel}>{translate('2WheelsTeams')}</MyAppText>
                            </View>
                            <View style={styles.statistic}>
                                <MyAppText style={styles.statisticNumber}>{statistics.teams4Wheels}</MyAppText>
                                <MyAppText style={styles.statisticLabel}>{translate('4WheelsTeams')}</MyAppText>
                            </View>
                            <View style={styles.statistic}>
                                <MyAppText style={styles.statisticNumber}>{statistics.teamsOnFoot}</MyAppText>
                                <MyAppText style={styles.statisticLabel}>{translate('OnFootTeams')}</MyAppText>
                            </View>
                            <View style={styles.statistic}>
                                <MyAppText style={styles.statisticNumber}>{statistics.otherTeams}</MyAppText>
                                <MyAppText style={styles.statisticLabel}>{translate('otherTeams')}</MyAppText>
                            </View>
                        </View>
                    </View>
                    <View style={styles.statisticsContainer}>
                        <MyAppText style={styles.statisticsHeader}>{translate('occurrences_resume')}</MyAppText>
                        <View style={styles.statisticsList}>
                            <View style={styles.statistic}>
                                <MyAppText style={styles.statisticNumber}>{statistics.pendingOccurrences}</MyAppText>
                                <MyAppText style={styles.statisticLabel}>{translate('pending')}</MyAppText>
                            </View>
                            <View style={styles.statistic}>
                                <MyAppText style={styles.statisticNumber}>{statistics.inServiceOccurrences}</MyAppText>
                                <MyAppText style={styles.statisticLabel}>{translate('in_service')}</MyAppText>
                            </View>
                            <View style={styles.statistic}>
                                <MyAppText style={styles.statisticNumber}>{statistics.onGoingOccurrences}</MyAppText>
                                <MyAppText style={styles.statisticLabel}>{translate('ongoing')}</MyAppText>
                            </View>
                            <View style={styles.statistic}>
                                <MyAppText style={styles.statisticNumber}>{statistics.concludedOccurrences}</MyAppText>
                                <MyAppText style={styles.statisticLabel}>{translate('concluded')} ({translate('last12Hours')})</MyAppText>
                            </View>
                        </View>
                    </View></> : <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size='large' color={theme.colors.loadingColor} /></View>}
            </View>
            <View style={styles.card}>
                <View style={styles.mapTopBar}>
                    <View style={styles.filters}>
                        <View>
                            <MyCheckbox
                                style={styles.checkbox}
                                label={translate('occurrences')}
                                checked={showOccurrences}
                                setChecked={setShowOccurrences}
                            />
                        </View>
                        <View>
                            <MyCheckbox
                                style={styles.checkbox}
                                label={translate('agents')}
                                checked={showAgents}
                                setChecked={setShowAgents}
                            />
                        </View>
                    </View>
                </View>
                <View style={styles.mapBottomBar}>
                    <View style={styles.filters}>
                        <MyCheckbox
                            style={styles.checkbox}
                            label={translate('pointsOfInterest')}
                            checked={showMapPoi}
                            setChecked={setShowMapPoi}
                        />
                    </View>
                </View>
                <View style={styles.overlaySelector}>
                    <MapPolygonDropdown
                        setPolygons={setPolygons}
                    />
                </View>
                <Map
                    showMapPoi={showMapPoi}
                    occurrencePositions={showOccurrences ? occurrencePositions : []}
                    agentPositions={showAgents ? agentPositions : []}
                    polygons={polygons}
                />
            </View>
        </View >
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 5
    },
    header: {
        flexDirection: 'column',
        minHeight: 150
    },
    statisticsContainer: {
        flexDirection: 'column',
        flex: 1,
    },
    statistic: {
        flex: 1,
    },
    statisticsHeader: {
        color: theme.colors.primaryButton.background,
        fontSize: 16
    },
    statisticsList: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    statisticNumber: {
        color: theme.colors.primaryButton.background,
        fontSize: 28
    },
    statisticLabel: {
        color: theme.colors.cardDetailText,
        fontSize: 12,
    },
    card: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        padding: 10,
        backgroundColor: theme.colors.container.background,
        flex: 1
    },
    mapTopBar: {
        position: 'absolute',
        justifyContent: 'space-between',
        flexDirection: 'row',
        zIndex: 1000,
        top: 10,
        right: 10,
        paddingLeft: 30,
        paddingHorizontal: 10,
        paddingVertical: 8,
        flexWrap: 'wrap',
        gap: 10,
        display: 'flex',
        width: '100%',
    },
    mapBottomBar: {
        position: 'absolute',
        flexDirection: 'row',
        zIndex: 1000,
        bottom: 10,
        left: 260,
        paddingHorizontal: 10,
        paddingVertical: 8,
        flexWrap: 'wrap',
        gap: 10,
        display: 'flex',
    },
    overlaySelector: {
        position: 'absolute',
        zIndex: 1000,
        bottom: 10,
        left: 10,
        width: 260,
        paddingHorizontal: 10,
        paddingVertical: 8,
    },
    checkbox: {
        height: 38
    },
    filters: {
        flexDirection: 'row',
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.placeholder,
        maxWidth: 290,
        paddingHorizontal: 8,
        columnGap: 8,
        height: 40,
    },
}));
