import React, { useEffect, useState } from 'react';
import { dispatchService } from '../../services/central-api/dispatch';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';
import { useAuth } from '../../context/Auth';

interface Props {
    value: number[];
    setValue: React.Dispatch<React.SetStateAction<number[]>>;
    actingBodyId: number;
    zIndex: number;
}

function UnitNamesDropdown({ value, setValue, actingBodyId, zIndex }: Props) {
    const [selectableUnitNames, setSelectableUnitNames] = useState<{ label: string, value: number; }[]>([]);
    const { user: authenticatedUser } = useAuth();

    async function getUnitNames() {
        const actingBody = getActingBodyId();
        if (actingBody === 0) {
            setSelectableUnitNames([]);
            return;
        }
        try {
            const unitNames = await dispatchService.getUnitNames(actingBodyId);
            setSelectableUnitNames([
                ...unitNames.map((role) => {
                    return { value: role.id, label: role.name };
                })
            ]);

        } catch (err) {
            console.error(err);
        }
    }

    function getActingBodyId() {
        if (!authenticatedUser || !authenticatedUser.actingBodyUnit.actingBodyId) {
            return 0;
        }

        if (authenticatedUser.isAdmin) {
            return actingBodyId;
        } else {
            return authenticatedUser.actingBodyUnit.actingBodyId;
        }
    }

    useEffect(() => {
        if (!authenticatedUser) {
            return;
        }
        getUnitNames();
    }, [actingBodyId, authenticatedUser]);

    return (
        <FilterDropdown
            label={translate('teams')}
            items={selectableUnitNames}
            multiple={true}
            mode='BADGE'
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default UnitNamesDropdown;
