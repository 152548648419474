import React, { useState } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { WindowInformation } from '../../../services/window-information';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import Toast from 'react-native-toast-message';
import moment from 'moment';
import MyAppText from '../../../components/MyAppText';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import { ScrollView } from 'react-native-web-hover';
import FilterInputNumeric from '../../../components/Filter/components/FilterInputNumeric';
import CamerasDropdown from '../../../components/lpr/CamerasDropdown';
import BrandsDropdown from '../../../components/lpr/BrandsDropdown';
import ModelsDropdown from '../../../components/lpr/ModelDropdown';
import ColorsDropdown from '../../../components/lpr/ColorDropdown';
import MyRadio from '../../../components/MyRadio';
import DateTimePicker, { CalendarValue } from '../../../components/DateTimePicker';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
}

const regex = /^[a-zA-Z0-9 +\-./:=_ãõẽíáàâêóôúüçÃÕẼÍÁÀÂÊÓÔÚÜÇ]*$/;
const regPlate = new RegExp(/[A-Z]{3}[-]?[0-9][0-9A-Z][0-9]{2}/);

export default function ReportModal({ onClose, isModalVisible, setModalVisible }: ModalProps) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState<string>('');
    const [plate, setPlate] = useState<string>('');
    const [brand, setBrand] = useState<string>('');
    const [model, setModel] = useState<string>('');
    const [color, setColor] = useState<string>('');
    const [year, setYear] = useState<number | null>(null);
    const [startInDateFilter, setStartInDateFilter] = useState<CalendarValue>(null);
    const [startInHourFilter, setStartInHourFilter] = useState<string>('');
    const [endInDateFilter, setEndInDateFilter] = useState<CalendarValue>(null);
    const [endInHourFilter, setEndInHourFilter] = useState<string>('');

    const [selectedCameraFilter, setSelectedCameraFilter] = useState<string[]>([]);
    const [searchType, setSearchType] = useState<'plate' | 'characteristic'>('plate');

    const [estimatedStartZIndex, setEstimatedStartZIndex] = useState<number>(2);
    const [estimatedFinishZIndex, setEstimatedFinishZIndex] = useState<number>(1);


    function isNameInvalid(value: string) {
        return value == '' || !regex.test(value);
    }

    function isPlateInvalid(value: string) {
        return value == '' || value.length > 7 || !regPlate.test(value);
    }

    function isFormValid() {
        if (isNameInvalid(name)) {
            return false;
        } else if (!startInDateFilter || !startInHourFilter || !endInDateFilter || !endInHourFilter) {
            return false;
        } else if (moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf() >= moment(`${endInDateFilter} ${endInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf()) {
            return false;
        } else if (selectedCameraFilter.length == 0 && isPlateInvalid(plate) && searchType == 'plate') {
            return false;
        } else if (year !== null && (year > moment().year() || year < 1900)) {
            return false;
        }

        return true;
    }

    function onCloseNewReportModal() {
        setModalVisible(false);
        resetReportForm();
    }

    async function formSubmit() {
        try {
            await licensePlateRecognitionService.createReport({
                name: name,
                startDate: moment(`${moment(startInDateFilter).format('DD/MM/YYYY')} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm').toLocaleString(),
                endDate: moment(`${moment(endInDateFilter).format('DD/MM/YYYY')} ${endInHourFilter}`, 'DD/MM/YYYY HH:mm').toLocaleString(),
                plate: plate,
                brand: brand !== '' ? brand : undefined,
                model: model !== '' ? model : undefined,
                color: color !== '' ? color : undefined,
                year: year !== null ? year : undefined,
                camerasIds: selectedCameraFilter
            });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (error) {
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            onCloseNewReportModal();
            onClose();
        }
    }

    function resetReportForm() {
        setName('');
        setSelectedCameraFilter([]);
        setPlate('');
        setBrand('');
        setModel('');
        setColor('');
        setYear(null);
        setStartInDateFilter(null);
        setStartInHourFilter('');
        setEndInDateFilter(null);
        setEndInHourFilter('');
        setSearchType('plate');
    }

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => onCloseNewReportModal()} >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: 400 }]}>
                    <ScrollView contentContainerStyle={styles.scrollContent}>
                        <MyAppText style={{ fontSize: 22, fontWeight: 'bold', color: theme.colors.labelColor }}>{translate('detectionsReport')}</MyAppText>
                        <FormInput
                            label={translate('name')}
                            value={name}
                            onChangeText={setName}
                            invalid={isNameInvalid}
                            errorMessage={translate('invalidCharacters')}
                        />
                        <MyRadio
                            selected={searchType}
                            setSelected={setSearchType}
                            options={[
                                { label: translate('plate'), value: 'plate' },
                                { label: translate('characteristic'), value: 'characteristic' },
                            ]}
                        />
                        {searchType == 'plate' ?
                            <FormInput label={translate('plate')}
                                invalid={() => false}
                                value={plate}
                                onChangeText={(value) => {
                                    setPlate(value.toUpperCase());
                                }}
                            /> :
                            <>
                                <BrandsDropdown value={brand} setValue={setBrand} zIndex={6} />
                                <ModelsDropdown value={model} setValue={setModel} zIndex={5} loadValues={brand !== ''} />
                                <ColorsDropdown value={color} setValue={setColor} zIndex={4} loadValues={model !== ''} />
                                <FilterInputNumeric
                                    label={translate('year')}
                                    value={year}
                                    onChange={setYear}
                                    placeholder={translate('typeAValue')}
                                    maxValue={moment().year()}
                                    minValue={1900}
                                    disabled={color == ''}
                                />
                            </>
                        }
                        <CamerasDropdown value={selectedCameraFilter} setValue={setSelectedCameraFilter} zIndex={3} />

                        <View style={{ zIndex: estimatedStartZIndex }}>
                            <DateTimePicker
                                date={startInDateFilter}
                                onChangeDate={setStartInDateFilter}
                                time={startInHourFilter}
                                onChangeTime={setStartInHourFilter}
                                label={translate('beginIn')}
                                maxDate={new Date()}
                                onCalendarOpen={() => setEstimatedStartZIndex(99999)}
                                onCalendarClose={() => setEstimatedStartZIndex(2)}
                                labelFontSize={16}
                            />
                        </View>
                        <View style={{ zIndex: estimatedFinishZIndex }}>
                            <DateTimePicker
                                date={endInDateFilter}
                                onChangeDate={setEndInDateFilter}
                                time={endInHourFilter}
                                onChangeTime={setEndInHourFilter}
                                label={translate('endIn')}
                                maxDate={new Date()}
                                onCalendarOpen={() => setEstimatedFinishZIndex(99999)}
                                onCalendarClose={() => setEstimatedFinishZIndex(1)}
                                labelFontSize={16}
                            />
                        </View>

                    </ScrollView>
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => onCloseNewReportModal()}
                        disabled={!isFormValid()}
                        confirmText={'download'} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        minHeight: 150,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        rowGap: 15,
        maxHeight: 690,
        padding: 20
    },
    scrollContent: {
        rowGap: 15,
        padding: 5,
    }
}));
