import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, View, TouchableOpacity, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import { faChevronDown, faChevronUp, faLocationDot, faSearchPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Hoverable } from 'react-native-web-hover';
import { WindowInformation } from '../../../services/window-information';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

export default function OccurrenceFormCortexPlateDetected({ occurrence }: { occurrence: Occurrence; }) {
    const { styles, theme } = useStyles(stylesheet);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const windowInfo = WindowInformation();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    useEffect(() => {
        if (!occurrence.details || !occurrence.details.imageUrl) {
            return;
        }

        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const image = new Image();

        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 4;

            const rect = {
                minX: Infinity,
                maxX: 0,
                minY: Infinity,
                maxY: 0
            };

            if (occurrence.details && occurrence.details.platePosition) {
                for (const position of occurrence.details.platePosition) {
                    if (position.x < rect.minX) {
                        rect.minX = position.x;
                    }

                    if (position.x > rect.maxX) {
                        rect.maxX = position.x;
                    }

                    if (position.y < rect.minY) {
                        rect.minY = position.y;
                    }

                    if (position.y > rect.maxY) {
                        rect.maxY = position.y;
                    }
                }

                ctx.beginPath();
                ctx.moveTo(rect.minX, rect.minY);
                ctx.lineTo(rect.minX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.minY);
                ctx.closePath();
                ctx.stroke();
            }

        };

        image.src = occurrence.details.imageUrl;
    });

    if (!occurrence.resume || occurrence.triggerType !== 'cortex_plate_detected') {
        return <></>;
    }

    function keyValue(key: string, value: string | boolean | number, isDate?: boolean) {
        if (value == '') {
            return;
        } else if (typeof value == 'boolean') {
            return <MyAppText style={styles.detectionInfoText}><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {translate(value ? 'yes' : 'no')}</MyAppText>;
        } else if (isDate) {
            return <MyAppText style={styles.detectionInfoText}><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {moment(value).format('DD/MM/YYYY')}</MyAppText>;
        }
        return <MyAppText style={styles.detectionInfoText}><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {value}</MyAppText>;
    }

    return (
        <ScrollView contentContainerStyle={{ rowGap: 10 }}>
            <View>
                <MyAppText style={styles.plateText}>{translate('plate')}</MyAppText>
                <MyAppText style={styles.plateInfoText}>{occurrence.resume.placa}</MyAppText>
            </View>
            {occurrence.details?.imageUrl ? <Hoverable>
                {({ hovered }) => (<>
                    <View style={{ flexDirection: 'column' }} >
                        <MyAppText style={styles.imageText}>
                            {translate('detection')}
                        </MyAppText>
                        <View style={{ alignItems: 'center' }}>
                            <canvas style={{ width: '100%' }} ref={canvasRef} />
                        </View>
                    </View>
                    {hovered &&
                        <View style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#00000080',
                        }}>
                            <TouchableOpacity
                                style={{
                                    flex: 1,
                                    justifyContent: 'center'
                                }}
                                onPress={async () => {
                                    setIsModalVisible(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faSearchPlus} fontSize={28} color={'#FFFFFF'} />
                            </TouchableOpacity>
                        </View>
                    }
                </>)}
            </Hoverable> : <></>}

            <View style={styles.generalInformation}>
                <TouchableOpacity
                    style={styles.generalInformationDropdown}
                    onPress={() => setShowDetails(!showDetails)}>
                    <MyAppText>{translate('general_info')}</MyAppText>
                    <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} color={theme.colors.iconColor} />
                </TouchableOpacity>
                <View style={styles.tableLine}></View>
            </View>

            {showDetails ?
                <>
                    <View style={{ rowGap: 5 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }}>
                            <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 22 }} color={theme.colors.iconColor} />
                            <MyAppText style={styles.detectionInfoText}>
                                {moment(occurrence.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }}>
                            <FontAwesomeIcon icon={faLocationDot} style={{ width: 22 }} color={theme.colors.iconColor} />
                            <MyAppText style={styles.detectionInfoText}>
                                {occurrence.resume.local}
                            </MyAppText>
                        </View>
                    </View>
                    <View style={{ rowGap: 5 }}>
                        <>{keyValue('carMetadataRestrictionOccurrenceSystem', occurrence.resume.SistemaOcorrencia)}</>
                        <>{keyValue('carMetadataRestrictionPoliceReportYear', occurrence.resume.anobo)}</>
                        <>{keyValue('carMetadataManufacturingYear', occurrence.resume.anofabricacao)}</>
                        <>{keyValue('chassis', occurrence.resume.chassi)}</>
                        <>{keyValue('color', occurrence.resume.cor)}</>
                        <>{keyValue('carMetadataVehicleUpdateDateTime', occurrence.resume.datahora, true)}</>
                        <>{keyValue('createdAt', occurrence.resume.datainclusaosistema, true)}</>
                        <>{keyValue('carMetadataRestrictionReportDate', occurrence.resume.dataocorrencia, true)}</>
                        <>{keyValue('carMetadataMakeAndModel', occurrence.resume.marcamodelo)}</>
                        <>{keyValue('carMetadataRestrictionPoliceReportCity', occurrence.resume.municipiobo)}</>
                        <>{keyValue('carMetadataPlateMunicipality', occurrence.resume.municipioplaca)}</>
                        <>{keyValue('carMetadataRestrictionReportNature', occurrence.resume.naturezaocorrencia)}</>
                        <>{keyValue('carMetadataRestrictionDeclarantName', occurrence.resume.nomedeclarante)}</>
                        <>{keyValue('carMetadataRestrictionPoliceReportNumber', occurrence.resume.numerobo)}</>
                        <>{keyValue('origination', occurrence.resume.procedencia)}</>
                        <>{keyValue('carMetadataRestrictionPhoneExtension', occurrence.resume.ramalcontato)}</>
                        <>{keyValue('carMetadataRestrictionSystem', occurrence.resume.sistemaRestricao)}</>
                        <>{keyValue('phone', occurrence.resume.telefonecontato)}</>
                        <>{keyValue('carMetadataRestrictionPoliceReportState', occurrence.resume.ufbo)}</>
                        <>{keyValue('carMetadataStatePlate', occurrence.resume.ufplaca)}</>
                        <>{keyValue('carMetadataRestrictionPoliceUnit', occurrence.resume.unidadepolicial)}</>
                        <>{keyValue('historic', occurrence.resume.HistoricoOcorrencia)}</>
                    </View>
                </> : <></>}

            {/* Zoom image modal */}
            <Modal
                animationType='fade'
                transparent={true}
                visible={isModalVisible}
                onRequestClose={() => setIsModalVisible(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredView}>
                        <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : { width: '60%' }]}>
                            <View style={{ alignItems: 'flex-end' }}>
                                <TouchableOpacity
                                    style={{ width: 20, height: 20 }}
                                    onPress={() => setIsModalVisible(false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ width: 20 }} color={theme.colors.iconColor} />
                                </TouchableOpacity>
                            </View>

                            <TransformWrapper>
                                <TransformComponent>
                                    <canvas style={{ width: '100%' }} ref={canvasRef} />
                                </TransformComponent>
                            </TransformWrapper>
                        </View>
                    </View>
                </View>
            </Modal>
        </ScrollView >
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        padding: 16,
        backgroundColor: theme.colors.container.background,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        borderWidth: 1,
    },
    imageText: {
        color: theme.colors.cardDetailText,
        paddingBottom: 5,
        fontSize: 11,
    },
    detectionInfoText: {
        color: theme.colors.labelColor,
        fontSize: 12
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalBody: {
        backgroundColor: theme.colors.container.background,
        flexDirection: 'column',
        borderRadius: 8,
        rowGap: 20,
        padding: 30,
    },
    plateText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 14
    },
    plateInfoText: {
        color: theme.colors.textColor,
        fontWeight: 'bold',
        fontSize: 24
    },
    tableLine: {
        borderBottomColor: theme.colors.cardBorder,
        borderBottomWidth: 1,
        flex: 1
    },
    generalInformation: {
        flexDirection: 'column',
        paddingHorizontal: 5,
        flex: 1,
        gap: 10
    },
    generalInformationDropdown: {
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1
    }
}));
