import React, { useEffect, useState } from 'react';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';
import { gcmAgentService } from '../../services/central-api/gcm-agent';

interface Props {
    value: number;
    setValue: (value: number, item: DropdownResource | undefined) => void;
    zIndex?: number;
    disabled?: boolean;
    labelFontSize?: number;
    label?: string;
}

export default function PersonRelationDropdown({ value, setValue, zIndex, disabled, labelFontSize, label }: Props) {
    const [items, setItems] = useState<{ label: string, value: number; }[]>([]);

    async function getPersonRelations() {
        try {
            const response = await gcmAgentService.getSimplifiedPersonRelations();
            setItems(response.map(unitProgram => ({
                label: unitProgram.name, value: unitProgram.id!
            })));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getPersonRelations();
    }, []);

    return (
        <FilterDropdown
            label={label ?? translate('relation')}
            items={items}
            multiple={false}
            setValue={(v) => {
                const itemId = v(-1);
                const item = items.find(i => i.value == itemId);
                setValue(itemId, item ? {
                    id: item.value,
                    name: item.label
                } : undefined);
            }}
            value={value}
            zIndex={zIndex}
            disabled={disabled}
            labelFontSize={labelFontSize}
        />
    );
}