import React, { useEffect, useState } from 'react';
import { View, Modal, ActivityIndicator } from 'react-native';
import { WindowInformation } from '../../../services/window-information';
import FormActions from '../../formActions';
import { dispatchService } from '../../../services/central-api/dispatch';
import MyRadio from '../../MyRadio';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface FinishReasonModalParameters {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onSubmit: () => void;
    finishCategory: number;
    finishSubCategory: number;
    setFinishCategory: (value: number) => void;
    setFinishSubCategory: (value: number) => void;
    actingBodyId: number;
}
export default function FinishReasonModal({
    isModalVisible,
    setModalVisible,
    onSubmit,
    finishCategory,
    finishSubCategory,
    setFinishCategory,
    setFinishSubCategory,
    actingBodyId
}: FinishReasonModalParameters) {
    const { styles, theme } = useStyles(stylesheet);
    const windowInfo = WindowInformation();

    const [finishCategories, setFinishCategories] = useState<FinishCategoryBase[]>([]);
    const [loading, setIsLoading] = useState(false);

    async function loadPageInfo() {
        try {
            if (actingBodyId !== 0) {
                setIsLoading(true);
                const finishCategories = await dispatchService.getSimplifiedFinishReasons(actingBodyId);
                setFinishCategories(finishCategories);
            }
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        if (isModalVisible) {
            loadPageInfo();
        }
    }, [isModalVisible, actingBodyId]);

    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                setModalVisible(false);
                setFinishCategory(0);
                setFinishSubCategory(0);
            }}
            transparent={true}
        >
            <View style={styles.modalContainer}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : {}]}>
                        {loading ?
                            <View style={{ height: 40 }}>
                                <ActivityIndicator size='small' color={theme.colors.loadingColor} />
                            </View>
                            : finishCategories.length ?
                                <View>
                                    <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('finishReason')}</MyAppText>
                                    <View style={styles.content(windowInfo.isMobile)}>
                                        <MyRadio
                                            style={styles.customRadio}
                                            selected={String(finishCategory)}
                                            setSelected={(value) => {
                                                setFinishCategory(value);
                                                setFinishSubCategory(0);
                                            }}
                                            options={finishCategories.map((finishCategory) => {
                                                return {
                                                    label: finishCategory.name,
                                                    value: finishCategory.id
                                                };
                                            })}
                                        />
                                        {finishCategory && (finishCategories.map(fg => fg.id)).includes(finishCategory) ?
                                            <View style={{ rowGap: 10, flex: 1 }}>
                                                <MyRadio
                                                    style={styles.customRadio}
                                                    selected={String(finishSubCategory)}
                                                    setSelected={(value) => {
                                                        setFinishSubCategory(value);
                                                    }}
                                                    options={(finishCategories.find(finishCat => finishCat.id == finishCategory) as FinishCategory).finishSubCategories
                                                        .map((finishSubCategory) => {
                                                            return {
                                                                label: finishSubCategory.name,
                                                                value: finishSubCategory.id
                                                            };
                                                        })}
                                                />
                                            </View> : null}
                                    </View>
                                </View> :
                                <View>
                                    <MyAppText>{translate('NonFinishCategoryMessage')}</MyAppText>
                                </View>
                        }

                        <View style={[styles.row, { justifyContent: 'flex-end' }]}>
                            <FormActions
                                onSubmit={() => {
                                    onSubmit();
                                    setModalVisible(false);
                                }}
                                onClose={() => {
                                    setModalVisible(false);
                                    setFinishCategory(0);
                                    setFinishSubCategory(0);
                                }}
                                disabled={!(finishCategory && finishSubCategory)} />
                        </View>
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        padding: 16,
        backgroundColor: theme.colors.container.background,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        borderWidth: 1,
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalBody: {
        backgroundColor: theme.colors.container.background,
        flexDirection: 'column',
        borderRadius: 8,
        rowGap: 20,
        padding: 30,
    },
    row: {
        flexDirection: 'column',
        columnGap: 30,
    },
    content: (isMobile: boolean) => ({
        flexDirection: isMobile ? 'column' : 'row',
        gap: 30,
        flex: 1
    }),
    customRadio: {
        flexDirection: 'column',
        rowGap: 10,
    },
}));
