import React, { useState } from 'react';
import Filters from '../../../components/Filter';
import { AccessParamList } from '../../../typings/Params';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import ActingBodiesDropdown from '../../../components/Dropdowns/ActingBodiesDropdown';
import { useAuth } from '../../../context/Auth';
import ActingBodiesUnitsDropdown from '../../dispatch/Units/ActingBodiesUnitsDropdown';
import GenericUserDropdown from '../../../components/Dropdowns/GenericUserDropdown';
import CameraDropdown from '../../../components/access/CameraDropdown';

interface Props {
    filters: AccessParamList['Groups'];
    setFilters: React.Dispatch<React.SetStateAction<AccessParamList['Groups']>>;
    textFilter: string;
    setTextFilter: React.Dispatch<React.SetStateAction<string>>;
}

function Filter({ filters, setFilters, setTextFilter, textFilter }: Props) {
    const { user: authenticatedUser } = useAuth();

    const [actingBodyId, setActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [actingBodyUnitIds, setActingBodyUnitIds] = useState<number[]>(filters.actingBodyUnitIds ?? []);
    const [userId, setUserId] = useState<number>(filters.userId ?? 0);
    const [selectedCameraId, setSelectedCameraId] = useState<string>(filters.cameraId ?? '');

    const navigation = useNavigation<NavigationProp<AccessParamList, 'Groups'>>();

    const handleCleanFilter = () => {
        setTextFilter('');
        setActingBodyUnitIds([]);
        setActingBodyId(0);
        setUserId(0);
        setSelectedCameraId('');
    };

    const handleFilter = () => {
        setFilters({
            ...filters,
            textFilter,
            actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
            actingBodyUnitIds: actingBodyUnitIds.length ? actingBodyUnitIds : undefined,
            userId: userId !== 0 ? userId : undefined,
            cameraId: selectedCameraId !== '' ? selectedCameraId : undefined
        });

        navigation.setParams({
            actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
            actingBodyUnitIds: actingBodyUnitIds.length ? actingBodyUnitIds : undefined,
            textFilter,
            userId: userId !== 0 ? userId : undefined,
            page: 1,
            cameraId: selectedCameraId !== '' ? selectedCameraId : undefined
        });
    };

    function getActingBodyId() {
        if (!authenticatedUser || !authenticatedUser.actingBodyUnit.actingBodyId) {
            return 0;
        } else if (authenticatedUser.isAdmin) {
            return actingBodyId;
        } else {
            return authenticatedUser.actingBodyUnit.actingBodyId;
        }
    }

    const customSetActingBodyId: React.Dispatch<React.SetStateAction<number>> = (value) => {
        setActingBodyId(value);
        setActingBodyUnitIds([]);
        setUserId(0);
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('typeToSearch')}
                value={textFilter}
                placeholder={translate('typeToSearch')}
                onChange={setTextFilter}
            />
            {authenticatedUser?.isAdmin &&
                (
                    <>
                        <ActingBodiesDropdown
                            value={actingBodyId}
                            setValue={customSetActingBodyId}
                            zIndex={5} />
                        <ActingBodiesUnitsDropdown
                            actingBodyId={actingBodyId}
                            value={actingBodyUnitIds}
                            setValue={setActingBodyUnitIds}
                            zIndex={4} />
                    </>
                )
            }
            <GenericUserDropdown
                value={userId}
                setValue={setUserId}
                actingBodyId={getActingBodyId()}
                zIndex={3}
                label='user' />
            <CameraDropdown setValue={setSelectedCameraId} value={selectedCameraId} zIndex={2} />

        </Filters>
    );
}

export default Filter;
