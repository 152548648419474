import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from '../../../services/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons';
import Toast from 'react-native-toast-message';
import DeleteModal from '../../../components/DeleteModal';
import { dispatchService } from '../../../services/central-api/dispatch';
import { Pagination } from '../../../components/Pagination';
import { ClientError } from '../../../services/central-api/base-service';
import MyDataTable from '../../../components/MyDataTable';
import { RouteProp, useIsFocused, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import useFilters from '../../../components/Filter/hooks/useFilters';
import FilterInput from '../../../components/Filter/components/FilterInput';
import Filter from './Filters';
import CustomButton from '../../../components/CustomButton';
import { useStyles, createStyleSheet } from 'react-native-unistyles';
import EquipmentsModal from './EquipmentsModal';
import { useAuth } from '../../../context/Auth';

export default function Equipments({ navigation }: { navigation: Navigation; }) {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<DispatchParamList, 'Equipments'>>();
    const { user: authenticatedUser } = useAuth();

    const [filters, setFilters] = useFilters<DispatchParamList['Equipments']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });
    const [totalItems, setTotalItems] = useState(0);
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');

    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedEquipment, setSelectedEquipment] = useState<Equipment>();
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [equipments, setEquipments] = useState<Equipment[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    async function getEquipments(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await dispatchService.getEquipments(filters);
            setEquipments(response.rows);
            setTotalItems(response.count);

        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }
    const isFocused = useIsFocused();
    useEffect(() => {
        if (isFocused) {
            getEquipments();
        }
    }, [filters, isFocused]);

    function handleEditClick(equipment: Equipment) {
        setSelectedEquipment(equipment);
        setModalVisible(true);
    }

    function showDeleteConfirmationDialog(equipment: Equipment) {
        setSelectedEquipment(equipment);
        setIsDeleteModalVisible(true);
    }

    async function confirmDelete(equipment: Equipment) {
        try {
            await dispatchService.deleteEquipment(equipment.id);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });

            setIsDeleteModalVisible(false);
            getEquipments();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function handleFormClose() {
        setSelectedEquipment(undefined);
        setModalVisible(false);
        getEquipments();
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            navigation.setParams({
                ...filters,
                textFilter
            });
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                    <FilterInput
                        label={translate('searchEquipment')}
                        value={textFilter}
                        placeholder={translate('typeToSearch')}
                        onChange={text => {
                            setTextFilter(text);
                        }}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={{ flex: 1 }}></View>
                <View style={styles.buttonsContainer}>
                    <Filter
                        authenticatedUser={authenticatedUser}
                        filters={filters}
                        setFilters={setFilters}
                        textFilter={textFilter}
                        setTextFilter={setTextFilter} />
                    <CustomButton icon={faPlus} text={translate('create')} onPress={() => {
                        setSelectedEquipment(undefined);
                        setModalVisible(true);
                    }} />
                </View>
            </View>

            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        columns={[
                            {
                                name: 'ID',
                                selector: row => row.id,
                                grow: 0
                            },
                            {
                                name: translate('name'),
                                selector: row => row.name,
                                wrap: true,
                                grow: 4
                            },
                            {
                                name: translate('actingBody'),
                                selector: row => row.actingBody.name,
                                wrap: true
                            },
                            {
                                name: translate('team'),
                                selector: row => row.unit?.unitName.name || '',
                                wrap: true,
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row =>
                                    <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                        <TouchableOpacity onPress={() => handleEditClick(row)}>
                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => showDeleteConfirmationDialog(row)}>
                                            <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                    </View>
                            }
                        ]}
                        data={equipments}
                        progressPending={isLoading}
                    />
                </View>
                <Pagination
                    currentPage={filters.page}
                    totalItems={totalItems}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                />
            </View>

            <EquipmentsModal
                isModalVisible={isModalVisible}
                authenticatedUser={authenticatedUser}
                equipment={selectedEquipment}
                onClose={handleFormClose}
            />

            <DeleteModal
                setModalVisible={setIsDeleteModalVisible}
                isModalVisible={isDeleteModalVisible}
                itemName={String(selectedEquipment?.name) || ''}
                onSubmit={async () => {
                    if (selectedEquipment) {
                        await confirmDelete(selectedEquipment);
                    }
                }}
                onCancel={() => {
                    setSelectedEquipment(undefined);
                }}
            />

        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
}));

