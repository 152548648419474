import React from 'react';
import { View } from 'react-native';
import { useStyles, createStyleSheet } from 'react-native-unistyles';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import { ScrollView } from 'react-native-web-hover';
import moment from 'moment';

interface Params {
    isLoading: boolean;
    carFromChassis: CarFromChassis[];
}

export default function ChassisContext({ isLoading, carFromChassis }: Params) {
    const { styles } = useStyles(styleSheet);

    function keyValue(key: string, value: string | boolean | number, isDate?: boolean) {
        if (value == '') {
            return;
        } else if (typeof value == 'boolean') {
            return <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {translate(value ? 'yes' : 'no')}</MyAppText>;
        } else if (isDate) {
            return <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {moment(value).format('DD/MM/YYYY')}</MyAppText>;
        }
        return <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate(key)}:</MyAppText> {value}</MyAppText>;
    }

    return (
        <ScrollView contentContainerStyle={{ gap: 5 }}>
            {!isLoading && carFromChassis && carFromChassis.map((carInfo) => (
                <View key={carInfo.id} style={styles.cardBody}>
                    <>{keyValue('carMetadataUniqueVehicleIdentifier', carInfo.identificadorUnicoVeiculo)}</>
                    <>{keyValue('plate', carInfo.placa)}</>
                    <>{keyValue('chassis', carInfo.chassi)}</>
                    <>{keyValue('carMetadataRegistrationState', carInfo.ufEmplacamento)}</>
                    <>{keyValue('carMetadataPlateMunicipality', carInfo.municipioPlaca)}</>
                    <>{keyValue('carMetadataRegistrationMunicipalityCode', carInfo.codigoMunicipioEmplacamento)}</>
                    <>{keyValue('carMetadataRenavam', carInfo.renavam)}</>
                    <>{keyValue('carMetadataVehicleJurisdictionState', carInfo.ufJurisdicaoVeiculo)}</>
                    <>{keyValue('carMetadataPreRegistrationDate', carInfo.dataPreCadastro, true)}</>
                    <>{keyValue('carMetadataRegistrationDate', carInfo.dataEmplacamento, true)}</>
                    <>{keyValue('carMetadataVehicleUpdateDate', carInfo.dataAtualizacaoVeiculo, true)}</>
                    <>{keyValue('carMetadataVehicleUpdateDateTime', carInfo.dataHoraAtualizacaoVeiculo, true)}</>
                    <>{keyValue('carMetadataInvoicedDocumentType', carInfo.tipoDocumentoFaturado)}</>
                    <>{keyValue('carMetadataInvoicedIdentificationNumber', carInfo.numeroIdentificacaoFaturado)}</>
                    <>{keyValue('carMetadataInvoiceState', carInfo.ufFatura)}</>
                    <>{keyValue('carMetadataDocumentType', carInfo.tipoDocumentoProprietario)}</>
                    <>{keyValue('carMetadataVehicleManufacturingMonth', carInfo.mesFabricacaoVeiculo)}</>
                    <>{keyValue('carMetadataInvoicedVehicleDestinationState', carInfo.ufDestinoVeiculoFaturado)}</>
                    <>{keyValue('carMetadataImporterIdentificationNumber', carInfo.numeroIdentificacaoImportador)}</>
                    <>{keyValue('carMetadataImportProcessNumber', carInfo.numeroProcessoImportacao)}</>
                    <>{keyValue('carMetadataImportDeclarationNumber', carInfo.numeroDeclaracaoImportacao)}</>
                    <>{keyValue('carMetadataImportDeclarationDate', carInfo.dataDeclaracaoImportacao, true)}</>
                    <>{keyValue('carMetadataSrfAgencyCode', carInfo.codigoOrgaoSRF)}</>
                    <>{keyValue('carMetadataTaxRegionAgencyDescription', carInfo.descricaoOrgaoRegiaoFiscal)}</>
                    <>{keyValue('carMetadataTaxRestrictionDeadlineDate', carInfo.dataLimiteRestricaoTributaria, true)}</>
                    <>{keyValue('carMetadataVehicleTransferCountry', carInfo.paisTransferenciaVeiculo)}</>
                    <>{keyValue('carMetadataCustomsRecord', carInfo.registroAduaneiro)}</>
                    <>{keyValue('carMetadataManufacturingYear', carInfo.anoFabricacao)}</>
                    <>{keyValue('carMetadataModelYear', carInfo.anoModelo)}</>
                    <>{keyValue('carMetadataMakeAndModel', carInfo.marcaModelo)}</>
                    <>{keyValue('carMetadataMakeAndModelCode', carInfo.codigoMarcaModelo)}</>
                    <>{keyValue('carMetadataVehicleGroup', carInfo.grupoVeiculo)}</>
                    <>{keyValue('carMetadataVehicleType', carInfo.tipoVeiculo)}</>
                    <>{keyValue('carMetadataVehicleTypeCode', carInfo.codigoTipoVeiculo)}</>
                    <>{keyValue('carMetadataSpecies', carInfo.especie)}</>
                    <>{keyValue('carMetadataSpeciesCode', carInfo.codigoEspecie)}</>
                    <>{keyValue('carMetadataBody', carInfo.carroceria)}</>
                    <>{keyValue('carMetadataBodyCode', carInfo.codigoCarroceira)}</>
                    <>{keyValue('carMetadataColor', carInfo.cor)}</>
                    <>{keyValue('carMetadataColorCode', carInfo.codigoCor)}</>
                    <>{keyValue('carMetadataFuel', carInfo.combustivel)}</>
                    <>{keyValue('carMetadataCategory', carInfo.categoria)}</>
                    <>{keyValue('carMetadataCategoryCode', carInfo.codigoCategoria)}</>
                    <>{keyValue('carMetadataAssemblyType', carInfo.tipoMontagem)}</>
                    <>{keyValue('carMetadataPower', carInfo.potencia)}</>
                    <>{keyValue('carMetadataCubicCapacity', carInfo.cilindrada)}</>
                    <>{keyValue('carMetadataSeatingCapacity', carInfo.lotacao)}</>
                    <>{keyValue('carMetadataMaximumLoadCapacity', carInfo.capacidadeMaximaCarga)}</>
                    <>{keyValue('carMetadataGrossVehicleWeight', carInfo.pesoBrutoTotal)}</>
                    <>{keyValue('carMetadataMaximumTowingCapacity', carInfo.capacidadeMaximaTracao)}</>
                    <>{keyValue('carMetadataNationalVehicleIndicator', carInfo.indicadorVeiculoNacional)}</>
                    <>{keyValue('carMetadataChassisRemarkingIndicator', carInfo.indicadorRemarcacaoChassi)}</>
                    <>{keyValue('carMetadataBodyNumber', carInfo.numeroCarroceria)}</>
                    <>{keyValue('carMetadataTransmissionBoxNumber', carInfo.numeroCaixaCambio)}</>
                    <>{keyValue('carMetadataNumberOfAxles', carInfo.quantidadeEixo)}</>
                    <>{keyValue('carMetadataRearAxleNumber', carInfo.numeroEixoTraseiro)}</>
                    <>{keyValue('carMetadataAuxiliaryAxleNumber', carInfo.numeroEixoAuxiliar)}</>
                    <>{keyValue('carMetadataEngineNumber', carInfo.numeroMotor)}</>
                    <>{keyValue('carMetadataEngineConfigurationUsageLicenseNumber', carInfo.numeroLicencaUsoConfiguracaoVeiculosMotor)}</>
                    <>{keyValue('carMetadataOwnerName', carInfo.nomePossuidor)}</>
                    <>{keyValue('carMetadataOwnerOrigin', carInfo.origemPossuidor)}</>
                    <>{keyValue('carMetadataProprietorName', carInfo.nomeProprietario)}</>
                    <>{keyValue('carMetadataLesseeName', carInfo.nomeArrendatario)}</>
                    <>{keyValue('carMetadataLastCRVIssuanceDate', carInfo.dataEmissaoUltimoCRV, true)}</>
                    <>{keyValue('carMetadataCrvSequenceNumber', carInfo.numeroSequenciaCRV)}</>
                    <>{keyValue('carMetadataCrvNumber', carInfo.numeroCRV)}</>
                    <>{keyValue('carMetadataCrvCopyNumber', carInfo.numeroViaCRV)}</>
                    <>{keyValue('carMetadataCrvSecurityCode', carInfo.codigoSegurancaCRV)}</>
                    <>{keyValue('carMetadataCrvlTypeNumber', carInfo.numeroTipoCRLV)}</>
                    <>{keyValue('carMetadataCrvlIssuanceDate', carInfo.dataEmissaoCRLV, true)}</>
                    <>{keyValue('carMetadataCrvlCopyNumber', carInfo.numeroViaCRLV)}</>
                    <>{keyValue('carMetadataLastLicensingYear', carInfo.anoUltimoLicenciamnento)}</>
                    <>{keyValue('carMetadataLicensingValidityMonthYear', carInfo.mesAnoValidadeLicenciamento)}</>
                    <>{keyValue('carMetadataIpvaValue', carInfo.valorIPVA)}</>
                    <>{keyValue('carMetadataVehicleRestriction1', carInfo.restricaoVeiculo1)}</>
                    <>{keyValue('carMetadataVehicleRestriction2', carInfo.restricaoVeiculo2)}</>
                    <>{keyValue('carMetadataVehicleRestriction3', carInfo.restricaoVeiculo3)}</>
                    <>{keyValue('carMetadataVehicleRestriction4', carInfo.restricaoVeiculo4)}</>
                    <>{keyValue('carMetadataVehicleStatus', carInfo.situacaoVeiculo)}</>
                    <>{keyValue('carMetadataReplicationDate', carInfo.dataReplicacao, true)}</>
                    <>{keyValue('carMetadataActiveFlag', carInfo.flagAtivo)}</>
                    <>{keyValue('carMetadataLicensedVehicleCirculationIndicator', carInfo.indicadorVeiculoLicenciadoCirculacao)}</>
                    <>{keyValue('carMetadataTheftUpdateDate', carInfo.dataAtualizacaoRouboFurto, true)}</>
                    <>{keyValue('carMetadataAlarmUpdateDate', carInfo.dataAtualizacaoAlarme, true)}</>
                    <>{keyValue('carMetadataNumberOfRegistrationRestrictions', carInfo.quantidadeRestricoesBaseEmplacamento)}</>
                    <View style={styles.cardBodySplitter}>
                        <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('carMetadataOwner')}</MyAppText>
                        <>{keyValue('plate', carInfo.proprietario.placa)}</>
                        <>{keyValue('carMetadataDocumentType', carInfo.proprietario.tipoDocumentoProprietario)}</>
                        <>{keyValue('carMetadataDocument', carInfo.proprietario.numeroDocumentoProprietario)}</>
                        <>{keyValue('carMetadataName', carInfo.proprietario.nomeProprietario)}</>
                        <>{keyValue('carMetadataAddress', carInfo.proprietario.enderecoProprietario)}</>
                    </View>
                    <View style={styles.cardBodySplitter}>
                        <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('carMetadataPossessor')}</MyAppText>
                        <>{keyValue('plate', carInfo.possuidor.placa)}</>
                        <>{keyValue('carMetadataDocumentType', carInfo.possuidor.tipoDocumentoPossuidor)}</>
                        <>{keyValue('carMetadataDocument', carInfo.possuidor.numeroDocumentoPossuidor)}</>
                        <>{keyValue('carMetadataName', carInfo.possuidor.nomePossuidor)}</>
                        <>{keyValue('carMetadataAddress', carInfo.possuidor.enderecoPossuidor)}</>
                    </View>
                    <View style={styles.cardBodySplitter}>
                        <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('carMetadataTenant')}</MyAppText>
                        <>{keyValue('plate', carInfo.arrendatario.placa)}</>
                        <>{keyValue('carMetadataDocumentType', carInfo.arrendatario.tipoDocumentoArrendatario)}</>
                        <>{keyValue('carMetadataDocument', carInfo.arrendatario.numeroDocumentoArrendatario)}</>
                        <>{keyValue('carMetadataName', carInfo.arrendatario.nomeArrendatario)}</>
                        <>{keyValue('carMetadataAddress', carInfo.arrendatario.enderecoArrendatario)}</>
                    </View>
                    {
                        carInfo.restricao.length ?
                            <View style={[styles.cardBodySplitter, styles.cardBody, styles.restriction]}>
                                <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('restriction')}</MyAppText>
                                {
                                    carInfo.restricao.map(restriction => (
                                        <View key={restriction.id} style={{ gap: 5 }}>
                                            <>{keyValue('carMetadataRestrictionReportDate', restriction.dataOcorrencia, true)}</>
                                            <>{keyValue('carMetadataRestrictionContactAreaCode', restriction.dddContato)}</>
                                            <>{keyValue('carMetadataRestrictionHistoric', restriction.historico)}</>
                                            <>{keyValue('carMetadataRestrictionPoliceReportYear', restriction.anoBO)}</>
                                            <>{keyValue('carMetadataRestrictionPoliceReportCity', restriction.municipioBO)}</>
                                            <>{keyValue('carMetadataRestrictionReportNature', restriction.naturezaOcorrencia)}</>
                                            <>{keyValue('carMetadataRestrictionDeclarantName', restriction.nomeDeclarante)}</>
                                            <>{keyValue('carMetadataRestrictionPoliceReportNumber', restriction.numeroBO)}</>
                                            <>{keyValue('plate', restriction.placa)}</>
                                            <>{keyValue('carMetadataRestrictionPhoneExtension', restriction.ramalContato)}</>
                                            <>{keyValue('carMetadataRestrictionSystem', restriction.sistema)}</>
                                            <>{keyValue('phone', restriction.telefoneContato)}</>
                                            <>{keyValue('carMetadataRestrictionPoliceReportState', restriction.ufBO)}</>
                                            <>{keyValue('carMetadataRestrictionPoliceUnit', restriction.unidadePolicial)}</>
                                        </View>
                                    ))
                                }
                            </View> : null
                    }
                    {
                        carInfo.indiceNacionalVeiculos.length ?
                            <View style={styles.cardBodySplitter}>
                                <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('nationalVehicleIndex')}</MyAppText>
                                {
                                    carInfo.indiceNacionalVeiculos.map(restriction => (
                                        <View key={restriction.id} style={{ gap: 5 }}>
                                            <>{keyValue('id', restriction.id)}</>
                                            <>{keyValue('method', restriction.metodo)}</>
                                            <>{keyValue('quantity', restriction.qtd)}</>
                                        </View>
                                    ))
                                }
                            </View> : null
                    }
                </View>
            ))}
        </ScrollView>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    cardBody: {
        gap: 10,
        backgroundColor: theme.colors.container.background,
        padding: 10,
        borderColor: theme.colors.primaryButton.disabledBackground,
        borderWidth: 1,
        borderRadius: 4,
    },
    cardBodySplitter: {
        paddingBottom: 10,
        paddingLeft: 10,
    },
    restriction: {
        borderColor: 'red'
    }
}));
