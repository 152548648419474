import React, { useState } from 'react';
import { View, ViewStyle, StyleProp, TextStyle } from 'react-native';
import MyAppText from './MyAppText';
import { TextInputMask } from 'react-native-masked-text';
import { translate } from '../services/translate';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

export type PhoneCode = string;

interface MyPhoneInputProps {
    label: string,
    placeholder?: string,
    value: string,
    invalid: (value: string) => boolean;
    onChangeText: (value: string) => void,
    viewStyle?: StyleProp<ViewStyle>;
    labelStyle?: StyleProp<TextStyle>;
}

export default function MyPhoneInput({ label, placeholder, value, invalid, onChangeText, viewStyle, labelStyle }: MyPhoneInputProps) {
    const [isInvalid, setIsInvalid] = useState(false);

    const { styles, theme } = useStyles(stylesheet);

    return (
        <View style={[{ rowGap: 5 }, viewStyle]}>
            <MyAppText style={[styles.label, labelStyle]}>{label}</MyAppText>
            <TextInputMask
                placeholder={placeholder}
                value={value}
                includeRawValueInChangeText={true}
                onChangeText={(maskedText, rawText) => {
                    onChangeText(rawText || '');
                }}
                type={'cel-phone'}
                style={[styles.input, {
                    color: value == '' ? theme.colors.placeholder : theme.colors.textColor,
                }, isInvalid ? styles.invalid : undefined]}
                options={{
                    maskType: 'BRL',
                    withDDD: true,
                    dddMask: '(99) '
                }}
                onBlur={() => {
                    setIsInvalid(invalid(value));
                }}
            />
            {isInvalid ?
                <MyAppText style={styles.error}>{translate('invalidPhone')}</MyAppText> : null}
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    label: {
        color: theme.colors.labelColor,
        height: 25,
    },
    input: {
        fontSize: 16,
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.field.background,
        borderColor: theme.colors.field.border,
        borderWidth: 1,
        maxHeight: 40,
        padding: 10,
        borderRadius: 4,
        color: theme.colors.field.text,
    },
    error: {
        color: '#FF0000',
        fontSize: 10
    },
    invalid: {
        borderColor: '#FF0000',
    },
}));
