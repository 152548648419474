import React, { useState, useEffect, useMemo } from 'react';
import { View, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import MyAppText from '../MyAppText';
import { translate } from '../../services/translate';
import Map from '../Map';
import MyDataTable from '../MyDataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faBuilding, faRoute, faSearch } from '@fortawesome/free-solid-svg-icons';
import { dispatchService, TeamNameCategory } from '../../services/central-api/dispatch';
import moment from 'moment';
import { MyCheckbox } from '../MyCheckbox';
import FilterInput from '../Filter/components/FilterInput';
import CustomButton from '../CustomButton';
import EquipmentsDropdown from './EquipmentsDropdown';
import ActingBodiesUnitsDropdown from './ActingBodyUnitDropdown';
import SkillsDropdown from './SkillsDropdown';
import DisplacedModal from './DisplacedModal';
import Tooltip from '../Tooltip';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import OccurrenceUnitContent from './OccurrenceUnitContent';
import { WindowInformation } from '../../services/window-information';
import MapPolygonDropdown from '../MapPolygonDropdown';

export interface UserAndDistances extends User {
    distance?: number;
}

export interface UnitAndDistance extends Unit {
    distance?: number;
    isStarter: boolean;
    displaced: boolean;
    displaceReasonId?: number;
}

interface DispatchTabParams {
    occurrence: Occurrence;
    pin?: LatLng;
    dispatchedUnits: Unit[];
    setDispatchedUnits: (value: Unit[]) => void;
}


export default function OccurrenceDetailsDispatchTab({ occurrence, pin, dispatchedUnits, setDispatchedUnits }: DispatchTabParams) {
    const { styles, theme } = useStyles(styleSheet);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const windowInfo = WindowInformation();

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [agentPositions, setAgentPositions] = useState<UnitMapPosition[]>([]);
    const [refetchUser, setRefetchUser] = useState<boolean>(false);
    const [unitsToDispatch, setUnitsToDispatch] = useState<Unit[]>([]);
    const [equipmentFilterList, setEquipmentFilterList] = useState<number[]>([]);
    const [skillFilterList, setSkillFilterList] = useState<number[]>([]);
    const [assignments, setAssignments] = useState<DropdownResource[]>([]);
    const [unitFilter, setUnitFilter] = useState('');
    const [displaceReason, setDisplaceReason] = useState<number>(0);
    const [displaceModal, setDisplaceModal] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState<UnitAndDistance>();

    const [actingBodyUnitIds, setActingBodyUnitIds] = useState<number[]>([]);
    const [polygons, setPolygons] = useState<MapPolygon[]>([]);

    async function getUnits(unitsDispatched: Unit[]) {
        try {
            if (!occurrence?.id) {
                return;
            }

            setIsLoading(true);
            const units = await dispatchService.getUnits({
                equipmentsIds: equipmentFilterList,
                skillsIds: skillFilterList,
                unitName: unitFilter,
                actingBodyId: occurrence.actingBodyId,
                actingBodyUnitIds: actingBodyUnitIds.length ? actingBodyUnitIds : undefined,
                dispatchedUnits: unitsDispatched.map(unit => unit.id)
            });
            for (const unit of units) {
                (unit as UnitAndDistance).isStarter = false;
                (unit as UnitAndDistance).displaced = false;
            }
            setUnitsToDispatch(units);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function getDispatchedUnits() {
        try {
            if (!occurrence?.id) {
                return;
            }
            const unitsDispatched = await dispatchService.getOccurrenceDispatchedUnits(occurrence.id);
            for (const unit of unitsDispatched) {
                const foundUnit = unit.OccurrenceUnits?.find(x => x.OccurrenceUnit?.occurrenceId == occurrence.id);
                (unit as UnitAndDistance).isStarter = foundUnit && foundUnit.OccurrenceUnit ? foundUnit.OccurrenceUnit.isStarter : false;
                (unit as UnitAndDistance).displaced = foundUnit && foundUnit.OccurrenceUnit ? foundUnit.OccurrenceUnit.displaced : false;
            }
            setDispatchedUnits(unitsDispatched);
            if (occurrence.situation !== 'concluded') {
                await getUnits(unitsDispatched);
            } else {
                setIsLoading(false);
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function getAssignments() {
        try {
            const assignments = await dispatchService.getSimplifiedAssignments(occurrence.actingBodyId);
            setAssignments(assignments);
        } catch (err) {
            console.error(err);
        }
    }

    function toRadians(degrees: number) {
        return degrees * (Math.PI / 180);
    }

    function haversineDistance(location1: LatLng, location2: LatLng) {
        const deltaLatitude = toRadians(location2.lat - location1.lat);
        const deltaLongitude = toRadians(location2.lng - location1.lng);
        const haversineComponent = Math.sin(deltaLatitude / 2) ** 2 +
            Math.cos(toRadians(location1.lat)) * Math.cos(toRadians(location2.lat)) *
            Math.sin(deltaLongitude / 2) ** 2;
        const earthRadiusKm = 6371 * 2;
        const distance = earthRadiusKm * Math.asin(Math.sqrt(haversineComponent));
        return parseFloat(distance.toFixed(1));
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            getUnits(dispatchedUnits);
            setRefetchUser(!refetchUser);
        }
    }

    const renderStatus = (unit: UnitAndDistance) => {
        let customStyle = styles.green;
        let customName = 'active';

        if (unit.displaced) {
            customName = 'displaced';
            customStyle = styles.turquoise;
        } else if (unit.definitiveStart && !unit.definitiveFinish && moment(unit.estimatedFinish).isBefore(moment())) {
            customName = 'activeAfterSchedule';
            customStyle = styles.red;
        } else if (unit.definitiveFinish) {
            customName = 'finished';
            customStyle = styles.grey;
        } else if (unit.OccurrenceUnits && unit.OccurrenceUnits.length) {
            for (const occurrence of unit.OccurrenceUnits) {
                if (occurrence.OccurrenceUnit?.displaced) {
                    customName = 'displacedInOtherOccurrence';
                    customStyle = styles.blue;
                    break;
                }
            }
        }

        return <Tooltip tooltipDirection='left' tooltipContent={translate(customName)}>
            <View style={[styles.circle, customStyle]} />
        </Tooltip>;
    };

    const renderAttending = (unit: UnitAndDistance) => {
        if (unit.OccurrenceUnits && unit.OccurrenceUnits.length) {
            for (const occurrence of unit.OccurrenceUnits) {
                let title = '';
                if (occurrence.triggerType == 'face_detected') {
                    title = translate('faceDetectedAlert');
                } else if (occurrence.triggerType == 'mp_face_detected') {
                    title = translate('mpFaceDetectedAlert');
                } else if (['scenechangedetection', 'defocus'].includes(occurrence.triggerType || '')) {
                    title = translate('cameraDepredationSuspect');
                } else if (occurrence.triggerType == 'plate_detected') {
                    title = translate('plateDetectedAlert');
                } else if (occurrence.triggerType == 'mp_plate_detected') {
                    title = translate('mpPlateDetectedAlert');
                } else if (occurrence.triggerType == 'irregular_vehicle') {
                    title = translate('irregular_vehicle');
                } else if (occurrence.triggerType == 'clone_suspect') {
                    title = translate('clone_suspect');
                } else if (occurrence.triggerType == 'area_invasion') {
                    title = translate('areaInvasionAlert');
                } else if (occurrence.triggerType == 'alarm_center') {
                    title = translate('alarmCenterAlert');
                } else if (occurrence.triggerType == 'guardian_app') {
                    title = translate('guardianAppAlert');
                } else if (occurrence.triggerType == 'not_allowed_face_detected') {
                    title = translate('notAllowedFaceDetectedAlert');
                } else if (occurrence.triggerType == 'sigrc') {
                    return translate('sigrcAlert');
                } else if (occurrence.triggerType == 'cortex_plate_detected') {
                    return translate('cortexPlateDetectedAlert');
                } else if (occurrence.triggerType == null && occurrence.occurrenceType) {
                    title = occurrence.occurrenceType.name;
                }
                return <MyAppText>{title}</MyAppText>;
            }
            return <MyAppText>{translate('yes')}</MyAppText>;
        }
        return <MyAppText>{translate('no')}</MyAppText>;
    };

    useEffect(() => {
        getAssignments();
        getDispatchedUnits();
    }, []);

    useEffect(() => {
        if (occurrence.situation == 'concluded') {
            return;
        }

        getAgentPositions();
        const interval = setInterval(() => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will work only when the page is active
            if (!window.location.href.includes('/occurrences/detail')) {
                return;
            }

            getAgentPositions();
        }, 30000);
        return () => clearInterval(interval);
    }, [refetchUser]);

    async function getAgentPositions() {
        try {
            const agentPositions = await dispatchService.getAgentPositions(unitFilter);
            setAgentPositions(agentPositions);
        } catch (err) {
            console.error(err);
        }
    }

    function updateUnitAgentPositions(pin: LatLng, units: UnitAndDistance[]) {
        for (const unit of units) {
            let distance = Infinity;
            if (unit.unitName && [TeamNameCategory.LIGHT_VEHICLE, TeamNameCategory.MOTORCYCLE, TeamNameCategory.HEAVY_VEHICLE].includes(unit.unitName.category)) {
                const agentPosition = agentPositions.find(ap => ap.user.unit?.id == unit.id);
                if (!agentPosition || moment(agentPosition.positionUpdatedAt).isBefore(moment().subtract(10, 'minutes'))) {
                    continue;
                }
                distance = haversineDistance(pin, {
                    lat: Number(agentPosition.latitude),
                    lng: Number(agentPosition.longitude),
                });
                for (const user of unit.users as UserAndDistances[]) {
                    user.distance = distance;
                }
            } else {
                for (const user of unit.users as UserAndDistances[]) {
                    const agentPosition = agentPositions.find(ap => ap.userId == user.id);
                    if (!agentPosition || moment(agentPosition.positionUpdatedAt).isBefore(moment().subtract(10, 'minutes'))) {
                        continue;
                    }
                    user.distance = haversineDistance(pin, {
                        lat: Number(agentPosition.latitude),
                        lng: Number(agentPosition.longitude),
                    });
                    if (user.distance < distance) {
                        distance = user.distance;
                    }
                }
            }
            unit.distance = distance;
        }
    }

    useEffect(() => {
        if (!pin || occurrence.situation == 'concluded') {
            return;
        }
        updateUnitAgentPositions(pin, dispatchedUnits as UnitAndDistance[]);
        updateUnitAgentPositions(pin, unitsToDispatch as UnitAndDistance[]);
        forceUpdate();
    }, [pin, dispatchedUnits, agentPositions, unitsToDispatch]);

    const checkDisabledStarter = useMemo(() => {
        if (occurrence.situation == 'concluded') {
            return true;
        }

        if ((dispatchedUnits as UnitAndDistance[]).findIndex(du => du.displaced) != -1) {
            return true;
        }
        return false;
    }, [dispatchedUnits, selectedUnit]);

    return (
        <View style={styles.container}>
            {occurrence.situation == 'concluded' ? <></> :
                <View style={[styles.tableFilters, { zIndex: 2 }]}>
                    <FilterInput
                        value={unitFilter}
                        placeholder={translate('searchUnit')}
                        onChange={setUnitFilter}
                        onKeyPress={handleKeyDown}
                    />
                    <ActingBodiesUnitsDropdown
                        value={actingBodyUnitIds}
                        setValue={setActingBodyUnitIds}
                        zIndex={4}
                        actingBodyId={occurrence.actingBodyId}
                    />
                    <EquipmentsDropdown
                        value={equipmentFilterList}
                        setValue={setEquipmentFilterList}
                        actingBodyId={occurrence.actingBodyId}
                        zIndex={3}
                    />
                    <SkillsDropdown
                        value={skillFilterList}
                        setValue={setSkillFilterList}
                        actingBodyId={occurrence.actingBodyId}
                        zIndex={2}
                    />
                    <CustomButton
                        onPress={() => {
                            getUnits(dispatchedUnits);
                            setRefetchUser(!refetchUser);
                        }}
                        text={translate('toSearch')}
                        icon={faSearch}
                    />
                </View>
            }
            <View style={{ flex: 1, flexDirection: windowInfo.isMobile ? 'column' : 'row' }}>
                <View style={styles.leftArea(windowInfo.isMobile)}>
                    <View style={[styles.table, { rowGap: 20 }]}>
                        {occurrence.situation == 'concluded' ? <></> :
                            <View style={styles.table}>
                                <View style={styles.tableFilters}>
                                    <MyAppText style={styles.optionsButtonText}>
                                        {translate('availableUnits')}
                                    </MyAppText>
                                </View>
                                <View style={{ zIndex: -1, flex: 1 }}>
                                    <MyDataTable
                                        columns={[
                                            {
                                                name: translate('team'),
                                                selector: row => row.unitName.name || '',
                                                wrap: true,
                                            },
                                            {
                                                name: translate('attending'),
                                                wrap: true,
                                                cell: row =>
                                                    <>
                                                        {renderAttending(row)}
                                                    </>,
                                            },
                                            {
                                                name: translate('status'),
                                                cell: row =>
                                                    <View>
                                                        {renderStatus(row)}
                                                    </View>,
                                                grow: 0,
                                                center: true,
                                            },
                                            {
                                                name: <FontAwesomeIcon icon={faBuilding} fontSize={16} color={theme.colors.iconColor} />,
                                                selector: row =>
                                                    `${row.actingBodyUnit?.actingBody?.name || ''}/${row.actingBodyUnit?.name || ''}`,
                                                wrap: true,
                                                center: true,
                                            },
                                            {
                                                center: true,
                                                name: <View style={{ flexDirection: 'row', columnGap: 5 }}>
                                                    <FontAwesomeIcon icon={faRoute} fontSize={16} color={theme.colors.iconColor} />
                                                    <MyAppText>(km)</MyAppText>
                                                </View>,
                                                selector: row => row.distance != Infinity && row.distance ? row.distance : '',
                                                sortable: true,
                                                width: '100px',
                                            },
                                            {
                                                button: true,
                                                cell: row => {
                                                    const idx = unitsToDispatch.findIndex((el) => el.id == row.id);

                                                    return <TouchableOpacity onPress={() => {
                                                        unitsToDispatch.splice(idx, 1);
                                                        if (!dispatchedUnits.length) {
                                                            row.isStarter = true;
                                                        }
                                                        dispatchedUnits.push(row);
                                                        setUnitsToDispatch([...unitsToDispatch]);
                                                        setDispatchedUnits([...dispatchedUnits]);
                                                    }} style={[styles.button]}>
                                                        <FontAwesomeIcon fontSize={16} icon={faArrowDown} />
                                                    </TouchableOpacity>;
                                                },
                                                width: '40px'
                                            }
                                        ]}
                                        expandableRows
                                        onSelectedRowsChange={({ selectedRows }) => {
                                            if (selectedRows.length !== unitsToDispatch.length) {
                                                setUnitsToDispatch(selectedRows);
                                            }
                                        }}
                                        expandableRowsComponent={({ data }) => <OccurrenceUnitContent unit={data} assignments={assignments} />}
                                        data={(unitsToDispatch as UnitAndDistance[]).sort((a, b) => {
                                            if (a.distance === undefined) return 1;
                                            if (b.distance === undefined) return -1;
                                            return a.distance - b.distance;
                                        })}
                                        progressPending={isLoading}
                                        shortPadding={true}
                                        tableFlex={true}
                                    />
                                </View>
                            </View>}
                        <View style={styles.table}>
                            <View style={styles.tableFilters}>
                                <MyAppText style={styles.optionsButtonText}>
                                    {translate('dispatchedUnits')}
                                </MyAppText>
                            </View>
                            <View style={{ zIndex: -1, flex: 1 }}>
                                <MyDataTable
                                    columns={[
                                        {
                                            name: translate('team'),
                                            selector: row => row.unitName.name || '',
                                            wrap: true,
                                        },
                                        {
                                            name: '',
                                            cell: row => {
                                                return <MyCheckbox
                                                    label={row.isStarter ? translate('starter') : translate('support')}
                                                    checked={row.isStarter}
                                                    setChecked={() => {
                                                        for (const unit of dispatchedUnits as UnitAndDistance[]) {
                                                            unit.isStarter = false;
                                                        }
                                                        row.isStarter = true;
                                                        forceUpdate();
                                                    }}
                                                    disabled={checkDisabledStarter}
                                                />;
                                            },
                                            wrap: true,
                                            center: true,
                                            maxWidth: '80px',
                                            minWidth: '80px'
                                        },
                                        {
                                            name: '',
                                            cell: row => {
                                                if (row.isStarter) {
                                                    return <TouchableOpacity onPress={async () => {
                                                        setDisplaceModal(true);
                                                        setSelectedUnit(row);
                                                    }} style={[styles.displaceButton, row.displaced ? { minWidth: 115, maxWidth: 115 } : {}]}>
                                                        <MyAppText style={styles.text}>{translate(row.displaced ? 'displaced' : 'displace')}</MyAppText>
                                                    </TouchableOpacity>;
                                                }
                                                return <></>;
                                            },
                                            wrap: true,
                                            center: true,
                                            maxWidth: '115px',
                                            minWidth: '115px',
                                            omit: occurrence.situation == 'concluded'
                                        },
                                        {
                                            name: translate('attending'),
                                            wrap: true,
                                            center: true,
                                            cell: row =>
                                                <>
                                                    {renderAttending(row)}
                                                </>,
                                        },
                                        {
                                            name: translate('status'),
                                            cell: row =>
                                                <View>
                                                    {renderStatus(row)}
                                                </View>,
                                            grow: 0,
                                            center: true,
                                        },
                                        {
                                            name: <FontAwesomeIcon icon={faBuilding} fontSize={16} color={theme.colors.iconColor} />,
                                            selector: row =>
                                                `${row.actingBodyUnit?.actingBody?.name || ''}/${row.actingBodyUnit?.name || ''}`,
                                            wrap: true,
                                            center: true,
                                        },
                                        {
                                            center: true,
                                            name: <View style={{ flexDirection: 'row', columnGap: 5 }}>
                                                <FontAwesomeIcon icon={faRoute} fontSize={16} color={theme.colors.iconColor} />
                                                <MyAppText>(km)</MyAppText>
                                            </View>,
                                            selector: row => row.distance != Infinity && row.distance ? row.distance : '',
                                            sortable: true,
                                            width: '100px',
                                            omit: occurrence.situation == 'concluded'
                                        },
                                        {
                                            button: true,
                                            cell: row => {
                                                if (row.isStarter) {
                                                    return <></>;
                                                }

                                                const idx = dispatchedUnits.findIndex((el) => el.id == row.id);

                                                return <TouchableOpacity onPress={() => {
                                                    dispatchedUnits.splice(idx, 1);
                                                    unitsToDispatch.push(row);
                                                    setUnitsToDispatch([...unitsToDispatch]);
                                                    setDispatchedUnits([...dispatchedUnits]);
                                                }} style={[styles.button]}>
                                                    <FontAwesomeIcon fontSize={16} icon={faArrowUp} />
                                                </TouchableOpacity>;
                                            },
                                            width: '40px',
                                            omit: occurrence.situation == 'concluded'
                                        }
                                    ]}
                                    expandableRows
                                    onSelectedRowsChange={({ selectedRows }) => {
                                        if (selectedRows.length !== dispatchedUnits.length) {
                                            setDispatchedUnits(selectedRows);
                                        }
                                    }}
                                    expandableRowsComponent={({ data }) => <OccurrenceUnitContent unit={data} assignments={assignments} occurrenceSituation={occurrence.situation} />}
                                    data={(dispatchedUnits as UnitAndDistance[]).sort((a, b) => {
                                        if (a.distance === undefined) return 1;
                                        if (b.distance === undefined) return -1;
                                        return a.distance - b.distance;
                                    })}
                                    progressPending={isLoading}
                                    shortPadding={true}
                                    tableFlex={true}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                <View style={windowInfo.isMobile ? { height: 200 } : { flex: 1 }}>

                    <View style={styles.overlaySelector}>
                        <MapPolygonDropdown
                            setPolygons={setPolygons}
                            dropDownDirection={'BOTTOM'}
                        />
                    </View>
                    <Map
                        pinnedMarker={pin}
                        agentPositions={agentPositions}
                        polygons={polygons}
                    />
                </View>
            </View>
            {selectedUnit && <DisplacedModal
                isModalVisible={displaceModal}
                setIsModalVisible={setDisplaceModal}
                setValue={setDisplaceReason}
                value={displaceReason}
                unit={selectedUnit}
                onSubmit={() => {
                    setDisplaceModal(false);
                    if (selectedUnit) {
                        const unitIndex = dispatchedUnits.findIndex(u => u.id == selectedUnit.id);
                        (dispatchedUnits as UnitAndDistance[])[unitIndex].displaced = true;
                        (dispatchedUnits as UnitAndDistance[])[unitIndex].displaceReasonId = displaceReason;
                        forceUpdate();
                    }
                }}
                isDisplaced={!!(selectedUnit && selectedUnit.displaced)}
                onRemoveDisplaced={() => {
                    if (selectedUnit) {
                        const copy = [];
                        for (const du of dispatchedUnits) {
                            (du as UnitAndDistance).displaced = false;
                            (du as UnitAndDistance).displaceReasonId = undefined;
                            copy.push(du);
                        }
                        setDisplaceReason(0);
                        setDispatchedUnits(copy);
                        setDisplaceModal(false);
                    }
                }}
            />}
        </View >);
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        gap: 5,
        backgroundColor: theme.colors.cardBackground,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.cardBorder,
        padding: 10,
    },
    green: {
        backgroundColor: 'green',
    },
    red: {
        backgroundColor: 'red',
    },
    turquoise: {
        backgroundColor: 'turquoise'
    },
    blue: {
        backgroundColor: '#1500ff'
    },
    grey: {
        backgroundColor: 'grey'
    },
    circle: {
        width: 15,
        height: 15,
        borderRadius: 7.5,
    },
    status: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 5
    },
    tableFilters: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        flexWrap: 'wrap'
    },
    leftArea: (isMobile: boolean) => ({
        flex: !isMobile ? 1 : undefined,
        flexDirection: 'column',
        flexGrow: !isMobile ? 2 : undefined,
        height: isMobile ? 600 : undefined,
    }),
    table: {
        flex: 1,
    },
    optionsButtonText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        fontWeight: 'bold',
    },
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 25
    },
    displaceButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.colors.sidebar.background,
        borderRadius: 2,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        gap: 5,
        padding: 5,
        minWidth: 95,
        maxWidth: 95,
        maxHeight: 35
    },
    text: {
        color: theme.colors.sidebar.item.text,
        fontSize: 12,
    },
    overlaySelector: {
        position: 'absolute',
        zIndex: 1000,
        top: 10,
        left: 10,
        width: 260,
        paddingHorizontal: 10,
        paddingVertical: 8,
    },
}));
