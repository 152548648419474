import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import EventList from '../../../components/facial/EventList';
import { facialService } from '../../../services/central-api/facial';
import { RouteProp, useRoute } from '@react-navigation/native';
import { FacialParamList } from '../../../typings/Params';
import useFilters from '../../../components/Filter/hooks/useFilters';
import FacialSearchFilter from './Filters';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface SearchParams {
    navigation: Navigation;
}

export default function Search({ navigation }: SearchParams) {
    const { styles } = useStyles(stylesheet);

    const route = useRoute<RouteProp<FacialParamList, 'Search'>>();
    const [hasMoreResults, setHasMoreResults] = useState(false);
    const [filters, setFilters] = useFilters<FacialParamList['Search']>({
        ...route.params,
        page: Math.max(route.params?.page - 1, 0),
        limit: Number(route.params.limit) || 10,
    });

    useEffect(() => {
        getFacialDetections();
    }, [filters]);

    const [isLoading, setIsLoading] = useState(true);
    const [detections, setDetections] = useState<FacialDetection[]>([]);

    async function getFacialDetections() {
        try {
            setIsLoading(true);

            const newDetections = await facialService.getDetections(filters);
            if (newDetections.length < filters.limit) {
                setHasMoreResults(false);
            } else {
                setHasMoreResults(true);
            }
            if (filters.page == 0) {
                detections.length = 0;
            }

            setDetections(prevDetections => [...prevDetections, ...newDetections]);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={styles.buttonsContainer}>
                    <FacialSearchFilter
                        filters={filters}
                        setFilters={setFilters}
                    />
                </View>
            </View>
            <EventList
                events={detections}
                navigation={navigation}
                loadMore={() => setFilters({ ...filters, page: filters.page + 1 })}
                hasMoreResults={hasMoreResults}
                isLoading={isLoading}
                isPolling={false}
                module='facial'
                page='search'
            />
        </View>
    );
}

const stylesheet = createStyleSheet(() => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'flex-end'
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    }
}));

