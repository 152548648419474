import React, { useRef, useState } from 'react';
import { View } from 'react-native';
import MyAppText from './MyAppText';
import { TextInputMask, TextInputMaskMethods } from 'react-native-masked-text';
import { translate } from '../services/translate';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface MyDateInputProps {
    label: string;
    date: string;
    onChangeDate: (value: string) => void;
    editable?: boolean;
    hasMaxWidth?: boolean;
    labelFontSize?: number;
}

type InputMask = TextInputMask & TextInputMaskMethods;

export default function MyDateInput({ label, date, onChangeDate, hasMaxWidth = true, editable = true, labelFontSize }: MyDateInputProps) {
    const { styles, theme } = useStyles(styleSheet);

    const [isInvalid, setIsInvalid] = useState(false);
    const dateInputRef = useRef<InputMask>(null);

    return (
        <View style={{ rowGap: 5 }}>
            <MyAppText style={styles.label(labelFontSize || 14)}>{label}</MyAppText>
            <View style={{ flexDirection: 'row', columnGap: 5 }}>
                <TextInputMask
                    value={date}
                    placeholder='DD/MM/YYYY'
                    onChangeText={(text: string) => {
                        onChangeDate(text);
                    }}
                    keyboardType={'numeric'}
                    type={'datetime'}
                    style={[styles.input, hasMaxWidth ? { maxWidth: 125 } : { flex: 1 }, {
                        color: date == '' ? theme.colors.placeholder : theme.colors.textColor,
                    }, !editable ? styles.disabled : undefined, isInvalid ? styles.invalid : undefined]}
                    options={{
                        format: 'DD/MM/YYYY'
                    }}
                    onBlur={() => {
                        setIsInvalid(!dateInputRef.current?.isValid() || false);
                    }}
                    ref={dateInputRef}
                    editable={editable}
                />
            </View>
            {isInvalid ?
                <MyAppText style={styles.error}>{translate('invalidDate')}</MyAppText> : null}
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    label: (fontSize: number) => ({
        color: theme.colors.labelColor,
        fontSize,
    }),
    input: {
        fontSize: 16,
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.field.background,
        borderColor: theme.colors.field.border,
        borderWidth: 1,
        maxHeight: 40,
        padding: 10,
        borderRadius: 4,
        color: theme.colors.field.text,
    },
    error: {
        color: '#FF0000',
        fontSize: 10
    },
    invalid: {
        borderColor: '#FF0000',
    },
    disabled: {
        backgroundColor: theme.colors.field.disabledBackground,
    }
}));