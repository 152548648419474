import React, { useEffect, useState } from 'react';
import { View, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { WindowInformation } from '../../../services/window-information';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import FilterSwitch from '../../../components/Filter/components/FilterSwitch';
import { clonedPlateService } from '../../../services/central-api/cloned-plate';
import { Tabs } from '../../../components/Tabs';
import MyDataTable from '../../../components/MyDataTable';
import moment from 'moment';
import CustomButton from '../../../components/CustomButton';

interface ModalProps {
    isModalVisible: boolean;
    onClose: () => void;
    plateData: BaseClonedPlate;
}
const regPlate = new RegExp(/[A-Z]{3}[-]?[0-9][0-9A-Z][0-9]{2}/);

export default function ClonedPlateModal({ onClose, plateData, isModalVisible }: ModalProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [plate, setPlate] = useState('');
    const [description, setDescription] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [cloneEvents, setCloneEvents] = useState<BaseCloneEvent[]>([]);

    function clearValues() {
        setPlate('');
        setDescription('');
        setIsActive(true);
    }

    function isPlateInvalid(value: string) {
        return value == '' || value.length > 7 || !regPlate.test(value);
    }

    function isDescriptionInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isPlateInvalid(plate) && !isDescriptionInvalid(description);
    }

    async function formSubmit() {
        try {
            await clonedPlateService.updateClonedPlate(plateData.id, {
                description,
                isActive
            });
            onClose();
        } catch (err) {
            console.error(err);
        }
    }

    async function getClonedPlateById() {
        try {
            const response = await clonedPlateService.getClonedPlateById(plateData.id);
            setPlate(response.plate);
            setDescription(response.description);
            setIsActive(response.isActive);
            setCloneEvents(response.cloneEvents);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (plateData) {
            getClonedPlateById();
        }
    }, [plateData]);

    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                onClose();
                clearValues();
            }}
            transparent={true}
        >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '50%' }]}>
                    <Tabs tabs={[{
                        key: 'plate',
                        label: translate('plate')
                    }, {
                        key: 'associatedEvents',
                        label: translate('associatedEvents')
                    }]}>
                        {({ selectedTab }) => {
                            if (selectedTab == 'plate') {
                                return <View style={{ rowGap: 25 }}>
                                    <FormInput
                                        label={translate('plate')}
                                        placeholder={translate('plate')}
                                        value={plate}
                                        onChangeText={(value) => {
                                            if (value.length > 7) {
                                                return;
                                            }
                                            setPlate(value.toUpperCase());
                                        }}
                                        invalid={isPlateInvalid}
                                        disabled={plateData?.id ? true : false}
                                    />
                                    <FormInput
                                        label={translate('description')}
                                        placeholder={translate('description')}
                                        value={description}
                                        onChangeText={setDescription}
                                        invalid={isDescriptionInvalid}
                                        multiline={true}
                                        numberOfLines={3}
                                    />
                                    <FilterSwitch value={isActive} label={translate('active')} direction='row' onChange={setIsActive} />
                                </View>;
                            } else if (selectedTab == 'associatedEvents') {
                                return <MyDataTable
                                    columns={[
                                        {
                                            name: 'ID',
                                            selector: row => row.id,
                                            grow: 0
                                        },
                                        {
                                            name: translate('model'),
                                            selector: row => row.model ?? '',
                                            wrap: true,
                                        },
                                        {
                                            name: translate('registeredSpeed'),
                                            selector: row => `${row.speed}km/h`,
                                            wrap: true,
                                        },
                                        {
                                            name: translate('captureDate'),
                                            selector: row => moment(row.detectedOn).format('DD/MM/YYYY'),
                                            wrap: true,
                                        },
                                        {
                                            name: translate('action'),
                                            button: true,
                                            cell: row => <View style={{ gap: 10, flexDirection: 'row', flex: 1 }}>
                                                <CustomButton text={translate('firstDetection')}
                                                    onPress={() => window.open(`/LPR/live-events/lpr-detection/${row.firstDetectionId}`)} />
                                                <CustomButton text={translate('secondDetection')}
                                                    onPress={() => window.open(`/LPR/live-events/lpr-detection/${row.secondDetectionId}`)} />
                                            </View>
                                        }
                                    ]}
                                    data={cloneEvents}
                                />;
                            }
                        }}
                    </Tabs>
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={onClose}
                        disabled={!isFormValid()} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        minHeight: 420,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    }
}));
