import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import MyDataTable from '../../../components/MyDataTable';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import moment from 'moment';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface UnitFormOccurrencesProps {
    occurrences?: Occurrence[];
}

export default function UnitFormOccurrences({ occurrences }: UnitFormOccurrencesProps) {
    const { styles, theme } = useStyles(stylesheet);

    return <View style={{ rowGap: 5, flex: 1, minWidth: 250 }}>
        <MyDataTable
            columns={[
                {
                    name: translate('nProtocol'),
                    cell: row => {
                        return <TouchableOpacity onPress={() => window.open(`/dispatch/occurrences/detail/${row.id}`)} >
                            <MyAppText style={{ textDecorationLine: 'underline' }}>
                                {row.id}
                            </MyAppText>
                        </TouchableOpacity>;
                    },
                },
                {
                    name: translate('type'),
                    cell: row => {
                        return <View style={styles.content}>
                            <MyAppText >{row.occurrenceType ? row.occurrenceType.name : row.triggerType ? translate(row.triggerType) : ''}</MyAppText>
                        </View>;
                    },
                    grow: 1,
                    wrap: true
                },
                {
                    name: translate('acted'),
                    cell: row => {
                        return <View style={styles.content}>
                            <MyAppText>
                                {row.OccurrenceUnit?.acknowledged ? translate('yes') : translate('no')}
                            </MyAppText>
                            <MyAppText style={{ fontSize: 11 }}>
                                ({row.OccurrenceUnit?.isStarter ? translate('starter') : translate('support')})
                            </MyAppText>
                        </View>;
                    },
                },
                {
                    name: translate('dispatchedAt'),
                    cell: row => {
                        return <View style={styles.content}>
                            <MyAppText>
                                {moment(row.OccurrenceUnit?.createdAt).format('DD/MM/YYYY HH:mm')}
                            </MyAppText>
                        </View>;
                    },
                },
                {
                    name: translate('finishedAt'),
                    cell: row => {
                        return <View style={styles.content}>
                            <MyAppText style={{ color: !row.finishedAt ? theme.colors.disabledText : undefined }}>
                                {row.finishedAt ? moment(row.finishedAt).format('DD/MM/YYYY HH:mm') : translate('status_open')}
                            </MyAppText>
                        </View>;
                    },
                },
            ]}
            data={(occurrences || []).sort((a, b) => {
                const aCreatedAt = moment(a.OccurrenceUnit?.createdAt || 0).valueOf();
                const bCreatedAt = moment(b.OccurrenceUnit?.createdAt || 0).valueOf();
                return aCreatedAt < bCreatedAt ? 1 : -1;
            })}
        />
    </View>;
}

const stylesheet = createStyleSheet(() => ({
    content: {
        width: '100%',
        flexDirection: 'row',
        gap: 5,
        alignItems: 'baseline',
    },
}));