import React, { useEffect, useState } from 'react';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';
import { facialService } from '../../services/central-api/facial';

interface Props {
    value: number[];
    setValue: React.Dispatch<React.SetStateAction<number[]>>;
    zIndex: number;
}

function MarkerDropdown({ value, setValue, zIndex }: Props) {
    const [items, setItems] = useState<{ label: string, value: number; }[]>([]);

    async function getItems() {
        try {
            const markers = await facialService.getSimplifiedFacialMarkers();
            setItems(markers.map((marker) => {
                return {
                    value: marker.id,
                    label: marker.name
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getItems();
    }, []);

    return (
        <FilterDropdown
            label={translate('tags')}
            items={items}
            multiple={true}
            mode='BADGE'
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}

export default MarkerDropdown;
