import React, { useState } from 'react';
import { TouchableOpacity, View, Image, ImageSourcePropType } from 'react-native';
import { translate } from '../services/translate';
import MyAppText from '../components/MyAppText';
import { WindowInformation } from '../services/window-information';
import UserInformationModal from '../components/UserInformationModal';
import { ScrollView } from 'react-native-web-hover';
import { createStyleSheet, UnistylesRuntime, useStyles } from 'react-native-unistyles';
import CustomButton from '../components/CustomButton';
import { useAuth } from '../context/Auth';

interface MenuComponentProps {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

interface MenuItem {
    action: () => void;
    icon: ImageSourcePropType;
    text: string;
    disabled: boolean;
}

export default function Menu({ navigation }: MenuComponentProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();
    const [showDropDown, setShowDropDown] = useState(false);
    const { user: authenticatedUser } = useAuth();


    const menuItems: MenuItem[] = [{
        action: () => navigation.navigate('Cameras'),
        icon: require('../../assets/icon_cameras.png'),
        text: translate('cameras'),
        disabled: false
    },
    {
        action: () => {
            if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.facial_module_access) {
                return navigation.navigate('Facial');
            }

            if (
                authenticatedUser?.permissions.manage_wanted_person
                || authenticatedUser?.permissions.manage_missing_person
                || authenticatedUser?.permissions.manage_allow_list_person
            ) {
                return navigation.navigate('Facial', { screen: 'PeopleList' });
            }
        },
        icon: require('../../assets/icon_facial.png'),
        text: translate('facial'),
        disabled: !(
            authenticatedUser?.isAdmin
            || authenticatedUser?.permissions.facial_module_access
            || authenticatedUser?.permissions.manage_wanted_person
            || authenticatedUser?.permissions.manage_missing_person
            || authenticatedUser?.permissions.manage_allow_list_person
        )
    },
    {
        action: () => navigation.navigate('SmartSearchFacial'),
        icon: require('../../assets/icon_smart_search_facial.png'),
        text: translate('smartSearchFacial'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.smart_search_module_access)
    },
    {
        action: () => navigation.navigate('LPR'),
        icon: require('../../assets/icon_lpr.png'),
        text: translate('lpr'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.lpr_module_access)
    },
    {
        action: () => navigation.navigate('Events'),
        icon: require('../../assets/icon_events.png'),
        text: translate('events'),
        disabled: !(
            authenticatedUser?.isAdmin ||
            authenticatedUser?.permissions.events_module_access ||
            authenticatedUser?.permissions.events_manage_missing_face_occurrence ||
            authenticatedUser?.permissions.events_manage_not_allowed__face_occurrence ||
            authenticatedUser?.permissions.events_manage_wanted_face_occurrence ||
            authenticatedUser?.permissions.events_manage_wanted_plate_occurrence ||
            authenticatedUser?.permissions.events_manage_irregular_vehicle_occurrence ||
            authenticatedUser?.permissions.events_manage_clone_suspect_occurrence ||
            authenticatedUser?.permissions.events_manage_area_invasion_occurrence ||
            authenticatedUser?.permissions.events_manage_mp_wanted_plate_occurrence ||
            authenticatedUser?.permissions.events_manage_mp_wanted_face_occurrence ||
            authenticatedUser?.permissions.events_manage_alarm_center_occurrence ||
            authenticatedUser?.permissions.events_manage_guardian_app_occurrence ||
            authenticatedUser?.permissions.events_manage_sigrc_occurrence ||
            authenticatedUser?.permissions.events_manage_cortex_plate_occurrence ||
            authenticatedUser?.permissions.events_manage_manual_occurrence
        )
    },
    {
        action: () => {
            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_occurrences
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_occurrences
                || authenticatedUser?.permissions.reopen_occurrence
                || authenticatedUser?.permissions.transfer_occurrence
            ) {
                return navigation.navigate('Dispatch', { screen: 'Occurrences' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_occurrence_tags
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_occurrence_tags
            ) {
                return navigation.navigate('Dispatch', { screen: 'OccurrenceTags' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_occurrence_types
            ) {
                return navigation.navigate('Dispatch', { screen: 'OccurrenceTypes' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_equipments
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_equipments
            ) {
                return navigation.navigate('Dispatch', { screen: 'Equipments' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_download_report
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_download_report
                || authenticatedUser?.permissions.dispatch_generate_report
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_reports
            ) {
                return navigation.navigate('Dispatch', { screen: 'DispatchReports' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_report_tags
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_report_tags
            ) {
                return navigation.navigate('Dispatch', { screen: 'ReportTags' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_skills
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_skills
            ) {
                return navigation.navigate('Dispatch', { screen: 'Skills' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_assignments
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_assignments
            ) {
                return navigation.navigate('Dispatch', { screen: 'Assignments' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_vehicle_relations
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_vehicle_relations
            ) {
                return navigation.navigate('Dispatch', { screen: 'VehicleRelations' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_person_relations
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_person_relations
            ) {
                return navigation.navigate('Dispatch', { screen: 'PersonRelations' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_objects_categories
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_objects_categories
            ) {
                return navigation.navigate('Dispatch', { screen: 'ObjectsCategories' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_team_names
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_unit_names
            ) {
                return navigation.navigate('Dispatch', { screen: 'TeamNames' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_units
                || authenticatedUser?.permissions.dispatch_manage_acting_body_units
                || authenticatedUser?.permissions.dispatch_pause_units
            ) {
                return navigation.navigate('Dispatch', { screen: 'Units' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_manage_finish_categories
                || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_finish_categories
            ) {
                return navigation.navigate('Dispatch', { screen: 'FinishCategories' });
            }

            if (
                authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dispatch_module_access
                || authenticatedUser?.permissions.dispatch_see_resume
            ) {
                return navigation.navigate('Dispatch', { screen: 'Resume' });
            }
        },
        icon: require('../../assets/icon_dispatch.png'),
        text: translate('dispatch'),
        disabled: !(
            authenticatedUser?.isAdmin
            || authenticatedUser?.permissions.dispatch_module_access
            || authenticatedUser?.permissions.dispatch_manage_units
            || authenticatedUser?.permissions.dispatch_manage_acting_body_units
            || authenticatedUser?.permissions.dispatch_download_report
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_download_report
            || authenticatedUser?.permissions.dispatch_generate_report
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_reports
            || authenticatedUser?.permissions.dispatch_manage_skills
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_skills
            || authenticatedUser?.permissions.dispatch_manage_equipments
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_equipments
            || authenticatedUser?.permissions.dispatch_manage_objects_categories
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_objects_categories
            || authenticatedUser?.permissions.dispatch_manage_finish_categories
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_finish_categories
            || authenticatedUser?.permissions.dispatch_manage_team_names
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_unit_names
            || authenticatedUser?.permissions.dispatch_manage_person_relations
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_person_relations
            || authenticatedUser?.permissions.dispatch_manage_vehicle_relations
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_vehicle_relations
            || authenticatedUser?.permissions.dispatch_manage_assignments
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_assignments
            || authenticatedUser?.permissions.dispatch_manage_report_tags
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_report_tags
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_occurrence_types
            || authenticatedUser?.permissions.dispatch_manage_occurrence_tags
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_occurrence_tags
            || authenticatedUser?.permissions.dispatch_manage_occurrences
            || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_occurrences
            || authenticatedUser?.permissions.dispatch_see_resume
            || authenticatedUser?.permissions.reopen_occurrence
            || authenticatedUser?.permissions.transfer_occurrence
            || authenticatedUser?.permissions.dispatch_pause_units
        )
    },
    {
        action: () => navigation.navigate('GcmAgent'),
        icon: require('../../assets/icon_app_agent.png'),
        text: translate('appAgent'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.receive_occurrences || authenticatedUser?.permissions.search_people_by_picture)
    },
    {
        action: () => navigation.navigate('GuardianApp'),
        icon: require('../../assets/icon_guardian_app.png'),
        text: translate('guardianApp'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.guardian_app_module_access)
    },
    {
        action: () => {
            if (authenticatedUser?.isAdmin ||
                authenticatedUser?.permissions.access_edit_user_settings ||
                authenticatedUser?.permissions.access_edit_user_acting_body_units) {
                return navigation.navigate('Access');
            }
            if (authenticatedUser?.permissions.access_management_user_tags) {
                return navigation.navigate('Access', {
                    screen: 'UserTags'
                });
            }
            if (authenticatedUser?.permissions.access_edit_permission_level_settings) {
                return navigation.navigate('Access', {
                    screen: 'PermissionLevels'
                });
            }
            if (authenticatedUser?.permissions.access_edit_group_settings) {
                return navigation.navigate('Access', {
                    screen: 'Groups'
                });
            }
            if (authenticatedUser?.permissions.access_edit_turnstile) {
                return navigation.navigate('Access', {
                    screen: 'Turnstiles'
                });
            }
            if (authenticatedUser?.permissions.access_edit_platform_settings) {
                return navigation.navigate('Access', {
                    screen: 'PlatformSettings'
                });
            }
        },
        icon: require('../../assets/icon_access.png'),
        text: translate('access'),
        disabled: !(authenticatedUser?.isAdmin
            || authenticatedUser?.permissions.access_edit_user_settings
            || authenticatedUser?.permissions.access_edit_user_acting_body_units
            || authenticatedUser?.permissions.access_management_user_tags
            || authenticatedUser?.permissions.access_edit_permission_level_settings
            || authenticatedUser?.permissions.access_edit_group_settings
            || authenticatedUser?.permissions.access_edit_platform_settings
            || authenticatedUser?.permissions.access_edit_turnstile
        )
    },
    {
        action: () => navigation.navigate('CRM'),
        icon: require('../../assets/icon_crm.png'),
        text: 'CRM',
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.crm_module_access)
    },
    {
        action: () => navigation.navigate('Logs'),
        icon: require('../../assets/icon_logs.png'),
        text: 'Logs',
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.logs_module_access)
    },
    {
        action: () => {
            if (authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dashboard_module_access
                || authenticatedUser?.permissions.dashboard_see_occurrence) {
                return navigation.navigate('Dashboard', {
                    screen: 'DashboardOccurrences'
                });
            }

            if (authenticatedUser?.permissions.dashboard_see_cameras) {
                return navigation.navigate('Dashboard', {
                    screen: 'DashboardCameras'
                });
            }

            if (authenticatedUser?.permissions.dashboard_see_agents
                || authenticatedUser?.permissions.dashboard_see_agents_acting_body_units
                || authenticatedUser?.permissions.dashboard_see_agents_acting_bodies) {
                return navigation.navigate('Dashboard', {
                    screen: 'DashboardAgents'
                });
            }

            if (authenticatedUser?.isAdmin
                || authenticatedUser?.permissions.dashboard_see_vehicles
                || authenticatedUser?.permissions.dashboard_see_vehicles_acting_bodies) {
                return navigation.navigate('Dashboard', {
                    screen: 'DashboardVehicles'
                });
            }
        },
        icon: require('../../assets/icon_dashboard.png'),
        text: translate('dashboard'),
        disabled: !(
            authenticatedUser?.isAdmin
            || authenticatedUser?.permissions.dashboard_module_access
            || authenticatedUser?.permissions.dashboard_see_occurrence
            || authenticatedUser?.permissions.dashboard_see_cameras
            || authenticatedUser?.permissions.dashboard_see_agents
            || authenticatedUser?.permissions.dashboard_see_agents_acting_bodies
            || authenticatedUser?.permissions.dashboard_see_agents_acting_body_units
        )
    }, {
        action: () => navigation.navigate('ClonedPlate'),
        icon: require('../../assets/icon_cloned_plate.png'),
        text: translate('clonedPlate'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.cloned_plate_module_access)
    }];

    return (
        <>
            <View style={styles.container}>
                <View style={{ paddingHorizontal: windowInfo.width < 580 ? 10 : 80 }}>
                    {authenticatedUser ?
                        <MyAppText style={styles.welcomeText}>{translate('welcome') + ', ' + (authenticatedUser?.warName || authenticatedUser?.name) + '.'}</MyAppText>
                        : <></>
                    }
                </View>
                <ScrollView contentContainerStyle={styles.scrollContent(windowInfo.width < 580)}>
                    {menuItems.filter(item => !item.disabled).map((item, itemIndex) => (
                        <TouchableOpacity key={itemIndex} onPress={item.action} style={styles.card(windowInfo.width)}>
                            <View style={styles.iconBox}>
                                <Image source={item.icon} style={styles.icon} />
                            </View>
                            <View style={styles.textContent}>
                                <MyAppText style={styles.text}>{item.text}</MyAppText>
                                <CustomButton onPress={item.action} text={translate('starting')} type='secondary' />
                            </View>
                        </TouchableOpacity>
                    ))}
                </ScrollView>
            </View>
            <UserInformationModal acceptedTermsOfUse={authenticatedUser?.acceptedTermsOfUse || false} showDropDown={showDropDown} setShowDropDown={setShowDropDown} navigation={navigation} />
        </>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        rowGap: 40,
        paddingTop: 40
    },
    welcomeText: {
        fontSize: 24,
        fontWeight: 'bold',
        color: theme.colors.labelColor
    },
    card: (width: number) => ({
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
        height: 90,
        gap: 15,
        width: width < 406 ? 335 : 406,
        borderWidth: 1,
        borderColor: theme.colors.container.border
    }),
    iconBox: {
        height: 54,
        width: 54,
        borderRadius: 4,
        backgroundColor: UnistylesRuntime.themeName === 'light' ? '#000028' : '#222426',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        height: 36,
        width: 36,
        objectFit: 'contain',
    },
    text: {
        color: theme.colors.labelColor,
        fontSize: 16,
        fontWeight: 'bold',
        whiteSpace: 'break-spaces'
    },
    scrollContent: (smallPadding: boolean) => ({
        paddingHorizontal: smallPadding ? 10 : 80,
        rowGap: 20,
        columnGap: 16,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    }),
    textContent: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'space-between'
    }
}));
