import { TranslateOptions, I18n } from 'i18n-js';
import en from '../languages/english.json';
import pt from '../languages/portuguese.json';

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Localization from 'expo-localization';

const i18n = new I18n();

// eslint-disable-next-line @typescript-eslint/ban-types
type i18nKeys = keyof typeof en | String;

async function ChangeLanguage(lang: string) {

    if (lang == '') {
        i18n.locale = Localization.locale;
    } else {
        i18n.locale = lang;
    }
    await AsyncStorage.setItem('Translate', lang);
}

i18n.translations = { en, pt };
i18n.enableFallback = true;
i18n.locale = Localization.locale;

async function getDefaultLanguage() {
    const defaultLanguage = await AsyncStorage.getItem('Translate');
    if (!defaultLanguage) {
        return 'pt-br';
    }
    return defaultLanguage;
}

const translate = (key: i18nKeys, options?: TranslateOptions) => i18n.t(key as string, options);
const translateWithoutErrors = (key: i18nKeys, options?: TranslateOptions) => i18n.t(key as string, options ?? { defaultValue: key });
export { getDefaultLanguage, ChangeLanguage, translate, translateWithoutErrors };
