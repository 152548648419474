import React, { useState } from 'react';
import { View, Modal } from 'react-native';
import MyAppText from '../../MyAppText';
import { translate } from '../../../services/translate';
import { WindowInformation } from '../../../services/window-information';
import FormInput from '../../formInput';
import MyDropDownPicker from '../../MyDropDownPicker';
import FormActions from '../../formActions';
import { ScrollView } from 'react-native-web-hover';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import OccurrenceTypeDropdown from '../OccurrenceTypeDropdown';
import MyDateInput from '../../MyDateInput';
import FilterSwitch from '../../Filter/components/FilterSwitch';
import ProvidenceDropdown from '../ProvidenceDropdown';
import GroupContainer from '../../GroupContainer';
import PersonRelationDropdown from '../PersonRelationDropdown';
import HandcuffReasonDropdown from '../HandcuffReasonDropdown';
import MyPhoneInput from '../../MyPhoneInput';

interface InvolvedPersonModalParameters {
    involvedPerson: InvolvedPerson;
    setInvolvedPerson: (value: InvolvedPerson) => void;
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onSubmit: () => void;
    actingBodyId: number;
    occurrenceSituation: OccurrenceSituation;
}

export default function InvolvedPersonModal({ involvedPerson, setInvolvedPerson, isModalVisible, setModalVisible, onSubmit, actingBodyId, occurrenceSituation }: InvolvedPersonModalParameters) {
    const { styles } = useStyles(stylesheet);
    const windowInfo = WindowInformation();

    const [documentTypeDropdown, setDocumentTypeDropdown] = useState<boolean>(false);
    const [genderDropdown, setGenderDropdown] = useState<boolean>(false);

    function isValidForm() {
        if (occurrenceSituation == 'concluded') {
            return false;
        }
        if (involvedPerson.name === '') {
            return false;
        }
        if (involvedPerson.motherName === '') {
            return false;
        }
        if (involvedPerson.birth === '') {
            return false;
        }
        if (involvedPerson.document === '') {
            return false;
        }
        return true;
    }

    return (
        <Modal
            visible={isModalVisible}
            animationType='fade'
            onRequestClose={() => {
                setModalVisible(false);
            }}
            transparent={true}
        >
            <View style={styles.modalContainer}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : { width: '60%' }]}>
                        <ScrollView>
                            <View style={{ rowGap: 20 }}>
                                <View style={[styles.row, { zIndex: 13 }]}>
                                    <FormInput
                                        label={translate('name')}
                                        placeholder={translate('name')}
                                        value={involvedPerson.name}
                                        onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, name: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                        disabled={occurrenceSituation == 'concluded'}
                                    />
                                    <FormInput
                                        label={translate('motherName')}
                                        placeholder={translate('motherName')}
                                        value={involvedPerson.motherName}
                                        onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, motherName: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                        disabled={occurrenceSituation == 'concluded'}
                                    />
                                    <View style={[{ rowGap: 5 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                        <MyDateInput label={translate('birth')}
                                            labelFontSize={16}
                                            date={involvedPerson.birth}
                                            onChangeDate={(text: string) => {
                                                setInvolvedPerson({ ...involvedPerson, birth: text });
                                            }}
                                            hasMaxWidth={false}
                                            editable={occurrenceSituation != 'concluded'} />

                                    </View>
                                    <View style={[{ gap: 5, zIndex: 12 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                        <MyAppText style={styles.inputLabel}>{translate('gender')}</MyAppText>
                                        <MyDropDownPicker
                                            placeholder={translate('gender')}
                                            multiple={false}
                                            mode='BADGE'
                                            open={genderDropdown}
                                            setOpen={setGenderDropdown}
                                            value={involvedPerson.gender}
                                            setValue={(value) => {
                                                setInvolvedPerson({ ...involvedPerson, gender: value(involvedPerson.gender) });
                                            }}
                                            items={['male', 'female'].map((item: string) => {
                                                return {
                                                    label: translate(item), value: item
                                                };
                                            })}
                                            searchable={false}
                                            zIndex={11}
                                            disabled={occurrenceSituation == 'concluded'}
                                        />
                                    </View>
                                    <View style={[{ gap: 5, zIndex: 10 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                        <PersonRelationDropdown
                                            actingBodyId={actingBodyId}
                                            setValue={(value, item) => {
                                                setInvolvedPerson({
                                                    ...involvedPerson,
                                                    personRelationId: value,
                                                    personRelation: item,
                                                });
                                            }}
                                            value={involvedPerson.personRelationId}
                                        />
                                    </View>
                                    <FormInput
                                        label={translate('arrestWarrantNumber')}
                                        placeholder={translate('arrestWarrantNumber')}
                                        value={involvedPerson.arrestWarrantNumber || ''}
                                        onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, arrestWarrantNumber: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                        disabled={occurrenceSituation == 'concluded'}
                                    />
                                    <View style={[{ gap: 5, zIndex: 8 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                        <ProvidenceDropdown
                                            actingBodyId={actingBodyId}
                                            setValue={(value) => {
                                                setInvolvedPerson({ ...involvedPerson, providenceId: value(involvedPerson.providenceId) });
                                            }}
                                            value={involvedPerson.providenceId}
                                        />
                                    </View>
                                    <FormInput
                                        label={translate('address')}
                                        placeholder={translate('address')}
                                        value={involvedPerson.address}
                                        onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, address: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                        disabled={occurrenceSituation == 'concluded'}
                                    />
                                    <FormInput
                                        label={translate('neighborhood')}
                                        placeholder={translate('neighborhood')}
                                        value={involvedPerson.neighborhood}
                                        onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, neighborhood: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                        disabled={occurrenceSituation == 'concluded'}
                                    />
                                    <MyPhoneInput
                                        label={translate('phone')}
                                        placeholder={translate('phone')}
                                        value={involvedPerson.phoneNumber}
                                        onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, phoneNumber: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                        labelStyle={{
                                            fontSize: 16
                                        }}
                                    />
                                </View>

                                <View style={[styles.row, { zIndex: 7 }]}>
                                    <View style={[windowInfo.isMobileOrTablet ? { width: '100%' } : {}]}>
                                        <FilterSwitch
                                            value={involvedPerson.arrested}
                                            label={translate('arrested')}
                                            onChange={(value) => {
                                                setInvolvedPerson({ ...involvedPerson, arrested: Boolean(value) });
                                            }}
                                            direction='column'
                                            disabled={occurrenceSituation == 'concluded'}
                                        />
                                    </View>
                                    {involvedPerson.arrested
                                        ? <>
                                            <FormInput
                                                label={translate('policeReport')}
                                                placeholder={translate('policeReport')}
                                                value={involvedPerson.policeReport || ''}
                                                onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, policeReport: value })}
                                                invalid={() => false}
                                                viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                                disabled={occurrenceSituation == 'concluded'}
                                            />
                                            <View style={[{ zIndex: 6 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                                <OccurrenceTypeDropdown
                                                    value={involvedPerson.policeReportNatureId || 0}
                                                    setValue={(value) => {
                                                        setInvolvedPerson({
                                                            ...involvedPerson,
                                                            policeReportNatureId: value(-1)
                                                        });
                                                    }}
                                                    actingBodyId={actingBodyId}
                                                    label={translate('policeReportNature')}
                                                    disabled={occurrenceSituation == 'concluded'}
                                                />
                                            </View>
                                        </>
                                        : null
                                    }
                                    <View style={[windowInfo.isMobileOrTablet ? { width: '100%' } : {}]}>
                                        <FilterSwitch
                                            value={involvedPerson.handcuffUsed}
                                            label={translate('handcuffUsed')}
                                            onChange={(value) => {
                                                setInvolvedPerson({ ...involvedPerson, handcuffUsed: Boolean(value) });
                                            }}
                                            direction='column'
                                            disabled={occurrenceSituation == 'concluded'}
                                        />
                                    </View>
                                    {involvedPerson.handcuffUsed ?
                                        <View style={[{
                                            gap: 5, zIndex: 5
                                        }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                            <HandcuffReasonDropdown
                                                actingBodyId={actingBodyId}
                                                setValue={(value) => {
                                                    setInvolvedPerson({ ...involvedPerson, handcuffReasonId: value(-1) });
                                                }}
                                                value={involvedPerson.personRelationId}
                                            />
                                        </View> : null
                                    }
                                </View>

                                <GroupContainer label={translate('document')}>
                                    <View style={[styles.row, { zIndex: 3 }]}>
                                        <View style={[{ gap: 5, zIndex: 2 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                            <MyAppText style={styles.inputLabel}>{translate('type')}</MyAppText>
                                            <MyDropDownPicker
                                                placeholder={translate('type')}
                                                multiple={false}
                                                mode='BADGE'
                                                open={documentTypeDropdown}
                                                setOpen={setDocumentTypeDropdown}
                                                value={involvedPerson.documentType}
                                                setValue={(value) => {
                                                    setInvolvedPerson({ ...involvedPerson, documentType: value(involvedPerson.documentType) });
                                                }}
                                                items={['rg', 'cpf'].map((item: string) => {
                                                    return {
                                                        label: item.toUpperCase(), value: item
                                                    };
                                                })}
                                                searchable={false}
                                                zIndex={1}
                                                disabled={occurrenceSituation == 'concluded'}
                                            />
                                        </View>
                                        <FormInput
                                            label={translate('number')}
                                            placeholder={translate('number')}
                                            value={involvedPerson.document}
                                            onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, document: value })}
                                            invalid={() => false}
                                            viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                            disabled={occurrenceSituation == 'concluded'}
                                        />
                                        <FormInput
                                            label={translate('series')}
                                            placeholder={translate('series')}
                                            value={involvedPerson.documentSeries}
                                            onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, documentSeries: value })}
                                            invalid={() => false}
                                            viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                            disabled={occurrenceSituation == 'concluded'}
                                        />
                                        <FormInput
                                            label={translate('publicBody')}
                                            placeholder={translate('publicBody')}
                                            value={involvedPerson.publicBody || ''}
                                            onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, publicBody: value })}
                                            invalid={() => false}
                                            viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                            disabled={occurrenceSituation == 'concluded'}
                                        />
                                        <FormInput
                                            label={translate('federalUnit')}
                                            placeholder={translate('federalUnit')}
                                            value={involvedPerson.documentState || ''}
                                            onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, documentState: value })}
                                            invalid={() => false}
                                            viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                            disabled={occurrenceSituation == 'concluded'}
                                        />
                                    </View>

                                </GroupContainer>
                                <GroupContainer label={translate('companyOrActingBody')}>
                                    <View style={[styles.row]}>
                                        <FormInput
                                            label={translate('name')}
                                            placeholder={translate('name')}
                                            value={involvedPerson.company}
                                            onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, company: value })}
                                            invalid={() => false}
                                            viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                            disabled={occurrenceSituation == 'concluded'}
                                        />
                                        <FormInput
                                            label={translate('role')}
                                            placeholder={translate('role')}
                                            value={involvedPerson.companyRole}
                                            onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, companyRole: value })}
                                            invalid={() => false}
                                            viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                            disabled={occurrenceSituation == 'concluded'}
                                        />
                                        <FormInput
                                            label={translate('address')}
                                            placeholder={translate('address')}
                                            value={involvedPerson.companyAddress}
                                            onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, companyAddress: value })}
                                            invalid={() => false}
                                            viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                            disabled={occurrenceSituation == 'concluded'}
                                        />
                                        <FormInput
                                            label={translate('neighborhood')}
                                            placeholder={translate('neighborhood')}
                                            value={involvedPerson.companyNeighborhood}
                                            onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, companyNeighborhood: value })}
                                            invalid={() => false}
                                            viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                            disabled={occurrenceSituation == 'concluded'}
                                        />
                                        <MyPhoneInput
                                            label={translate('phone')}
                                            placeholder={translate('phone')}
                                            value={involvedPerson.companyPhoneNumber}
                                            onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, companyPhoneNumber: value })}
                                            invalid={() => false}
                                            viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                            labelStyle={{
                                                fontSize: 16
                                            }}
                                        />
                                    </View>

                                </GroupContainer>

                                <GroupContainer label={translate('otherUsedEquipments')}>
                                    <View style={styles.row}>
                                        <View style={[windowInfo.isMobileOrTablet ? { width: '100%' } : {}]}>
                                            <FilterSwitch
                                                value={involvedPerson.usedFireGun}
                                                label={translate('fireGun')}
                                                onChange={(value) => {
                                                    setInvolvedPerson({ ...involvedPerson, usedFireGun: Boolean(value) });
                                                }}
                                                direction='column'
                                                disabled={occurrenceSituation == 'concluded'}
                                            />
                                        </View>
                                        <View style={[windowInfo.isMobileOrTablet ? { width: '100%' } : {}]}>
                                            <FilterSwitch
                                                value={involvedPerson.usedBat}
                                                label={translate('bat')}
                                                onChange={(value) => {
                                                    setInvolvedPerson({ ...involvedPerson, usedBat: Boolean(value) });
                                                }}
                                                direction='column'
                                                disabled={occurrenceSituation == 'concluded'}
                                            />
                                        </View>
                                        <View style={[windowInfo.isMobileOrTablet ? { width: '100%' } : {}]}>
                                            <FilterSwitch
                                                value={involvedPerson.usedElectricGun}
                                                label={translate('electricGun')}
                                                onChange={(value) => {
                                                    setInvolvedPerson({ ...involvedPerson, usedElectricGun: Boolean(value) });
                                                }}
                                                direction='column'
                                                disabled={occurrenceSituation == 'concluded'}
                                            />
                                        </View>
                                        <View style={[windowInfo.isMobileOrTablet ? { width: '100%' } : {}]}>
                                            <FilterSwitch
                                                value={involvedPerson.usedSpreaderGas}
                                                label={translate('spreaderGas')}
                                                onChange={(value) => {
                                                    setInvolvedPerson({ ...involvedPerson, usedSpreaderGas: Boolean(value) });
                                                }}
                                                direction='column'
                                                disabled={occurrenceSituation == 'concluded'}
                                            />
                                        </View>
                                    </View>
                                </GroupContainer>

                                <GroupContainer label={translate('movedTo')}>
                                    <View style={styles.row}>
                                        <View style={[windowInfo.isMobileOrTablet ? { width: '100%' } : {}]}>
                                            <FilterSwitch
                                                value={involvedPerson.movedToFirstAidStation}
                                                label={translate('firstAidStation')}
                                                onChange={(value) => {
                                                    setInvolvedPerson({ ...involvedPerson, movedToFirstAidStation: Boolean(value) });
                                                }}
                                                direction='column'
                                                disabled={occurrenceSituation == 'concluded'}
                                            />
                                        </View>
                                        <View style={[windowInfo.isMobileOrTablet ? { width: '100%' } : {}]}>
                                            <FilterSwitch
                                                value={involvedPerson.movedToHospital}
                                                label={translate('hospital')}
                                                onChange={(value) => {
                                                    setInvolvedPerson({ ...involvedPerson, movedToHospital: Boolean(value) });
                                                }}
                                                direction='column'
                                                disabled={occurrenceSituation == 'concluded'}
                                            />
                                        </View>
                                        <View style={[windowInfo.isMobileOrTablet ? { width: '100%' } : {}]}>
                                            <FilterSwitch
                                                value={involvedPerson.movedToLegalMedicalInstitute}
                                                label={translate('legalMedicalInstitute')}
                                                onChange={(value) => {
                                                    setInvolvedPerson({ ...involvedPerson, movedToLegalMedicalInstitute: Boolean(value) });
                                                }}
                                                direction='column'
                                                disabled={occurrenceSituation == 'concluded'}
                                            />
                                        </View>
                                        <View style={[windowInfo.isMobileOrTablet ? { width: '100%' } : {}]}>
                                            <FilterSwitch
                                                value={involvedPerson.movedToPoliceStation}
                                                label={translate('policeStation')}
                                                onChange={(value) => {
                                                    setInvolvedPerson({ ...involvedPerson, movedToPoliceStation: Boolean(value) });
                                                }}
                                                direction='column'
                                                disabled={occurrenceSituation == 'concluded'}
                                            />
                                        </View>
                                    </View>
                                </GroupContainer>


                                <View style={[styles.row, { justifyContent: 'flex-end' }]}>
                                    <FormActions
                                        onSubmit={() => {
                                            onSubmit();
                                            setModalVisible(false);
                                        }}
                                        onClose={() => setModalVisible(false)}
                                        disabled={!isValidForm()} />
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const stylesheet = createStyleSheet(theme => ({
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalBody: {
        backgroundColor: theme.colors.container.background,
        flexDirection: 'column',
        borderRadius: 8,
        rowGap: 20,
        minWidth: 300,
        minHeight: 300,
        maxHeight: 680,
        padding: 30,
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        columnGap: 30,
        minHeight: 'auto',
        rowGap: 20,
    },
    inputLabel: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },
}));
