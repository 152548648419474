import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import { AlreadyActiveUnitWithName, gcmAgentService } from '../../../services/central-api/gcm-agent';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import moment from 'moment';
import { useStyles, createStyleSheet } from 'react-native-unistyles';
import CustomButton from '../../../components/CustomButton';
import FinishEarlyModal from './FinishEarlyModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export default function AgentUnits() {
    const { styles } = useStyles(styleSheet);

    const [units, setUnits] = useState<SimplifiedUnit[]>([]);
    const [unitId, setUnitId] = useState<number>(0);
    const [unitPickerOpen, setUnitPickerOpen] = useState(false);
    const [earlyFinishModal, setEarlyFinishModal] = useState(false);
    const [reason, setReason] = useState<string>('');
    const [alreadyActive, setAlreadyActive] = useState(false);

    const [assignments, setAssignments] = useState<DropdownResource[]>([]);
    const [assignmentId, setAssignmentId] = useState<number>(0);
    const [assignmentPickerOpen, setAssignmentPickerOpen] = useState(false);

    async function getPageInformation() {
        try {
            const units = await gcmAgentService.getAgentUnits();
            setUnits(units);
            const assignments = await gcmAgentService.getAssignments();
            setAssignments(assignments);
            const agentActive = await gcmAgentService.getAgentActiveUnit();
            if (agentActive.unit) {
                setUnitId(agentActive.unit.id);
                setAlreadyActive(true);
            }
            if (agentActive.assignment) {
                setAssignmentId(agentActive.assignment.id);
            }

        } catch (err) {
            console.error(err);
        }
    }

    async function setActiveUnit() {
        try {
            if (alreadyActive) {
                await gcmAgentService.setAgentActiveAssignmentUnit({ assignmentId });
            } else {
                await gcmAgentService.setAgentActiveUnit({ unitId, assignmentId });
            }
            setAlreadyActive(true);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });

        } catch (err) {
            if (err instanceof AlreadyActiveUnitWithName) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message, { actingBody: err.actingBody }),
                    visibilityTime: 10000,
                });
            } else if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function checkResetUnit() {
        const unitToLeave = units.find(unit => unit.id == unitId);
        if (unitToLeave && moment(unitToLeave.estimatedFinish).toDate().getTime() > moment().toDate().getTime()) {
            setEarlyFinishModal(true);
        } else {
            await resetUnit();
        }
    }

    async function resetUnit() {
        try {
            await gcmAgentService.resetAgentActiveUnit(reason);
            setUnitId(0);
            setAssignmentId(0);
            setAlreadyActive(false);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('SUCCESS_RESET_UNIT_FOR_AGENT'),
            });
            getPageInformation();

        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }

    }

    function alertLateActivatedUnit() {
        const foundUnit = units.find(unit => unit.id === unitId);
        if (!foundUnit || foundUnit.OccurrenceUnits.length) {
            return null;
        }

        const timeDiff = moment().diff(moment(foundUnit.estimatedFinish), 'minutes');

        if (timeDiff < 0) {
            return null;
        }

        const message = timeDiff > 0 && timeDiff <= 30
            ? translate('lateActivatedUnitMessage', { minutes: 30 - timeDiff })
            : translate('lateActivatedUnitMessageWithoutTime');

        return (
            <View style={styles.lateActivatedBox}>
                <FontAwesomeIcon icon={faExclamationCircle} fontSize={22} color="#B8401B" />
                <MyAppText style={styles.lateActivatedText}>{message}</MyAppText>
            </View>
        );
    }

    function isFormValid() {
        return unitId && assignmentId;
    }

    useEffect(() => {
        getPageInformation();
    }, []);

    return (
        <View style={styles.container}>
            <View style={{ justifyContent: 'flex-end' }}>
                <CustomButton
                    text={translate('RESET_UNIT_FOR_AGENT')}
                    textStyle={alreadyActive ? { color: '#FE0127' } : {}}
                    onPress={() => checkResetUnit()}
                    disabled={!alreadyActive}
                    style={alreadyActive ? { borderColor: '#FE0127' } : {}}
                    type={alreadyActive ? 'secondary' : undefined}
                />
            </View>
            <View style={styles.cardList}>
                <View style={{ rowGap: 20, zIndex: 2 }}>
                    <View style={{ zIndex: 3, rowGap: 5 }}>
                        <MyAppText>{translate('team')}</MyAppText>
                        <MyDropDownPicker
                            open={unitPickerOpen}
                            setOpen={setUnitPickerOpen}
                            value={unitId}
                            setValue={(value) => {
                                const selectedUnitId = value(unitId);
                                const selectedUnit = units.find(unit => unit.id == selectedUnitId);
                                setUnitId(selectedUnitId);

                                if (selectedUnit) {
                                    const selectedAssignment = selectedUnit.UnitUsers.find(user => user.UnitUser.unitId == selectedUnit.id);
                                    if (selectedAssignment && selectedAssignment.UnitUser.assignmentId) {
                                        setAssignmentId(selectedAssignment.UnitUser.assignmentId);
                                    }
                                }
                            }}

                            items={units.map((item: SimplifiedUnit) => {
                                return {
                                    label: `${item.unitName.name} - ${moment(item.estimatedStart).format('DD/MM/YYYY HH:mm')}`, value: item.id
                                };
                            })}
                            searchable={true}
                            disabled={alreadyActive}
                        />
                    </View>
                    <View style={{ zIndex: 2, rowGap: 5 }}>
                        <MyAppText>{translate('assignment')}</MyAppText>
                        <MyDropDownPicker
                            open={assignmentPickerOpen}
                            setOpen={setAssignmentPickerOpen}
                            value={assignmentId}
                            setValue={setAssignmentId}
                            items={assignments.map((item: DropdownResource) => {
                                return {
                                    label: item.name, value: item.id
                                };
                            })}
                            searchable={true}
                        />
                    </View>
                </View>
                {alertLateActivatedUnit()}
                <CustomButton
                    text={translate('save')}
                    onPress={() => setActiveUnit()}
                    disabled={!isFormValid()}
                />
            </View>
            <FinishEarlyModal
                reason={reason}
                setReason={setReason}
                isModalVisible={earlyFinishModal}
                setModalVisible={setEarlyFinishModal}
                onClose={() => {
                    resetUnit();
                    setReason('');
                }}
            />
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    row: {
        flexDirection: 'column',
        columnGap: 30,
        justifyContent: 'flex-end'
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.container.border,
        backgroundColor: theme.colors.container.background,
        padding: 10,
        flex: 1,
        gap: 20
    },
    lateActivatedBox: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: '#B8401B',
        backgroundColor: theme.colors.container.background,
        padding: 10,
        maxHeight: 100,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10
    },
    lateActivatedText: {
        color: '#B8401B',
        fontSize: 16,
        fontWeight: 'bold'
    }
}));
