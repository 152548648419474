import { PaginatedResource } from '../../typings/Paginated';
import { translate } from '../translate';
import { BaseService, ClientError, GenericPaginatedParameters, InvalidValue, NotFound, PartialBy, Unauthorized, UnexpectedError } from './base-service';


interface UpdateCameraParameters {
    title?: string;
    address?: string;
    latitude?: number;
    longitude?: number;
    tags?: number[];
    alertGroupId?: number;
}

export interface GetCamerasPaginatedParameters extends GenericPaginatedParameters {
    offline?: boolean;
    showDisabled: boolean;
    hideChildren: boolean;
    type?: CameraType;
    integrationType?: IntegrationType;
}

export interface GetCamerasParameters {
    tags?: number[];
    installationCompanyId?: number;
    internetCompanyId?: number;
    online?: boolean;
    offline?: boolean;
    hasLpr?: boolean;
    hasFacial?: boolean;
    isPinned?: boolean;
    beginIn?: number;
    endIn?: number;
    orderBy?: string;
    sortOrder?: string;
    hideChildren?: boolean;
    types?: CameraType[];
}

export interface GetCamerasMapParameters {
    tags?: number[];
    online?: boolean;
    offline?: boolean;
    types?: CameraType[];
    integrationType?: IntegrationType[];
}

export enum MosaicPreset {
    '4_PRESET' = '4',
    '8_PRESET' = '8',
    '9_PRESET' = '9',
    '10_PRESET' = '10',
    '13_PRESET' = '13',
    '16_PRESET' = '16',
    '18_PRESET' = '18',
    '27_PRESET' = '27',
    '36_PRESET' = '36',
}

export type MosaicCamera = (Camera & { position: number; });

export interface GetPaginatedVideoAnalysisFilters extends GenericPaginatedParameters {
    status?: string;
    startDate?: number;
    endDate?: number;
    requesterUserId?: number;
}

interface AddVideoAnalysis {
    prompt: string;
    msStart: number;
    msFinish: number;
}

class CameraService extends BaseService {

    async getCameras(params: GetCamerasParameters): Promise<DashboardCamera[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/cameras${this.encodeQueryParams({
            ...params,
            showDeleted: false,
            tags: params.tags?.join(',')
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getMosaics(params: GenericPaginatedParameters): Promise<PaginatedResource<Mosaic>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dashboards${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getCamerasSimplified(params: GetCamerasParameters): Promise<AccessSimplifiedCameraData[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/cameras/simplified${this.encodeQueryParams({
            ...params,
            showDeleted: false,
            tags: params.tags?.join(',')
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        const cameraList = await res.json();

        return cameraList.map((camera: AccessSimplifiedCameraData) => {
            if (!camera.tagList) return camera;

            //We do not want to filter on the back, but it must be done somewhere
            camera.tagList = JSON.parse(String(camera.tagList));
            return camera;
        });
    }

    async getCamerasPaginated(params: GetCamerasPaginatedParameters): Promise<PaginatedResource<PaginatedCamera>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/cameras/paginated${this.encodeQueryParams({
            limit: params.limit,
            page: params.page,
            textFilter: params.textFilter,
            offline: params.offline,
            showDisabled: params.showDisabled,
            hideChildren: params.hideChildren,
            type: params.type,
            integrationType: params.integrationType
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async downloadCameraReport(offline: boolean, showDisabled: boolean) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/cameras-report${this.encodeQueryParams({
            offline,
            showDisabled,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })}`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                serialNo: translate('serialNo'),
                title: translate('title'),
                internetType: translate('internetType'),
                createdAt: translate('createdAt'),
                status: translate('status'),
                type: translate('type'),
                tags: translate('tags'),
                installationCompany: translate('installationCompany'),
                internetCompany: translate('internetCompany'),
                disabled: translate('disabled'),
                pinned: translate('pinned'),
                panoramic: translate('panoramic'),
                fiber: translate('fiber'),
                latitude: translate('latitude'),
                longitude: translate('longitude')
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.blob();
    }

    async getTimeline(id: string): Promise<CameraRecordList> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${id}/timeline`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getPresets(cameraId: string): Promise<Preset[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${cameraId}/ptz/presets`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async savePreset({ cameraId, preset }: { cameraId: string, preset: Preset; }): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${cameraId}/ptz/preset`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                id: preset.id,
                name: preset.name
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async goToPreset({ cameraId, presetId }: { cameraId: string; presetId: string; }): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${cameraId}/ptz/goto-preset`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                id: presetId,
                speed: 0.01
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async watchingPtzCamera({ cameraId }: { cameraId: string; }): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${cameraId}/ptz/watching`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async moveCamera({ cameraId, movement, speed }: { cameraId: string, movement: keyof PtzMovementList, speed: number; }): Promise<void> {
        const token = await this.getToken();

        const movementList: PtzMovementList = {
            up: { x: 0, y: speed, zoom: 0 },
            down: { x: 0, y: -speed, zoom: 0 },
            right: { x: speed, y: 0, zoom: 0 },
            rightUp: { x: speed, y: speed, zoom: 0 },
            rightDown: { x: speed, y: -speed, zoom: 0 },
            left: { x: -speed, y: 0, zoom: 0 },
            leftUp: { x: -speed, y: speed, zoom: 0 },
            leftDown: { x: -speed, y: -speed, zoom: 0 },
            zoomIn: { x: 0, y: 0, zoom: speed },
            zoomOut: { x: 0, y: 0, zoom: -speed },
        };

        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${cameraId}/ptz/move`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(movementList[movement])
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    /**
     *
     * @throws {ClientError} RtspAlreadyRegistered | SerialNoAlreadyRegistered | RtspCameraMustHaveAnAddress
     * @returns
     */
    async updateCamera(id: string, data: UpdateCameraParameters): Promise<Camera> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${id}`, {
            method: 'PATCH',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(data)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async getCamera(id: string): Promise<Camera> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async getCamerasMap(params: GetCamerasMapParameters): Promise<CameraMap[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/cameras/map${this.encodeQueryParams({
            ...params,
            tags: params.tags?.join(',')
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async silenceCamera(cameraId: string) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${cameraId}/silence`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getDownloads(params: GenericPaginatedParameters): Promise<PaginatedResource<Download>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/downloads/paginated${this.encodeQueryParams({
            ...params,
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getDownload(id: number): Promise<{ resultUrl: string; }> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/camera-manager/download/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createCameraDownload({ cameraId, name, msStart, msFinish, tags }: { cameraId: string; name: string; msStart: number; msFinish: number; tags?: number[]; }) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${cameraId}/download`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({
                name, msStart, msFinish, tags
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 406) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getMosaicCameras(id: string): Promise<MosaicCamera[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dashboard/${id}/cameras`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }
        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getMosaic(id: string): Promise<Mosaic> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dashboard/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async createMosaic(params: { title: string, camerasToAdd: string[]; preset: MosaicPreset; }): Promise<Mosaic> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dashboard`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateMosaic(dashboard: Mosaic, camerasToAdd: string[]): Promise<Mosaic> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dashboard/${dashboard.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ title: dashboard.title, preset: dashboard.preset, camerasToAdd })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'InvalidValue') {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteMosaic(id: string) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dashboard/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async changeMosaicCamerasPosition(id: string, params: { cameraId: string; position: number; }) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dashboard/${id}/change-position`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }
    }

    async getPointOfInterests(): Promise<PointOfInterest[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/points-of-interest`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createPointOfInterest(params: Omit<PointOfInterest, 'id'>): Promise<PointOfInterest> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/point-of-interest`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updatePointOfInterest(params: PartialBy<PointOfInterest, 'id'>): Promise<PointOfInterest> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/point-of-interest/${params.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deletePointOfInterest(id: number): Promise<void> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/point-of-interest/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getCameraTagsSimplified(): Promise<BaseCameraTag[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/tags-simplified`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getCameraTags(params: GenericPaginatedParameters): Promise<PaginatedResource<CameraTag>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/paginated-tags${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createCameraTag(params: CreateTag): Promise<CameraTag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/tag`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateCameraTag(params: PartialBy<BaseCameraTag, 'id'>): Promise<CameraTag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/tag/${params.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteCameraTag(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/tag/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getDownloadTags(params: GenericPaginatedParameters): Promise<PaginatedResource<DownloadTag>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/downloads/tags${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedDownloadTags(): Promise<DownloadTag[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/downloads/simplified-tags`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createDownloadTag(params: CreateTag): Promise<Tag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/downloads/tag`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateDownloadTag(params: PartialBy<BaseDownloadTag, 'id'>): Promise<DownloadTag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/downloads/tag/${params.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteDownloadTag(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/downloads/tag/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    /** Video Analysis */

    async getPaginatedVideoAnalysis(filters: GetPaginatedVideoAnalysisFilters): Promise<PaginatedResource<VideoAnalysis>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/video-analysis/paginated${this.encodeQueryParams({
            ...filters
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status === 401) {
            throw new Unauthorized();
        }

        if (res.status !== 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getVideoAnalysis(id: number): Promise<VideoAnalysis> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/video-analysis/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status === 401) {
            throw new Unauthorized();
        }

        if (res.status !== 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createVideoAnalysis(id: string, body: AddVideoAnalysis): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${id}/video-analysis`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(body)
        });

        if (res.status === 401) {
            throw new Unauthorized();
        }

        if (res.status !== 200) {
            throw new UnexpectedError();
        }

        return;
    }

    async getUsers(): Promise<SimplifiedUser[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/users`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async searchCameras({ textFilter }: { textFilter?: string; }): Promise<SimplifiedCamera[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/camera-manager/cameras/search${this.encodeQueryParams({ textFilter })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createMaintenanceRequest(id: string, body: { reason: string; }): Promise<MaintenanceRequest> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${id}/maintenance-request`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(body)
        });

        if (res.status == 400) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status === 401) {
            throw new Unauthorized();
        }

        if (res.status !== 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createEmergencyMaintenanceRequest(id: string, body: { reason: string; }): Promise<MaintenanceRequest> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/camera-manager/camera/${id}/emergency-maintenance-request`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(body)
        });

        if (res.status == 400) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status === 401) {
            throw new Unauthorized();
        }

        if (res.status !== 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAlertGroups(params: GenericPaginatedParameters): Promise<PaginatedResource<AlertGroupList>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alert-groups${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async createAlertGroup(alertGroup: Omit<AlertGroup, 'id'>): Promise<AlertGroup> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alert-group`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(alertGroup)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateAlertGroup(alertGroup: PartialBy<AlertGroup, 'id'>): Promise<AlertGroup> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alert-group/${alertGroup.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(alertGroup)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAlertGroup(id: number): Promise<Omit<AlertGroup, 'cameras'> & { cameras: AlertGroupSimplifiedCamera[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alert-group/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteAlertGroup(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alert-group/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }
}

export const cameraService = new CameraService();
